import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Site } from 'app/shared/models';

@Component({
  selector: 'app-site-viewer-header',
  templateUrl: './site-viewer-header.component.html',
  styleUrls: ['../site-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteViewerHeaderComponent implements OnInit {

  @Input() site: Site;

  constructor() { }

  ngOnInit() { }

  get addressSite() {
    if (this.site.siteInfo.addressSite && this.site.siteInfo.addressSite.formattedAddress) {
      return `${this.site.siteInfo.addressSite.formattedAddress}`;
    }
    if (this.site.siteInfo.addressSite &&
      this.site.siteInfo.addressSite.city && this.site.siteInfo.addressSite.country && this.site.siteInfo.addressSite.country.name) {
      return `${this.site.siteInfo.addressSite.city}, ${this.site.siteInfo.addressSite.country.name}`;
    }
    return this.site.siteInfo.addressSite.city;
  }

  get siteType() {
    return this.site.siteInfo.type;
    // return Object.keys(this.site.siteInfo.type).slice(this.site.siteInfo.type.length / 2);
  }
  get pictureUrl() {
    return this.site.siteInfo.pictureUrl;
  }

  get logoUrl() {
    return this.site.siteInfo.logoUrl;
  }
}
