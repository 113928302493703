import { Directive, ElementRef, Input } from '@angular/core';
import shave, { IShaveOptions } from 'shave';

// usages
// app-shave [shaveMaxHeight]="25"
// [app-shave] = "{ character: '👀' }"[shaveMaxHeight] = '125';

@Directive({
  selector: '[app-shave]',
})
export class ShaveDirective {
  private _shaveOptions: IShaveOptions = {};
  private _maxHeight = 100;

  @Input()
  set shave(val) {
    if (val) {
      this._shaveOptions = val;
    }
    this._runShave();
  }

  @Input()
  set shaveMaxHeight(val) {
    if (val) {
      this._maxHeight = val;
    }
    this._runShave();
  }

  constructor(private ele: ElementRef) {}

  private _runShave() {
    shave(this.ele.nativeElement, this._maxHeight, this._shaveOptions);
  }
}
