import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pubmedUrl'
})
export class PubmedUrlPipe implements PipeTransform {

  transform(pmid: any, ...args: any[]): any {
    return ('https://www.ncbi.nlm.nih.gov/pubmed/' + pmid);
  }

}
