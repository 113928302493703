import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-typing-carousel',
  templateUrl: './simple-typing-carousel.component.html',
  styleUrls: ['./simple-typing-carousel.component.scss'],
})
export class SimpleTypingCarouselComponent implements OnInit {
  @Input() introText: string;
  @Input() toRotate: string[];
  @Input() period = 1200;

  constructor() {}

  el: HTMLElement;
  loopNum = 0;
  txt = '';
  isDeleting = false;

  ngOnInit() {
    this.el = document.getElementById('txtRotate');
    this.tick();
  }

  tick() {
    const i = this.loopNum % this.toRotate.length;
    const fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    let delta = 300 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(() => {
      this.tick();
    }, delta);
  }
}

// for multiple spans..

// import { Component, Input, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-simple-typing-carousel',
//   templateUrl: './simple-typing-carousel.component.html',
//   styleUrls: ['./simple-typing-carousel.component.scss'],
// })
// export class SimpleTypingCarouselComponent implements OnInit {
//   @Input() introText: string;
//   constructor() {}

//   toRotate;
//   el;
//   loopNum = 0;
//   period: number;
//   txt = '';
//   isDeleting = false;

//   ngOnInit() {
//     this.onload();
//   }

//   TxtRotate(el, toRotate, period) {
//     this.toRotate = toRotate;
//     this.el = el;
//     this.loopNum = 0;
//     this.period = parseInt(period, 10) || 2000;
//     this.txt = '';
//     this.tick();
//     this.isDeleting = false;
//   }

//   public tick() {
//     var i = this.loopNum % this.toRotate.length;
//     var fullTxt = this.toRotate[i];

//     if (this.isDeleting) {
//       this.txt = fullTxt.substring(0, this.txt.length - 1);
//     } else {
//       this.txt = fullTxt.substring(0, this.txt.length + 1);
//     }

//     this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

//     // const that = this;
//     var delta = 300 - Math.random() * 100;

//     if (this.isDeleting) {
//       delta /= 2;
//     }

//     if (!this.isDeleting && this.txt === fullTxt) {
//       delta = this.period;
//       this.isDeleting = true;
//     } else if (this.isDeleting && this.txt === '') {
//       this.isDeleting = false;
//       this.loopNum++;
//       delta = 500;
//     }

//     setTimeout(() => {
//       this.tick();
//     }, delta);
//   }

//   onload() {
//     var elements = document.getElementsByClassName('txt-rotate');
//     for (var i = 0; i < elements.length; i++) {
//       var toRotate = elements[i].getAttribute('data-rotate');
//       var period = elements[i].getAttribute('data-period');
//       if (toRotate) {
//         this.TxtRotate(elements[i], JSON.parse(toRotate), period);
//       }
//     }
//     // INJECT CSS
//     var css = document.createElement('style');
//     css.type = 'text/css';
//     css.innerHTML = '.txt-rotate > .wrap { border-right: 0.08em solid #666 }';
//     document.body.appendChild(css);
//   }
// }
