import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Site } from 'app/shared/models';
import { Observable, combineLatest } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { SiteService } from 'app/core/site.service';
import { AuthService } from 'app/core/auth.service';
import { MapService } from 'app/map/map.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { Idea } from 'app/groups/project-idea/models/idea';
import { LatLng } from 'app/stepper/job/model/latLng';
import { MarkerIcon } from 'app/map/map.models';
import { ScientistUser } from 'app/shared/models/user/scientistUser';
import { acaConfig } from 'aca-config';

declare const google: any;
declare const MarkerClusterer: any;
declare const OverlappingMarkerSpiderfier: any;
@Component({
  selector: 'app-specific-list-map',
  templateUrl: './specific-list-map.component.html',
  styleUrls: ['./specific-list-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificListMapComponent implements AfterViewInit, OnChanges {
  @Input() memberUid$: ScientistUser[];
  @Input() siteUid$: Observable<MondoUser[]>;
  @Input() idea: Idea;
  @ViewChild('infoWindowContainer', { read: ViewContainerRef, static: false })
  infoWindowContainer: ViewContainerRef;
  @ViewChild('userCard', { static: true }) userCardTemplate: TemplateRef<any>;
  @ViewChild('siteCard', { static: true }) siteCardTemplate: TemplateRef<any>;
  @ViewChild('ideaCard', { static: true })
  ideaCardTemplate: TemplateRef<any>;

  markersStyles;
  clusterOptions = MapService.clusterOptions;
  mapOptions = MapService.mapOptions;
  spiderOptions = MapService.spiderOptions;

  map: any;
  oms: any;
  markerClusterer: any;
  infoWindow;

  siteMarkers;
  memberMarkers;

  memberSiteList$: Observable<Site[]>;
  memberSiteList: Site[];

  constructor(
    private siteService: SiteService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private mapService: MapService
  ) {}

  async ngAfterViewInit() {
    await this.mapService.load();
    await this.initMap();
    this.loadSites();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.memberUid$.previousValue &&
      changes.memberUid$.previousValue.length
    ) {
      this.clearMarkers(this.memberMarkers);
    }
    if (changes.memberUid$.currentValue.length) {
      if (this.map) {
        this.loadMembers();
      } else {
        setTimeout(() => {
          this.loadMembers();
        }, 500);
      }
    }
  }

  async initMap() {
    const { Map } = await google.maps.importLibrary('maps');
    this.map = new Map(document.getElementById('map'), this.mapOptions);

    this.markerClusterer = new MarkerClusterer(
      this.map,
      [],
      this.clusterOptions
    );

    this.oms = new OverlappingMarkerSpiderfier(this.map, this.spiderOptions);

    google.maps.event.addListener(this.map, 'click', () => {
      this.closeWindow();
    });

    this.markersStyles = this.mapService.setupMarkerStyles();
  }

  showCVPreview(key: string): void {
    this.authService.showCVPreview(key);
  }

  showSitePreview(key: string): void {
    this.authService.showCompanyPreview(key);
  }

  loadMembers() {
    this.memberMarkers = [];
    this.memberUid$.forEach((result) => {
      const key = 'user';
      const mapStyle = this.markersStyles.u0Marker;
      const latLng = result.personalDetails.address.latLng;
      if (latLng && key && mapStyle) {
        const marker = this.createMarker(latLng, mapStyle, key, result);
        this.memberMarkers.push(marker);
      }
    });
    this.addMarkers(this.memberMarkers);
  }

  loadSites() {
    this.siteMarkers = [];
    this.memberSiteList$ = this.siteUid$.pipe(
      mergeMap((uids) =>
        combineLatest(
          uids.map((uid) => this.siteService.getSiteFromUserId(uid.uid))
        )
      ),
      tap((results) => {
        this.clearMarkers(this.siteMarkers);
        this.siteMarkers = [];
      }),
      tap((results) => {
        results.forEach((result) => {
          const key = 'site';
          const mapStyle = this.markersStyles.siteMarker;
          const latLng = result.siteInfo.addressSite.latLng;
          if (latLng && key && mapStyle) {
            const marker = this.createMarker(latLng, mapStyle, key, result);
            this.siteMarkers.push(marker);
          }
        });
      }),
      tap(() => {
        this.addMarkers(this.siteMarkers);
      })
    );
  }

  createMarker(
    latLng: LatLng,
    icon: MarkerIcon,
    markerDataKey: string,
    markerData: any
  ) {
    const marker = new google.maps.Marker({
      position: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
      icon: icon,
    });
    marker[markerDataKey] = markerData;
    google.maps.event.addListener(marker, 'spider_click', () => {
      this.openInfoWindow(marker);
    });

    return marker;
  }

  addMarkers(markers) {
    if (this.oms && this.markerClusterer && markers.length) {
      markers.forEach((marker) => {
        this.oms.addMarker(marker);
      });
      this.markerClusterer.addMarkers(markers, false);
    }
  }

  clearMarkers(markers) {
    if (this.oms && this.markerClusterer && markers.length) {
      this.markerClusterer.removeMarkers(markers);
      if (this.oms) {
        markers.forEach((marker) => {
          this.oms.removeMarker(marker);
        });
      }
    }
  }

  openInfoWindow(marker: any) {
    if (!this.infoWindow) {
      this.infoWindow = new google.maps.InfoWindow();
    }

    const content = this.getInfoWindowContent(marker);
    if (content) {
      this.infoWindow.setContent(content);
      this.infoWindow.open(this.map, marker);
      this.cdr.detectChanges();
    }
  }

  getInfoWindowContent(marker): HTMLDivElement {
    if (marker) {
      let cardTemplate;
      let context;

      if (marker.user && this.userCardTemplate) {
        cardTemplate = this.userCardTemplate;
        context = {
          $implicit: marker.user,
          user: marker.user,
        };
      } else if (marker.site && this.siteCardTemplate) {
        cardTemplate = this.siteCardTemplate;
        context = {
          $implicit: marker.site,
          site: marker.site,
        };
      } else if (marker.idea && this.ideaCardTemplate) {
        cardTemplate = this.ideaCardTemplate;
        context = {
          $implicit: marker.idea,
          idea: marker.idea,
        };
      }

      if (cardTemplate) {
        // Create the embedded view with the provided context
        const embeddedView = this.infoWindowContainer.createEmbeddedView(
          cardTemplate,
          context
        );
        const infoWindowContent = document.createElement('div');
        // Append the root nodes of the embedded view to the info window content
        embeddedView.rootNodes.forEach((node) => {
          infoWindowContent.appendChild(node);
        });

        return infoWindowContent;
      }
    }

    return null;
  }

  closeWindow() {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }
}
