import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthService } from 'app/core/auth.service';
import { ChatService } from 'app/core/chat.service';
import { ChatRoomDialogComponent } from 'app/shared-ui/chat/chat-room-dialog/chat-room-dialog.component';
import { MondoUser } from 'app/shared/models/user/mondoUser';

@Component({
  selector: 'app-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatButtonComponent {
  @Input() userId: string;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private chatService: ChatService
  ) {}

  get isOwner(): boolean {
    return this.user ? this.userId === this.user.uid : true;
  }

  get user(): MondoUser {
    return this.authService.getCurrentUser();
  }

  openChat() {
    const roomId = this.chatService.getRoomIdFromUser(this.userId);
    if (roomId) {
      const dialogRef = this.dialog.open(ChatRoomDialogComponent, {
        maxWidth: '100vw',
      });

      dialogRef.componentInstance.activeChatKey = roomId;

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }
}
