import { PubMedAuthor } from './pubmed';

export class Author {
  public static fromJson({ foreName, lastName, key, initials }): Author {
    return new Author(foreName, lastName, key, initials);
  }

  public static fromPubMed(author: PubMedAuthor): Author {
    return new Author(
      author.ForeName,
      author.LastName,
      author.ForeName + author.LastName + author.Initials + author['@ValidYN'],
      author.Initials
    );
  }

  constructor(
    public foreName = '',
    public lastName = '',
    public key = '',
    public initials = ''
  ) {}
}
