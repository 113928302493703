import { Component, OnInit, Input } from '@angular/core';
import { CvStepId } from '../../../stepper/cv/model/cv-steps';
import { CV } from '../../models/cv/cv';
import { Education } from '../../models/cv/education';
import { WorkExperience } from '../../models/cv/work-experience';
import { ResearchStay } from '../../models/cv/research-stay';

@Component({
  selector: 'app-cv-view-education-work-exp',
  templateUrl: './cv-view-education-work-exp.component.html',
  styleUrls: ['./cv-view-education-work-exp.component.scss'],
})
export class CvViewEducationWorkExpComponent implements OnInit {
  @Input() cv: CV;
  cvSteps = CvStepId;
  constructor() {}

  ngOnInit() {}

  get workAndEducations(): WorkEducationExperience[] {
    const workExp = this.cv ? this.cv.experience.workExperiences : [];
    const educaExp = this.cv ? this.cv.experience.educations : [];
    const researchExp = this.cv ? this.cv.experience.researches : [];
    const works = workExp.map(
      (work) =>
        new WorkEducationExperience(ExperienceType.work, work, work.jobTitle)
    );
    const educas = educaExp.map(
      (edu) =>
        new WorkEducationExperience(ExperienceType.education, edu, edu.eduTitle)
    );
    const research = researchExp.map(
      (edu) =>
        new WorkEducationExperience(
          ExperienceType.research,
          edu,
          edu.projectTitle
        )
    );
    const all = works.concat(educas).concat(research);
    return all.length > 0
      ? all.sort((a, b) => this.getTimeSortNumber(a.exp, b.exp))
      : null;
  }

  private getTimeSortNumber(
    expA: WorkExperience | Education | ResearchStay,
    expB: WorkExperience | Education | ResearchStay
  ): number {
    const aEndTime =
      expA && expA.isCurrent
        ? new Date(9000000000000).getTime() +
          (expA.startDate && expA.startDate.getTime())
        : expA.endDate
        ? expA.endDate.getTime()
        : expA.startDate
        ? expA.startDate.getTime()
        : -Infinity;
    // use Infinity, if things without dates to be on the top of the list
    const bEndTime =
      expB && expB.isCurrent
        ? new Date(9000000000000).getTime() +
          (expB.startDate && expB.startDate.getTime())
        : expB.endDate
        ? expB.endDate.getTime()
        : expB.startDate
        ? expB.startDate.getTime()
        : -Infinity;
    // use Infinity, if things without dates to be on the top of the list
    return bEndTime - aEndTime;
  }

  getEducation(workEdu: WorkEducationExperience): Education {
    return workEdu.type === ExperienceType.education
      ? (workEdu.exp as Education)
      : null;
  }

  getWork(workEdu: WorkEducationExperience): WorkExperience {
    return workEdu.type === ExperienceType.work
      ? (workEdu.exp as WorkExperience)
      : null;
  }

  getResearch(workEdu: WorkEducationExperience): ResearchStay {
    return workEdu.type === ExperienceType.research
      ? (workEdu.exp as ResearchStay)
      : null;
  }

  getEducationLine(workEdu: WorkEducationExperience): string {
    const edu = this.getEducation(workEdu);
    return edu && edu.line !== '' ? edu.line : null;
  }

  private getTitleLength(workEdu: WorkEducationExperience): number {
    const edutitle = this.getEducationLine(workEdu);
    let title = workEdu.title;
    if (edutitle) {
      title += edutitle;
    }
    return title.length;
  }

  extraLongTitleNoDesc(workEdu: WorkEducationExperience): boolean {
    const titleLength = this.getTitleLength(workEdu);
    return (
      workEdu &&
      workEdu.title &&
      !!workEdu.exp &&
      !workEdu.exp.description &&
      titleLength >= 63
    );
  }
  extraLongTitle(workEdu: WorkEducationExperience): boolean {
    const titleLength = this.getTitleLength(workEdu);
    return (
      workEdu &&
      workEdu.title &&
      !!workEdu.exp &&
      !!workEdu.exp.description &&
      titleLength >= 63
    );
  }
  longTitleNoDesc(workEdu: WorkEducationExperience): boolean {
    const titleLength = this.getTitleLength(workEdu);
    return (
      workEdu &&
      workEdu.title &&
      !!workEdu.exp &&
      !workEdu.exp.description &&
      titleLength >= 50 &&
      titleLength < 63
    );
  }
  longTitle(workEdu: WorkEducationExperience): boolean {
    const titleLength = this.getTitleLength(workEdu);
    return (
      workEdu &&
      workEdu.title &&
      !!workEdu.exp &&
      !!workEdu.exp.description &&
      titleLength >= 50 &&
      titleLength < 63
    );
  }
  mediumTitleNoDesc(workEdu: WorkEducationExperience): boolean {
    const titleLength = this.getTitleLength(workEdu);
    return (
      workEdu &&
      workEdu.title &&
      !!workEdu.exp &&
      !workEdu.exp.description &&
      titleLength > 30 &&
      titleLength < 50
    );
  }
  mediumTitle(workEdu: WorkEducationExperience): boolean {
    const titleLength = this.getTitleLength(workEdu);
    return (
      workEdu &&
      workEdu.title &&
      !!workEdu.exp &&
      !!workEdu.exp.description &&
      titleLength > 30 &&
      titleLength < 50
    );
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}

export enum ExperienceType {
  education = 'education',
  work = 'work',
  research = 'research',
}

export class WorkEducationExperience {
  constructor(
    public type: ExperienceType,
    public exp: WorkExperience | Education | ResearchStay,
    public title: string
  ) {}
}
