import { Injectable } from '@angular/core';
import { Job } from '../stepper/job/model/job';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { DatabaseHandlerService } from 'app/stepper/shared/services/database-handler.service';
import { AuthService } from 'app/core/auth.service';
import {
  catchError,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { IndustryUser } from 'app/shared/models/user/industryUser';
import { Company } from '../stepper/job/model/company';
import {
  combineLatest as observableCombineLatest,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { formatText, hardcodedValues } from 'hardcodedValues';
import { ScientistUser } from 'app/shared/models/user/scientistUser';
import { StatusContext } from 'app/stepper/shared/model/mondo-status/status-context';
import { TimePeriod } from '../stepper/job/model/timePeriod';
import { JobInfo } from '../stepper/job/model/jobInfo';
import { ResearchQualifications } from '../stepper/job/model/researchQualifications';
import { Languages } from 'app/shared/models/languages/languages';
import { Contact } from '../stepper/job/model/contact';
import { JobLinks } from '../stepper/job/model/JobLinks';
import { ResearchGroup } from '../stepper/job/model/researchGroup';
import { HelperService } from 'app/core/helper.service';
import { canSeeElement, isCVRValid } from '@helpers';
import {
  Category0,
  Category1,
  FieldOfInterest,
  IFilter,
  Site,
  Technique,
  UserFilter,
} from 'app/shared/models';
import { RoutingService } from 'app/core/routing.service';
import { RoutingModel } from 'app/app.routing-model';
import { AccountType } from 'app/shared/consts/accountType';
import { DAO } from 'app/shared-services/db-access/dao';
import { StorageService } from 'app/core/storage.service';
import { Upload } from 'app/shared/models';
import { acaConfig } from 'aca-config';
import { JobType, JobTypeAndDesc } from 'app/stepper/shared/model/job-type';
import { acaNames } from 'aca-names';

@Injectable()
export class JobService extends DatabaseHandlerService {
  private firstLoad = false;
  uploadsMarkedForDeletion: Upload[] = [];
  private jobTypeAndDesc: any[];
  constructor(
    public dao: DAO,
    authService: AuthService,
    fnsHelper: HelperService,
    private routingService: RoutingService,
    private storageService: StorageService
  ) {
    super(
      dao,
      authService,
      DataConstants.DRAFT_JOBS,
      DataConstants.USER_JOBS,
      DataConstants.PUBLISHED_JOBS,
      DataConstants.JOB_STATUS,
      fnsHelper
    );
    this.jobTypeAndDesc = this.initializeJobTypes();
  }

  initializeJobTypes(): JobTypeAndDesc[] {
    return Object.keys(JobType)
      .filter((key) => isNaN(Number(key)))
      .map((key, index) => ({
        key: key.charAt(0).toUpperCase() + key.slice(1),
        descKey: `${key}Desc`,
        value: index,
      }))
      .filter((jobType) => acaNames[jobType.key]);
  }

  async updateDraftJob(job: Job): Promise<void> {
    if (!this.firstLoad) {
      this.firstLoad = true;
      return;
    }
    await this.cleanDeletedAttachments();
    return this.update(job, Job.toJson);
  }

  jobExists(id: string): Promise<boolean> {
    return this.exists(id);
  }

  getPublishedJob(jobKey: string): Observable<Job> {
    return this.getItem<Job>(Job.fromJson, jobKey, true);
  }

  getNumberPublishedJobs(): Observable<number> {
    return this.getDraftJobs().pipe(
      map((jobs) => {
        const published = jobs.filter((job) => job.status.isPublished());
        return published.length;
      })
    );
  }

  canPublishJob(): Observable<boolean> {
    return this.getNumberPublishedJobs().pipe(
      map(
        (numberOfPublishedJobs) =>
          numberOfPublishedJobs < this.authService.allowedNumberOfJobs
      )
    );
  }

  getDraftJob(jobKey: string): Observable<Job> {
    return this.getItem<Job>(Job.fromJson, jobKey);
  }

  getDraftJobs(): Observable<Job[]> {
    return this.getDraftItems(Job.fromJson);
  }

  getPublishedJobs$(): Observable<Job[]> {
    // return this.dao.list$(DataConstants.PUBLISHED_JOBS, (ref) =>
    //   ref.orderByChild('madePublic')
    // );

    return this.dao
      .list(DataConstants.PUBLISHED_JOBS, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        mergeMap((jobIds) => {
          if (jobIds.length === 0) {
            return of([]);
          }
          return observableCombineLatest(
            jobIds.map((jobIdSnap) =>
              this.getItem<Job>(Job.fromJson, jobIdSnap.key)
            )
            // ).pipe(
            //   map((a: any) =>
            //     a
            //       .filter((b) => !!b)
            //       .sort((first, second) => (first.name < second.name ? -1 : 1))
            //   )
          );
        }),
        catchError((error) => {
          return throwError(new Error(error));
        }),
        shareReplay(1)
        // takeUntil(this.authService.userLogged)
      );
  }

  private loadJob(key: string) {
    this.routingService.navigateToRoute(RoutingModel.jobBuilder.route, [
      key,
      0,
    ]);
  }

  getRelatedJobIds$(resultId: string) {
    return resultId
      ? this.dao.object$(DataConstants.JOB_LIST + resultId).pipe(
          map((ids) => (ids ? Object.keys(ids).map((id) => id) : [])),
          filter((id) => id.length !== 0)
        )
      : of([]);
  }

  private updateJobIdInListOfJobs(job: Job, active = true) {
    const parentId = job.company.uid;
    const jobId = job.key;
    if (parentId && jobId) {
      return this.dao
        .object(DataConstants.JOB_LIST + parentId + '/' + jobId)
        .set(active ? active : {});
    }
  }

  unpublishJob(job: Job) {
    this.updateJobIdInListOfJobs(job, false);
    return this.unpublish(job);
  }

  createJobFromSite(site: Site) {
    if (
      this.authService.canCreateJobFromSite ||
      this.authService.getCurrentUser().uid === site.ownerId
    ) {
      const { nameSite, addressSite, pictureUrl, email, phone, coverUrl, cvr } =
        site.siteInfo;
      const {
        interests,
        category0,
        category1,
        category2,
        category3,
        category4,
        category5,
        techniques,
      } = site.experience;
      const companyDetails = new Company(
        site.key,
        nameSite,
        cvr,
        email,
        2,
        addressSite,
        pictureUrl,
        coverUrl
      );
      const researchQualificationDetails = new ResearchQualifications(
        interests,
        category0,
        category1,
        category2,
        category3,
        category4,
        category5,
        techniques
      );
      const contactInfo = new Contact(
        '',
        new JobLinks(),
        new ResearchGroup(),
        email,
        phone
      );

      this.createJob(
        companyDetails,
        researchQualificationDetails,
        contactInfo
      ).then((jobkey) => {
        this.loadJob(jobkey);
      });
    }
  }

  private getFormattedDate(today?: Date): string {
    const date = today ? today : new Date();
    switch (hardcodedValues.dateFormat) {
      case 'en-US':
        return `${date.getMonth() + 1}/${date.getDate()}-${date.getFullYear()}`;
      case 'da-DK':
        return `${date.getDate()}/${date.getMonth() + 1}-${date.getFullYear()}`;
      default:
        return `${date.getMonth() + 1}/${date.getDate()}-${date.getFullYear()}`;
    }
  }

  async createJob(
    initCompanyDetails?: Company,
    initResearchQualifications?: ResearchQualifications,
    initContact?: Contact
  ): Promise<string> {
    const jobName = `${
      initCompanyDetails
        ? initCompanyDetails.name
        : hardcodedValues.newJobPosition
    } ${this.getFormattedDate()}`;

    const jobType = this.jobTypes.length === 1 ? this.jobTypes[0].value : null;
    const job = new Job(
      new StatusContext(),
      jobName,
      '',
      '',
      null,
      null,
      null,
      new TimePeriod(new Date(), null, true),
      new JobInfo('', '', '', '', '', '', '', jobType),
      [],
      initCompanyDetails ? initCompanyDetails : this.initialCompanyDetails,
      initResearchQualifications
        ? initResearchQualifications
        : new ResearchQualifications(),
      new Languages(),
      new TimePeriod(),
      initContact ? initContact : this.initContact,
      false
    );
    return this.createItemAnStatus(job, Job.toJson);
  }

  get jobTypes(): JobTypeAndDesc[] {
    return this.jobTypeAndDesc;
  }

  get initContact(): Contact {
    if (this.authService.isScientist) {
      const scientist = this.authService.getCurrentUser() as ScientistUser;
      if (scientist.personalDetails && scientist.personalDetails.contactInfo) {
        const { phone, email } = scientist.personalDetails.contactInfo;
        return new Contact(
          '',
          new JobLinks(),
          new ResearchGroup(),
          email,
          phone
        );
      }
      // } else if (this.authService.isCompany) {
      //   const industry = this.authService.getCurrentUser() as IndustryUser;
      //   if (industry.)
      //   return new Contact('', new JobLinks(), new ResearchGroup(), '', '');
    } else {
      return new Contact();
    }
  }

  get initialCompanyDetails(): Company {
    if (acaConfig.fillCompanyInfoFromProfileInJobPost) {
      if (this.authService.isScientist) {
        const scientist = this.authService.getCurrentUser() as ScientistUser;
        if (scientist.personalDetails) {
          const { companyName, address, pictureUrl, coverUrl } =
            scientist.personalDetails;
          const { email } = scientist.personalDetails.contactInfo;
          return new Company(
            scientist.publishedCv,
            companyName,
            '',
            email,
            2,
            address,
            pictureUrl,
            coverUrl
          );
        }
      } else if (this.authService.isCompany) {
        const industry = this.authService.getCurrentUser() as IndustryUser;
        return industry.company ? industry.company : new Company();
      } else {
        return new Company();
      }
    } else {
      return new Company();
    }
  }

  private async removeFileFromStorage(index, arr: Upload[]) {
    await this.storageService
      .deleteFile(arr[index].path, true)
      .toPromise()
      .then(() => {
        arr.splice(index, 1);
      })
      .catch((e) => {
        if (this.uploadsMarkedForDeletion.length !== 0) {
          arr.splice(index, 1);
        }
      });
  }

  cleanDeletedAttachments() {
    this.uploadsMarkedForDeletion.forEach(async (val, index, arr) => {
      await this.removeFileFromStorage(index, arr);
    });

    this.uploadsMarkedForDeletion = [];
  }

  async removeJob(id: string): Promise<void> {
    const jobProfilePath = `job/${id}/${id}`;
    const imgLinkPath = `job/${id}/imageLink/imageLink`;
    this.storageService.deleteFile(jobProfilePath).toPromise();
    this.storageService.deleteFile(imgLinkPath).toPromise();
    await this.cleanDeletedAttachments(); // run all uploads and delete each!!!!
    return this.removeItemAndStatus(id);
  }

  duplicateJob(job: Job) {
    this.duplicateItemAndStatus(job, Job.toJson);
  }

  public getNumberOfCurrentApplicants(jobId: string) {
    return this.getCurrentApplicants(DataConstants.JOB_APPLICANTS + jobId);
  }

  public minRequirementForPublish(job: Job): string {
    let text = '';
    if (!this.hasCompanyName(job)) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.CompanyName
      )}\n`;
    }
    if (!this.hasTitle(job)) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.titleOfThePosition
      )}\n`;
    }
    if (!this.hasType(job)) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.JobType
      )}\n`;
    }
    if (!this.hasFoi(job)) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.Foi
      )}\n`;
    }
    // if (!this.hasTech(job)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.Tech
    //   )}\n`;
    // }
    if (!this.hasCVR(job) && hardcodedValues.registerCVRONSites) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.cvr
      )}\n`;
    }
    if (!isCVRValid(job.company.cvr) && hardcodedValues.registerCVRONSites) {
      text += `⛔ ${hardcodedValues.cvr} ${hardcodedValues.invalid}\n`;
    }
    if (!this.hasEmail(job) && hardcodedValues.registerVisitFunctions) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.applicationEmail
      )}\n`;
    }
    // if (!this.hasApplicationLink(job)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.URLToTheOfficialJobApplicationForm
    //   )}\n`;
    // }
    return text;
  }

  private hasCVR(job: Job): boolean {
    return job.company.cvr ? job.company.cvr.length === 8 : false;
  }

  private hasTitle(job: Job): boolean {
    return !!job.jobInfo.position.trim();
  }

  private hasEmail(job: Job): boolean {
    return !!job.company.email.trim();
  }

  private hasApplicationLink(job: Job): boolean {
    return !!job.contact.jobLinks.urlJobApplication;
  }

  private hasCompanyName(job: Job): boolean {
    return !!job.company.name.trim();
  }

  private hasType(job: Job): boolean {
    return job.jobInfo.jobType !== null;
  }

  private hasFoi(job: Job): boolean {
    return !!job.researchQualifications.wantedFieldOfInterest.length;
  }

  private hasTech(job: Job): boolean {
    return !!job.researchQualifications.wantedTechniques.length;
  }

  canSeeCat1() {
    const validAccountTypes = [
      AccountType.scientist,
      AccountType.labTech,
      AccountType.medLabTech,
      AccountType.ssoAuthedUser,
      AccountType.industry,
      AccountType.university,
    ];

    if (
      this.authService.getCurrentUser() &&
      this.authService.getCurrentUser().type
    ) {
      return canSeeElement(
        validAccountTypes,
        'Category1',
        this.authService.getCurrentUser().type
      );
    } else {
      return false;
    }
  }

  public getFilters(entity: Job): IFilter[] {
    if (!entity) {
      return [];
    }
    let foiList = [];
    let cat0List = [];
    let cat1List = [];
    let cat2List = [];
    let cat3List = [];
    let cat4List = [];
    let cat5List = [];
    let techList = [];

    if (entity.researchQualifications.wantedFieldOfInterest) {
      foiList = entity.researchQualifications.wantedFieldOfInterest.map(
        (i: FieldOfInterest) => {
          return new UserFilter(i.uid, i.name, 'interests');
        }
      );
    }

    if (entity.researchQualifications.category0) {
      cat0List = entity.researchQualifications.category0.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category0');
      });
    }
    if (entity.researchQualifications.category1) {
      cat1List = entity.researchQualifications.category1.map((i: Category1) => {
        return new UserFilter(i.uid, i.name, 'category1');
      });
    }
    if (entity.researchQualifications.category2) {
      cat2List = entity.researchQualifications.category2.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category2');
      });
    }
    if (entity.researchQualifications.category3) {
      cat3List = entity.researchQualifications.category3.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category3');
      });
    }
    if (entity.researchQualifications.category4) {
      cat4List = entity.researchQualifications.category4.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category4');
      });
    }
    if (entity.researchQualifications.category5) {
      cat5List = entity.researchQualifications.category5.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category5');
      });
    }
    if (entity.researchQualifications.wantedTechniques) {
      techList = entity.researchQualifications.wantedTechniques.map(
        (i: Technique) => {
          return new UserFilter(i.id, i.name, 'techniques');
        }
      );
    }
    return [
      ...foiList,
      ...techList,
      ...cat0List,
      ...cat1List,
      ...cat2List,
      ...cat3List,
      ...cat4List,
      ...cat5List,
    ];
  }
}
