
import { Guid } from '../guid';
import { CvField } from '../cv/cv-field';
import { LanguageProficiency } from './language-proficiency';
import { LanguageMultiSelector } from './language-multiselector';

export class LanguageDetails extends CvField implements JsonLanguage {
  public static fromJson(
    {
      uid,
      language,
      level,
      exclude
    }: JsonLanguage,
    cvId: string
  ): LanguageDetails {
    return new LanguageDetails(
      uid,
      cvId,
      language,
      level,
      exclude
    );
  }

  public static toJson(item: LanguageDetails): JsonLanguage {
    return {
      uid: item.uid,
      cvId: item.cvId,
      language: item.language,
      level: item.level,
      exclude: item.exclude
    };
  }
  constructor(
    public readonly uid = Guid.newGuid(),
    public cvId = '',
    public language: LanguageMultiSelector = new LanguageMultiSelector(),
    public level = LanguageProficiency.fluent,
    public exclude = false
  ) {
    super(exclude);
  }
}

export interface JsonLanguage {
  uid: string;
  cvId: string;
  language: LanguageMultiSelector;
  level: LanguageProficiency;
  exclude: boolean;
}
