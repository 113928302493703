import { Group, IGroup } from '../../models/group';
import { GroupType } from '../../models/group-type';
import { GroupPrivacy } from '../../models/groupPrivacy';
import { GroupTags } from '../../models/groupTags';
import { MondoLocation } from '../../../stepper/job/model/mondoLocation';
import { GroupPermissions } from '../../models/groupPermissions';
import { Upload } from '../../../shared/models';
import { SiteIdeaStatus } from '../../../shared/models/siteIdeaStatus';

export class Idea implements IIdea<Date>, Group {
  public readonly groupType = GroupType.Ideas;
  public static fromJson({
    name,
    created,
    ownerId,
    lastUpdate,
    coverUrl,
    videoUrl,
    website,
    websiteCollaboratory,
    tags,
    uploads,
    wantedDaysOfExperience0,
    wantedDaysOfExperience1,
    wantedDaysOfExperience2,
    wantedDaysOfExperience3,
    wantedDaysOfExperience4,
    wantedDaysOfExperience5,
    privacy,
    permissions,
    membersCount,
    logoUrl,
    location,
    description,
    publicityDate,
    key,
    forumId,
    ownerGroupId,
    ownerGroupType,
    siteKey,
    siteDescription,
    siteStatus,
  }: IIdea<number>): Idea {
    return new Idea(
      name,
      description,
      ownerId,
      key,
      lastUpdate ? new Date(lastUpdate) : null,
      created ? new Date(created) : null,
      publicityDate ? new Date(publicityDate) : null,
      membersCount,
      GroupPermissions.fromJson(permissions),
      privacy,
      logoUrl,
      coverUrl,
      videoUrl,
      location,
      website,
      websiteCollaboratory,
      tags ? GroupTags.fromJson(tags) : new GroupTags(),
      uploads ? uploads : [],
      wantedDaysOfExperience0,
      wantedDaysOfExperience1,
      wantedDaysOfExperience2,
      wantedDaysOfExperience3,
      wantedDaysOfExperience4,
      wantedDaysOfExperience5,
      forumId,
      ownerGroupId,
      ownerGroupType,
      siteKey,
      siteDescription,
      siteStatus
    );
  }

  public static toJson(idea: Idea): IIdea<number> | any {
    return {
      name: idea.name,
      ownerId: idea.ownerId,
      key: idea.key,
      created: idea.created ? idea.created.getTime() : null,
      lastUpdate: idea.lastUpdate ? idea.lastUpdate.getTime() : null,
      publicityDate: idea.publicityDate ? idea.publicityDate.getTime() : null,
      coverUrl: idea.coverUrl,
      description: idea.description,
      location: MondoLocation.fromJson(idea.location),
      logoUrl: idea.logoUrl,
      permissions: GroupPermissions.toJson(idea.permissions),
      privacy: idea.privacy,
      tags: GroupTags.toJson(idea.tags),
      uploads: idea.uploads ? idea.uploads : [],
      wantedDaysOfExperience0: idea.wantedDaysOfExperience0
        ? idea.wantedDaysOfExperience0
        : 0,
      wantedDaysOfExperience1: idea.wantedDaysOfExperience1
        ? idea.wantedDaysOfExperience1
        : 0,
      wantedDaysOfExperience2: idea.wantedDaysOfExperience2
        ? idea.wantedDaysOfExperience2
        : 0,
      wantedDaysOfExperience3: idea.wantedDaysOfExperience3
        ? idea.wantedDaysOfExperience3
        : 0,
      wantedDaysOfExperience4: idea.wantedDaysOfExperience4
        ? idea.wantedDaysOfExperience4
        : 0,
      wantedDaysOfExperience5: idea.wantedDaysOfExperience5
        ? idea.wantedDaysOfExperience5
        : 0,
      videoUrl: idea.videoUrl,
      website: idea.website,
      websiteCollaboratory: idea.websiteCollaboratory
        ? idea.websiteCollaboratory
        : null,
      groupType: GroupType.Ideas,
      forumId: idea.forumId,
      ownerGroupId: idea.ownerGroupId ? idea.ownerGroupId : '',
      ownerGroupType: idea.ownerGroupType ? idea.ownerGroupType : '',
      siteKey: idea.siteKey ? idea.siteKey : '',
      siteDescription: idea.siteDescription ? idea.siteDescription : '',
      siteStatus: idea.siteStatus
        ? idea.siteStatus
        : SiteIdeaStatus.SiteIdeaActive,
    };
  }
  constructor(
    public name: string,
    public description: string,
    public ownerId: string,
    public key: string,
    public lastUpdate: Date,
    public created: Date,
    public publicityDate: Date,
    public membersCount: number,
    public permissions: GroupPermissions,
    public privacy: GroupPrivacy,
    public logoUrl: string,
    public coverUrl: string,
    public videoUrl: string,
    public location: MondoLocation = new MondoLocation(),
    public website: string,
    public websiteCollaboratory: string,
    public tags: GroupTags,
    public uploads: Upload[] = [],
    public wantedDaysOfExperience0 = 0,
    public wantedDaysOfExperience1 = 0,
    public wantedDaysOfExperience2 = 0,
    public wantedDaysOfExperience3 = 0,
    public wantedDaysOfExperience4 = 0,
    public wantedDaysOfExperience5 = 0,
    public forumId: string,
    public ownerGroupId: string,
    public ownerGroupType: GroupType,
    public siteKey: string,
    public siteDescription: string,
    public siteStatus: SiteIdeaStatus
  ) {}
}

export interface IIdea<D> extends IGroup<D> {
  websiteCollaboratory: string;
}
