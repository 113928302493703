import { JsonLanguage } from './language-details';
import { LanguageDetails } from './language-details';

export class Languages implements JsonLanguages {
  public static fromJson(
    {
      languages
    }: JsonLanguages,
    cvId: string
  ): Languages {
    const lang = new Languages();
    lang.languages = languages
      ? languages.map(l => LanguageDetails.fromJson(l, cvId))
      : [];

    return lang;
  }

  public static toJson(langs: Languages): JsonLanguages {
    return {
      languages: langs.languages.map(a => LanguageDetails.toJson(a)),

    };
  }

  public constructor(
    public languages: LanguageDetails[] = []
  ) { }
}

interface JsonLanguages {
  languages: JsonLanguage[];
}
