import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-confirm-button',
  template: `
    <button
      style="min-height: 37.5px"
      mat-button
      color="primary"
      matTooltip="{{ confirmText | preventHardcoding }} {{
        translatedTooltipItemText
      }}"
      [disabled]="disabled"
      (click)="canConfirm ? confirmItem() : prepareForConfirm()"
    >
      <mat-icon>{{ icon }}</mat-icon>
      <span *ngIf="canConfirm" style="padding-left: 5px;">{{
        confirmText | preventHardcoding
      }}</span>
    </button>

    <button
      style="min-height: 37.5px"
      *ngIf="canConfirm"
      mat-button
      [disabled]="disabled"
      (click)="cancel()"
    >
      {{ 'cancel' | preventHardcoding }}
    </button>
  `,
})
export class ConfirmButtonComponent {
  @Input() translatedTooltipItemText: string;
  @Input() confirmText = 'confirm';
  @Input() disabled: boolean = false;
  @Input() icon: string = 'check_circle';
  @Output() confirm = new EventEmitter<boolean>();
  canConfirm: boolean = false;

  prepareForConfirm() {
    if (!this.disabled) {
      this.canConfirm = true;
    }
  }

  cancel() {
    this.canConfirm = false;
  }

  confirmItem() {
    if (!this.disabled) {
      this.confirm.emit(true);
      this.canConfirm = false;
    }
  }
}
