import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { PresenceService } from 'app/core/presence.service';
import { Observable } from 'rxjs';
import { IPresence } from '../../../shared/components/user-status/user-status.component';

@Component({
  selector: 'app-presence-indicator',
  templateUrl: './presence-indicator.component.html',
  styleUrls: ['./presence-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresenceIndicatorComponent implements OnInit {
  @Input() uid: string;

  presence$: Observable<IPresence>;

  constructor(private presenceService: PresenceService) { }

  ngOnInit() {
    this.presence$ = this.presenceService.getPresence(this.uid);
  }
}
