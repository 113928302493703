import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { AuthService } from 'app/core/auth.service';
import { NotesService } from 'app/core/notes.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-notes',
  template: `
    <ng-container *ngIf="reqPerm && notes | async"></ng-container>
    <button
      class="no-print"
      mat-raised-button
      color="primary"
      [class.some-notes]="someNotes"
      *ngIf="reqPerm"
      (click)="openNotes()"
    >
      {{ 'Notes' | preventHardcoding }}
    </button>
  `,
  styles: ['.some-notes {background-color: var(--warn);}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements OnChanges {
  @Input() uid!: string;

  notes;
  someNotes: boolean;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private noteService: NotesService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.uid.currentValue) {
      this.notes = this.noteService
        .getListOfNotesPerUser(this.uid)
        .pipe(tap((notes) => (this.someNotes = notes && notes.length > 0)));
    }
  }

  get reqPerm() {
    return this.authService.canUseNotes;
  }

  openNotes() {
    this.dialog.open(NotesDialogComponent, {
      width: '550px',
      maxHeight: '100vh',
      panelClass: 'notes-dialog',
      data: {
        uid: this.uid,
      },
    });
  }
}
