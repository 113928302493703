import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenFirstCharInEveryWord'
})
export class ShortenFirstCharInEveryWordPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    const firstNames: string[] = value.split(' ');
    let result = '';
    firstNames.map(name => {
      result += name.substring(0, 1) + '. ';
    });

    return result;
  }

}
