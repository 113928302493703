import { Component, OnInit } from '@angular/core';
import { acaConfig } from 'aca-config';
import { Event } from 'app/groups/events/models/event';
import { OnlineFormat } from 'app/groups/events/models/onlineFormat';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';
import { TimePeriod } from 'app/stepper/job/model/timePeriod';
import { WordpressService } from 'app/wordpress/wordpress.service';

@Component({
  selector: 'app-wordpress-event-widget',
  templateUrl: './wordpress-event-widget.component.html',
  styleUrls: ['./wordpress-event-widget.component.scss'],
})
export class WordpressEventWidgetComponent implements OnInit {
  events: Event[];
  constructor(private wordpressService: WordpressService) {}

  async ngOnInit() {
    this.events = (await this.wordpressService.events).map((event) => {
      const event_start_date = this.getEventStartDate(
        event.acf.acf_event_date,
        event.acf.acf_event_start_time
      );
      const showTime =
        event.acf.acf_event_start_time &&
        event.acf.acf_event_start_time.trim() !== ''
          ? true
          : false;

      return new Event(
        event.id,
        'any',
        event.title.rendered,
        0,
        0,
        0,
        0,
        0,
        event.acf.news_event_featured_image
          ? event.acf.news_event_featured_image.url
          : event.acf.news_event_overview_image.url,
        'desc',
        false,
        !!!'isOnline',
        OnlineFormat.Other,
        '',
        event.acf.acf_event_external_link
          ? event.acf.acf_event_external_link
          : event.link,
        // event.acf.acf_event_register_link
        //   ? event.acf.acf_event_register_link
        //   : event.acf.acf_event_external_link,
        new MondoLocation(),
        // 'holmevej',
        // '123',
        // 'placeId',
        // 'Aarhus',
        // 'holmvej 123 Aarhus',
        // '8270',
        // new Country('dk', 'Denmark'),
        // new LatLng(0, 0)
        event_start_date,
        event_start_date,
        event_start_date,
        'logoUrl',
        999,
        null,
        null,
        null,
        null,
        GroupPrivacy.OpenPrivate,
        null,
        null,
        [],
        0,
        0,
        0,
        0,
        0,
        0,
        '',
        '',
        event.link,
        null,
        new TimePeriod(
          new Date(event_start_date),
          new Date(event.acf.acf_event_end_date),
          false,
          false
        ),
        { showTime }
      );
    });
  }

  getEventStartDate(acf_event_date, acf_event_start_time): Date {
    const baseDate = new Date(acf_event_date);
    const year = baseDate.getFullYear();
    const month = baseDate.getMonth();
    const day = baseDate.getDate();
    const newEventStartDate = new Date(year, month, day);

    if (acf_event_start_time && acf_event_start_time.trim() !== '') {
      const [hours, minutes] = acf_event_start_time.split('.').map(Number);
      newEventStartDate.setHours(hours, minutes, 0, 0);
    } else {
      newEventStartDate.setHours(0, 0, 0, 0);
    }

    return newEventStartDate;
  }

  get TwitterListHref() {
    return acaConfig.TwitterListHref;
  }

  get ExternalFeedRight() {
    return acaConfig.ExternalFeedRight;
  }

  gotoEventsRoute() {
    window.open(acaConfig['externalLinkWordpressEvents'], '_blank').focus();
  }
  gotoEvent(link: string) {
    window.open(link, '_blank').focus();
  }

  trackByFn(index, item: Event) {
    return item && item.key;
  }
}
