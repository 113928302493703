import { routerTransition } from 'router.animations';
import {
  trigger,
  state,
  animate,
  transition,
  style,
  query,
  stagger,
  keyframes,
  animation
} from '@angular/animations';

export const leaveTimings = '1000ms cubic-bezier(.8,-0.49,.36,1)';
export const enterTimings = '1000ms cubic-bezier(.65,0,.32,1.55)';
export const enterInit = 'translateX(-100%)';
export const leaveInit = 'translateX(100%)';


export const routerAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('routerAnimation', [
    transition('* => map', [
      style({position: 'relative'}),
      // Initial state of new route
      query(':enter',
        style({
          position: 'absolute',
          left: '0',
          top: '0',
          width: '100%',
          opacity: 0,
          transform: enterInit
        }),
        { optional: true }
      ),
      query(':leave',
        style({
          position: 'absolute',
          left: '0',
          top: '64px',
          width: '100%',
          opacity: 1,
          transform: 'translateX(0)'
        }),
        { optional: true }
      ),
      // move page off screen right on leave
      query(
        ':leave',
        animate(
          leaveTimings,
          style({
            position: 'absolute',
            left: '0',
            top: '64px',
            width: '100%',
            opacity: 0,
            transform: leaveInit
          })
        ),
        { optional: true }
      ),
      // move page in screen from left to right
      query(
        ':enter',
        animate(
          enterTimings,
          style({
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            opacity: 1,
            transform: 'translateX(0)'
          })
        ),
        { optional: true }
      )
    ]),
    transition('map => *', [
      style({position: 'relative'}),
      // Initial state of new route
      query(':enter',
        style({
          position: 'absolute',
          left: '0',
          top: '64px',
          width: '100%',
          opacity: 0,
          transform: enterInit
        }),
        { optional: true }
      ),
      query(':leave',
        style({
          position: 'absolute',
          left: '0',
          top: '0',
          width: '100%',
          opacity: 1,
          transform: 'translateX(0)'
        }),
        { optional: true }
      ),
      // move page off screen right on leave
      query(
        ':leave',
        animate(
          leaveTimings,
          style({
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            opacity: 0,
            transform: leaveInit
          })
        ),
        { optional: true }
      ),
      // move page in screen from left to right
      query(
        ':enter',
        animate(
          enterTimings,
          style({
            position: 'absolute',
            left: '0',
            top: '64px',
            width: '100%',
            opacity: 1,
            transform: 'translateX(0)'
          })
        ),
        { optional: true }
      )
    ]),

    transition('* <=> *', [
      style({position: 'relative'}),
      // Initial state of new route
      query(':enter',
        style({
          position: 'absolute',
          left: '0',
          top: '64px',
          width: '100%',
          opacity: 0,
          transform: enterInit
        }),
        { optional: true }
      ),
      query(':leave',
      style({
        position: 'absolute',
        left: '0',
        top: '64px',
        width: '100%',
        opacity: 1,
        transform: 'translateX(0)'
      }),
      { optional: true }
    ),
      // move page off screen right on leave
      query(
        ':leave',
        animate(
          leaveTimings,
          style({
            position: 'absolute',
            left: '0',
            top: '64px',
            width: '100%',
            opacity: 0,
            transform: leaveInit
          })
        ),
        { optional: true }
      ),
      // move page in screen from left to right
      query(
        ':enter',
        animate(
          enterTimings,
          style({
            position: 'absolute',
            left: '0',
            top: '64px',
            width: '100%',
            opacity: 1,
            transform: 'translateX(0)'
          })
        ),
        { optional: true }
      )
    ])
  ]);

  export const routerAnimationHinge =
  trigger('routerAnimationHinge', [
    transition('* <=> *', [
      style({position: 'relative'}),
      // Initial state of new route
      query(':enter',
        style({
          position: 'absolute',
          left: 0,
          top: '0',
          width: '100%',
          opacity: 0,
          // transform: 'translateX(-100%)',
        }),
        { optional: true }
      ),
      query(':leave',
      style({
        position: 'absolute',
        left: '0',
        top: '0',
        // width: '100%',
        opacity: 1,
        // transform: 'translateX(0)'
      }),
      { optional: true }
    ),
      // move page off screen right on leave
      query(':leave',
        animate(
          '2000ms ease-in-out', keyframes([
            style({
              position: 'absolute',
              left: 0,
              // width: '90%',
              opacity: 1,
              'transform-origin': 'top left',
              transform: 'scale(0.5)',
              offset: 0
            }),
            style({
              position: 'absolute',
              // left: 0,
              opacity: 1,
              // 'transform-origin': 'top left',
              transform: 'rotate3d(0, 0, 1, 80deg)',
              offset: 0.2
            }),
            style({
              position: 'absolute',
              // left: 0,
              opacity: 1,
              // 'transform-origin': 'top left',
              transform: 'rotate3d(0, 0, 1, 60deg)',
              offset: 0.4
            }),
            style({
              position: 'absolute',
              // left: 0,
              opacity: 1,
              // 'transform-origin': 'top left',
              transform: 'rotate3d(0, 0, 1, 80deg)',
              offset: 0.6
            }),
            style({
              position: 'absolute',
              // left: 0,
              opacity: 1,
              // 'transform-origin': 'top left',
              transform: 'rotate3d(0, 0, 1, 60deg)',
              offset: 0.8
            }),
            style({
              position: 'absolute',
              left: 0,
              // width: '75%',
              opacity: 0,
              'transform-origin': 'bottom right',
              transform: 'scale(0.3) translate3d(0, 700px, 0)',
              offset: 1
            })
          ])
        ),
        { optional: true }
      ),
      // move page in screen from left to right
      query(
        ':enter',
        animate(
          '2000ms ease-in',
          keyframes([
            style({
              position: 'absolute',
              left: 0,
              top: '0',
              opacity: 0,
              transform: 'perspective(400px) rotate3d(0, 1, 0, 90deg)',
              offset: 0
            }),
            // style({
            //   position: 'absolute',
            //   left: 0,
            //   opacity: 1,
            //   transform: ' rotate3d(0, 0, 1, 80deg)',
            //   offset: 0.2
            // }),
            style({
              position: 'absolute',
              left: 0,
              opacity: 1,
              transform: 'perspective(400px) rotate3d(0, 1, 0, -20deg)',
              offset: 0.4
            }),
            style({
              position: 'absolute',
              left: 0,
              opacity: 1,
              transform: 'perspective(400px) rotate3d(0, 1, 0, 10deg)',
              offset: 0.6
            }),
            style({
              position: 'absolute',
              left: 0,
              opacity: 1,
              transform: 'perspective(400px) rotate3d(0, 1, 0, -5deg)',
              offset: 0.8
            }),
            style({
              position: 'absolute',
              left: 0,
              top: '0',
              opacity: 1,
              transform: 'perspective(400px)',
              offset: 1
            })
          ])
        ),
        { optional: true }
      )
    ])
  ]);
