import { Component, OnInit } from '@angular/core';
import { acaConfig } from 'aca-config';

declare const Cookies: any;

@Component({
  selector: 'app-cookie-consent-dialog',
  template: `
    <ng-container *ngIf="!isIframe">
      <div
        *ngIf="!allCookiesAccepted && !functionalCookiesAccepted"
        class="cookie-consent-dialog"
      >
        <div *ngIf="!learnMore" class="learn-more">
          <div style="width: 100%;">
            {{
              (marketingCookies
                ? 'CookieInfoMarketing'
                : 'CookieInfoFunctional'
              ) | preventHardcoding
            }}
          </div>
          <div class="cookie-actions">
            <button mat-button class="read-more" (click)="toggleDisclaimer()">
              {{ 'AcceptLearnMore' | preventHardcoding }}
            </button>
            <span class="accept-buttons">
              <button class="fancy-button" (click)="acceptFunctionalCookies()">
                {{ 'AcceptFunctionalCookies' | preventHardcoding }}
              </button>
              <button
                *ngIf="marketingCookies"
                class="fancy-button"
                (click)="acceptAllCookies()"
              >
                {{ 'AcceptAllCookies' | preventHardcoding }}
              </button>
            </span>
          </div>
        </div>
        <div *ngIf="learnMore" class="learn-more">
          <div style="width: 100%;">
            {{
              (marketingCookies
                ? 'CookieDisclaimerMarketing'
                : 'CookieDisclaimerFunctional'
              ) | preventHardcoding
            }}
          </div>
          <button mat-button (click)="toggleDisclaimer()">
            {{ 'CookieOkay' | preventHardcoding }}
          </button>
        </div>
      </div>

      <div
        class="cookie-icon"
        *ngIf="functionalCookiesAccepted || allCookiesAccepted"
        (click)="rejectCookie()"
      >
        🍪
      </div>
    </ng-container>
  `,
  styleUrls: ['./cookie-consent-dialog.component.scss'],
})
export class CookieConsentDialogComponent implements OnInit {
  functionalCookiesAccepted: boolean;
  allCookiesAccepted: boolean;
  learnMore: boolean;
  marketingCookies: boolean;
  isIframe: boolean;

  constructor() {}

  acceptFunctionalCookies(): void {
    if (typeof Cookies !== 'undefined') {
      Cookies.set('functionalCookiesAccepted', 'true', { secure: true });
      this.functionalCookiesAccepted = true;
    }
    localStorage.setItem('functionalCookiesAccepted', 'true');
    window.location.reload();
  }

  acceptAllCookies(): void {
    if (typeof Cookies !== 'undefined') {
      Cookies.set('allCookiesAccepted', 'true', { secure: true });
      this.allCookiesAccepted = true;
    }
    localStorage.setItem('allCookiesAccepted', 'true');
    window.location.reload();
  }

  toggleDisclaimer(): void {
    this.learnMore = !this.learnMore;
  }

  rejectCookie(): void {
    if (typeof Cookies !== 'undefined') {
      Cookies.set('functionalCookiesAccepted', 'false', { secure: true });
      Cookies.set('allCookiesAccepted', 'false', { secure: true });
      this.functionalCookiesAccepted = false;
      this.allCookiesAccepted = false;
    }
    localStorage.setItem('functionalCookiesAccepted', 'false');
    localStorage.setItem('allCookiesAccepted', 'false');
  }

  ngOnInit() {
    if (window.top !== window.self) {
      this.isIframe = true;
    } else {
      this.isIframe = false;
    }
    this.marketingCookies = !!acaConfig.ExternalLinkedinFeed;
    if (typeof Cookies !== 'undefined') {
      this.functionalCookiesAccepted =
        Cookies.get('functionalCookiesAccepted') === 'true';
      this.allCookiesAccepted = Cookies.get('allCookiesAccepted') === 'true';
    }
    if (!this.functionalCookiesAccepted) {
      this.functionalCookiesAccepted =
        localStorage.getItem('functionalCookiesAccepted') === 'true';
    }
    if (!this.allCookiesAccepted) {
      this.allCookiesAccepted =
        localStorage.getItem('allCookiesAccepted') === 'true';
    }
  }
}
