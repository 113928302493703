import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { HelperService } from 'app/core/helper.service';
import { GroupRole, GroupRoleHolder } from 'app/groups/models/group-role';
import { GroupType } from 'app/groups/models/group-type';
import {
  IGroupRoleRequest,
  MembershipHandlerFunctions,
} from 'app/shared-services/membership/models/membership-function-interfaces';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DAO } from '../shared-services/db-access/dao';

@Injectable({
  providedIn: 'root',
})
export class GroupRoleService {
  userId: string;
  constructor(
    public fnsHelper: HelperService,
    private dao: DAO,
    private authService: AuthService
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });
  }

  public async setRole(
    groupId: string,
    groupType: GroupType,
    userId: string,
    role: GroupRole
  ) {
    return this.fnsHelper
      .createFunctionPromise<IGroupRoleRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        userId: userId,
        role: role,
        functionHandle: MembershipHandlerFunctions.membershipSetGroupRole,
      })
      .catch((err) => console.error('error - membership - set role: ' + err));
  }

  public getRole$(
    userId: string,
    groupType: GroupType,
    groupId: string
  ): Observable<GroupRole> {
    return this.dao.object$<GroupRole>(
      `${DataConstants.USER_GROUP_ROLES}${userId}/${groupType}/${groupId}`
    );
  }

  public getUserRolesByType$(
    userId: string,
    groupType: GroupType
  ): Observable<{}> {
    return this.dao.object$(
      `${DataConstants.USER_GROUP_ROLES}${userId}/${groupType}`
    );
  }

  public getAllGroupRoleHolders$(
    groupId: string,
    groupType: GroupType,
    includeSelf = false
  ): Observable<GroupRoleHolder[]> {
    return this.dao
      .object$<Map<string, GroupRole>>(
        `${DataConstants.GROUP_ROLE}${groupType}/${groupId}`
      )
      .pipe(
        map((groupRoles) => {
          const groupRoleHolders = groupRoles ? groupRoles : [];
          return Object.keys(groupRoleHolders)
            .filter((u) => !!u)
            .filter((userId) => (includeSelf ? true : userId !== this.userId))
            .map((userId) => {
              return {
                userId: userId,
                role: groupRoleHolders[userId] as GroupRole,
              };
            });
        })
      );
  }
}
