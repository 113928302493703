import { Component, Input, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

@Component({
  selector: 'app-mat-tree',
  templateUrl: './mat-tree.component.html',
  styleUrls: ['./mat-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatTreeComponent {
  @Input() nestedDataSource: MatTreeNestedDataSource<any>;
  @Input() nestedTreeControl: NestedTreeControl<any>;
  @Input() template: TemplateRef<any>;
  @Input() template2: TemplateRef<any>;

  toggleTree(node) {
    this.nestedTreeControl.toggle(node);
  }

  hasChild = (_: number, node) => !!node.children && node.children.length > 0;
  isPublished = (node) => node.hasOwnProperty('prop') ? node.prop.status : true;
}
