import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, Navigation } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterEventService {

  private _lastNavigationState = new ReplaySubject(1);

  navigationStart$ = this.router.events.pipe(filter((e): e is NavigationStart => e instanceof NavigationStart));
  navigationEnd$ = this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd));

  // navigationEndReplay$ = this.navigationEnd$.pipe(shareReplay(1));

  lastNavigationReplay$ = this._lastNavigationState.asObservable();

  constructor(
    private router: Router
  ) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => {
        return this.router.getCurrentNavigation();
      })
    ).subscribe((navigation: Navigation) => {
      this._lastNavigationState.next(navigation);
    });
  }

}
