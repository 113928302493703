import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AppCustomPreloader } from './app.custom-preloader';
import { CoreModule } from './core/core.module';
import { MondoErrorHandler } from './shared/models/mondoErrorHandler';
import { EmailActionManagerComponent } from './email-action-manager/email-action-manager.component';
import 'hammerjs';
import { SharedPipesModule } from './shared-pipes/shared-pipes.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { NavbarComponent } from './navbar/navbar.component';
import { SharedDirectivesModule } from './shared-directives/shared-directives.module';
import { AngularMaterialSharedModule } from './angular-material-shared/angular-material-shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationsNewModule } from './notifications-new/notifications-new.module';
import { AngularfireWrapperModule } from './angularfire-wrapper/angularfire-wrapper.module';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { SuggestTagDialogModule } from './suggest-tag-dialog/suggest-tag-dialog.module';
import { SharedModule } from './shared/shared.module';
import { CookieConsentModule } from './cookie-consent/cookie-consent.module';
import { TourGuideModule } from './tour-guide/tour-guide.module';

@NgModule({
  declarations: [AppComponent, EmailActionManagerComponent, NavbarComponent],
  providers: [
    { provide: ErrorHandler, useClass: MondoErrorHandler },
    AppCustomPreloader,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularfireWrapperModule,
    CoreModule,
    AngularMaterialSharedModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedModule,
    ConfirmDialogModule,
    SuggestTagDialogModule,
    CookieConsentModule,
    TourGuideModule,
    NotificationsNewModule,
    AppRoutingModule,
    ServiceWorkerModule.register(environment.serviceWorker, {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
