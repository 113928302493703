import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { PostService } from 'app/core/post.service';
import { ForumService } from 'app/forum/services/forum.service';
import { PostFormService } from 'app/forum/services/post-form.service';
import { MentionService } from 'app/mention/mention.service';
import { rank, RankResponse } from 'app/shared/common/post-algorithm';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-post-step-general',
  templateUrl: './post-step-general.component.html',
  styleUrls: ['./post-step-general.component.scss'],
})
export class PostStepGeneralComponent implements OnInit {
  @ViewChild('title', { static: false }) title: ElementRef;
  @Input() postKey: string;
  @Input() forumId: string;
  uploadPath: string;
  showImageUpload = false;
  showVideo = false;
  showFileUpload = false;
  showFeedback = false;
  showAllFeedback = true;
  loaded = false;

  allUsersForTagging$: Observable<MondoUser[]>;
  taggedUsers: MondoUser[] = [];

  mentionConfig = {
    items: [], // Initialize with empty array
    triggerChar: '@',
    labelKey: 'displayName', // The key to display in the mention list
    disableSearch: false, // Set to true if you want to disable the search feature
    maxItems: 5, // Maximum number of items shown in the mention list
    // mentionSelect: (item) => {
    //   console.log('meh', item);
    //   return `@${item.displayName}`;
    // },
  };

  constructor(
    private postFormService: PostFormService,
    public postService: PostService,
    public forumService: ForumService,
    private authService: AuthService,
    private mentionService: MentionService
  ) {}

  ngOnInit() {
    this.uploadPath = `${this.authService.getCurrentUser().uid}/uploads/posts/${
      this.postKey
    }`;

    this.allUsersForTagging$ = this.mentionService
      .getUsersForTagging$()
      .pipe(tap((users) => (this.mentionConfig.items = users)));
  }

  onCurrentlyMentionedItemsChanged(event: any) {
    const uniqueSet = new Set(event);
    this.taggedUsers = Array.from(uniqueSet) as MondoUser[];
    const uniqueUserIdsSet = this.taggedUsers.map((user) => user.uid);
    const taggedUserIds = Array.from(uniqueUserIdsSet).filter(
      (userId) => userId !== undefined && userId !== null
    );
    this.postFormService.addTaggedUsers(taggedUserIds);
  }

  formatTaggedUserList(taggedUsers: any[]): string {
    return this.mentionService.formatTaggedUserList(taggedUsers);
  }

  ngAfterViewInit() {
    this.title && this.title.nativeElement.focus();
  }

  get isSuperAdmin(): boolean {
    return this.authService.isSuperAdmin;
  }

  private getPlainText(text: string) {
    var resultStr = '';

    // Ignore the <p> tag if it is in very start of the text
    if (text.indexOf('<p>') == 0) resultStr = text.substring(3);
    else resultStr = text;

    // Replace <p> with two newlines
    resultStr = resultStr.replace(/<p>/gi, '\r\n\r\n');
    // Replace <br /> with one newline
    resultStr = resultStr.replace(/<br \/>/gi, '\r\n');
    resultStr = resultStr.replace(/<br>/gi, '\r\n');

    // Strip off other HTML tags.
    return resultStr.replace(/<[^<|>]+?>/gi, '');
  }

  getRank(): RankResponse {
    const postForm = this.postForm.value;
    const postBody = this.getPlainText(postForm.body);
    const postImage = !!postForm.imageLink;
    const postVideo = !!postForm.youtubeId;
    const tags = this.forumService.getFilters(postForm);

    return rank(postBody, postImage, postVideo, tags.length);
  }

  get postForm() {
    return this.postFormService.getForm();
  }
  get postFormBody() {
    return this.postFormService.getForm().getSafeControl((x) => x.body);
  }
  get postFormEmail() {
    return this.postFormService.getForm().getSafeControl((x) => x.email);
  }
  get postFormTaggedUsers() {
    return this.postFormService.getForm().getSafeControl((x) => x.taggedUserIds)
      .value;
  }

  toggleImg(event) {
    if (event.pointerType || this.isMouseClickOnSafari(event)) {
      this.showImageUpload = !this.showImageUpload;
      this.showVideo = false;
      this.showFileUpload = false;
    } else {
      console.log(
        'event.constructor && event.constructor.name',
        event.constructor && event.constructor.name
      );
      console.log(
        'is safari mouse event? ',
        event.constructor && event.constructor.name === 'MouseEvent'
      );
      console.log('event.pointerType', event.pointerType);
      console.log('event', event);
    }
  }

  private isMouseClickOnSafari(event) {
    return (
      event && event.constructor && event.constructor.name === 'MouseEvent'
    );
  }

  toggleVideo() {
    this.showVideo = !this.showVideo;
    this.showImageUpload = false;
    this.showFileUpload = false;
  }
  toggleFileUpload() {
    this.showFileUpload = !this.showFileUpload;
    this.showImageUpload = false;
    this.showVideo = false;
  }

  removeFile(i) {
    this.postService.uploadsMarkedForDeletion.push(
      this.postFormService.uploads.value[i]
    );
    this.postFormService.uploads.removeAt(i);
  }

  public fileUpload($event) {
    this.postFormService.addUpload($event);
  }

  trackByFn(index, item) {
    return item.path;
  }

  onPicUpload(url: string) {
    this.postFormService.imageLink.setValue(url);
  }

  get imageLinkPath(): string {
    return `/forums/${this.forumId}/posts/${this.postKey}/imageLink`;
  }
}
