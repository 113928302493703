import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotificationItemComponent } from './components/notification-center/notification-item/notification-item.component';
import { AngularMaterialSharedModule } from 'app/angular-material-shared/angular-material-shared.module';
import { SharedDirectivesModule } from 'app/shared-directives/shared-directives.module';
@NgModule({
  declarations: [NotificationCenterComponent, NotificationItemComponent],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    SharedPipesModule,
    RouterModule,
    AngularMaterialSharedModule,
    SharedDirectivesModule,
    InfiniteScrollModule,
  ],
  exports: [NotificationCenterComponent],
  entryComponents: [NotificationCenterComponent],
})
export class NotificationsNewModule {}
