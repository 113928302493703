import { ItemStatus } from './db-strings/ItemStatus';
import { IStatus } from './i-status';
import { Deleted } from './states/public/deleted';
import { Published } from './states/public/published';
import { QueuedForPublished } from './states/public/queued-for-publish';
import Unpublished from './states/public/unpublished';

export namespace StatusFactory {
  export function creatStatus(status: ItemStatus): IStatus {
    switch (status) {
      case ItemStatus.deleted:
        return new Deleted();
      case ItemStatus.published:
        return new Published();
      case ItemStatus.queuedForPublish:
        return new QueuedForPublished();
      case ItemStatus.unpublished:
        return new Unpublished();
    }

    return null;
  }
}
