import { Inject, Injectable } from '@angular/core';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { DAO } from 'app/shared-services/db-access/dao';
import { map, tap } from 'rxjs/operators';
import { AngularFireRemoteConfig, SETTINGS } from '@angular/fire/remote-config';
import {
  changeHardcodedValue,
  handleRemoteConfigValues,
} from 'hardcodedValues';
import { interval } from 'rxjs';
import { remoteConfig } from 'firebase';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  fetchUpdates = false;
  constructor(
    private dao: DAO,
    private remoteConfig: AngularFireRemoteConfig,
    @Inject(SETTINGS) private settings: remoteConfig.Settings
  ) {
    this.colors$();
    this.hardcodedValues$();
    this.initRemoteConfig();
  }

  initRemoteConfig() {
    this.remoteConfig.activate().then(() => {
      this.remoteConfig
        .getAll()
        .then((values) => handleRemoteConfigValues(values));
    });

    if (this.fetchUpdates) {
      interval(this.settings.minimumFetchIntervalMillis).subscribe(() => {
        this.remoteConfig.fetchAndActivate().then(() => {
          this.remoteConfig
            .getAll()
            .then((values) => handleRemoteConfigValues(values));
        });
      });
    }
  }

  colors$() {
    this.dao
      .list(DataConstants.CONFIG_COLORS)
      .stateChanges(['child_added', 'child_changed', 'child_removed'])
      .pipe(
        map((snap) => {
          const key = snap.payload.key;
          const doc = document.documentElement;
          if (snap.type === 'child_removed') {
            // console.log('Removing this style: ', key);
            doc.style.removeProperty(`--${key}`);
          } else {
            const data = snap.payload.val();
            // console.log('Changing this style: ', key, data);
            doc.style.setProperty(`--${key}`, `${data}`, 'important');
          }
        }),
        tap(() => {
          window.dispatchEvent(new Event('resize'));
        })
      )
      .subscribe();
  }
  hardcodedValues$() {
    this.dao
      .list(DataConstants.CONFIG_HARDCODED_VALUES)
      .stateChanges(['child_added', 'child_changed', 'child_removed'])
      .pipe(
        map((snap) => {
          const key = snap.payload.key;
          if (snap.type === 'child_removed') {
            // console.log('Removing this hardcodedValue: ', key);
          } else {
            const data = snap.payload.val() as boolean | Number | string;
            // console.log('Changing this hardcodedValue: ', key, data);
            changeHardcodedValue(key, data);
          }
        }),
        tap(() => {
          window.dispatchEvent(new Event('resize'));
        })
      )
      .subscribe();
  }
}
