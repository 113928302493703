import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { Testimonial, Partner } from 'app/shared/models';
import { DAO } from 'app/shared-services/db-access/dao';

@Injectable()
export class PartnersService {

  constructor(private db: DAO) { }

  getPartners(): Observable<Partner[]> {
    return this.db.list$<Partner>(DataConstants.PARTNERS);
  }

  getTestimonials(): Observable<Testimonial[]> {
    return this.db.list$<Testimonial>(DataConstants.TESTIMONIALS);
  }

  addTesti() {
    const newTe = new Testimonial('Mia Rasbech', '', '', '', `We have been introduced the new
    development at Academondo and find
    the development both innovative and
    interesting for us to use. At Dansk Industri we house more than 200 companies
    working within Life Science and through
    networking and collaboration we aim at
    developing optimal growth conditions
    for our members. For this we find the
    tools provided by Academondo very
    useful and our plan is to register on the
    site when it is ready.`);
    this.db.list(DataConstants.TESTIMONIALS).push(newTe);
  }
}
