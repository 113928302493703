import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-destroy',
  template: '',
})
export class DestroyComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject();
  private subscriptions: Array<Subscription> = [];
  constructor() {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.destroy$.complete();
  }

  safeSubscribe<T>(
    observable: Observable<T>,
    subscribeCb: (t: T) => void
  ): void {
    this.subscriptions.push(
      observable.pipe(takeUntil(this.destroy$)).subscribe(subscribeCb)
    );
  }
}
