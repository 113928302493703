import { JobInfo } from './jobInfo';
import { Company } from './company';
import { ResearchGroup } from './researchGroup';
import {
  ResearchQualifications,
  JsonResearchQualifications,
} from './researchQualifications';
import { TimePeriod, JsonTimePeriod } from './timePeriod';
import { Contact } from './contact';
import { IBaseStepItem } from '../../shared/model/IBaseStepItem';
import { ItemType } from '../../shared/model/ItemType';
import { StatusContext } from '../../shared/model/mondo-status/status-context';
import { Languages } from '../../../shared/models/languages/languages';
import { Upload } from '../../../shared/models';

export class Job implements IBaseStepItem, JsonJob<Date> {
  public readonly type = ItemType.Job;
  public static fromJson(
    {
      name,
      created,
      ownerId,
      lastUpdate,
      madePublic,
      jobVisibilityPeriod,
      jobInfo,
      uploads,
      company,
      researchQualifications,
      languages,
      employmentPeriod,
      contact,
      contactConsent,
      expireWarned,
    }: JsonJob<number>,
    key: string,
    status: StatusContext = new StatusContext()
  ): Job {
    return new Job(
      status,
      name,
      ownerId,
      key,
      created ? new Date(created) : null,
      lastUpdate ? new Date(lastUpdate) : null,
      madePublic ? new Date(madePublic) : null,
      TimePeriod.fromJson(jobVisibilityPeriod),
      JobInfo.fromJson(jobInfo),
      uploads ? uploads : [],
      Company.fromJson(company),
      ResearchQualifications.fromJson(researchQualifications),
      Languages.fromJson(languages || {}, key),
      TimePeriod.fromJson(employmentPeriod),
      Contact.fromJson(contact),
      contactConsent || false,
      expireWarned || false
    );
  }

  public static toJson(job: Job): JsonJob<number> {
    return {
      name: job.name,
      ownerId: job.ownerId,
      key: job.key,
      created: job.created ? job.created.getTime() : null,
      lastUpdate: job.lastUpdate ? job.lastUpdate.getTime() : null,
      madePublic: job.madePublic ? job.madePublic.getTime() : null,
      jobVisibilityPeriod: TimePeriod.toJson(job.jobVisibilityPeriod),
      jobInfo: job.jobInfo,
      uploads: job.uploads || [],
      company: job.company,
      researchQualifications: job.researchQualifications,
      languages: Languages.toJson(job.languages),
      employmentPeriod: TimePeriod.toJson(job.employmentPeriod),
      contact: job.contact,
      contactConsent: job.contactConsent ? job.contactConsent : false,
      expireWarned: false,
    };
  }

  constructor(
    public status: StatusContext = new StatusContext(),
    public name = '',
    public ownerId = '',
    public key = '',
    public created: Date = null,
    public lastUpdate: Date = null,
    public madePublic: Date = null,
    public jobVisibilityPeriod: TimePeriod = new TimePeriod(null, null, true),
    public jobInfo: JobInfo = new JobInfo(),
    public uploads: Upload[] = [],
    public company: Company = new Company(),
    public researchQualifications: ResearchQualifications = new ResearchQualifications(),
    public languages: Languages = new Languages(),
    public employmentPeriod: TimePeriod = new TimePeriod(),
    public contact: Contact = new Contact(),
    public contactConsent = false,
    public expireWarned = false
  ) {}
}
interface JsonJob<T> {
  name: string;
  ownerId: string;
  key: string;
  created: T;
  lastUpdate: T;
  madePublic: T;
  jobVisibilityPeriod: JsonTimePeriod<T>;
  jobInfo: JobInfo;
  uploads: Upload[];
  company: Company;
  researchQualifications: JsonResearchQualifications;
  languages;
  employmentPeriod: JsonTimePeriod<T>;
  contact: Contact;
  contactConsent: boolean;
  expireWarned: boolean;
}
