import { Injectable } from '@angular/core';
import { RoutingModel } from 'app/app.routing-model';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { StorageService } from 'app/core/storage.service';
import { ForumService } from 'app/forum/services/forum.service';
import { Community } from 'app/groups/communities/models/community';
import { Group } from 'app/groups/models/group';
import { GroupRelation } from 'app/groups/models/group-relation';
import { GroupType } from 'app/groups/models/group-type';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';
import { GroupTags } from 'app/groups/models/groupTags';
import { GroupService } from 'app/groups/services/group.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { Site, Upload } from 'app/shared/models';
import { SiteIdeaStatus } from 'app/shared/models/siteIdeaStatus';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';
import { formatText, hardcodedValues } from 'hardcodedValues';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { GroupPermissions } from '../../models/groupPermissions';
import { IIdea, Idea } from '../models/idea';
import { isObject } from 'app/shared/common/acaLodash';

@Injectable({
  providedIn: 'root',
})
export class IdeasService {
  userId: string;
  uploadsMarkedForDeletion: Upload[] = [];

  constructor(
    private dao: DAO,
    private authService: AuthService,
    private forumService: ForumService,
    private routingService: RoutingService,
    private groupService: GroupService,
    private storageService: StorageService
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });
  }

  private async removeFileFromStorage(index, arr: Upload[]) {
    await this.storageService
      .deleteFile(arr[index].path, true)
      .toPromise()
      .then(() => {
        arr.splice(index, 1);
      })
      .catch((e) => {
        if (this.uploadsMarkedForDeletion.length !== 0) {
          arr.splice(index, 1);
        }
      });
  }

  cleanDeletedAttachments() {
    this.uploadsMarkedForDeletion.forEach(async (val, index, arr) => {
      await this.removeFileFromStorage(index, arr);
    });

    this.uploadsMarkedForDeletion = [];
  }

  public getPublicIdeas$(): Observable<Idea[]> {
    return this.dao
      .list$<IIdea<number>>(DataConstants.PUBLIC_GROUPS + GroupType.Ideas)
      .pipe(
        map((groups) =>
          groups.map((group) =>
            isObject(group) ? Idea.fromJson(group as IIdea<number>) : undefined
          )
        )
      );
  }

  public canCreateNewDraftIdea(): boolean {
    return this.authService.canCreateDraftIdeas;
  }

  public createIdeaFromSite(site: Site) {
    if (
      this.authService.canCreateIdeaFromSite ||
      (this.authService.getCurrentUser() &&
        this.authService.getCurrentUser().uid === site.ownerId)
    ) {
      this.createIdeaAndNavigate(site);
    }
  }

  public getIdeas$(relation: GroupRelation): Observable<Array<Idea>> {
    return this.groupService.getGroups$(relation, GroupType.Ideas).pipe(
      map((groups) =>
        groups.filter((group) => {
          return group && group.key;
        })
      ),
      map((groups) => groups as Array<Idea>)
    );
  }

  public getIdea$(
    key: string,
    relation?: GroupRelation
  ): Observable<Idea | undefined> {
    return this.groupService
      .getGroup$(key, GroupType.Ideas, relation)
      .pipe(map((groups) => groups as Idea));
  }

  public async isDraftPublished(key: string): Promise<boolean> {
    return this.groupService.isDraftPublished(key, GroupType.Ideas);
  }

  public async updateDraftIdea(idea: Idea): Promise<void> {
    await this.cleanDeletedAttachments(); // run all uploads and delete each!!!!
    return this.groupService.updateDraftGroup(idea);
  }

  async publishIdea(idea: Idea): Promise<void> {
    if (this.checkPermissionPublishIdea() && this.minReqForPublish(idea)) {
      return this.groupService.publishGroup(idea.key, GroupType.Ideas);
    } else {
      return Promise.reject();
    }
  }

  private minRequirementForPublish(idea: Idea): string {
    let text = '';
    if (!idea.name) {
      text +=
        '⛔ ' +
        formatText(hardcodedValues.youHaveToAddX, hardcodedValues.Name) +
        '\n';
    }
    return text;
  }

  private minReqForPublish(idea: Idea): boolean {
    const text = this.minRequirementForPublish(idea);
    if (text.length > 0) {
      this.authService.notEnoughPermission(text);
      return false;
    } else {
      return true;
    }
  }

  async removeIdea(key: string): Promise<void> {
    await this.cleanDeletedAttachments(); // run all uploads and delete each!!!!
    return this.groupService.removeGroup(key, GroupType.Ideas);
  }

  public checkPermissionCreateDraftIdea() {
    return this.groupService.checkPermissionCreateDraftGroup(
      GroupType.Ideas,
      'MissingCanCreateDraftIdeaMsg'
    );
  }

  public checkPermissionPublishIdea() {
    return this.groupService.checkPermissionPublishGroup(
      GroupType.Ideas,
      'MissingCanPublishIdeaMsg'
    );
  }

  public async createIdeaAndNavigate(site?: Site) {
    await this.createIdea(site)
      .then((idea) => {
        this.routingService.navigateToRoute(RoutingModel.ideas.path, [
          'edit',
          idea.key,
          0,
        ]);
      })
      .catch((e) => {});
  }

  private async createIdea(
    site?: Site,
    privacySetting = GroupPrivacy.OpenPrivate
  ): Promise<Idea> {
    if ((site && site.key) || this.checkPermissionCreateDraftIdea()) {
      const now = new Date();

      const forum = await this.forumService.createForum(true, true);

      const idea: Idea = {
        name:
          site && site.siteInfo && site.siteInfo.nameSite
            ? `${hardcodedValues.Idea} - ${site.siteInfo.nameSite}`
            : hardcodedValues.initialIdeaName,
        ownerId: this.userId,
        key: null,
        created: now,
        lastUpdate: now,
        publicityDate: null,
        coverUrl: '',
        description: hardcodedValues.defaultIdeaDescription,
        location: new MondoLocation(),
        logoUrl: '',
        membersCount: 1,
        permissions: new GroupPermissions(),
        privacy: site && site.key ? GroupPrivacy.Private : privacySetting,
        tags: new GroupTags(),
        uploads: [],
        wantedDaysOfExperience0: 0,
        wantedDaysOfExperience1: 0,
        wantedDaysOfExperience2: 0,
        wantedDaysOfExperience3: 0,
        wantedDaysOfExperience4: 0,
        wantedDaysOfExperience5: 0,
        groupType: GroupType.Ideas,
        videoUrl: '',
        website: '',
        websiteCollaboratory: '',
        forumId: forum.id,
        ownerGroupId: null,
        ownerGroupType: null,
        siteKey: site ? site.key : '',
        siteDescription: site && site.siteInfo ? site.siteInfo.info : '',
        siteStatus:
          site && site.siteInfo
            ? site.siteInfo.siteStatus
            : SiteIdeaStatus.SiteIdeaActive,
        // ownerGroupId: ownerGroup ? ownerGroup.key : null,
        // ownerGroupType: ownerGroup ? ownerGroup.groupType : null,
      };

      return (await this.groupService.createGroup(idea)) as Idea;
    } else {
      return Promise.reject();
    }
  }

  async createSubgroupAndNavigate(
    ownerGroup?: Group,
    privacySetting?: GroupPrivacy,
    checked = false
  ) {
    await this.createSubgroup(ownerGroup, privacySetting, checked)
      .then((subgroup) => {
        this.routingService.navigateToRoute(RoutingModel.ideas.path, [
          'edit',
          subgroup.key,
          0,
        ]);
      })
      .catch((e) => {});
  }

  public async createSubgroup(
    ownerGroup?: Group,
    privacySetting = GroupPrivacy.OpenPrivate,
    checked = false
  ): Promise<Community> {
    if (checked || this.checkPermissionCreateDraftIdea()) {
      const now = new Date();

      const forum = await this.forumService.createForum(true, true);

      const subgroup: Community = {
        name: hardcodedValues.initialSubCommunityName,
        ownerId: this.userId,
        key: null,
        created: now,
        lastUpdate: now,
        publicityDate: null,
        coverUrl: '',
        description: '',
        location: new MondoLocation(),
        logoUrl: '',
        membersCount: 1,
        permissions: new GroupPermissions(),
        privacy: privacySetting,
        tags: new GroupTags(),
        uploads: [],
        wantedDaysOfExperience0: 0,
        wantedDaysOfExperience1: 0,
        wantedDaysOfExperience2: 0,
        wantedDaysOfExperience3: 0,
        wantedDaysOfExperience4: 0,
        wantedDaysOfExperience5: 0,
        groupType: GroupType.Communities,
        videoUrl: '',
        website: '',
        forumId: forum.id,
        ownerGroupId: ownerGroup ? ownerGroup.key : null,
        ownerGroupType: ownerGroup ? ownerGroup.groupType : null,
      };

      const group = (await this.groupService.createGroup(
        subgroup
      )) as Community;
      return group;
    } else {
      return Promise.reject();
    }
  }

  public duplicateIdea(idea: Idea) {
    return this.groupService.duplicateGroup(idea);
  }

  getRelatedIdeaIds$(resultId: string) {
    return resultId
      ? this.dao.object$(DataConstants.IDEA_LIST + resultId).pipe(
          map((ids) => (ids ? Object.keys(ids).map((id) => id) : [])),
          filter((id) => id.length !== 0)
        )
      : of([]);
  }
}
