import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { PresenceService } from 'app/core/presence.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserStatusComponent implements OnInit {

  @Input() uid: string;

  presence$: Observable<IPresence>;

  constructor(private presence: PresenceService) { }

  ngOnInit() {
    this.presence$ = this.presence.getPresence(this.uid);
  }
}

export interface IPresence {
  status: string;
  timestamp: string;
}
