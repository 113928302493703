import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SearchService } from 'app/core/search.service';
import { RoutingModel } from 'app/app.routing-model';
import { SearchResultV2 } from 'app/shared/common/search.model';
import { Site } from 'app/shared/models';
import { JobService } from 'app/core/job.service';
import { AuthService } from 'app/core/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PreviewSiteComponent } from 'app/shared/components/preview-site/preview-site.component';
import { acaConfig } from 'aca-config';
import { IFilter } from 'app/shared/models';
import { getFiltersFromSearchResults } from '@helpers';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyCardComponent implements OnInit {
  site$: Observable<Site>;
  @Input() siteResult: SearchResultV2;
  @Input() showScoreBar = false;
  @Input() map = false;
  @Output() siteLoad: EventEmitter<boolean> = new EventEmitter<boolean>();
  expanded = false;
  filters: IFilter[] = [];
  canOpenDescription = true;
  viewEntireDescription = false;

  constructor(
    private router: Router,
    private searchService: SearchService,
    private jobService: JobService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.viewEntireDescription = !this.canOpenDescription;
    this.filters = getFiltersFromSearchResults(this.siteResult);
  }

  toggleDescription(e) {
    // e.preventDefault();
    e.stopPropagation();
    this.viewEntireDescription = !this.viewEntireDescription;
  }

  getStateClass() {
    return this.viewEntireDescription ? 'expanded' : 'collapsed';
  }

  canShowPreview() {
    return acaConfig.slimSiteIndex ? this.authService.isAdmin : true;
  }

  showPreview(key: string): void {
    if (key && this.canShowPreview()) {
      // this.siteService.logView(site); // todo
      this.dialog.open(PreviewSiteComponent, {
        panelClass: 'preview-dialog',
        data: { key: key },
      });
    }
  }

  get isAdmin() {
    return this.authService.isAdmin;
  }

  showProfile(id: string) {
    const link = this.router.serializeUrl(
      this.router.createUrlTree([
        RoutingModel.home.path,
        RoutingModel.siteBuilder.path,
        id,
        '0',
      ])
    );
    window.open(link, '_blank');
  }

  createJob(site: Site) {
    this.jobService.createJobFromSite(site);
  }
  canCreateJob(site: Site): boolean {
    const isOwner = site
      ? site.ownerId ===
        (this.authService.getCurrentUser()
          ? this.authService.getCurrentUser().uid
          : -1)
      : false;
    return this.authService.canCreateJobFromSite && isOwner;
    // return this.authService.canCreateJobFromSite || isOwner;
  }
}
