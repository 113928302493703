import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Comment } from 'app/forum/models/comment';
import { CommentService } from 'app/forum/services/comment.service';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';
import { isEmpty, last } from 'app/shared/common/acaLodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent extends DestroyComponent implements OnInit {
  @Input()
  postId: string;
  @Input()
  parrentCommentId?: string;
  @Input()
  commentIdToShow?: string;
  @Input()
  isAdmin: boolean;
  @Input()
  forceShowSubComments: boolean;
  @Input()
  showNewCommentField = true;
  @Input()
  maximumNumberOfCommentsToShow: number;
  @Output()
  commentAdded = new EventEmitter<void>();
  @Input()
  canComment: boolean;
  @Input()
  currentLevel = 0;
  @Input() unfoldComments = false;

  finished = false;
  loading = false;
  comments: Map<string, Comment> = new Map();

  private comments$ = new BehaviorSubject<Array<Comment>>([]);

  private lastKey = null;
  private batch = 25;
  lastSize: number;
  filteredState = true;
  commentSubscriptions: Array<Subscription> = [];

  constructor(private commentService: CommentService) {
    super();
  }

  ngOnInit() {
    if (this.commentIdToShow) {
      this.forceShowSubComments = true;
      this.setCommentToList(this.commentIdToShow);
    } else {
      this.filteredState = false;
      this.getNextComments();
    }
  }

  private setCommentToList(commentId: string) {
    this.safeSubscribe(
      this.commentService.getComment$(commentId),
      (comment) => {
        if (comment.parrentCommentId) {
          this.setCommentToList(comment.parrentCommentId);
        } else {
          this.comments.set(comment.id, comment);
          this.comments$.next(Array.from(this.comments.values()));
        }
      }
    );
  }

  loadComments() {
    this.filteredState = false;
    this.onScroll();
  }

  onScroll() {
    if (
      this.maximumNumberOfCommentsToShow === undefined &&
      !this.filteredState
    ) {
      this.getNextComments();
    }
  }

  getComments(): Observable<Array<Comment>> {
    return this.comments$.asObservable();
  }

  onCommentAdded() {
    this.finished = false;
    this.loading = true;
    this.lastKey = undefined;
    this.comments.clear();
    this.commentSubscriptions.forEach((s) => s.unsubscribe());
    this.commentSubscriptions = [];
    this.getNextComments();
    this.commentAdded.emit();
  }

  commentRemoved(comment: Comment) {
    this.comments.delete(comment.id);
    this.comments$.next(Array.from(this.comments.values()));
  }

  private getNextComments() {
    this.loading = true;

    this.commentSubscriptions.push(
      this.getComments$().subscribe((comments) => {
        if (!isEmpty(comments)) {
          this.lastKey = last(comments).id;
          comments.forEach((c) => this.comments.set(c.id, c));
          this.comments$.next(Array.from(this.comments.values()));
          this.loading = false;
        }
      })
    );
  }

  private getComments$(): Observable<Array<Comment>> {
    if (this.parrentCommentId) {
      return this.commentService.getSubComments$(
        this.parrentCommentId,
        this.maximumNumberOfCommentsToShow || this.batch,
        this.lastKey
      );
    } else {
      return this.commentService.getComments$(
        this.postId,
        this.maximumNumberOfCommentsToShow || this.batch,
        this.lastKey
      );
    }
  }

  trackByFn(index, comment: Comment) {
    return comment.id;
  }
}
