import { Component, Input, OnInit } from '@angular/core';
import { ForumPost } from 'app/forum/models/forum-post';
import { PostFormService } from 'app/forum/services/post-form.service';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';
import { MondoStep } from 'app/stepper/shared/model/MondoStep';
import { PostStepIndex } from 'app/stepper/shared/model/stepIndex';
import { PostStepGeneralComponent } from './steps/post-step-general/post-step-general.component';
import { PostStepTopicsComponent } from './steps/post-step-topics/post-step-topics.component';
import { PostStepViewComponent } from './steps/post-step-view/post-step-view.component';
import { Group } from 'app/groups/models/group';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from 'app/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-post-builder',
  templateUrl: './post-builder.component.html',
  styleUrls: ['./post-builder.component.scss'],
})
export class PostBuilderComponent extends DestroyComponent implements OnInit {
  @Input() post: ForumPost;
  @Input() updatingPost = false;
  @Input() group: Group;

  steps: MondoStep[];

  constructor(
    public postFormService: PostFormService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PostBuilderComponent>
  ) {
    super();
  }

  ngOnInit() {
    this.postFormService.patchPost(this.post);
    this.setupSteps();
  }

  public handleKeyPress(event): void {
    // if (event.keyCode === 13 && event.ctrlKey) {
    //   this.save().then(() => this.dialogRef.close());
    // }
    if (event.code === 'Escape') {
      this.cancel();
    }
  }

  async save() {}

  cancel() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {});
    dialogRef.componentInstance.title = this.updatingPost
      ? 'discardChangesPostUpdateTitle'
      : 'discardChangesPostCreateTitle';
    dialogRef.componentInstance.text = this.updatingPost
      ? 'discardChangesPostUpdateBody'
      : 'discardChangesPostCreateBody';
    dialogRef.componentInstance.yesText = 'yes';
    dialogRef.componentInstance.noText = 'no';

    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .toPromise()
        .then((cancel) => {
          if (cancel) {
            this.dialogRef.close();
          }
        });
    });
  }

  private setupSteps() {
    const general = new MondoStep(
      PostStepIndex.general,
      PostStepGeneralComponent,
      'generalInformation',
      new Map()
        .set('postKey', this.post.key)
        .set('forumId', this.group.forumId),
      [true, false]
    );
    const topics = new MondoStep(
      PostStepIndex.topics,
      PostStepTopicsComponent,
      'topics',
      new Map(),
      [false, false]
    );
    const view = new MondoStep(
      PostStepIndex.view,
      PostStepViewComponent,
      'Preview',
      new Map()
        .set('post', this.post)
        .set('updatingPost', this.updatingPost)
        .set('group', this.group),
      [false, true]
    );

    this.steps = [general, topics, view];
  }

  onStepChanged(index: PostStepIndex) {}
}
