import { LatLng } from './latLng';
import { Country } from '../../../shared/models/country';

// To be refactored in accordance with google api
export class MondoLocation {
  public static fromJson({
    street,
    number,
    placeId,
    city,
    formattedAddress,
    postalCode,
    country,
    latLng,
  }: MondoLocation): MondoLocation {
    let fAddress = formattedAddress;
    if (formattedAddress) {
      fAddress = formattedAddress;
    } else {
      fAddress = '';
      if (street) {
        fAddress += street;
      }
      if (postalCode) {
        fAddress += ', ' + postalCode;
      }
      if (city) {
        fAddress += ' ' + city;
      }
    }

    return new MondoLocation(
      street,
      number,
      placeId,
      city,
      fAddress,
      postalCode,
      country,
      latLng
    );
  }
  constructor(
    public street = '',
    public number = '',
    public placeId = '',
    public city = '',
    public formattedAddress = '',
    public postalCode = '',
    public country: Country = new Country(),
    public latLng: LatLng = new LatLng()
  ) {}
}
