import { EducationLevel } from '../../../shared/models/cv/education-level';
import { Guid } from '../../../shared/models/guid';
import { Country } from '../../../shared/models/country';
import { CvField } from './cv-field';

export class Education extends CvField implements JsonEducation<Date> {
  public static fromJson(
    {
      uid,
      eduTitle,
      level,
      line,
      university,
      department,
      country,
      startDate,
      endDate,
      description,
      isCurrent,
      exclude
    }: JsonEducation<number>,
    cvId: string
  ): Education {
    return new Education(
      uid,
      cvId,
      eduTitle,
      level,
      line,
      university,
      department,
      country ? (country as Country) : new Country(),
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
      description,
      isCurrent,
      exclude
    );
  }

  public static toJson(item: Education): JsonEducation<number> {
    return {
      uid: item.uid,
      cvId: item.cvId,
      eduTitle: item.eduTitle,
      level: item.level,
      line: item.line,
      university: item.university,
      department: item.department,
      country: item.country,
      startDate: item.startDate ? item.startDate.getTime() : null,
      endDate: item.endDate ? item.endDate.getTime() : null,
      description: item.description,
      isCurrent: item.isCurrent,
      exclude: item.exclude
    };
  }
  constructor(
    public readonly uid = Guid.newGuid(),
    public cvId = '',
    public eduTitle = '',
    public level = EducationLevel.bachelor,
    public line = '',
    public university = '',
    public department = '',
    public country: Country = new Country(),
    public startDate: Date = null,
    public endDate: Date = null,
    public description = '',
    public isCurrent = false,
    public exclude = false
  ) {
    super(exclude);
  }
}

export interface JsonEducation<T> {
  uid: string;
  cvId: string;
  eduTitle: string;
  level: EducationLevel;
  line: string;
  university: string;
  department: string;
  country: Country;
  startDate: T;
  endDate: T;
  description: string;
  isCurrent: boolean;
  exclude: boolean;
}
