import { Component, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { Note } from 'app/shared/models/note';

@Component({
  selector: 'app-notes-viewer',
  templateUrl: './notes-viewer.component.html',
  styleUrls: ['./notes-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesViewerComponent implements OnChanges {

  @Input() notes!: Note[];

  filteredNotes: Note[];
  query = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'notes': {
            if (this.notes && this.notes.length > 0) {
              this.filteredNotes = this.notes
            .sort((a, b) => {
              if (a.created > b.created) {
                return -1;
              } else if (a.created < b.created) {
                return 1;
              }
              return 0;
            });
            } else {
              this.filteredNotes = [];
            }
          }
        }
      }
    }
  }

  filterByName() {
    const _filter = (item: Note) =>
      item.text.toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
      item.authorName.toLowerCase().indexOf(this.query.toLowerCase()) > -1;

    if (this.query !== '') {
      this.filteredNotes = this.notes.filter(_filter);
    } else {
      this.filteredNotes = this.notes;
    }
  }

  trackByFn(index, item) {
    return (item.key || item.id || item.uid);
  }
}
