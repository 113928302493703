import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  AfterViewInit,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MapService } from '../../../map/map.service';
import {
  SearchableTypes,
  SearchResultV2,
} from 'app/shared/common/search.model';
import { GroupRelation } from 'app/groups/models/group-relation';
import { Attendance } from 'app/groups/events/models/attendance';
import { LatLng } from 'app/stepper/job/model/latLng';
import { MarkerIcon } from 'app/map/map.models';
import { acaConfig } from 'aca-config';

declare const google: any;
declare const MarkerClusterer: any;
declare const OverlappingMarkerSpiderfier: any;
@Component({
  selector: 'app-search-map',
  templateUrl: './search-map.component.html',
  styleUrls: ['./search-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchMapComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() searchResults: SearchResultV2[];
  @Input() showAttendingIcons = false;
  @Input() isAuthed: boolean;
  @Input() attendance: Map<string, Attendance> = new Map<string, Attendance>();
  @Input() communityRelation: Map<string, GroupRelation> = new Map<
    string,
    GroupRelation
  >();
  @Input() eventRelation: Map<string, GroupRelation> = new Map<
    string,
    GroupRelation
  >();
  @Input() ideaRelation: Map<string, GroupRelation> = new Map<
    string,
    GroupRelation
  >();

  @ViewChild('infoWindowContainer', { read: ViewContainerRef, static: false })
  infoWindowContainer: ViewContainerRef;
  @ViewChild('userCard', { static: true }) userCardTemplate: TemplateRef<any>;
  @ViewChild('siteCard', { static: true }) siteCardTemplate: TemplateRef<any>;
  @ViewChild('communityCard', { static: true })
  communityCardTemplate: TemplateRef<any>;
  @ViewChild('eventCard', { static: true })
  eventCardTemplate: TemplateRef<any>;
  @ViewChild('ideaCard', { static: true })
  ideaCardTemplate: TemplateRef<any>;

  searchableTypes = SearchableTypes;

  markersStyles;
  clusterOptions = MapService.clusterOptions;
  mapOptions = MapService.mapOptions;
  spiderOptions = MapService.spiderOptions;

  map: any;
  oms: any;
  markerClusterer: any;
  infoWindow;

  searchResultMarkers;

  constructor(private cdr: ChangeDetectorRef, private mapService: MapService) {}

  ngOnInit() {}

  async ngAfterViewInit() {
    await this.mapService.load();
    await this.initMap();
    this.loadSearchResults();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.searchResults.firstChange) {
      this.clearMarkers(this.searchResultMarkers);
      this.loadSearchResults();
    }
  }

  async initMap() {
    const { Map } = await google.maps.importLibrary('maps');
    this.map = new Map(document.getElementById('map'), this.mapOptions);

    this.markerClusterer = new MarkerClusterer(
      this.map,
      [],
      this.clusterOptions
    );

    this.oms = new OverlappingMarkerSpiderfier(this.map, this.spiderOptions);

    google.maps.event.addListener(this.map, 'click', () => {
      this.closeWindow();
    });

    this.markersStyles = this.mapService.setupMarkerStyles();
  }

  loadSearchResults() {
    this.searchResultMarkers = [];
    this.searchResults.forEach((result) => {
      let latLng;
      let key;
      let mapStyle;
      switch (result.type) {
        case SearchableTypes.User:
          key = 'user';
          mapStyle = this.markersStyles.u0Marker;
          break;
        case SearchableTypes.Site:
          key = 'site';
          mapStyle = this.markersStyles.siteMarker;
          break;
        case SearchableTypes.Community:
          key = 'community';
          mapStyle = this.markersStyles.cMarker;
          break;
        case SearchableTypes.Event:
          key = 'event';
          mapStyle = this.markersStyles.eMarker;
          break;
        case SearchableTypes.Idea:
          key = 'idea';
          mapStyle = this.markersStyles.iMarker;
          break;
      }

      latLng = result.customAttributes['latLng']
        ? JSON.parse(result.customAttributes['latLng'])
        : null;
      if (latLng && key && mapStyle) {
        const marker = this.createMarker(latLng, mapStyle, key, result);
        this.searchResultMarkers.push(marker);
      }
    });
    this.addMarkers(this.searchResultMarkers);
  }

  createMarker(
    latLng: LatLng,
    icon: MarkerIcon,
    markerDataKey: string,
    markerData: any
  ) {
    const marker = new google.maps.Marker({
      position: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
      icon: icon,
    });
    marker[markerDataKey] = markerData;
    google.maps.event.addListener(marker, 'spider_click', () => {
      this.openInfoWindow(marker);
    });

    return marker;
  }

  addMarkers(markers) {
    if (this.oms && this.markerClusterer && markers.length) {
      markers.forEach((marker) => {
        this.oms.addMarker(marker);
      });
      this.markerClusterer.addMarkers(markers, false);
    }
  }

  clearMarkers(markers) {
    if (this.oms && this.markerClusterer && markers.length) {
      this.markerClusterer.removeMarkers(markers);
      if (this.oms) {
        markers.forEach((marker) => {
          this.oms.removeMarker(marker);
        });
      }
    }
  }

  openInfoWindow(marker: any) {
    if (!this.infoWindow) {
      this.infoWindow = new google.maps.InfoWindow();
    }

    const content = this.getInfoWindowContent(marker);
    if (content) {
      this.infoWindow.setContent(content);
      this.infoWindow.open(this.map, marker);
      this.cdr.detectChanges();
    }
  }

  getInfoWindowContent(marker): HTMLDivElement {
    if (marker) {
      let cardTemplate;
      let context;

      if (marker.user && this.userCardTemplate) {
        cardTemplate = this.userCardTemplate;
        context = {
          $implicit: marker.user,
          user: marker.user,
        };
      } else if (marker.site && this.siteCardTemplate) {
        cardTemplate = this.siteCardTemplate;
        context = {
          $implicit: marker.site,
          site: marker.site,
        };
      } else if (marker.community && this.communityCardTemplate) {
        cardTemplate = this.communityCardTemplate;
        context = {
          $implicit: marker.community,
          community: marker.community,
        };
      } else if (marker.event && this.eventCardTemplate) {
        cardTemplate = this.eventCardTemplate;
        context = {
          $implicit: marker.event,
          event: marker.event,
        };
      } else if (marker.idea && this.ideaCardTemplate) {
        cardTemplate = this.ideaCardTemplate;
        context = {
          $implicit: marker.idea,
          idea: marker.idea,
        };
      }

      if (cardTemplate) {
        // Create the embedded view with the provided context
        const embeddedView = this.infoWindowContainer.createEmbeddedView(
          cardTemplate,
          context
        );
        const infoWindowContent = document.createElement('div');
        // Append the root nodes of the embedded view to the info window content
        embeddedView.rootNodes.forEach((node) => {
          infoWindowContent.appendChild(node);
        });

        return infoWindowContent;
      }
    }

    return null;
  }

  closeWindow() {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }
}
