import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit, AfterViewInit {
  @Input() active: boolean;
  @Input() barbara = false;

  constructor() {}

  ngOnInit() {
    if (this.active === undefined && this.barbara) {
      this.active = true;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.active = false;
    }, 0);
  }
}
