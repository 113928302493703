import { Component, Input, OnInit } from '@angular/core';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AIAPIService } from 'app/core/aiapi.service';
import { map } from 'rxjs/operators';
// import { AIAPIService } from '../../ai/aiapi.service';

@Component({
  selector: 'app-chatbot-preview',
  templateUrl: './chatbot-preview.component.html',
  styleUrls: ['./chatbot-preview.component.scss'],
})
export class ChatbotPreviewComponent implements OnInit {
  params$;
  chatId: string;
  // chatSrc: SafeResourceUrl;
  theme;
  chatflowConfig;
  constructor(
    private route: ActivatedRoute,
    private api: AIAPIService // private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.params$ = this.route.params.pipe(
      map((params) => {
        this.chatId = undefined;
        setTimeout(() => {
          this.chatId = params['id'];
          if (this.chatId) {
            // this.chatSrc = this.getSafeLink(this.chatId);
            this.theme = this.api.getThemeById(this.chatId);
            this.chatflowConfig = this.api.getConfigById(this.chatId);
          }
        }, 0);
      })
    );
  }

  // private getSafeLink(key: string): SafeResourceUrl {
  //   const url = `https://ai-minds.web.app/ai/chatbot/${key}`;
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  // }
}
