import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Job } from 'app/stepper/job/model/job';
import { JobService } from 'app/core/job.service';
import { SearchResultV2 } from 'app/shared/common/search.model';
import { environment } from 'environments/environment';
import { RoutingModel } from 'app/app.routing-model';
import { MatDialog } from '@angular/material/dialog';
import { JobApplicationComponent } from 'app/shared/components/ac/job-application/job-application.component';
import { AuthService } from 'app/core/auth.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { JobApplicationHelperComponent } from 'app/shared/components/ac/job-application-helper/job-application-helper.component';
import { JobPreviewDialogComponent } from '../job-preview-dialog/job-preview-dialog.component';
import { IFilter } from 'app/shared/models';
import {
  Deadline,
  getDeadlineState,
  getFiltersFromSearchResults,
} from '@helpers';
import { acaConfig } from 'aca-config';
import { JobApplicationSimpleComponent } from '../ac/job-application-simple/job-application-simple.component';

@Component({
  selector: 'app-job-result-viewer',
  templateUrl: './job-result-viewer.component.html',
  styleUrls: ['./job-result-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobResultViewerComponent implements OnInit {
  @Input() jobResult: SearchResultV2;
  @Input() public showScoreBar = false;
  @Input() replaceContent = false;
  @Input() clickableTags = true;

  // job$: Observable<Job>;
  applicants$: Observable<any[]>;
  applicationAllowed: boolean;
  hasPublicCV: boolean;
  currentUser: MondoUser;
  expanded = false;
  filters: IFilter[] = [];
  deadlineState = Deadline;
  shareUrl: string;

  constructor(
    private jobService: JobService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // this.job$ = this.jobService.getPublishedJob(this.jobResult.id);
    this.applicants$ = this.jobService.getNumberOfCurrentApplicants(
      this.jobResult.id
    );
    this.currentUser = this.authService.getCurrentUser();
    this.hasPublicCV = this.currentUser
      ? !!this.currentUser.publishedCv
      : false;
    this.filters = getFiltersFromSearchResults(this.jobResult);

    this.shareUrl = `${environment.academondoUrl}/job/${this.jobResult.id}`;
  }

  get canSeeApplyJobButton() {
    return this.authService.canSeeApplyJobButton;
  }

  getDeadline(): Deadline {
    const endDate = new Date(this.jobResult.expiryDate);
    return getDeadlineState(endDate);
  }

  showPreview(key: string): void {
    // this.jobService.logView(job); // fix me
    this.dialog.open(JobPreviewDialogComponent, {
      maxWidth: '85vw',
      panelClass: 'preview-dialog',
      data: { key: key },
    });
  }

  showJob(job: Job): void {
    window
      .open(
        environment.academondoUrl + RoutingModel.job.route + '/' + job.key,
        '_blank'
      )
      .focus();
  }

  public applyForJob(event, applicants: string[]): void {
    event.preventDefault();
    if (acaConfig.registerVisitFunctions) {
      this.applyDialog(this.jobResult);
    } else {
      this.applySimpleDialog(this.jobResult);
    }
  }

  public showApplicationHelper(event, applicants: string[]): void {
    event.preventDefault();
    this.applicationHelperDialog(this.jobResult);
  }

  private applyDialog(job: SearchResultV2): void {
    this.dialog.open(JobApplicationComponent, {
      maxWidth: '460px',
      maxHeight: '98vh',
      width: '100%',
      panelClass: 'plan-dialog',
      data: { job: job },
    });
  }

  private applySimpleDialog(job: SearchResultV2): void {
    this.dialog.open(JobApplicationSimpleComponent, {
      maxWidth: '460px',
      maxHeight: '98vh',
      width: '100%',
      panelClass: 'plan-dialog',
      data: { job: job },
    });
  }

  private applicationHelperDialog(job: SearchResultV2): void {
    this.dialog.open(JobApplicationHelperComponent, {
      maxWidth: '760px',
      maxHeight: '98vh',
      width: '100%',
      panelClass: 'plan-dialog',
      data: { job: job },
    });
  }

  public hasUserApplied(applicants: string[]): boolean {
    return applicants.some((element) => {
      return element === (this.currentUser ? this.currentUser.uid : -1);
    });
  }

  public applyButtonText(applicants: string[]): string {
    if (this.hasUserApplied(applicants)) {
      return 'userAppliedToJob';
    } else {
      return 'Apply';
    }
  }

  public applySimpleButtonText(applicants: string[]): string {
    if (this.hasUserApplied(applicants)) {
      return 'ShowInterestApliedSimple';
    } else {
      return 'ShowInterestApplySimple';
    }
  }

  getApplicantsCounterDisplayText(currentApplicants, maxApplicants): string {
    if (currentApplicants >= maxApplicants) {
      return 'maxApplicationsReceived';
    } else {
      return '';
    }
  }

  createMailtoHref(): string {
    const displayName = this.jobResult.facilityName
      ? acaConfig.language === 'da'
        ? `Hej ${this.jobResult.facilityName},`
        : `Hi ${this.jobResult.facilityName},`
      : '';
    const subject = `${
      acaConfig.language === 'da'
        ? 'Forespørgelse på dit opslag:'
        : 'Request regarding your post:'
    } ${this.jobResult.customAttributes.fullName} ${
      acaConfig.language === 'da' ? 'på' : 'on'
    } ${acaConfig.academondo}`;

    const bodyPartTwo =
      acaConfig.language === 'da'
        ? `Jeg vil gerne høre mere om ${this.jobResult.customAttributes.fullName}`
        : `I would like to hear more about ${this.jobResult.customAttributes.fullName}`;
    const mailtoUrl = `mailto:${this.jobResult.customAttributes.contactEmail}
    ?subject=${encodeURI(subject)}
    &body=${encodeURI(displayName)}%0A
    %0A${encodeURI(bodyPartTwo)}
    `;
    return mailtoUrl;
  }

  canSeeCat1() {
    return this.jobService.canSeeCat1();
  }
}
