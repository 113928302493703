import { User } from 'firebase/app';
import { AccountPermission } from '../../consts/accountPermission';
import { AccountType } from '../../consts/accountType';
import { IFilter } from '../iFilter';

export class MondoUser implements JsonMondoUser<Date> {
  static fromFirebaseUser(
    fbUser: User,
    accountType: AccountType,
    dateCreated: Date
  ): MondoUser {
    return new MondoUser(
      fbUser.uid,
      dateCreated,
      fbUser.email,
      fbUser.photoURL,
      fbUser.displayName,
      accountType
    );
  }

  static fromJson(
    {
      dateCreated,
      lastLogin,
      email,
      photoURL,
      displayName,
      type,
      publishedCv,
      startUpInfo,
      version,
      welcomeEmail,
      filters,
      coverUrl,
      disableChat,
    }: JsonMondoUser<number>,
    uid: string,
    permission: AccountPermission
  ): MondoUser {
    return new MondoUser(
      uid,
      dateCreated ? new Date(dateCreated) : null,
      email,
      photoURL,
      displayName,
      type,
      permission,
      publishedCv,
      startUpInfo,
      lastLogin ? new Date(lastLogin) : null,
      version,
      welcomeEmail,
      filters,
      coverUrl,
      disableChat
    );
  }

  static toJson(user: MondoUser): JsonMondoUser<number> {
    return {
      email: user.email,
      dateCreated: user.dateCreated ? user.dateCreated.getTime() : null,
      lastLogin: user.lastLogin ? user.lastLogin.getTime() : null,
      photoURL: user.photoURL,
      displayName: user.displayName,
      type: user.type,
      publishedCv: user.publishedCv,
      startUpInfo: user.startUpInfo,
      version: user.version,
      welcomeEmail: user.welcomeEmail,
      filters: user.filters,
      coverUrl: user.coverUrl,
      disableChat: user.disableChat,
    };
  }

  constructor(
    public readonly uid: string,
    public dateCreated: Date = null,
    public email: string,
    public photoURL = '',
    public displayName = '',
    public type: AccountType = AccountType.scientist,
    public readonly permission: AccountPermission = AccountPermission.basic,
    public publishedCv = '',
    public startUpInfo: StartUpInfo = new StartUpInfo(),
    public lastLogin: Date = null,
    public version: MondoVersion = MondoVersion.version2,
    public welcomeEmail = true,
    public filters: IFilter[] = [],
    public coverUrl = '',
    public disableChat = false
  ) {}
}

export enum MondoVersion {
  version1, // initial version
  version2, // refactored cv database, jobs introduced
}

export class StartUpInfo {
  public static fromJson({
    hasSeenBuilderInfo,
    hasSeenJobInfo,
    hasSeenNetworkInfo,
    hasSeenSiteInfo,
  }) {
    return new StartUpInfo(
      hasSeenBuilderInfo,
      hasSeenJobInfo,
      hasSeenNetworkInfo,
      hasSeenSiteInfo
    );
  }
  constructor(
    public hasSeenBuilderInfo = false,
    public hasSeenJobInfo = false,
    public hasSeenNetworkInfo = false,
    public hasSeenSiteInfo = false
  ) {}
}

export interface JsonMondoUser<T> {
  dateCreated: T;
  lastLogin: T;
  email: string;
  photoURL: string;
  displayName: string;
  type: AccountType;
  publishedCv: string;
  startUpInfo: StartUpInfo;
  version: MondoVersion;
  welcomeEmail: boolean;
  filters: IFilter[];
  coverUrl: string;
  disableChat: boolean;
}
