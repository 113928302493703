export class Testimonial {
  constructor(
    public name = '',
    public title = '',
    public association = '',
    public imgUrl = '',
    public quote = '',
    public profileLink = '',
  ) { }
}
