import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieConsentDialogComponent } from './cookie-consent-dialog/cookie-consent-dialog.component';
import { AngularMaterialSharedModule } from 'app/angular-material-shared/angular-material-shared.module';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';

@NgModule({
  declarations: [CookieConsentDialogComponent],
  imports: [CommonModule, AngularMaterialSharedModule, SharedPipesModule],
  exports: [CookieConsentDialogComponent],
})
export class CookieConsentModule {}
