import { SearchableTypes } from '../../shared/common/search.model';
import { GroupType } from '../../groups/models/group-type';
import { Upload } from '../../shared/models';
import { ForumTopic } from './forum-topic';

export class ForumPost implements IForumPost<Date> {
  public static fromJson({
    ownerId,
    forumId,
    created,
    lastUpdate,
    latestComment,
    topic,
    groupId,
    groupType,
    email,
    phone,
    uploads,
    title,
    userTags,
    taggedUserIds,
    body,
    youtubeId,
    imageLink,
    imageUrl,
    key,
    numberOfComments,
    teaser,
    type,
  }: IForumPost<number>): ForumPost {
    return {
      key: key,
      type: SearchableTypes.GroupPublicPost,
      title: title,
      userTags: userTags,
      taggedUserIds: taggedUserIds,
      ownerId: ownerId,
      forumId: forumId,
      created: created ? new Date(created) : undefined,
      lastUpdate: lastUpdate ? new Date(lastUpdate) : undefined,
      latestComment: latestComment ? new Date(latestComment) : undefined,
      topic: topic,
      groupId: groupId,
      groupType: groupType,
      email: email,
      phone: phone,
      uploads: uploads,
      body: body,
      youtubeId: youtubeId,
      imageLink: imageLink,
      imageUrl: imageUrl,
      numberOfComments: numberOfComments,
      teaser: teaser || false,
    };
  }

  public static toJson(forumPost: ForumPost): IForumPost<number> {
    return {
      key: forumPost.key,
      type: SearchableTypes.GroupPublicPost,
      title: forumPost.title,
      userTags: forumPost.userTags ? forumPost.userTags : '',
      taggedUserIds: forumPost.taggedUserIds ? forumPost.taggedUserIds : [],
      ownerId: forumPost.ownerId,
      forumId: forumPost.forumId,
      created: forumPost.created ? forumPost.created.getTime() : null,
      lastUpdate: forumPost.lastUpdate ? forumPost.lastUpdate.getTime() : null,
      latestComment: forumPost.latestComment
        ? forumPost.latestComment.getTime()
        : null,
      topic: forumPost.topic ? ForumTopic.toJson(forumPost.topic) : null,
      groupId: forumPost.groupId,
      groupType: forumPost.groupType,
      email: forumPost.email,
      phone: forumPost.phone,
      uploads: forumPost.uploads || [],
      body: forumPost.body,
      youtubeId: forumPost.youtubeId,
      imageLink: forumPost.imageLink,
      imageUrl: forumPost.imageUrl,
      numberOfComments: forumPost.numberOfComments,
      teaser: forumPost.teaser || false,
    };
  }

  constructor(
    public key = null,
    public type = SearchableTypes.GroupPublicPost,
    public title = '',
    public userTags = '',
    public taggedUserIds: string[] = [],
    public body = '',
    public youtubeId = '',
    public imageLink = '',
    public imageUrl = '',
    public uploads: Upload[] = [],
    public phone = '',
    public email = '',
    public ownerId = '',
    public forumId = '',
    public created: Date = null,
    public lastUpdate: Date = null,
    public latestComment: Date = null,
    public topic: ForumTopic = new ForumTopic(),
    public groupId = '',
    public groupType = GroupType.Communities,
    public numberOfComments = 0,
    public teaser = false
  ) {}
}

export interface IForumPost<T> {
  key: string;
  type: SearchableTypes;
  title: string;
  userTags: string;
  taggedUserIds: string[];
  body: string;
  youtubeId: string;
  imageLink: string;
  imageUrl: string;
  uploads: Upload[];
  phone: string;
  email: string;
  ownerId: string;
  forumId: string;
  created: T;
  lastUpdate: T;
  latestComment: T;
  topic: ForumTopic;
  groupId: string;
  groupType: GroupType;
  numberOfComments: number;
  teaser: boolean;
}
