import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RoutingModel } from 'app/app.routing-model';
import { RoutingService } from 'app/core/routing.service';

@Component({
  selector: 'app-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingFooterComponent implements OnInit {
  @Input() hbp = false;

  constructor(
    private sanitizer: DomSanitizer,
    private routingService: RoutingService
  ) {}

  ngOnInit() {}

  getfbPagePluginLink(link: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  showTerms(e) {
    e.preventDefault();
    this.routingService.navigateToUrlWithDataArray(RoutingModel.gdpr.path, [
      'terms-and-conditions',
    ]);
  }

  showPrivacyPolicy(e) {
    e.preventDefault();
    this.routingService.navigateToUrlWithDataArray(RoutingModel.gdpr.path, [
      'privacy-policy',
    ]);
  }
}
