import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title = '';
  @Input() text = '';
  @Input() textX = '';
  @Input() textY = '';
  @Input() hint = '';
  @Input() hintX = '';
  @Input() hintY = '';
  @Input() yesText = '';
  @Input() noText = '';
  @Input() specialButtonText?: string;
  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

  ngOnInit() {}

  okOkClicked() {
    this.dialogRef.close(true);
  }

  okSpecialClicked() {
    this.dialogRef.close('special');
  }
  okCancelClicked() {
    this.dialogRef.close(null);
  }
}
