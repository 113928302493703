import { EducationLevel } from '../../../shared/models/cv/education-level';
import {
  FieldOfInterest,
  Technique,
  Category0,
  Category1,
} from '../../../shared/models';

export class ResearchQualifications implements JsonResearchQualifications {
  public static fromJson(
    {
      wantedFieldOfInterest,
      category0,
      category1,
      category2,
      category3,
      category4,
      category5,
      wantedTechniques,
      wantedDaysOfExperience0,
      wantedDaysOfExperience1,
      wantedDaysOfExperience2,
      wantedDaysOfExperience3,
      wantedDaysOfExperience4,
      wantedDaysOfExperience5,
      qualificationFreeText,
      minimumEducationalLevel,
    }: JsonResearchQualifications = {} as ResearchQualifications
  ) {
    return new ResearchQualifications(
      wantedFieldOfInterest,
      category0,
      category1,
      category2,
      category3,
      category4,
      category5,
      wantedTechniques,
      wantedDaysOfExperience0,
      wantedDaysOfExperience1,
      wantedDaysOfExperience2,
      wantedDaysOfExperience3,
      wantedDaysOfExperience4,
      wantedDaysOfExperience5,
      qualificationFreeText,
      minimumEducationalLevel
    );
  }
  constructor(
    public wantedFieldOfInterest: FieldOfInterest[] = [],
    public category0: Category0[] = [],
    public category1: Category1[] = [],
    public category2: Category0[] = [],
    public category3: Category0[] = [],
    public category4: Category0[] = [],
    public category5: Category0[] = [],
    public wantedTechniques: Technique[] = [],
    public wantedDaysOfExperience0 = 0,
    public wantedDaysOfExperience1 = 0,
    public wantedDaysOfExperience2 = 0,
    public wantedDaysOfExperience3 = 0,
    public wantedDaysOfExperience4 = 0,
    public wantedDaysOfExperience5 = 0,
    public qualificationFreeText = '',
    public minimumEducationalLevel = EducationLevel.none
  ) {}
}

export interface JsonResearchQualifications {
  wantedFieldOfInterest: FieldOfInterest[];
  category0: Category0[];
  category1: Category1[];
  category2: Category0[];
  category3: Category0[];
  category4: Category0[];
  category5: Category0[];
  wantedTechniques: Technique[];
  wantedDaysOfExperience0: number;
  wantedDaysOfExperience1: number;
  wantedDaysOfExperience2: number;
  wantedDaysOfExperience3: number;
  wantedDaysOfExperience4: number;
  wantedDaysOfExperience5: number;
  qualificationFreeText: string;
  minimumEducationalLevel: EducationLevel;
}
