import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { Note } from 'app/shared/models';
import { of } from 'rxjs';
import { DAO } from 'app/shared-services/db-access/dao';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(private db: DAO, private authService: AuthService) {}

  addNote({ uid, text }) {
    const currentUser = this.authService.getCurrentUser();

    const newPostKey = this.db.ref().child(DataConstants.ROOT_NOTES).push().key;

    const note = new Note(
      currentUser.uid,
      currentUser.displayName || currentUser.uid,
      text,
      newPostKey,
      uid
    );

    // Write the new post's data simultaneously in the posts list and the user's post list
    const updates = {};
    updates[`${DataConstants.ROOT_NOTES}${uid}/${newPostKey}`] = note;
    // updates['/user-posts/' + uid + '/' + newPostKey] = note; "Atomic update!"

    return this.db.ref().update(updates, (error) => {
      if (error) {
        this.authService.notEnoughPermission('defaultError', 3000);
      }
    });
  }
  deleteNote(key: string, entityUid: string) {
    const updates = {};
    updates[`${DataConstants.ROOT_NOTES}${entityUid}/${key}`] = null;
    // updates['/user-posts/' + uid + '/' + newPostKey] = note; "Atomic update!"

    return this.db.ref().update(updates, (error) => {
      if (error) {
        this.authService.notEnoughPermission('defaultError', 3000);
      }
    });
  }

  getListOfNotesPerUser(uid) {
    if (uid) {
      return this.db.list$(DataConstants.ROOT_NOTES + uid, (ref) =>
        ref.orderByChild('created').limitToLast(50)
      );
    } else {
      return of([]);
    }
  }
}
