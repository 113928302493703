import { MailFrequency } from '../src/app/notifications-new/models/mail-frequency';
import { acaConfig } from '../src/aca-config';
import { acaNames } from '../src/aca-names';

const common = {
  yourPost: 'your post',
  yourPosts: 'your posts',
  aPost: 'a post',
  thePost: 'the post',
  ThePost: 'The post',
  newPost: 'new post',
  NewPost: 'New post',
  NewPosts: 'New posts',
  post: 'post',
  Post: 'Post',
  posts: 'posts',
  Posts: 'Posts',
  PostBuilder: 'Post builder',
};

export const hardcodedValuesENG = {
  academondo: acaConfig.academondo,
  academondoDash: acaConfig.academondoDash,
  academondoCom: acaConfig.academondoCom,
  academondoUrl: acaConfig.academondoUrl,
  supportMail: acaConfig.supportMail,
  reviewMail: acaConfig.reviewMail,
  senderMail: acaConfig.senderMail,
  defaultUserCoverUrl: acaConfig.defaultUserCoverUrl,
  defaultEventCoverUrlCredit: acaConfig.defaultEventCoverUrlCredit,
  defaultEventCoverUrl: acaConfig.defaultEventCoverUrl,
  defaultCommunityCoverUrlCredit: acaConfig.defaultCommunityCoverUrlCredit,
  defaultCommunityCoverUrl: acaConfig.defaultCommunityCoverUrl,
  defaultIdeaCoverUrlCredit: acaConfig.defaultIdeaCoverUrlCredit,
  defaultIdeaCoverUrl: acaConfig.defaultIdeaCoverUrl,
  welcomeWidgetCoverUrlCredit: acaConfig.welcomeWidgetCoverUrlCredit,
  welcomeWidgetCoverUrl: acaConfig.welcomeWidgetCoverUrl,
  welcomeWidgetIcon: acaConfig['welcomeWidgetIcon']
    ? acaConfig['welcomeWidgetIcon']
    : false,
  welcomeWidgetIconInv: acaConfig['welcomeWidgetIconInv']
    ? acaConfig['welcomeWidgetIconInv']
    : false,
  welcomeWidgetLogo: acaConfig['welcomeWidgetLogo']
    ? acaConfig['welcomeWidgetLogo']
    : false,
  welcomeWidgetLogoInv: acaConfig['welcomeWidgetLogoInv']
    ? acaConfig['welcomeWidgetLogoInv']
    : false,
  EUWelcomeWidgetIcon: acaConfig['EUWelcomeWidgetIcon']
    ? acaConfig['EUWelcomeWidgetIcon']
    : false,
  EUWelcomeWidgetLogo: acaConfig['EUWelcomeWidgetLogo']
    ? acaConfig['EUWelcomeWidgetLogo']
    : false,
  welcomeWidgetVideo: acaConfig['welcomeWidgetVideo']
    ? acaConfig['welcomeWidgetVideo']
    : false,
  welcomeWidgetVideoHeadline: acaConfig['welcomeWidgetVideoHeadline']
    ? acaConfig['welcomeWidgetVideoHeadline']
    : false,
  welcomeWidgetVideoPrimaryColorBackground: acaConfig[
    'welcomeWidgetVideoPrimaryColorBackground'
  ]
    ? acaConfig['welcomeWidgetVideoPrimaryColorBackground']
    : false,
  showWelcomeWidget: acaConfig.showWelcomeWidget,

  // cookies
  AcceptFunctionalCookies: `Got it`,
  AcceptAllCookies: `Accept all`,
  AcceptLearnMore: `Learn more`,
  CookieOkay: `Okay`,
  get CookieInfoFunctional() {
    return acaConfig['CookieInfoFunctionalText']
      ? acaConfig['CookieInfoFunctionalText']
      : `By continuing to browse ${this.academondo}, you agree to the use of cookies to remember your login after you close the browser.`;
  },
  get CookieInfoMarketing() {
    return acaConfig['CookieInfoMarketingText']
      ? acaConfig['CookieInfoMarketingText']
      : `By continuing to browse ${this.academondo}, you agree to the use of cookies to remember your login after you close the browser.`;
  },
  get CookieDisclaimerFunctional() {
    return acaConfig['CookieDisclaimerFunctionalText']
      ? acaConfig['CookieDisclaimerFunctionalText']
      : `The cookies used for ${this.academondo} doesn't track personal information.`;
  },
  get CookieDisclaimerMarketing() {
    return acaConfig['CookieDisclaimerMarketingText']
      ? acaConfig['CookieDisclaimerMarketingText']
      : `${this.CookieDisclaimerFunctional}
    
    If you accept all cookies, we can display a LinkedIn feed for you. However, this includes the use of tracking cookies.`;
  },

  get googleFonts() {
    return acaConfig['googleFonts']
      ? acaConfig['googleFonts']
      : `https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,500,600,700|Roboto:200,300,400,500,600,700|Oswald:200,300,400,500,600,700|Material+Icons&display=swap`;
  },

  // sign up
  byContinuingYouConsent:
    'By continuing you consent and agree to the processing of your data on the following',
  termsLink: acaConfig.termsLink,
  termsText: acaConfig['termsText'] ? acaConfig['termsText'] : '',
  terms: 'terms',
  Terms: 'Terms',
  howWeTreatYourData: 'If you want to see how we treat your data - see our',
  privacyPolicyLink: acaConfig.privacyPolicyLink,
  privacyPolicyText: acaConfig['privacyPolicyText']
    ? acaConfig['privacyPolicyText']
    : '',
  get privacyPolicy() {
    return acaNames['privacyPolicy']
      ? acaNames['privacyPolicy']
      : 'privacy policy';
  },
  get PrivacyPolicy() {
    return acaNames['PrivacyPolicy']
      ? acaNames['PrivacyPolicy']
      : 'Privacy policy';
  },
  combineTermsAndPolicy: acaConfig['combineTermsAndPolicy']
    ? acaConfig['combineTermsAndPolicy']
    : false,
  combineTermsAndPolicyText: acaNames['combineTermsAndPolicyText']
    ? acaNames['combineTermsAndPolicyText']
    : '',
  copyRightDate: acaConfig.copyRightDate,
  showTermsInNavbarMenu: acaConfig.showTermsInNavbarMenu,

  webAccessibilityLink: acaConfig['webAccessibilityLink']
    ? acaConfig['webAccessibilityLink']
    : '',
  webAccessibility: 'web accessibility',
  WebAccessibility: 'Web accessibility',

  chatbotBuilderApiHost: acaConfig['chatbotBuilderApiHost']
    ? acaConfig['chatbotBuilderApiHost']
    : '',

  chatbotLangFuseApiHost: acaConfig['chatbotLangFuseApiHost']
    ? acaConfig['chatbotLangFuseApiHost']
    : '',
  chatbotLangSmithApiHost: acaConfig['chatbotLangSmithApiHost']
    ? acaConfig['chatbotLangSmithApiHost']
    : '',

  notEnoughPermission: acaNames.notEnoughPermission,
  YouDontHaveAccess: `You don't have access to that page`,
  welcomeToAcademondo: `Welcome to ${acaConfig.academondo}`,
  welcomeToAcademondoDash: `Welcome to ${acaConfig.academondo}`,
  welcomeToPublicUserProfile: 'Welcome to your public profile',
  suggestionSalute: `Thank you for making ${acaConfig.academondo} better, your suggestion will be reviewed`,
  sendSuggestion: 'Send suggestion',

  academondoStreet: acaConfig.academondoStreet,
  academondoPostboxCity: acaConfig.academondoPostboxCity,
  academondoCVR: acaConfig.academondoCVR,
  academondoPhone: acaConfig.academondoPhone,

  twitterLink: acaConfig.twitterLink,
  facebookLink: acaConfig.facebookLink,
  linkedinLink: acaConfig.linkedinLink,
  instagramLink: acaConfig.instagramLink,
  fbPagePluginLink: acaConfig.fbPagePluginLink,

  // intro dialog/help
  welcomeToHeader: `Welcome to ${acaNames.dashboardWelcomeTitle}`,
  // intro dialog - cv
  helpheaderCV: acaNames.helpheaderCV,
  helpLineOneCV: acaNames.helpLineOneCV,
  helpLineTwop1CV: acaNames.helpLineTwop1CV,
  helpLineTwop2CV: acaNames.helpLineTwop2CV,
  helpLineTwop3CV: acaNames.helpLineTwop3CV,
  helpLineThreep1CV: acaNames.helpLineThreep1CV,
  helpLineThreep2CV: acaNames.helpLineThreep2CV,

  // intro dialog - job
  helpheaderJob: acaNames.helpheaderJob,
  helpLineOneJob: acaNames.helpLineOneJob,
  helpLineTwoJob: acaNames.helpLineTwoJob,

  scienceMatchRoute: acaNames.scienceMatchRoute,
  scienceMatch: acaNames.scienceMatch,
  scienceMatchSubtitle: acaNames.scienceMatchSubtitle,
  scienceMatchSearchResults: 'Search Results',
  scienceMatchMatchYourTags: 'Match your tags',
  showSearchSideBySide: `Side by side`,
  showSearchOneColumn: `One column`,
  term: 'term',
  SearchByX: `Search by {0}`,
  chooseX: `Filter by {0}...`,
  wantedX: `Wanted {0}`,
  suggestNewX: `Suggest {0}`,
  areYouSureWantToDeleteX: 'Are you sure you want to delete {0}?',
  facility: acaNames['facility'] ? acaNames['facility'] : 'workplace',
  Facility: acaNames['Facility'] ? acaNames['Facility'] : 'Workplace',
  name: 'name',
  hideAdvancedSearchOptions: 'Hide advanced search',
  showAdvancedSearchOptions: 'Show advanced search',
  showInvitedAndMembers: `Show invited and ${acaNames.members}`,
  showMaxSearchResultSelector: acaConfig.showMaxSearchResultSelector,
  maxDistanceInSearch: acaConfig.maxDistanceInSearch,
  maxSearchResults: acaConfig.maxSearchResults,
  showProfileWidgetStats: acaConfig.showProfileWidgetStats,
  showCreateButtonCommunityInProfileWidget:
    acaConfig.showCreateButtonCommunityInProfileWidget,
  showCreateButtonEventInProfileWidget:
    acaConfig.showCreateButtonEventInProfileWidget,
  showCreateButtonIdeaInProfileWidget:
    acaConfig.showCreateButtonIdeaInProfileWidget,

  ifYouHaveSuggestion: `If you have a suggestion that you would like to add to our database we would like to hear from you. Please fill out the form below.`,

  description: 'description',
  Description: 'Description',

  TagDescRequired: acaConfig['TagDescRequired']
    ? acaConfig['TagDescRequired']
    : false,

  category: 'Category',
  Category: 'Category',
  categories: 'Categories',
  foi: `${acaNames.foi}`,
  Foi: `${acaNames.Foi}`,
  interests: `${acaNames.shortFoi}`,
  Interests: `${acaNames.ShortFoi}`,
  fois: `${acaNames.fois}`,
  Fois: `${acaNames.Fois}`,

  tech: acaNames.tech,
  Tech: acaNames.Tech,
  techs: acaNames.techs,
  Techs: acaNames.Techs,

  companySubtype: acaNames.companySubtype,
  CompanySubtype: acaNames.CompanySubtype,
  companySubtypes: acaNames.companySubtypes,
  CompanySubtypes: acaNames.CompanySubtypes,

  network: acaNames.network,
  Network: acaNames.Network,
  networks: acaNames.networks,
  Networks: acaNames.Networks,
  networkSubtype: acaNames['networkSubtype'] ? acaNames['networkSubtype'] : '',
  NetworkSubtype: acaNames['NetworkSubtype'] ? acaNames['NetworkSubtype'] : '',
  networkSubtypes: acaNames['networkSubtypes']
    ? acaNames['networkSubtypes']
    : '',
  NetworkSubtypes: acaNames['NetworkSubtypes']
    ? acaNames['NetworkSubtypes']
    : '',
  language: 'language',
  Language: 'Language',
  languages: 'languages',
  Languages: 'Languages',

  useLanguageForCV: acaNames.useLanguageForCV,
  category0: `${acaNames.category0}`,
  Category0: `${acaNames.Category0}`,
  categories0: `${acaNames.categories0}`,
  Categories0: `${acaNames.Categories0}`,
  category1: `${acaNames.category1}`,
  Category1: `${acaNames.Category1}`,
  categories1: `${acaNames.categories1}`,
  Categories1: `${acaNames.Categories1}`,
  category2: `${acaNames.category2}`,
  Category2: `${acaNames.Category2}`,
  categories2: `${acaNames.categories2}`,
  Categories2: `${acaNames.Categories2}`,
  category3: `${acaNames.category3}`,
  Category3: `${acaNames.Category3}`,
  categories3: `${acaNames.categories3}`,
  Categories3: `${acaNames.Categories3}`,
  category4: `${acaNames.category4}`,
  Category4: `${acaNames.Category4}`,
  categories4: `${acaNames.categories4}`,
  Categories4: `${acaNames.Categories4}`,
  category5: `${acaNames.category5}`,
  Category5: `${acaNames.Category5}`,
  categories5: `${acaNames.categories5}`,
  Categories5: `${acaNames.Categories5}`,
  Category0Hint: `${acaNames.Category0Hint}`,
  Category0Link: `${acaNames.Category0Link}`,
  Category0Dialog: acaNames.Category0Dialog,
  Category0Tooltip: acaNames.Category0Tooltip,
  Category1Hint: `${acaNames.Category1Hint}`,
  Category1Link: `${acaNames.Category1Link}`,
  Category1Dialog: acaNames.Category1Dialog,
  Category1Tooltip: acaNames.Category1Tooltip,
  Category2Hint: `${acaNames.Category2Hint}`,
  Category2Link: `${acaNames.Category2Link}`,
  Category2Dialog: acaNames.Category2Dialog,
  Category2Tooltip: acaNames.Category2Tooltip,
  Category3Hint: `${acaNames.Category3Hint}`,
  Category3Link: `${acaNames.Category3Link}`,
  Category3Dialog: acaNames.Category3Dialog,
  Category3Tooltip: acaNames.Category3Tooltip,
  Category4Hint: `${acaNames.Category4Hint}`,
  Category4Link: `${acaNames.Category4Link}`,
  Category4Dialog: acaNames.Category4Dialog,
  Category4Tooltip: acaNames.Category4Tooltip,
  Category5Hint: `${acaNames.Category5Hint}`,
  Category5Link: `${acaNames.Category5Link}`,
  Category5Dialog: acaNames.Category5Dialog,
  Category5Tooltip: acaNames.Category5Tooltip,
  FoiHint: `${acaNames.FoiHint}`,
  FoiLink: `${acaNames.FoiLink}`,
  FoiDialog: acaNames.FoiDialog,
  FoiTooltip: acaNames.FoiTooltip,
  TechHint: `${acaNames.TechHint}`,
  TechLink: `${acaNames.TechLink}`,
  TechDialog: acaNames.TechDialog,
  TechTooltip: acaNames.TechTooltip,
  showCategories3InCVEducationStep: acaNames.showCategories3InCVEducationStep,

  // email validation
  pressBelowToValidateEmail: 'Press the button below to validate your email.',
  validateEmail: 'Validate email',
  validateEmailAndChangeEmail: 'Validate email and change email',
  validationFailedResendEmail:
    '❗ Validation failed, resend the validation email and check your inbox/spam folder.',
  resendValidationEmail: 'Resend validation email',
  enterNewPassword: 'Enter your new password',
  confirmPassword: 'Confirm password',
  theGivenEmailIsNotVerified: 'This account is not verified',
  thatEmailAlreadyExists: 'An email has been sent.',
  wrongEmailOrPassword:
    'If you have an account, check that you have entered your email and password correctly.',
  wrongEmail: 'Email badly formatted',
  accountDisabled: 'Account disabled',
  unknownError: 'Unknown error!',
  'Unknown error!': 'Unknown error!',
  forErrorsTryAgain: ' for errors and try again.',
  duplicateNewName: ' copy',

  thankYouForUsing: `Thank you for using ${acaConfig.academondo}! :)`,
  internetExplorerEdgeNotSupported: `Internet Explorer is not supported by ${acaConfig.academondo}.`,
  pleaseUseModernBrowser:
    'Please use a modern browser like Chrome, Firefox, Safari or Edge',
  getChrome: 'Get Chrome',
  accountSelector1: acaNames.accountSelector1,
  accountSelector2: acaNames.accountSelector2,
  accountSelector3: acaNames.accountSelector3,
  accountSelector4: acaNames.accountSelector4,

  forceMembership: 'Add',
  forceMembershipHint: `You are about to impose membership`,
  inviteToAcademondo: `Invite to ${acaConfig.academondo}`,
  inviteToAcademondoText: acaNames.inviteToAcademondoText,
  Invite: 'Invite',
  ok: 'ok',
  Ok: 'Ok',
  invite: 'Invite',
  Uninvite: 'Withdraw invitation',
  begin: 'Begin',
  enter: 'Enter',
  cancel: 'Cancel',
  confirm: 'Confirm',

  // Chat Bot
  ChatBot: 'Chat bot',
  get ChatBotText() {
    return `Title`;
  },
  get groupChatBotTextLabel() {
    return `${this.ChatBot} title`;
  },
  get groupChatBotTextTooltip() {
    return `Give the ${this.ChatBot} a name`;
  },
  get ChatBotKey() {
    return `Key`;
  },
  get groupChatBotKeyLabel() {
    return `${this.ChatBot} key`;
  },
  get groupChatBotKeyTooltip() {
    return `add a key to enable the ${this.ChatBot}`;
  },

  // Kanban
  get Kanban() {
    return acaNames['Kanban'] ? acaNames['Kanban'] : 'Kanban';
  },
  KanbanDescription:
    'Streamline your project management with our kanban app featuring drag-and-drop tasks, descriptions, color selectors, deadlines, and countdown timers.',
  ChooseATemplate: 'Choose a template',
  OrStartFromScratch: 'Or start from scratch',
  groupKanbanEnabled: acaConfig.groupKanbanEnabled,
  get showGroupKanbanPlugin() {
    return `Enable ${this.Kanban}`;
  },
  Drag: 'Drag',
  task: 'task',
  Task: 'Task',
  board: 'board',
  Board: 'Board',

  useCtrlPlusNumber: 'Use Ctrl + number to select color',
  get addKanban() {
    return `${this.add} ${this.Kanban} `;
  },
  get KanbanName() {
    return `${this.Kanban} title`;
  },
  get KanbanText() {
    return `What shall we call this ${this.Kanban}?`;
  },
  get addTask() {
    return `${this.add} ${this.task}`;
  },
  get updateTask() {
    return `${this.Update} ${this.task}`;
  },
  get TaskDescription() {
    return `${this.Task} ${this.description} `;
  },
  get addBoard() {
    return `${this.add} ${this.board} `;
  },
  get BoardTitle() {
    return `${this.Board} title`;
  },
  get BoardText() {
    return `What shall we call this ${this.board}?`;
  },

  acceptAccess: 'Accept',
  acceptRequestForMembership: 'Accept request',
  rejectRequestForMembership: 'Reject request',
  request: 'request',
  requested: 'requested',
  Request: 'Request',
  Requested: 'Requested',
  invited: 'invited',
  Invited: 'Invited',
  selectedUsers: 'selected users?',
  search: 'Search',
  searchFor: 'Search for',

  showSearchForXButtonInProfileWidget:
    acaConfig.showSearchForXButtonInProfileWidget,
  showSearchForXButtonInProfileWidgetText:
    acaConfig.showSearchForXButtonInProfileWidgetText,

  useEnterForSearch: acaConfig['useEnterForSearch']
    ? acaConfig['useEnterForSearch']
    : false,

  clearSearch: 'Clear search',
  withinARadiusOf: 'Within a distance of',
  radius: 'Km',
  distanceFromYou: 'Distance',

  showAvailableJobs: `Currently available ${acaNames.jobs}`,
  showExpiredJobs: `Expired ${acaNames.jobs}`,

  mostRecentExperience: 'Most recent experience',
  academicTitle: acaNames.academicTitle,
  subTitle: acaNames.subTitle,
  achievedAcademicDegree: 'Achieved academic degree',
  any: 'Any',
  NearYou: 'Near you',

  Experience0: acaNames.Experience0,
  experience0: `${acaNames.experience0}`,
  experience0Years: `${acaNames.Experience0} years`,
  ofExperience0Experience: `of ${acaNames.experience0} experience`,
  yearsOfExperience0: `y of ${acaNames.experience0} exp`,

  Experience1: acaNames.Experience1,
  experience1: `${acaNames.experience1}`,
  experience1Years: `${acaNames.Experience1} years`,
  ofExperience1Experience: `of ${acaNames.experience1} experience`,
  yearsOfExperience1: `y of ${acaNames.experience1} exp`,

  Experience2: acaNames.Experience2,
  experience2: `${acaNames.experience2}`,
  experience2Years: `${acaNames.Experience2} years`,
  ofExperience2Experience: `of ${acaNames.experience2} experience`,
  yearsOfExperience2: `y of ${acaNames.experience2} exp`,

  Experience3: acaNames.Experience3,
  experience3: `${acaNames.experience3}`,
  experience3Years: `${acaNames.Experience3} years`,
  ofExperience3Experience: `of ${acaNames.experience3} experience`,
  yearsOfExperience3: `y of ${acaNames.experience3} exp`,

  Experience4: acaNames.Experience4,
  experience4: `${acaNames.experience4}`,
  experience4Years: `${acaNames.Experience4} years`,
  ofExperience4Experience: `of ${acaNames.experience4} experience`,
  yearsOfExperience4: `y of ${acaNames.experience4} exp`,

  Experience5: acaNames.Experience5,
  experience5: `${acaNames.experience5}`,
  experience5Years: `${acaNames.Experience5} years`,
  ofExperience5Experience: `of ${acaNames.experience5} experience`,
  yearsOfExperience5: `y of ${acaNames.experience5} exp`,
  ExperienceUnit0: acaNames.ExperienceUnit0,
  ExperienceUnit1: acaNames.ExperienceUnit1,
  ExperienceUnit2: acaNames.ExperienceUnit2,
  ExperienceUnit3: acaNames.ExperienceUnit3,
  ExperienceUnit4: acaNames.ExperienceUnit4,
  ExperienceUnit5: acaNames.ExperienceUnit5,

  country: 'Country',
  degree: 'Degree',
  viewMore: 'View more',
  viewLess: 'View less',
  viewProfile: 'View profile',
  searchForAcademondoCompanyProfiles: `Search for ${acaNames.sites}`,
  searchForAcademondoProfiles: `Search for ${acaNames.users}`,
  PublishedCVProfileTag: `${acaNames.Published} ${acaConfig.academondo} profile`,
  create: 'create',
  Create: 'Create',
  account: 'account',
  Account: 'Account',
  accountType: 'Account type',
  changeAccountType: 'Change account type',
  createAccount: 'Create account',
  createNewCompany: `Create ${acaNames.newsite}`,
  createNew: 'Create new',
  companyName: acaNames.companyName,
  CompanyName: acaNames.CompanyName,
  CompanyNameJob: acaNames.CompanyNameJob,
  login: 'Login',
  myProfile: 'My Profile',
  MagicEmailSignIn: 'with our passwordless auth',
  GetLoginLink: 'Get the link',
  checkYourEmailForMagicLoginLink: 'Check your email for our magic link',
  'The email provided does not match the sign-in email address.':
    'The email provided does not match the sign-in email address',
  'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.':
    'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
  enableMagicLink: acaConfig.enableMagicLink,

  iconIsUsedProfilePicture: 'Icon is used as profile picture in the forum',
  iconIsUsedOnMap: 'Icon is used on map',
  pleaseEnterValidUrl: 'Please enter a valid url',
  websiteUrl: acaNames.siteWebsiteUrl,
  networkWebsiteUrl: 'Website url',

  writeInfoAboutCompany: acaNames.writeInfoAboutCompany,
  writeQualificationsPosition: acaNames.writeQualificationsPosition,
  iAmACompany: `I am ${acaNames.asite}`,
  iAmAUser: `I am ${acaNames.auser}`,
  UserDoesntExistAnymore: `Anonymous`,

  selected: 'Selected',
  JobType: `${acaNames.JobType}`,
  viewJob: `View ${acaNames.job}`,
  job: acaNames.job,

  JobType0: `${acaNames.JobType0}`,
  JobType1: `${acaNames.JobType1}`,
  JobType2: `${acaNames.JobType2}`,
  JobType3: `${acaNames.JobType3}`,
  JobType4: `${acaNames.JobType4}`,
  JobType5: `${acaNames.JobType5}`,
  JobType6: `${acaNames.JobType6}`,
  JobType7: `${acaNames.JobType7}`,
  JobType8: `${acaNames.JobType8}`,
  JobType9: `${acaNames.JobType9}`,

  jobType0Desc: `${acaNames.jobType0Desc}`,
  jobType1Desc: `${acaNames.jobType1Desc}`,
  jobType2Desc: `${acaNames.jobType2Desc}`,
  jobType3Desc: `${acaNames.jobType3Desc}`,
  jobType4Desc: `${acaNames.jobType4Desc}`,
  jobType5Desc: `${acaNames.jobType5Desc}`,
  jobType6Desc: `${acaNames.jobType6Desc}`,
  jobType7Desc: `${acaNames.jobType7Desc}`,
  jobType8Desc: `${acaNames.jobType8Desc}`,
  jobType9Desc: `${acaNames.jobType9Desc}`,

  newCompany: `${acaNames.Newsite}`,
  company: acaNames.site,
  Company: acaNames.Site,
  CompanyJobStep: acaNames.CompanyJobStep,
  companies: acaNames.sites,
  Companies: acaNames.Sites,
  user: acaNames.user,
  User: acaNames.User,
  users: acaNames.users,
  Users: acaNames.Users,

  admin: 'Admin',
  Specials: acaNames.Specials,
  UserType0: acaNames.UserType0,
  UserTypes0: acaNames.UserTypes0,
  UserType1: acaNames.UserType1,
  UserTypes1: acaNames.UserTypes1,
  UserType2: acaNames.UserType2,
  UserTypes2: acaNames.UserTypes2,
  UserType3: acaNames.UserType3,
  UserTypes3: acaNames.UserTypes3,
  UserType4: acaNames.UserType4,
  UserTypes4: acaNames.UserTypes4,
  UserType5: acaNames.UserType5,
  UserTypes5: acaNames.UserTypes5,

  AllUserTypesWithoutCV: `${acaNames.Users} without ${acaNames.cv}`,
  AllUserTypesWithCV: `${acaNames.Users} with ${acaNames.cv}`,
  PermissionGrantedTo: 'Permission granted to',
  AccountWasNotFoundWithThisEmail: 'Account was not found with this email',
  UsertypeChanged: 'Usertype changed',
  Grant: 'Grant',
  GrantOwnership: 'Grant Ownership',
  GrantOwnershipAndRemoveOldOwner: 'Grant Ownership And Remove Old Owner',
  ThisCannotBeUndone: 'This cannot be undone',
  permissionLevel: 'permission level',
  Permission: 'Permission',

  get ChangePermissionLevel() {
    return `${this.Change} ${this.permissionLevel}`;
  },
  sidePanel: 'Side panel',
  visitOfficialWebsite: 'Website',
  get visitCVWebsite() {
    return acaNames['visitCVWebsite']
      ? acaNames['visitCVWebsite']
      : this.visitOfficialWebsite;
  },
  clickToCopy: 'Click to copy email',
  profileBuilder: `${acaNames.Site}`,

  InstallPWA: 'Get the app',
  iosInstallTitle: `Install Instructions for iOS`,
  iosInstallInfo: `Thank you for using our app! Unfortunately, iOS do not support automatic installation prompts for web apps. However, you can
    still easily add our app to your home screen by following these steps:`,
  iosInstallLI1: 'Open Safari and navigate to our website.',
  Tap: 'Tap',
  iosInstallLI2: '',
  iosInstallLI3:
    'Scroll down until you see the "Add to Home Screen" option and tap it.',
  iosInstallLI4: 'Enter a name for the shortcut if prompted and tap "Add".',
  iosInstallLI5:
    'The app icon should now be on your home screen! You can open it like any other app. This also allows you to enable push notifications. You find that option under notification settings',

  both: 'Both',

  unableToFindAnythingMatchingYourSearchCriteria:
    'We were not able to find anything matching your search criteria.',

  pleaseChooseSomeSearchCriteria: 'Please choose some search criteria.',

  modeSwitcher: 'Mode switcher',

  awaitingApproval: 'Applied',
  youHaveBeenInvited: 'You have been invited, already',

  contractLength: 'Contract length',
  permanentContract: acaNames.permanentContract,
  pasteAccessNumber: 'Paste access number and press search',
  year: 'year',
  Year: 'Year',
  s: 's', // set to '' if no ending on plural of year
  yearss: 'years',

  years: 'year(s)',
  months: 'month(s)',
  monthss: 'months',
  month: 'month',
  MonthsMayOnlyBeBetween0And11: 'Months may only be between 0 and 11.',
  minimumWorkExperience: 'Minimum work experience',
  minimumEducationalLevel: 'Minimum educational level',
  educationalLevel: 'Educational level',
  selectMinimumEducationalLevel: 'Select minimum educational level',

  // date picker
  startDate: 'Start date',
  endDate: 'End date',
  startTime: 'Start time',
  endTime: 'End time',

  // personal details
  firstName: 'First name',
  middleName: 'Middle name',
  showMiddleName: acaConfig.showMiddleName,
  allowMultipleItems: acaConfig.allowMultipleItems,
  surname: 'Surname',
  Sex: 'Sex',
  showSexInPersonalDetails: acaConfig.showSexInPersonalDetails,
  showDepartmentInPersonalDetails: acaConfig.showDepartmentInPersonalDetails,
  dateOfBirth: 'Date of birth',
  dateOfBirthHint: acaNames.dateOfBirthHint,

  address: 'Address',
  siteAddressDisclaimer: `Adding an address will put ${acaNames.thesite} on the map`,
  placeAddressDisclaimer: `Adding an address will put this ${acaNames.place} on the map`,
  cvAddressDisclaimer: acaNames['cvAddressDisclaimer']
    ? acaNames['cvAddressDisclaimer']
    : `Adding your address(professional or home) will put you on the map`,
  cvAddress: 'Address',
  HideAdressForUserProfiles: acaConfig['HideAdressForUserProfiles']
    ? acaConfig['HideAdressForUserProfiles']
    : false,

  addressSelectorInvalid: 'Enter a valid address...',

  uploadProfilePicture: 'Upload profile picture',
  writeAboutYourself: acaNames.writeAboutYourself,
  showGithub: acaNames.showGithub,
  showOrcId: acaNames.showOrcId,
  showTwitter: acaNames.showTwitter,
  personalGithub: 'Github',
  personalOrcId: 'ORCID',
  personalTwitter: 'Twitter',
  orcId: 'ORCID',
  github: 'Github',
  twitter: 'Twitter',
  enterAValidGithub: 'Enter a valid github url ex. https://www.github.com/user',
  githubUrl: 'https://www.github.com/user',
  orcIdUrl: 'https://www.orcid.org/...',
  enterAValidTwitter:
    'Enter a valid twitter url ex. https://www.twitter.com/user',
  twitterUrl: 'https://www.twitter.com/user',
  personalLinkedin: 'Personal LinkedIn',
  companyLinkedin: acaNames['companyLinkedin']
    ? acaNames['companyLinkedin']
    : 'LinkedIn',
  linkedin: 'LinkedIn',
  get linkedinRemark() {
    return acaNames['linkedinRemark']
      ? acaNames['linkedinRemark']
      : `When registering with ${this.linkedin}, kindly note that the primary email associated with your ${this.linkedin} account will be the one utilized for receiving notifications from ${this.academondo}.`;
  },
  enterAValidLinkedin:
    'Enter a valid linkedin url ex. https://www.linkedin.com/in/name',
  enterAValidUrl: 'Enter a valid url ex. https://academondo.com',
  linkedUrl: 'https://www.linkedin.com/in/name',

  // education
  titleOfEducation: 'Title',
  titleOfEducationHint: acaNames.titleOfEducationHint,
  lineOfEducation: 'Line of education',
  educationalInstitution: 'Institution',
  department: 'department',
  Department: 'Department',
  writeProjectsQualificationsDuringStudies:
    'Write about the projects and qualifications gained during the studies',
  currentEducation: 'Current education',
  newEducation: 'New education',

  // conference
  title: 'Title',
  NoTitle: 'No title',
  city: 'City',
  contribution: 'Contribution',
  insertAbstract: 'Insert abstract',
  writeConference: 'Write about the conference and your contribution',
  newWorkshopConference: 'New Workshop/Conference',

  // researchStay
  newResearchStayStudiesAbroad: 'New research stay / studies abroad',
  universityInstitution: 'University/institution',
  lab: 'Lab', // laboratory
  writeAboutResearchStayQualifications:
    'Write about the research stay and qualifications gained during the studies',
  currentResearchStay: 'Current research stay',

  // teaching exp
  titleOfTeachingExperience: 'Title of teaching experience',
  writeAboutSpecificTopics:
    'Write about the specific topics you were giving lectures in',
  currentlyTeaching: 'Currently teaching',
  newTeachingExperience: 'New Teaching Experience',

  // work exp
  writeAboutPositionQualificationsRequiredPosition:
    'Write about the position and qualifications required in the position',
  employmentType: 'Employment type',
  companyInstitution: acaNames.Site,
  jobTitle: `title`,
  JobTitle: `Title`,
  currentEmployment: 'Current employment',
  newWorkExperience: 'New Work Experience',

  // additional information
  additionalInformationEtc: acaNames.additionalInformationEtc,
  writeTheRelevantInformation: acaNames.writeTheRelevantInformation,
  excludeFromCV: 'Exclude From profile',
  updateCV: 'Update profile',

  // publications
  searchThroughPubmed: 'Search through pubmed',
  searchByNameTitleOfPublication: 'Search by name or title of publication',
  searchResults: 'Search results',
  clearSearchResults: 'Clear search results',
  addAll: 'Add all',
  addedPublications: 'Added publications',
  removeAllArticles: 'Remove all articles',
  someArticlesWithoutTitleRemoved: 'Some articles without a title were removed',
  someArticlesWereAlreadyAddedPublications: `Some articles were already added to your publications on ${acaConfig.academondo}`,

  // education types
  None: 'None',
  'Academy Profession': 'Academy Profession',
  Bachelor: 'Bachelor',
  Master: 'Master',
  'Ph.D': 'Ph.D',
  Other: 'Other',

  // cv-builder
  saveProfileHelper:
    'Please ensure that the above requirements are met. When you are ready to save, you will see a 100% completion status, and a save button will become visible.',
  basisStepTitle: 'Basic profile',
  basisStepNextBtnText: 'Continue to extended',
  showTitleadditionalInformation: acaNames.showTitleadditionalInformation,
  additionalInformation: acaNames.additionalInformation,
  viewTemplate: 'View template',
  beginBuildingYourCV: `Build ${acaNames.yourcv}`,
  personalDetails: 'Personal details',
  showTitleintroductoryText: acaNames.showTitleintroductoryText,
  introductoryText: acaNames.introductoryText,
  workExperience: 'Work experience',
  education: 'Education',
  researchStayStudiesAbroad: 'Research stay / Studies abroad',
  conferenceAndWorkshops: 'Conference and workshops',
  teachingExperience: 'Teaching experience',
  publications: 'Publications',
  get saveToAcademondo() {
    return `Save to ${this.academondo}`;
  },
  print: 'Print',
  profileCVBuilder: `${acaNames.CV} builder`,
  press: 'Press',
  makeCVVisibleEmployersLatestChanges:
    'to make your profile visible to employers or update it with your latest changes',
  seeCoauthors: 'Check to see list of coauthors',
  youHaveToAddX: '{0} required',
  duplicateCVDone: `${acaNames.CV} duplicated!`,

  // cv-viewer
  showSiteTagsAsChips: acaConfig.showSiteTagsAsChips,
  showCvTagsAsChips: acaConfig.showCvTagsAsChips,
  current: 'Current',
  educationAndWorkExperience: 'Education and work experience',
  conferencesAndWorkshops: 'Conferences and workshops',

  // job-builder
  PastJobs: `Expired ${acaNames.jobs}`,
  jobWantedExperience: acaConfig.jobWantedExperience,
  jobStartTitle: 'Start',
  image: 'image',
  Image: 'Image',
  images: 'images',
  Images: 'Images',

  jobStartStep1: acaNames.jobStartStep1,
  jobStartStep2: acaNames.jobStartStep2,
  beginBuildingYourJobPost: acaNames.beginBuildingYourJobPost,
  jobInformation: `${acaNames.Job} information`,
  researchQualifications: 'Research qualifications',
  employmentPeriod: acaNames.employmentPeriod,
  contactInformation: 'Contact information',
  visibilityPeriod: 'Visibility period',
  PublishJob: acaNames.PublishJob,
  PublishJobs: acaNames.PublishJobs,
  uploadLogo: 'Upload logo',
  URLToTheOfficialJobSite: acaNames['URLToTheOfficialJobSite']
    ? acaNames['URLToTheOfficialJobSite']
    : `URL to the official site`,
  URLToTheOfficialJobApplicationForm: acaNames[
    'URLToTheOfficialJobApplicationForm'
  ]
    ? acaNames['URLToTheOfficialJobApplicationForm']
    : `URL to the official form`,
  pasteURLHere: 'Paste URL here',
  reseachGroup: 'Department',
  researchLeader: 'Contact person',
  writeNamesAndInformationOnThePeopleToContact:
    'Write names and information on the people to contact for further information.',
  permanentPosition: 'Permanent',
  temporaryPosition: 'Temporary',
  startAsSoonAsPossible: 'Start as soon as possible',
  titleOfThePosition: 'Title',
  writeInformationAboutThePositionTasksResponsibilities: `Describe ${acaNames.thejob}.`,
  PressTheButtonBelowOpenPublishMenu: `Press the button below to open the ${acaNames.publishMenu}. Here you will be able to see status, ${acaNames.publish}, update or remove ${acaNames.yourjob}.`,
  get YourApplicationDeadline6WeeksFromPublicationDate() {
    return `${this.applicationDeadline} can be up to 3 months from ${this.publicationDate}. When
  ${acaNames.thejob} expires it will be taken down 1 week later`;
  },
  makeVisibleToday: 'Make visible today',
  applicationDeadline: 'Deadline',
  PublicationDate: 'Start date',
  publicationDate: 'start date',
  cvr: 'CVR',
  invalid: 'invalid',
  valid: 'valid',

  cvrLengthError: 'CVR skal have en længde på 8',
  cvrRequiredError: 'CVR skal udfyldes',
  numberOfMaxApplicants: 'Maksimalt antal ansøgere',
  Apply: 'Apply',
  ApplyCommunity: acaNames.ApplyCommunity,
  ApplyCommunityTooltip: acaNames['ApplyCommunityTooltip']
    ? acaNames['ApplyCommunityTooltip']
    : acaNames['ApplyCommunity'],
  ApplyEvent: acaNames.ApplyEvent,
  ApplyEventTooltip: acaNames['ApplyEventTooltip']
    ? acaNames['ApplyEventTooltip']
    : acaNames['ApplyEvent'],
  ApplyIdea: acaNames.ApplyIdea,
  ApplyIdeaTooltip: acaNames['ApplyIdeaTooltip']
    ? acaNames['ApplyIdeaTooltip']
    : acaNames['ApplyIdea'],
  ApplyFilter: 'Apply filter',
  ResetFilter: 'Reset filter',
  userAppliedToJob: 'Open Bryder model',
  ShowInterestApplySimple: 'Show interest',
  ShowInterestApliedSimple: 'Interest shown',
  SimpleApplicationText:
    'Thank you. By pushing OK you confirm your interest and we will get back to you within a few days. Only you and the responsible can see that you’ve showed an interest.',
  SimpleApplicationTextSuccess: 'Thank you!',
  noMoreApplicantsTooltip: ' tager ikke imod flere ansøgninger',
  numberOfApplicants: 'Antal ansøgere',
  applicationEmail: 'Ansøgnings email',
  applicantionEmailRequiredError: 'Email skal udfyldes',

  // job publish menu
  publishMenu: acaNames.publishMenu,
  status: 'Status',
  numberOfPublishedJobs: `Number of ${acaNames.publishedPlural} ${acaNames.jobs}`,
  youCanPublishToMakeThisJobPublic: `You can ${acaNames.publish} to make this ${acaNames.job} searchable.`,
  draftHasBeenEdited:
    'The draft has been edited. You can republish your changes.',
  unpublishToRemove: 'Unpublish to remove the publicly visible version.',
  publicFrom: `${acaNames.Public} from`,
  yourCurrentPublicPeriod: `Your current ${acaNames.public} period is set to`,

  NoChangesHasBeenMade: 'No changes has been made',

  yourOldPublicPeriod: `Your previous ${acaNames.public} period was set to`,

  youNeedToProvideVisibilityPeriod: `You need to provide a visibility period to ${acaNames.publish}.`,
  youHaveReachedMaximumPublishedJobs: `You have reached your maximum number of ${acaNames.published} ${acaNames.jobs}, upgrade your plan or unpublish some ${acaNames.jobs}. Reach out to: ${acaConfig.supportMail}, for more info`,
  unpublish: 'Unpublish',
  unpublished: 'Unpublished',
  queuedForPublishing: `Queued for ${acaNames.publishing}`,
  publishing: `${acaNames.Publishing}...`,
  published: acaNames.Published,
  republish: 'Republish',

  oldPost: `${acaNames.Oldjob}`,
  Rejected: 'Rejected',

  new: 'new',
  New: 'New',
  updateCompanyDetails: `Update details`,
  YouHavePublishedJobWithNewDetails: `You have ${acaNames.published} ${acaNames.ajob} with new ${acaNames.site} details. Do you want to update your profile with the changes?`,
  yourCVIsPublished: `${acaNames.Yourcv} is now ${acaNames.published}!`,
  yourJobIsQueued: `${acaNames.Yourjob} is now queued and scheduled for publishing!`,
  yourCompanyIsPublished: `${acaNames.Site} is ${acaNames.published}!`,
  YourPostIsPublished: `${acaNames.Job} is ${acaNames.published}!`,
  YourPlaceIsPublished: `${acaNames.Place} is ${acaNames.published}!`,
  // job-viewer
  about: 'About',
  aboutTheCompany: `About the ${acaNames.site}`,
  aboutThePosition: `Description`,
  officialJobApplication: `Official ${acaNames.job} application`,
  officialJobSite: `Official ${acaNames.job} site`,
  jobApplication: `${acaNames.Job} application`,
  relevantLinks: 'Links',
  jobSite: `${acaNames.Job} site`,
  starting: 'Starting',
  asSoonAsPossible: 'As soon as possible',
  ending: 'Ending',
  experience: 'experience',
  qualifications: 'Qualifications',
  deadlineToday: `Deadline today`,
  deadlineExceeded: `Deadline exceeded`,
  eventDeadlineToday: `Deadline today`,
  eventDeadlineExceeded: `Deadline exceeded`,
  eventEndsToday: `Has been held`,
  eventHasEnded: `Has been held`,
  NewExternalLink: 'New link',
  newExternalLink: 'new link',
  externalLinks: 'External links',
  externalLinksDesc: 'Add links as menu items',
  externalLinkText: 'Link title',
  externalLinkTextPlaceholder: 'Title ex. Colab',
  externalLinkUrl: 'Insert link',
  externalLinkUrlPlaceholder: 'Link ex. colab.google',

  // site-builder
  showSiteInfoStep: acaConfig.showSiteInfoStep,
  showCVRInputInSiteInfo: acaConfig.showCVRInputInSiteInfo,
  siteInfoStepTitle: acaNames.siteInfoStepTitle,
  siteInfoDate: acaNames.siteInfoDate,
  siteInfoDateHint: acaNames.siteInfoDateHint,
  siteQuestion0: acaNames.siteQuestion0,
  siteQuestion0Hint: acaNames.siteQuestion0Hint,
  siteQuestion0Link: acaNames.siteQuestion0Link,
  siteQuestion0Dialog: acaNames.siteQuestion0Dialog,
  siteQuestion0Tooltip: acaNames.siteQuestion0Tooltip,
  siteQuestion1: acaNames.siteQuestion1,
  siteQuestion1Hint: acaNames.siteQuestion1Hint,
  siteQuestion1Link: acaNames.siteQuestion1Link,
  siteQuestion1Dialog: acaNames.siteQuestion1Dialog,
  siteQuestion1Tooltip: acaNames.siteQuestion1Tooltip,
  siteQuestion2: acaNames.siteQuestion2,
  siteQuestion2Hint: acaNames.siteQuestion2Hint,
  siteQuestion2Link: acaNames.siteQuestion2Link,
  siteQuestion2Dialog: acaNames.siteQuestion2Dialog,
  siteQuestion2Tooltip: acaNames.siteQuestion2Tooltip,
  siteQuestion3: acaNames.siteQuestion3,
  siteQuestion3Hint: acaNames.siteQuestion3Hint,
  siteQuestion3Link: acaNames.siteQuestion3Link,
  siteQuestion3Dialog: acaNames.siteQuestion3Dialog,
  siteQuestion3Tooltip: acaNames.siteQuestion3Tooltip,
  siteQuestion4: acaNames.siteQuestion4,
  siteQuestion4Hint: acaNames.siteQuestion4Hint,
  siteQuestion4Link: acaNames.siteQuestion4Link,
  siteQuestion4Dialog: acaNames.siteQuestion4Dialog,
  siteQuestion4Tooltip: acaNames.siteQuestion4Tooltip,
  priceOnSite: acaConfig.priceOnSite,
  slimSiteIndex: acaConfig.slimSiteIndex,
  siteIsUserDriven: acaConfig.siteIsUserDriven,
  siteStartStep1: `Welcome to the ${acaNames.site} builder, follow the steps to create the ${acaNames.site}.`,
  siteStartStep2: `Remember to save your ${acaNames.site} by pressing "Save" at the end of the builder!`,
  beginBuildingYourSite: `Build your ${acaNames.site}`,
  email: 'email',
  emails: 'emails',
  Email: 'Email',
  Emails: 'Emails',
  SiteEmail: `${acaNames.Site} email`,
  saveYourProfile: `Save ${acaNames.thesite}`,
  saveProfile: `Save ${acaNames.thesite}`,
  addImage: 'Add image',
  addFiles: 'Add files',
  addVideo: 'Add video',
  uploadCover: 'Upload cover',
  uploadIcon: 'Upload icon',
  uploadImageLink: 'Upload image',
  CatchPhrase: 'Slogan',
  siteIdeaStatus: 'Status',
  siteIdeaStatusHint: `Dont't forget to change update the settings of ${acaNames.theidea}, if you want to remove it from search.`,
  siteIdeaStatusLink: '',
  siteIdeaStatusDialog: false,
  siteIdeaStatusTooltip: true,
  SiteIdeaActive: 'Active',
  SiteIdeaDormant: 'Dormant',
  SiteIdeaClosed: 'Closed',

  // multi-selector
  weFoundNothingThatMatchesYourSearch:
    'We found nothing that matches your search.',
  useTheSearchFieldAboveToBeginSelection:
    'Use the search field above to begin selection.',

  // login
  resendVerificationMail: 'Send verification email',
  verificationMailSent:
    '❗ Verification email sent, please check your inbox/spam folder.',
  verificationMailSentToUser: 'Verification email sent to user.',
  pleaseVerifyYourEmail: `Please verify your email for your ${acaConfig.academondo} account`,
  dontHaveAnAccount: `Don't have an account?`,
  signUp: acaNames.signUp,
  disabledSignupEmail: acaConfig['disabledSignupEmail']
    ? acaConfig['disabledSignupEmail']
    : false,
  disabledSignupForm: acaConfig['disabledSignupForm']
    ? acaConfig['disabledSignupForm']
    : false,
  get DontHaveAccountText() {
    return acaNames['DontHaveAccountText']
      ? acaNames['DontHaveAccountText']
      : this.dontHaveAnAccount;
  },
  get DontHaveAccountLink() {
    return acaNames['DontHaveAccountLink']
      ? acaNames['DontHaveAccountLink']
      : '';
  },
  get SignupButtonText() {
    return acaNames['SignupButtonText']
      ? acaNames['SignupButtonText']
      : this.signUp;
  },
  emailAddressAlreadyInUse: `You've tried to signup, but you already have an account. Try to login or reset password, if you forgot your password.`,
  SignupAs: 'Sign up as',
  signIn: 'Sign in',
  containLeastOneNumberAndOneCapitalLetter:
    'Should contain a mixture of uppercase and lowercase letters and one number',
  mustBetween6And24Characters: 'Must be between 6 and 24 characters',

  EnterYourEmailAddressAndPasswordToAccessAccount:
    'Enter your email address and password to access account',

  // forum
  beginBuildingYourPost: `Build ${common.yourPost}`,
  generalInformation: 'Info',
  topics: 'Tags',

  postBuilder: `${common.PostBuilder}`,
  titleOfAnnouncement: `title`,
  TitleOfAnnouncement: `Title`,
  writeInformationAboutYourRequest: `Write information about ${common.yourPost}.`,
  useLinkButton: 'Use link button (',
  toAddLinks: ') below to add links.',
  AddSomethingToPost: `Add something to ${common.yourPost}`,

  // comments
  yourMessage: 'Your message...',
  yourComment: 'Your comment...',
  youNeedToTypeText: 'you need to type some text',
  commentHint: 'you need to type some text or add an image',
  Save: 'Save',
  Close: 'Close',
  reply: 'Reply',
  showReplies: 'Show replies',
  hideReplies: 'Hide replies',
  showMore: 'Show more',
  showLess: 'Show less',
  showTags: 'Show tags',
  hideTags: 'Hide tags',
  hideAll: 'Hide all',
  comments: 'comments',
  loadMore: 'Load more...',
  InviteMore: 'Invite more...',
  loadMoreComments: 'Load more comments',
  Loading: 'Loading',
  lastSeen: 'last seen',
  LastSeen: 'Last seen',
  edited: 'edited',
  Edited: 'Edited',
  charsLeft: 'characters left',
  unfoldComments: 'Indent comments',
  foldComments: 'Collapse comments',

  defaultStartStep1:
    'Welcome to the profile builder, follow the steps to create you profile.',
  defaultStartStep2:
    'Remember to save your profile by pressing "save" at the end of the builder!',

  // partners
  weProudlyPresentPartners: 'We proudly present our partners',

  // profileviewer
  Printable: 'Printable',
  noPublicProfile: `You don't have a public ${acaNames.cv} yet, create one and save it to ${acaConfig.academondo} to get started!`,
  makeYourProfile: `Make your ${acaNames.cv}`,
  noProfileWithThatID: `There is no ${acaNames.cv} with that Id`,
  pleaseSignInToSeeProfiles: `Please sign in to see ${acaNames.cvs}`,
  seeYourOwnProfile: `See ${acaNames.yourcv}`,
  saveChanges: 'Save changes',
  ChangesSaved: 'Saved',

  followUs: 'Follow us',
  phone: 'Phone',
  contact: 'Contact',

  contactEmail: 'Contact email',
  contactPhone: 'Contact phone',
  resetYourPassword: 'reset your password',
  ResetYourPassword: 'Reset your password',
  resetPassword: 'Reset password',
  forgotPassword: 'Reset your password',
  recoverAccount: 'Recover account',
  pressBelowToRecoverAccount: 'Press the button below to Recover your account',
  accountEmailRestoredYouMightWantToResetYourPassword:
    'Account email has been restored. You might want to reset your password',

  EnterYourEmailToGetStarted: 'Enter your email to get started',

  resetPWEmailSent:
    '❗If you have an account, a reset password link will be sent to your email, please check your inbox/spam folder',

  // new Email
  changeEmail: 'Change your email',
  newEmailConfirm: 'Are you sure you want to change your account email?',
  NewEmail: 'New email',
  newEmailText: `Email changed.
            Next time you sign in, use same password and verify new email`,
  verifyNewEmailText: `Verify new email.
            Next time you sign in, use same password`,
  password: 'Password',
  optional: 'Optional',
  accept: 'accept',
  Accept: 'Accept',
  decline: 'decline',
  Decline: 'Decline',
  accepting: 'Accepting',
  declining: 'Declining',
  anInvite: 'an invite',

  // emptyProfile
  createYourProfileAndLetTheWorldDiscoverYou:
    'Create your profile and let the world discover you',
  Profile: 'Profile',
  profile: 'profile',
  yourFree: 'your free',
  You: 'You',
  Yourself: 'You',
  Your: 'Your',
  Name: 'Name',
  yourTitle: 'Your title',
  yourAddress: 'Your address',
  aboutYourCompanyInstittution: 'About Your Company/Institution',
  yourMostRecentOccupation: 'Your most recent occupation',

  // filter-tags
  removeAllTags: 'Remove all tags',

  // profile-editor
  defaultNotificationFreq: acaConfig['defaultNotificationFreq']
    ? acaConfig['defaultNotificationFreq']
    : MailFrequency.Asap,
  defaultNotificationFreqForCompanies: acaConfig[
    'defaultNotificationFreqForCompanies'
  ]
    ? acaConfig['defaultNotificationFreqForCompanies']
    : MailFrequency.Asap,
  profileData: 'Profile editor',
  notificationSettings: 'Notifications',
  showNotificationIconWithText: acaConfig['showNotificationIconWithText']
    ? acaConfig['showNotificationIconWithText']
    : false,
  SaveNotificationSettings: 'Save Notification Settings',
  PushNotifications: 'Push notifications',
  PushNotification: 'Push notification',
  AlertPushTitle: 'Title',
  AlertPushTitleIntro: 'Attention',
  AlertPushMessage: 'Message',
  Newsletter: 'Newsletter',
  TheNotificatioPermissionNotGranted:
    'You have blocked notifications. To change your choice, click the padlock next to the url in your browser and allow notifications.',

  // notification settings
  SetNotificationSettingsToNever: 'Disable all notifications',
  SetNotificationSettingsToAsap: 'Set notification frequency to asap',
  SetNotificationSettingsToDaily: 'Set notification frequency to daily',
  SetNotificationSettingsToWeekly: 'Set notification frequency to weekly',
  SetNotificationSettingsToMonthly: 'Set notification frequency to monthly',
  Notifications: 'Notifications',
  Never: 'Never',
  Asap: 'Asap',
  Reminder: 'Reminder',
  Daily: 'Daily',
  Weekly: 'Weekly',
  Monthly: 'Monthly',

  NewReplyToYourComment: `New comment to a comment you have posted`,
  get taggedInAComment() {
    return `You are ${this.tagged} in a comment`;
  },
  get taggedInAPost() {
    return `You are ${this.tagged} in a ${this.post}`;
  },

  // Notifications
  userAcceptedGroupInviteFromYou_title: `{0} accepted your invitation`,
  userAcceptedGroupInviteFromYou_message: `{0} joined {1}`,
  userRejectedCommunityInviteFromYou_title: `{0} rejected your invitation`,
  userRejectedCommunityInviteFromYou_message: `{0} rejected your invitation to {1}`,
  ownerInvitedUserToCommunity_title: `Invitation to {0}`,
  ownerInvitedUserToCommunity_message: `{0} invited you to {1}`,
  userRequestsCommunityMembership_title: `Membership request to {0}`,
  userRequestsCommunityMembership_message: `{0} has requested membership to {1}`,
  ownerAcceptsCommunityMembershipRequest_title: `Your request to {0} was accepted`,
  ownerAcceptsCommunityMembershipRequest_message: `{0} has accepted your membership request to {1}`,
  ownerRejectsCommunityMembershipRequest_title: `Your request to {0} was rejected`,
  ownerRejectsCommunityMembershipRequest_message: `{0} has rejected your membership request to {1}`,
  youAreMadeOwnerOfGroup_title: `You are now owner of {0}`,
  youAreMadeOwnerOfGroup_message: `{0} have granted you ownership of {1}`,
  youAreMadeAdministatorOfGroup_title: `You are now ${acaNames.Manager} of {0}`,
  youAreMadeAdministatorOfGroup_message: `{0} have promoted you in {1}`,
  memberJoinedYourCommunity_title: `New member in {0}`,
  memberJoinedYourCommunity_message: `{0} joined {1}`,
  youWereKickedFromCommunity_title: `You were removed from {0}`,
  youWereKickedFromCommunity_message: `{0} removed you from {1}`,
  memberAttendingYourEvent_title: `{0} attending`,
  memberAttendingYourEvent_message: `{0} is attending {1}`,
  memberMaybeAttendingYourEvent_title: `{0} maybe attending`,
  memberMaybeAttendingYourEvent_message: `{0} has marked as maybe attending {1}`,
  memberDeclinedYourEvent_title: `{0} not attending`,
  memberDeclinedYourEvent_message: `{0} is not attending {1}`,
  memberIsInterestedYourEvent_title: `{0} is interested`,
  memberIsInterestedYourEvent_message: `{0} is interested in {1}`,
  newCommentInPostYouCreated_title: `{0} added a new comment to ${common.yourPost}`,
  newReactionOnPostYouCreated_title: `You have received a "{0}" by {1} on ${common.yourPost} "{2}"`,
  newReactionOnPostYouCreated_message: `You now have "{0}" reactions on "{1}"`,
  newReactionOnCommentYouCreated_title: `You have received a "{0}" by {1} on your comment "{2}"`,
  newReactionOnCommentYouCreated_message: `You now have "{0}" reactions on "{1`,
  newReplyToYourComment_title: `{0} replied to your comment`,
  newCommentToPostYouCommented_title: `{0} added a new comment`,
  newPostInGroupWereYouAreMember_title: `{0} added a ${common.newPost} to {1}`,
  newPostInGroupWereYouOwn_title: `{0} added a ${common.newPost} to {1}`,
  newChatMessage_title: `{0} sent you a message`,
  newPostMatchingYourTags_title: `{0} added a ${common.newPost}, matching your tags, to {1}`,
  get newJobMatchingYourTags_title() {
    return `{0} added ${acaNames.ajob}, matching your ${this.tags}`;
  },
  newGroupMatchingYourTags_subject: `{0} has created {1} on {2}, that matches your profile`,

  newGroupMatchingYourTags_title: `{0} matches your tags: {1}`,

  get taggedYouInAComment_title() {
    return `{0} ${this.tagged} you in a comment`;
  },

  get taggedYouInAPost_title() {
    return `{0} ${this.tagged} you in a ${this.post}`;
  },

  // Notification Settings
  EnablePushNotificationsDescription: `When you enable push notifications on your mobile, you will not receive push notifications on your computer, and vice versa. This means that you will only receive push notifications on the device where you have enabled them.`,
  comments_notificationSetting: `Comments`,
  community_notificationSetting: `${acaNames.Communities}`,
  communityOwners_notificationSetting: `Own ${acaNames.communities}`,
  posts_notificationSetting: `${common.Posts}`,
  newPostInGroupYouOwn_notificationSetting: `${common.NewPosts} in ${acaNames.communities} or ${acaNames.events} you own`,
  newPostInGroupYouAreMember_notificationSetting: `${common.NewPosts} in groups(${acaNames.communities} etc.), where you're member`,
  newPostMatchingYourTags_notificationSetting: `${common.NewPosts} that matches your tags`,
  newGroupMatchingYourTags_notificationSetting: `(${acaNames.community}${
    acaNames.Event ? '/' + acaNames.event : ''
  }${acaNames.Idea ? '/' + acaNames.idea : ''}), that matches your profile`,
  requestedMembership_notificationSetting: `Requests`,
  rejectedInvite_notificationSetting: `Rejected invitations`,
  memberLeft_notificationSetting: `User leave`,
  memberJoined_notificationSetting: `User joins`,
  acceptedInvite_notificationSetting: `Accepted invitations`,
  newCommentinPostWithTagMatch_notificationSetting: `New comment in ${common.post} with matching tags`,
  kicked_notificationSetting: `Kicked out`,
  invited_notificationSetting: `Invitations`,
  acceptedRequest_notificationSetting: `Request accepted`,
  rejectedRequest_notificationSetting: `Request rejected`,
  newCommentInPostYouFollow_notificationSetting: `New comments in ${common.post} you follow`,
  newCommentInPostYouCreated_notificationSetting: `New comments in ${common.post} you created`,
  newCommentInPostYouCommented_notificationSetting: `New comments in ${common.post} you have commented`,
  newReplyToYourComment_notificationSetting: `Replies to your comments`,
  get taggedInAComment_notificationSetting() {
    return `${this.Tagged} in comments`;
  },
  get taggedInAPost_notificationSetting() {
    return `${this.Tagged} in ${this.posts}`;
  },
  promoted_notificationSetting: `Promotions`,
  eventOwners_notificationSetting: `Own ${acaNames.events}`,
  event_notificationSetting: `${acaNames.Events}`,
  interested_notificationSetting: `Interested users`,
  declined_notificationSetting: `Declines`,
  maybe_notificationSetting: `Maybe users`,
  attending_notificationSetting: `Attending users`,
  requestedAttendance_notificationSetting: `Requests`,

  expiration_notificationSetting: 'Expiration',
  cvExpiration_notificationSetting: `${acaNames.CV}`,
  siteExpiration_notificationSetting: `${acaNames.Site}`,
  jobExpiration_notificationSetting: `${acaNames.Job}`,

  chat_notificationSetting: 'Chat',
  chatMessage_notificationSetting: 'Messages',

  reaction_notificationSetting: 'Reactions',
  job_notificationSetting: acaNames.Jobs,
  get newJobMatchingYourTags_notificationSetting() {
    return `${acaNames.Jobs}, that matches your ${this.tags}`;
  },

  noNotificationText: 'You have no notifications, yet!',
  markAsRead: 'Mark all as read',
  settings: 'settings',
  Notificationsettings: 'Notification settings',

  invitation: 'invitation',
  inApp: 'in app',
  optOuts: 'opt outs',
  addedA: 'added a',
  in: 'in',

  // uploading
  anyoneWithLinkCanDownloadFile:
    'NB: anyone with the link can download the file',
  uploads: 'Uploads',
  uploadsDescription: `
  This section displays uploaded files from all ${common.posts}.
  It is possible to include files while composing ${common.posts}.`,
  dragAndDropArea: 'Drag and Drop',
  orChooseFile: 'or choose a file…',
  downloadMe: 'Download Me!',
  of: ' of ',
  pause: 'pause',
  resume: 'Resume',

  yourCVs: `Your ${acaNames.cvs}`,
  yourJobs: `Your ${acaNames.jobs}`,
  sideBySide: 'Side by side',
  builderOnly: 'Builder only',
  viewerOnly: 'Viewer only',
  sorting: 'Sorting',
  help: 'Help',
  selectAnotherImage: 'Select another image',
  yes: 'Yes',
  no: 'No',
  becomeMemberCommunity: `${acaNames.becomeMemberCommunity}`,
  becomeMemberEvent: `${acaNames.becomeMemberEvent}`,
  becomeMemberIdea: `${acaNames.becomeMemberIdea}`,
  becomeMemberCommunityTooltip: acaNames['becomeMemberCommunityTooltip']
    ? acaNames['becomeMemberCommunityTooltip']
    : acaNames.becomeMemberCommunity,
  becomeMemberEventTooltip: acaNames['becomeMemberEventTooltip']
    ? acaNames['becomeMemberEventTooltip']
    : acaNames.becomeMemberEvent,
  becomeMemberIdeaTooltip: acaNames['becomeMemberIdeaTooltip']
    ? acaNames['becomeMemberIdeaTooltip']
    : acaNames.becomeMemberIdea,
  enterValidMail: 'Enter a valid e-mail address.',
  mustBe2Characters: 'must be at least 2 characters.',
  mustBeFilledOut: 'must be filled out.',
  required: 'required',
  Required: 'Required',
  delete: 'Delete',
  deletePost: `Delete ${common.post}`,
  deleteCV: `Delete this ${acaNames.cv}`,
  deleteJob: `Delete ${acaNames.job}`,
  deleteCompany: `Delete ${acaNames.site}`,
  newCV: acaNames.CV,
  post: common.post,
  Post: common.Post,
  posts: common.posts,
  Posts: common.Posts,
  thePost: common.thePost,
  newPost: common.newPost,
  NumberOfPosts: `Number of ${common.posts}`,
  NumberOfPostsSubTitle: `Only showing dates with more than 1 ${common.post}`,
  NumberOfPostsPast14DaysSubTitle: `Past 14 days`,
  NumberOfPostsPast1YearSubTitle: `Last year`,
  NumberOfPostsPast2YearSubTitle: `Year before last`,
  newJobPosition: acaNames.newJobPosition,
  uploadPdfLabel: `Upload files to ${common.yourPost}`,
  uploadPdf: 'Upload pdf',
  uploadPdfTooltip: 'Upload pdf',
  openProfileTooltip: `Open ${acaNames.cv} on new tab`,
  showDateOnPostsInFeedWidget: acaConfig.showDateOnPostsInFeedWidget,
  showMemberCountOnEventWidget: acaConfig.showMemberCountOnEventWidget,

  // network
  GroupStatistics: 'Groups',
  filter: `Filter`,
  createNewPost: `Create ${common.newPost}`,
  createNewEvent: `Create ${acaNames.newEvent}`,
  createNewSubgroup: `Create ${acaNames.newSubgroup}`,
  remove: 'remove',
  areYouSureYouWantToX: 'Are you sure you want to {0}?',
  areYouWantToLeaveX: 'Are you sure you want to leave {0}?',

  edit: 'Edit',
  Edit: 'Edit',
  rename: 'Rename',
  renameCV: `Rename ${acaNames.thecv}`,
  renameJob: `Rename ${acaNames.thejob}`,
  duplicate: 'Duplicate',
  done: 'Done',
  add: 'Add',
  back: 'back',
  Back: 'Back',
  next: 'next',
  Next: 'Next',
  logOut: 'Log out',
  Collapse: 'Collapse',
  maximize: 'Maximize',
  minimize: 'Minimize',
  hide: 'Hide',
  deviceLocationTooltip: 'Allow location to search near you',
  noJobExists: `There is no ${acaNames.job} with that ID`,
  jobsMatchingYourQualifications: `Sign in to search for ${acaNames.jobs} matching your qualifications`,
  landingPageRoute: 'welcome',

  Beginner: 'Beginner',
  Conversational: 'Conversational',
  Proficient: 'Proficient',
  Fluent: 'Fluent',
  Bilingual: 'Bilingual',
  Native: 'Native',

  // Account Permission
  Basic: acaNames.Basic,
  Extended: acaNames.Extended,
  Full: acaNames.Full,
  Admin: acaNames.Admin,

  GoToForum: 'Go to forum',
  Forum: 'Forum',
  calendar: 'Calendar',
  member: `${acaNames.member}`,
  Member: `${acaNames.Member}`,
  members: `${acaNames.members}`,
  Members: `${acaNames.Members}`,
  participant: `${acaNames.participant}`,
  Participant: `${acaNames.Participant}`,
  participants: `${acaNames.participants}`,
  Participants: `${acaNames.Participants}`,
  participantInvite: `${acaNames.participantInvite}`,
  ParticipantInvite: `${acaNames.ParticipantInvite}`,
  participantsInvite: `${acaNames.participantsInvite}`,
  ParticipantsInvite: `${acaNames.ParticipantsInvite}`,
  administrative: 'Administrative',
  employee: 'Employee ',
  employees: 'employees ',
  employeesDash: 'Employees - ',

  // network types
  subEventDisclaimer: `This ${acaNames.event} automatically invites all ${acaNames.members} of the `,
  Public: acaNames.Public,
  OpenPrivate: acaNames.OpenPrivate,
  Private: acaNames.Private,
  Hidden: acaNames.Hidden,
  InviteOnly: acaNames.InviteOnly,
  public: acaNames.public,
  openPrivate: acaNames.openPrivate,
  private: acaNames.private,
  inviteOnly: acaNames.inviteOnly,
  hidden: acaNames.hidden,

  University: 'University',
  Organisation: 'Organisation',

  // invite chips
  all: 'all',
  All: 'All',
  Alls: 'All',
  universities: 'universities',
  organisations: 'organisations',
  hospitals: 'hospitals',

  // routes
  CVBuilderRoute: acaNames.CVRoute,
  CVBuilderTitle: acaNames.CV,
  CVSearchRoute: 'Profile Search',
  jobBuilderRoute: acaNames.jobBuilderRoute,
  jobBuilderTitle: acaNames.jobBuilderTitle,
  adminRoute: 'Admin',
  profileEditorRoute: 'Profile Settings',
  profileRoute: 'Profile',
  jobRoute: `${acaNames.Job}`,
  mapRoute: 'map',
  mapTitle: 'Map',
  filesRoute: 'Uploads',
  aboutUsRoute: 'About Us',
  aboutJobSearchRoute: `Find ${acaNames.ajob}`,
  aboutJobBuilderRoute: `Post ${acaNames.ajob}`,
  newUserRoute: 'New User',
  chatRoute: 'Chat',
  cookiesRoute: 'Cookies',
  partnersRoute: 'Partners',
  dashboardRoute: acaNames.dashboardRoute,
  dashboardTitle: acaNames.dashboardTitle,
  homeRoute: 'company',
  searchRoute: 'Search',
  messagesRoute: 'Messages',
  siteBuilderRoute: acaNames.siteBuilderRoute,
  jobListRoute: `${acaNames.Job} catalogue`,
  siteListRoute: `${acaNames.Site} catalogue`,
  cvListRoute: `${acaNames.User} catalogue`,
  postSearchRoute: `Posts`,
  companySearchRoute: `${acaNames.Sites}`,
  candidateSearchRoute: `${acaNames.Users}`,
  homeProfileRoute: 'Profile',

  groupChat: 'Chat',
  Chats: 'Chats',
  OpenChat: 'Send a message',
  enableChat: acaConfig.enableChat,
  groupChatEnabled: acaConfig.groupChatEnabled,
  DisableChat: 'Turn off the chat feature',
  DisableChatDescription: `When you turn off the chat feature, other users will not be able to initiate a conversation with you, but if you message them, they can respond. You can still initiate conversations with other profiles.`,

  // router hints
  thesite: acaNames.thesite,
  site: acaNames.site,
  Site: acaNames.Site,
  sites: acaNames.sites,
  Sites: acaNames.Sites,
  CV: acaNames.CV,
  cv: acaNames.cv,
  CVs: acaNames.CVs,
  cvs: acaNames.cvs,

  // publishDialog
  thisWillMakeCVSearchable: acaNames.thisWillMakeCVSearchable,
  thisWillUpdateYourCurrentOnline: 'This will update your current online',
  Continue: 'Continue',
  giveTheCVAName: `Give ${acaNames.thecv} a name`,
  giveTheJobAName: `Give ${acaNames.thejob} a name`,

  // about-us
  Testimonials: 'Testimonials',
  Partners: 'Partners',
  Visit: 'Visit',
  Founders: 'Founders',

  // application process
  notRegistered: 'not registered',
  checkPdfAtPage: 'se bryder modellen på side 56',
  applicationMaterialLinkUrl:
    'https://www.akademikerkampagnen.dk/wp-content/uploads/2019/01/SKABv%C3%A6rdi_Ebog_komprimeret.pdf',
  applicationMaterialLinkText: 'Bryder model',
  applicationProcessLine1: 'Din opgave er nu at følge denne model',
  applicationProcessLine2: 'og sende din ansøgning',
  applicationProcessLine3: 'Held og lykke, benyt nedenstående email',
  applicationProcessLine4:
    'Ps. du kan altid vende tilbage til denne dialog ved at søge jobbet frem igen',
  maxApplicationsReceived: 'Fyldt',
  OnlyNumbersAllowedDontUseDash: 'kun tal er tilladt, bring ikke bindestreg',
  CprMustBeFilledOut: 'CPR nummer skal udfyldes.',
  EnterCprWithoutDash: 'Indtast CPR uden bindestreg',
  tryAgain: 'Prøv igen',
  pleaseProvideCPRToCompleteYourRegistration:
    'Ved at fortsætte accepterer du at benytter vi dit CPR nummer til din ansøgning',
  sendYourApplicationToThisEmail: 'Send din ansøgning til denne mail',
  ApplicationSuccess: 'Tak for din ansøgning, fortsæt til bryder modellen',
  Applications: 'Applications',
  cpr: 'CPR',
  CPRHint: acaNames.CPRHint,
  CPRLink: acaNames.CPRLink,
  CPRDialog: acaNames.CPRDialog,
  CPRTooltip: acaNames.CPRTooltip,
  somethingWentWrongAttemptingToApplyForJob:
    'Noget gik galt, prøv venligst igen',
  cvTechs: `${acaNames.CV} ${acaNames.techs}`,
  jobTechs: `${acaNames.Job} ${acaNames.techs}`,
  jobCat0: `${acaNames.Job} ${acaNames.category0}`,
  GraduateProgram: acaNames.GraduateProgram,
  GraduateProgramHint: acaNames.GraduateProgramHint,
  GraduateProgramLink: acaNames.GraduateProgramLink,
  GraduateProgramDialog: acaNames.GraduateProgramDialog,
  GraduateProgramTooltip: acaNames.GraduateProgramTooltip,
  GraduateProgramRequired: `${acaNames.GraduateProgram}?`,
  AreYouOnDimittendsats: acaNames.AreYouOnDimittendsats,
  Dimittendsats: acaNames.Dimittendsats,
  DimittendsatsHint: acaNames.DimittendsatsHint,
  DimittendsatsLink: acaNames.DimittendsatsLink,
  DimittendsatsDialog: acaNames.DimittendsatsDialog,
  DimittendsatsTooltip: acaNames.DimittendsatsTooltip,
  DimittendsatsRequired: `${acaNames.Dimittendsats}?`,
  AreYouOnAkademikerkampagnen: acaNames.AreYouOnAkademikerkampagnen,
  Akademikerkampagnen: acaNames.Akademikerkampagnen,
  AkademikerkampagnenHint: acaNames.AkademikerkampagnenHint,
  AkademikerkampagnenLink: acaNames.AkademikerkampagnenLink,
  AkademikerkampagnenDialog: acaNames.AkademikerkampagnenDialog,
  AkademikerkampagnenTooltip: acaNames.AkademikerkampagnenTooltip,
  AkademikerkampagnenRequired: `${acaNames.Akademikerkampagnen}?`,

  SSOEnabled: acaConfig.SSOEnabled,
  LinkedinEnabled: acaConfig.LinkedinEnabled,

  enabledSubgroups: acaConfig.enabledSubgroups,
  enabledSubgroupsInIdeas: acaConfig.enabledSubgroupsInIdeas,
  moveUpcomingGroupsToBottom: acaConfig['moveUpcomingGroupsToBottom']
    ? true
    : false,
  expireProfiles: acaConfig.expireProfiles,
  expireProfilesWarning: acaConfig['expireProfilesWarning']
    ? acaConfig['expireProfilesWarning']
    : 30,
  expireProfilesDeadline: acaConfig['expireProfilesDeadline']
    ? acaConfig['expireProfilesDeadline']
    : 60,
  get expireProfilesHint() {
    return `${acaNames.CVs} expires ${this.expireProfilesDeadline} days after last update`;
  },
  expireProfilesNotificationTitle: `${acaNames.CV} expiry notification`,
  get expireProfilesNotificationMessage() {
    return `${acaNames.Yourcv} is about to expire.
  30 days without an update and ${acaNames.thecv} will be removed from search, but it won't be deleted`;
  },
  expiredProfilesNotificationMessage: `${acaNames.Yourcv} has expired.
  login and save it to be visible again`,
  onlyCreateIdeasFromSite: acaConfig.onlyCreateIdeasFromSite,
  onlyCreateIdeasFromSiteDisclaimer: `Remember to update related ${acaNames.ideas}, if you make changes here`,
  expireSites: acaConfig.expireSites,
  expireSitesHint: `${acaNames.Sites} expires 365 days after last update`,
  expireSitesNotificationTitle: `${acaNames.Site} expiry notification`,
  expireSitesNotificationMessage: `${acaNames.Site} is about to expire.
  7 days without an update and ${acaNames.thesite} will be removed from search, but it won't be deleted`,
  jobImages: acaConfig.jobImages,
  enableJobs: acaConfig.enableJobs,
  jobResearchQualificationsAsFilters:
    acaConfig.jobResearchQualificationsAsFilters,
  expireJobsNotificationTitle: `${acaNames.Job} expiry notification`,
  expireJobsNotificationMessage: `${acaNames.Yourjob} will expire tomorrow.
  Set a new visibility period or ${acaNames.thejob} will be removed from search, but it won't be deleted`,

  registerCVRONSites: acaConfig['registerCVRONSites']
    ? acaConfig['registerCVRONSites']
    : false,

  useCategory2AsCategory0: acaConfig['useCategory2AsCategory0']
    ? acaConfig['useCategory2AsCategory0']
    : false,

  enabledGroupPushNotifications: acaConfig['enabledGroupPushNotifications']
    ? acaConfig['enabledGroupPushNotifications']
    : false,

  showCommunityWidgetFirst: acaConfig['showCommunityWidgetFirst']
    ? acaConfig['showCommunityWidgetFirst']
    : false,

  enableCoupling: acaConfig.enableCoupling,
  showApplicationProcessFunctions: acaConfig.showApplicationProcessFunctions,
  registerVisitFunctions: acaConfig.registerVisitFunctions,
  showRelatedJobsFunctions: acaConfig.showRelatedJobsFunctions,
  showCalendarInNetworks: acaConfig.showCalendarInNetworks,
  showBorderedButtonsInNavBar: acaConfig.showBorderedButtonsInNavBar,
  showCapitalizedButtonsInNavBar: acaConfig.showCapitalizedButtonsInNavBar,
  showAccountChangerInNavBar: acaConfig.showAccountChangerInNavBar,
  showAccountSelectorIcons: acaConfig.showAccountSelectorIcons,
  showChangeAccountTypeInSignup: acaConfig.showChangeAccountTypeInSignup,
  showSiteImageOnMap: acaConfig.showSiteImageOnMap,
  showPlaceImageOnMap: acaConfig.showPlaceImageOnMap,
  showNetworkImageOnMap: acaConfig.showNetworkImageOnMap,
  showCommunityImageOnMap: acaConfig.showCommunityImageOnMap,
  ideaWantedExperience: acaConfig.ideaWantedExperience,
  ideaImages: acaConfig.ideaImages,
  showIdeaImageOnMap: acaConfig.showIdeaImageOnMap,
  showEventImageOnMap: acaConfig.showEventImageOnMap,
  showCreatorOfCommunities: acaConfig.showCreatorOfCommunities,
  showCreatorOfEvents: acaConfig.showCreatorOfEvents,
  showCreatorOfIdeas: acaConfig.showCreatorOfIdeas,
  showOptionToRemoveUsersPostsAndEvEventsOnLeaving:
    acaConfig.showOptionToRemoveUsersPostsAndEvEventsOnLeaving,
  showOptionToRemoveUsersPostsAndEvEventsOnRemovingUser:
    acaConfig.showOptionToRemoveUsersPostsAndEvEventsOnRemovingUser,
  showInviteWidget: acaConfig.showInviteWidget,
  showBasicStep: acaConfig.showBasicStep,
  RegisterDirectMatch: acaConfig.RegisterDirectMatch,
  contactConsentGiven: acaConfig.contactConsentGiven,
  showCreateCommunityButtonForUserWithoutPermission:
    acaConfig.showCreateCommunityButtonForUserWithoutPermission,
  showCreateEventButtonForUserWithoutPermission:
    acaConfig.showCreateEventButtonForUserWithoutPermission,
  showCreateIdeaButtonForUserWithoutPermission:
    acaConfig.showCreateIdeaButtonForUserWithoutPermission,
  showPrintSiteButton: acaConfig.showPrintSiteButton,

  // suggestion

  PleaseReviewSuggestionFromX:
    'Please review this suggestion from ({0}) as soon as possible...',
  yourSuggestion: 'Your suggestion',
  hasBeenAddedThanks: 'has been added, thank you for your time!',
  areYouSureYouWantToDeleteTheSuggested:
    'Are you sure you want to delete the suggested',
  areYouSureYouWantToAddX: 'Are you sure you want to add {0}?',
  suggestTagDescription: `Choose from the list or suggest your own.
  Please note: Suggestions will have to be approved by an administrator. You will need to add the suggested tag to ${acaNames.yourcv} again after it has been approved.`,
  enableCategory0Suggestions: acaConfig.enableCategory0Suggestions,
  enableFOISuggestions: acaConfig.enableFOISuggestions,
  enableTechSuggestions: acaConfig.enableTechSuggestions,

  // fenerum

  PleaseCheckThatThisIsYourCorrectBillingInformation:
    'Please check that this is your correct billing information.',
  ThankYouYourAccountHasBeenCreated:
    'Thank you! Your account has been created.',
  SetupAccountingEmail: 'Setup accounting email',
  TryAgain: 'Try again',
  line: 'line',
  Zipcode: 'Zipcode',
  Submit: 'Submit',
  SomethingWentWrongPaymentAccount:
    'Something went wrong when attempting to submit payment account. Please, try again.',
  saveDiscount: 'Save',

  PleaseProvideNameEmailForAccountThisEmailInvoices:
    'Please provide name and an email for your account, this email will only be used for invoices etc.',
  ThankYouYourAccountingEmailHasBeenSet:
    'Thank you! Your accounting email has been set.',
  ContinueToCheckout: 'Continue to checkout',
  Checkout: 'Checkout',
  AccountingEmail: 'Accounting email',
  EmailMustBeAnEmailExSupportAtAcademondoCom:
    'Email must be an email ex. support@academondo.com.',
  ReceiveInvoice: 'Receive invoice?',
  ReceivePaymentConfirmation: 'Receive payment confirmation?',
  ReceiveSubscriptionNotifications: 'Receive subscription notifications?',
  SomethingWentWrongRecipientEmail:
    'Something went wrong when attempting to add recipient email to account. Please, try again.',

  PleaseSelectYourFormOfPayment: 'Please select your form of payment.',
  ThankYouYourSubscriptionHasBeenSubmitted:
    'Thank you! Your subscription has been submitted.',
  YouAreNowReadyToExploreOurUniverse:
    'You are now ready to explore our universe',
  YouAlreadyHaveASubscriptionReachOut: `You already have a subscription, reach out to support@academondo.com if you still don't have access.`,
  PaymentMethod: 'Payment method',
  PaymentCard: 'payment card - not supported yet',
  Invoice: 'Invoice',
  PaymentMethodMustBeSelected: 'Payment method must be selected.',
  SomethingWentWrongPaymentMethod:
    'Something went wrong when attempting to submit payment method. Please, try again.',

  // plan selector
  PlanSelectorTitle: acaNames.PlanSelectorTitle,
  PlanSelectorSubTitle: acaNames.PlanSelectorSubTitle,
  PlanSelectorParagraph1: acaNames.PlanSelectorParagraph1,
  PlanSelectorListItem1: acaNames.PlanSelectorListItem1,
  PlanSelectorListItem2: acaNames.PlanSelectorListItem2,
  PlanSelectorListItem3: acaNames.PlanSelectorListItem3,
  PlanSelectorListItem4: acaNames.PlanSelectorListItem4,

  // youtube video
  youtubeVideoInputTooltip: 'Insert a YouTube or Vimeo link or just the id',
  get youtubeVideoInputTooltipProfile() {
    return `Strengthen your first impression with a 30-second video where you basically repeat your ${this.introductoryText}. Upload it as unlisted (not searchable) on YouTube and insert the link here.`;
  },
  youtubeVideoInputLabel: 'Video',
  youtubeVideoInput: 'https://youtube.com/... or https://vimeo.com/id',

  // notes
  Notes: 'Notes',
  Comment: 'Comment',
  noteLabel: 'Leave a note...',
  notePlaceholder: 'Ex. Simon says...',
  noteText: 'Note',
  defaultError: 'Something went wrong, please try again.',
  notesHint: 'Write a note about subject',
  noteDeleteMsg: 'Are you sure you want to delete this note?',
  filterNotes: 'Filter notes',

  saveNoteHint: 'Use Ctrl + Enter to save',

  NewChat: 'New Chat',
  TypeYourMessageHere: 'Type your message here...',
  YouAreNotInAChatRoomYet: 'You have not started a chat',
  TimeToStartAChat: `It's time to start one!`,
  FindUsersToChatWith: `Find ${acaNames.users} to chat with`,

  // places
  place: `${acaNames.place}`,
  Place: `${acaNames.Place}`,
  newPlace: `New ${acaNames.place}`,
  placeName: `${acaNames.Place} name`,
  placesBuilder: `${acaNames.Place} Builder`,
  deletePlace: `Delete ${acaNames.Place}`,
  YouCanOnlySelect: 'You can only select',
  placeType: `${acaNames.Place} type`,

  placesUrl2: acaNames.placesUrl2,

  PlacesTypes0: acaNames.PlacesTypes0,
  PlacesTypes1: acaNames.PlacesTypes1,
  PlacesTypes2: acaNames.PlacesTypes2,
  PlacesTypes3: acaNames.PlacesTypes3,

  Anonymous: 'Anonymous',
  Anonymise: 'Anonymise', // leave blank if no anonymity
  descOfAnonymous: `
    When you check this, your profile won't show your personal details. \n
    Only administrators and you will see the infomation.
  `,

  // new-user
  youHaveBeenInvitedToJoin: `You have been invited to join ${acaConfig.academondo}!`,

  copiedToClipboard: 'copied to clipboard',
  Per: 'Per',
  Select: 'Select',

  '': '',

  VisibleForAll: 'Visible for all',
  get postTagsDescription() {
    return `Choosing ${this.tags} allows ${common.yourPost} to reach the right audience. (Optional)`;
  },

  get EditTags() {
    return `${this.Edit} ${this.tags}`;
  },
  EditTagsDisclaimer: `Make sure to reach out to Academondo before you start editing your tags.
For example, changes will not take effect until users go to their profile and modify their tags in the respective category. However, users will still be searchable using the renamed tag, and their profile will be found, but the old name will still be the one they used when they initially selected the tag."`,
  // reusable words
  with: 'with',
  without: 'without',
  toTag: 'tag',
  tagged: 'tagged',
  Tagged: 'Tagged',
  tag: 'tag',
  Tag: 'Tag',
  tags: 'tags',
  Tags: 'Tags',
  postedIn: 'posted in',
  hasCommentedOn: 'has commented on',
  replyOnComment: 'replied on comment',
  selectInApp: 'Select all InApp',
  selectEmail: 'Select all email',
  is: 'is',
  set: 'set',
  to: 'to',
  To: 'To',
  has: 'has',
  been: 'been',
  created: 'created',
  Created: 'Created',
  not: 'not',
  Number: 'Number',
  Info: 'Info',
  Toggle: 'Toggle',
  Or: 'Or',
  My: 'My',
  Leave: 'Leave',
  and: 'and',
  Enable: 'Enable',
  enabled: 'enabled',
  Enabled: 'Enabled',
  disabled: 'disabled',
  Disabled: 'Disabled',
  read: 'read',
  Read: 'Read',
  at: 'at',

  get useTriggerCharacterToTagUsers() {
    return `Use {0} to ${this.toTag} ${this.users}`;
  },
  get TagUsers() {
    return `${this.Tag} ${this.users}`;
  },
  get YouHaveTaggedUsers() {
    return `You have ${this.tagged}: {0}`;
  },

  enableTagUsersInPosts: acaConfig['enableTagUsersInPosts']
    ? acaConfig['enableTagUsersInPosts']
    : false,
  enableTagUsersInComments: acaConfig['enableTagUsersInComments']
    ? acaConfig['enableTagUsersInComments']
    : false,

  enableEventsForUnAuthedUsers: acaConfig['enableEventsForUnAuthedUsers']
    ? acaConfig['enableEventsForUnAuthedUsers']
    : false,
  enableCommunitiesForUnAuthedUsers: acaConfig[
    'enableCommunitiesForUnAuthedUsers'
  ]
    ? acaConfig['enableCommunitiesForUnAuthedUsers']
    : false,
  enableIdeasForUnAuthedUsers: acaConfig['enableIdeasForUnAuthedUsers']
    ? acaConfig['enableIdeasForUnAuthedUsers']
    : false,

  SeeAll: 'See all',

  // SSO
  SSOLoginIntro: `To continue, log in to ${acaNames.dashboardWelcomeTitle}`,
  SSOLogin: `Continue with ${acaConfig.academondo} account`,
  SSOLoginOutro: `Continue with email and password`,
  SSOSignupIntro: `Sign up for free to ${acaNames.dashboardWelcomeTitle}`,
  SSOSignup: `Sign up with ${acaConfig.academondo} account`,
  SSOSignupOutro: `Sign up with email and password`,
  ssoVerified: `Verified by ${acaConfig.academondo}`,

  // Linkedin SSO
  LinkedinLoginIntro: `To continue, log in using Linkedin`,
  LinkedinLogin: `Continue with your`,
  LinkedinContinue: `Continue to`,
  LinkedinLoginOutro: `Continue with email and password`,
  LinkedinSignupIntro: `Sign up for ${acaNames.dashboardWelcomeTitle}`,
  LinkedinSignup: `Sign up with your`,
  LinkedinSignupOutro: `Sign up with email and password`,

  // invite Email

  InviteExternal: 'Invite a friend',
  InviteExternalToGroup:
    'If you wish to invite a colleague or a friend, not part of the platform',

  groupSenderText: `and has recommended you to join {0}({1} on {2}). Once you have created your profile, you'll be able to access {0} using this link: {3}`,

  groupInitialInvitePersonalText: acaNames['groupInitialInvitePersonalText']
    ? acaNames['groupInitialInvitePersonalText']
    : `Check out {0} on ${acaConfig.academondo}, I believe it's something for you`,
  initialInvitePersonalText: acaNames['initialInvitePersonalText']
    ? acaNames['initialInvitePersonalText']
    : ``,

  Hi: 'Hi',
  YouHaveBeenInvitedBy: 'You have been invited by',
  toJoin: 'to join',

  YourInvitationTo: 'Your invitation to',
  hasBeenSent: 'has been sent',

  change: 'change',
  Change: 'Change',
  suggested: 'suggested',
  Suggested: 'Suggested',
  Suggestions: 'Suggestions',

  listOfSuggestionsEmpty: 'The list of suggestions is empty :-)',
  addSuggestionHelperText:
    'Please ensure to review and modify the name and description, while also checking for any potential duplicates or typos.',

  IncorrectFileFormat: 'Incorrect file format, please upload',
  FileSizeExceeds: 'File size exceeds',

  LoadEntities: 'Load Entities',
  ExportToExcel: 'Export to Excel',
  ExportToCsv: 'Export to CSV',

  // Admin Page

  umamiKey: acaConfig['umamiKey'] ? acaConfig['umamiKey'] : false,
  umamiShareURL: acaConfig['umamiShareURL']
    ? acaConfig['umamiShareURL']
    : false,

  umamiAnalytics: 'Analytics platform',

  UserAdministrationTitle: 'User Adm',
  CVsTitle: acaNames.CVs,
  CompaniesTitle: acaNames.Sites,
  JobsTitle: acaNames.Jobs,
  FenerumTitle: 'Subscriptions',
  StatisticsTitle: 'Unpublisher',
  ExcelStatisticsTitle: 'Excel statistics',
  PlacesTitle: `${acaNames.Places}`,
  AdminFunctionalityTitle: '⛔ Admin',
  NumberOfGroups: `Number of groups`,
  NumberOfUsers: `Number of ${acaNames.users}`,
  NumberOfSimultaneousUsers: `Number of simultaneous ${acaNames.users}`,
  SimultaneousUsers: `Simultaneous ${acaNames.users}`,
  CurrentUserTypePieTitle: `Pie chart - user types`,
  CurrentGroupsTypePieTitle: `Pie chart - groups`,
  Grid: 'Table',
  Chart: 'Chart',
  Charts: 'Charts',
  UserLimiter: `Min. ${acaNames.users}`,
  PostLimiter: `Min. ${common.posts}`,
  GroupLimiter: `Min. groups`,
  groupType: 'type',
  GroupType: 'Type',
  Reset: 'Reset',

  Show: 'Show',

  RegisterNewCandidate: 'Kandidat',

  directMatch: 'Direkte Match',

  directMatchAdded: 'Direkte Match tilføjet',

  JobMatchDetails: 'Job match detaljer',

  EndDirectMatch: 'Afslut registrering',

  onlyOwnerCanCreatePosts: `${acaNames.Members} cannot create ${common.posts}`,

  onlyOwnerCanComment: `${acaNames.Member} cannot add comments`,

  // intro dialog - contact Consent
  helpheaderContactConsent:
    'Find den rigtige leverandør i dit område. I indkøbsservice sparer vi dig for en masse ressourcer ved at screene leverandøren for dig.',
  helpLineOneContactConsent:
    'Det er gratis at få et tilbud fra os og du vælger selv om du vil gøre brug af det.',
  helpLineTwoContactConsent:
    'Du vil blive kontaktet indenfor 48 timer med en potentiel løsning af din opgave.',

  contactConsentExplanation: 'Description',

  imageLinkInput: 'https://imagelink.com/imageID',

  'Error: The email address is badly formatted.':
    'Error: The email address is badly formatted.', // signup error msg bad email

  'Wrong email or password.': 'Wrong email or password.', // login error msg

  showRelatedJobs: `Show related ${acaNames.jobs}`,
  hideRelatedJobs: `Hide`,

  result: 'result',
  results: 'results',

  endOfDatabase: 'The end',

  ThePasswordsMustMatch: 'The passwords must match',

  dateFormat: acaConfig.dateFormat,
  date: 'Date',
  datePrefix: 'at',
  aYear: 'a year',
  aMonth: 'a month',
  day: 'day',
  days: 'days',
  aDay: 'a day',
  hour: 'hour',
  hours: 'hours',
  anHour: 'an hour',
  minute: 'minute',
  minutes: 'minutes',
  aMinute: 'a minute',
  aFewSeconds: 'a few seconds',
  seconds: 'seconds',
  ago: ' ago',

  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',

  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',

  youHaveNoJobsCreateOneFromTheListOfCompanies: `You haven't created ${acaNames.ajob} yet - Create one here`,
  AllJobsAreNowPublished: `All ${acaNames.jobs} are now published`,

  Load: 'Load',
  Remove: 'Remove',
  Job: `${acaNames.Job}`,
  Jobs: `${
    acaNames.overwriteJobsButtonInSearch
      ? acaNames.overwriteJobsButtonInSearch
      : acaNames.Jobs
  }`,
  jobs: `${acaNames.jobs}`,
  RelatedJobs: `${acaNames.Jobs}`,

  showMembersPlugin: `Show ${acaNames.members}`,
  showCalendarPlugin: 'Show calendar plugin',
  showFilesPlugin: 'Show uploads',
  showMapsPlugin: 'Show map',
  showForumPlugin: 'Enable forum',
  get showGroupChatPlugin() {
    return `Enable ${this.groupChat}`;
  },
  showEventsPlugin: `Enable ${acaNames.events}`,
  showSubgroupsPlugin: `Enable ${acaNames.subgroups}`,
  membersCanCreateEventsPlugin: `${acaNames.Members} can create ${acaNames.events}`,
  hideFromSearchPlugin: `Hide ${acaNames.idea} from search (Important)`,
  canHideIdeaFromSearch: acaConfig.canHideIdeaFromSearch,
  membersCanCreateSubgroupsPlugin: `${acaNames.Members} can create ${acaNames.subgroups}`,
  membersCanInvitePlugin: `${acaNames.Members} can invite`,

  registration: 'registrering',
  VisitRegistrations: 'Registrations',
  VisitRegistrationsStoredAutomatically:
    'Registrations are saves automatically',
  JobRegistrations: `Past ${acaNames.jobs}`,
  RegisterVisitDialogText: `Registrering sendes til Admin,
    det vil ikke være muligt at ændre i din registreringen efterfølgende`,
  Send: 'Send',
  ThankYouForYourRegistrationOfVisitAt:
    'Tak for din registrering af besøget hos',

  // Dashboard
  showWidgets: 'Show widgets',
  hideWidgets: 'Hide widgets',
  dashboardWelcomeTitle: acaNames.dashboardWelcomeTitle,
  dashboardWelcomeSubTitle: acaNames.dashboardWelcomeSubTitle,
  dashboardWelcomeLinks: [
    // {
    //   text: 'Explore our services',
    //   link: 'https://ebrains.eu/#explore_anchor'
    // },
    // {
    //   text: `What is ${names.academondo}?`,
    //   link: `https://ebrains.eu/#What%20is%20EBRAINS?`
    // }
  ],

  // SubGroups

  subgroup: `${acaNames.subgroup}`,
  Subgroup: `${acaNames.Subgroup}`,
  subgroups: `${acaNames.subgroups}`,
  Subgroups: `${acaNames.Subgroups}`,

  inviteAllMembersToNewSubgroup: `invite all ${acaNames.members} to ${acaNames.yourNewSubgroup}`,

  // Events
  wordpressEvents: acaConfig['urlForWordpressACFEvents'] ? true : false,
  enableCountdownForEventDeadline: acaConfig['enableCountdownForEventDeadline']
    ? acaConfig['enableCountdownForEventDeadline']
    : false,
  AllUpcomingEvents: `Upcoming ${acaNames.events}`,
  WaitingForAccept: 'Waiting for accept',
  get WaitingForAcceptIdea() {
    return `${
      acaNames['WaitingForAcceptIdea']
        ? acaNames['WaitingForAcceptIdea']
        : this.WaitingForAccept
    }`;
  },
  get EventPinNotAllowed() {
    return `You cannot ${this.Pin} "${this.private}" or "${this.inviteOnly}" ${this.events} `;
  },
  get Pinned() {
    return `Pinned ${this.by} ${this.academondo}`;
  },
  Pin: 'Pin',
  UnPin: 'Unpin',
  SignupLink: 'Ticket provider link',
  signupLinkExample: 'Ticketmaster.dk, EventBrite etc.',
  EventSignupLink: `Signup here`,
  OnlineMeeting: 'Meeting link',
  isOnlineEvent: `Online ${acaNames.event}`,
  Online: `Online`,
  MeetingLink: 'Meeting Link',
  meetingLinkExample: 'Zoom, Teams, Meet, Jitsi etc.',
  DiscountCode: `Discount code`,
  discountCodeExample: `Enter discount code for ${acaNames.members}`,
  TaskDeadline: `Deadline`,
  get taskDeadlineHint() {
    return `${this.Task} deadline`;
  },
  Deadline: `Deadline`,
  DeadlineCountdown: `Deadline w/countdown`,
  EventDeadline: `Deadline`,
  eventDeadlineHint: `Registration deadline`,
  PleaseAddToYourCalendarButtonText: `Add to your calendar`,
  gotoEvents: `Go to ${acaNames.events}`,
  events: acaNames.events,
  event: acaNames.event,
  subevent: acaNames['subevent'] ? acaNames['subevent'] : acaNames.event,
  _events: acaNames.event,
  Events: acaNames.Events,
  SubEvents: `Sub${acaNames.events}`,
  Event: acaNames.Event,
  EventTitle: acaNames.EventsTitle,

  PastEvents: `Past ${acaNames.events}`,
  UpcomingEvents: `Upcoming ${acaNames.events}`,
  Discover: acaNames['Discover'] ? acaNames['Discover'] : 'Discover',
  initialEventName: `My new ${acaNames.event}`,
  newEvent: `New ${acaNames.event}`,
  eventsSubTitle: '',
  deleteEvent: `Delete ${acaNames.event}`,
  thisEvent: acaNames.thisevent,
  DeleteEvent: `Delete ${acaNames.event}`,
  PublishDraftEvent: `Save the draft`,
  eventStartStep1: `Welcome to ${acaNames.event} builder, follow the steps to create your new ${acaNames.event}.`,
  eventStartStep2: `${acaNames.Theevent} will be visible based on the privacy settings.`,
  beginBuildingYourEvent: `Build ${acaNames.yourEvent}`,
  typeOfEvent: `Tags`,
  PublishEvent: `${acaNames.Publish} ${acaNames.event}`,
  PublishSubevent: `${acaNames.Publish} ${
    acaNames['subevent'] ? acaNames['subevent'] : acaNames.event
  }`,
  locationOfEvent: `The physical address of ${acaNames.thisevent}`,
  locationOfSubevent: `The physical address of ${
    acaNames['thisSubevent'] ? acaNames['thisSubevent'] : acaNames.thisevent
  }`,
  eventName: `${acaNames.eventName}`,
  EventName: `${acaNames.EventName}`,
  subeventName: `${
    acaNames['subeventName'] ? acaNames['subeventName'] : acaNames.eventName
  }`,
  SubeventName: `${
    acaNames['SubeventName'] ? acaNames['SubeventName'] : acaNames.EventName
  }`,
  writeInformationAboutThePurposeOfThisEvent: `Description of {0}`,
  inviteOnlyEventDesc: `Only visible for invited ${acaNames.members}. Only invited ${acaNames.members} can participate.`,
  PrivateEventDesc: `Visible for all, but you have to be approved by the ${acaNames.Manager} to participate.`,
  OpenPrivateEventDesc: `Visible for all, everyone can participate.`,
  PublicEventDesc: `Visible for all, everyone can participate.`,
  YourEvents: `${acaNames.YourEvents}`,
  permissionsForEvent: `Enable features for your ${acaNames.members} of ${acaNames.theevent}`,
  get publishEventDesc() {
    return `By including ${this.tags} before creation, notifications will be sent directly to relevant ${this.users}.`;
  },
  inviteTo_events: `Invite to ${acaNames.event}`,
  MissingCanCreateDraftEventMsg: acaNames['MissingCanCreateDraftEventMsg']
    ? acaNames['MissingCanCreateDraftEventMsg']
    : `If you wish to create platform wide ${acaNames.events}.
Contact ${acaConfig.academondo} management team at ${acaConfig.supportMail} for permission to create ${acaNames.events}.`,
  MissingCanPublishEventsMsg: `You cannot ${acaNames.publish} ${acaNames.events}, reach out to ${acaConfig.supportMail} for more info`,
  attending: 'Attending',
  declined: 'Declined',
  maybe: 'Maybe',
  interested: 'Interested',
  hasInvitedYouTo: 'has invited you to',
  join: 'join',
  from: 'from',
  From: 'From',
  Dear: 'Dear',
  inviteName: 'Name of contact',
  personalText: 'Personal greeting',
  noReply: 'No reply',
  attending_action: 'Attend',
  declined_action: 'Decline',
  maybe_action: 'Maybe',
  interested_action: 'Show Interest',
  noReply_action: 'No reply',
  attendance: 'Attendance',
  selectAttendance: 'Select attendance',
  AllYourPostsFromXWillBeDeleted: `All ${common.yourPosts} from {0} will be deleted`,
  StartDateReq: `Start date required`,
  EndDateReq: `End date required`,
  youHaveBeenInvitedToEvent: `You have been invited`,
  youHaveDeclinedEvent: `You have declined`,
  whoCanCreateEventsMessage: `Only ${acaNames.Manager} and Owner can create ${acaNames.community} ${acaNames.events}`,

  hideAttending: acaConfig['hideAttending'] ? true : false,
  hideMaybe: acaConfig['hideMaybe'] ? true : false,

  NetworkCVStep: 'Invite your network',
  Home: acaNames.dashboardTitle,

  LeaveRemovePostsSpecialButton: `Leave and remove ${common.posts}`,

  // event search Settings
  hidePast: `Hide past`,
  hideStarted: `Hide started`,
  showOngoing: `Show ongoing`,
  Expired: `Expired`,
  showExpired: `Show expired`,
  show24Hours: 'Past 24 hours',
  show1Week: 'Past week',
  show4Weeks: 'Past 4 weeks',
  showAll: `Show all`,
  customRange: `Adjusted period`,

  // image-cropper
  rotateLeft: 'Rotate left',
  rotateRight: 'Rotate right',
  zoomIn: 'Zoom in',
  zoomOut: 'Zoom out',
  resetImage: 'Reset image',
  saveImage: 'Save image',
  MoveTheGridToCropImage: 'Move the grid to crop image',
  AspectRatioLocked: 'Aspect ratio: locked',

  // Ideas
  ideaGenerelStepDescription: acaNames['ideaGenerelStepDescription']
    ? acaNames['ideaGenerelStepDescription']
    : '',
  ideaMediaStepDescription: acaNames['ideaMediaStepDescription']
    ? acaNames['ideaMediaStepDescription']
    : '',
  ideaTagStepDescription: acaNames['ideaTagStepDescription']
    ? acaNames['ideaTagStepDescription']
    : '',
  Trainee: 'Trainee',
  FinishCoupling: 'Afslut registrering',
  FinishCouplingHint: `Du kan afslutte registreringen når alle trainees har fået tilknyttet 2 ${acaNames.jobs}`,
  jobUsedTooManyTimeInCouplings: 'Used too many times',
  couplingJobFirstContact: 'First contact',
  jobUsagesInThisCoupling: `Usages in this ${acaNames.idea}`,
  jobUsagesInAllCouplings: `Usages across all ${acaNames.ideas}`,
  DisplayName: 'Full name',
  Matched: 'Matched',
  MatchWithTrainee: 'Match med trainee',
  ACCoupling: 'T-forløb',
  ACCouplingTrainees: 'T-forløb Trainees',
  dateOfAction: 'Starttidspunkt for indsats',
  dateOfActionEnd: 'Sluttidspunkt for indsats',
  visitationDate: 'Visitationdato',
  dateOfPublication: 'Dato for formidling',
  responsibleOrg: 'Ansvarlig organisation/A-kasse',
  'trainee-no-status': '🔴',
  'trainee-progress': '🟡',
  'trainee-done': '🟢',
  IdeasIcon: acaNames.IdeasIcon,
  copyEvent: acaConfig.copyEvent,
  copyCommunity: acaConfig.copyCommunity,
  copyIdea: acaConfig.copyIdea,
  gotoIdea: `Go to ${acaNames.ideas}`,
  initialIdeaName: acaNames.initialIdeaName,
  idea: acaNames.idea,
  _ideas: acaNames.idea,
  Idea: acaNames.Idea,
  ideas: acaNames.ideas,
  Ideas: acaNames.Ideas,
  OpenIdeas: `Open ${acaNames.ideas}`,
  OpenCommunities: `Open ${acaNames.communities}`,
  IdeasTitle: acaNames.IdeasTitle,
  ideasSubTitle: '',
  typeOfIdea: acaNames.Category5 ? `Tags and ${acaNames.category5}` : `Tags`,
  writeInformationAboutThePurposeOfThisIdea: acaNames[
    'defaultIdeaDescriptionHelperText'
  ]
    ? acaNames['defaultIdeaDescriptionHelperText']
    : `Description of ${acaNames.idea}`,
  locationOfIdea: `The physical address of this ${acaNames.idea}`,
  ideaStartStep1: acaNames.ideaStartStep1,
  ideaStartStep2: acaNames.ideaStartStep2,
  beginBuildingYourIdea: `Build ${acaNames.yourIdea}`,
  newIdea: `Start`,
  permissionsForIdea: `Enable features for your ${acaNames.members}`,
  inviteOnlyIdeaDesc: `Only visible for invited ${acaNames.members}. Only invited ${acaNames.members} can participate.`,
  PublicIdeaDesc: `Visible for all, everyone can participate.`,
  OpenPrivateIdeaDesc: `Visible for all, everyone can participate and ${common.posts} are searchable.`,
  PrivateIdeaDesc: `Visible for all, but you have to be approved by the ${acaNames.Manager} to participate.`,
  publishIdea: `${acaNames.Publish} ${acaNames.idea}`,
  get publishIdeaDesc() {
    return `By including ${this.tags} before creation, notifications will be sent directly to relevant ${this.users}.`;
  },
  deleteIdea: `Delete ${acaNames.idea}`,
  confirmDeleteIdea: `Are you sure you would like to remove this ${acaNames.idea}?`,
  inviteTo_ideas: `Invite to ${acaNames.idea}`,
  PublicIdea: `${acaNames.Public} ${acaNames.Idea}`,
  PrivateIdea: `${acaNames.Private} ${acaNames.Idea}`,
  InviteOnlyIdea: `${acaNames.InviteOnly} ${acaNames.Idea}`,
  FilterIdeas: `Filter`,
  NoMatchingIdeas: `No hits...`,
  ideaName: acaNames.ideaName,
  IdeaName: acaNames.IdeaName,
  IdeasYouManage: `${acaNames.Ideas} you manage`,
  YourIdeas: `${acaNames.YourIdeas}`,
  DeleteIdea: `Delete ${acaNames.idea}`,
  PublishDraftIdea: `Save the draft`,
  MissingCanCreateDraftIdeaMsg: `If you wish to create ${acaNames.ideas}, please reach out to ${acaConfig.supportMail} for more info`,
  MissingCanPublishIdeaMsg: `You cannot ${acaNames.publish} ${acaNames.ideas}, reach out to ${acaConfig.supportMail} for more info`,
  defaultIdeaDescription: acaNames['defaultIdeaDescription']
    ? acaNames['defaultIdeaDescription']
    : '',
  showAdressSelectorInIdeas: acaNames.showAdressSelectorInIdeas,
  showWebsiteInIdeas: acaNames.showWebsiteInIdeas,
  websiteCollaboratoryUrl: acaNames.websiteCollaboratoryUrl,

  // Communities
  enableEvents: acaConfig.enableEvents,
  enableCommunities: acaConfig.enableCommunities,
  gotoCommunity: `Go to ${acaNames.communities}`,
  initialCommunityName: acaNames.initialCommunityName,
  initialSubCommunityName: acaNames.newSubgroup,
  community: acaNames.community,
  _communities: acaNames.community,
  Community: acaNames.Community,
  Communitys: acaNames.Communities,
  communities: acaNames.communities,
  Communities: acaNames.Communities,
  CommunitiesTitle: acaNames.communitiesTitle,
  communitiesSubTitle: '',
  media: 'Media',
  typeOfCommunity: `Tags`,
  uploadCoverImage: 'Upload cover image',
  coverImage: 'Cover image',
  writeInformationAboutThePurposeOfThisCommunity: `Description of {0}`,
  locationOfCommunity: `The physical address of this ${acaNames.community}`,
  locationOfSubCommunity: `The physical address of this ${acaNames.subgroup}`,
  communityStartStep1: `Welcome to ${acaNames.community} builder, follow the steps to create your new ${acaNames.community}.`,
  communityStartStep2: `The ${acaNames.community} will be visible based on the privacy settings.`,
  beginBuildingYourCommunity: `Build your ${acaNames.community}`,
  newCommunity: `New ${acaNames.community}`,
  privacySettings: 'Privacy and Settings',
  Settings: 'Settings',
  permissionsForCommunity: `Enable features for your ${acaNames.members}`,
  inviteOnlyCommunityDesc: `Only visible for invited ${acaNames.members}. Only invited ${acaNames.members} can participate.`,
  PublicCommunityDesc: `Visible for all, everyone can participate, ${common.posts} are searchable and will show up on the everybody's feed.`,
  OpenPrivateCommunityDesc: `Visible for all, everyone can participate and ${common.posts} are searchable.`,
  PrivateCommunityDesc: `Visible for all, but you have to be approved by the ${acaNames.Manager} to participate.`,
  publishCommunity: `${acaNames.Publish} ${acaNames.community}`,
  publishSubCommunity: `${acaNames.Publish} ${acaNames.subgroup}`,
  get publishCommunityDesc() {
    return `By including ${this.tags} before creation, notifications will be sent directly to relevant ${this.users}.`;
  },
  deleteCommunity: `Delete ${acaNames.community}`,
  deleteSubGroup: `Delete ${acaNames.subgroup}`,
  confirmDeleteCommunity: `Are you sure you would like to remove this ${acaNames.community}?`,
  Owner: 'Owner',
  Drafting: 'You are drafting',
  Moderator: `${acaNames.Moderator}`,
  Administrator: `${acaNames.Manager}`,
  groupRelation: 'Membership',
  groupRelations: 'Memberships',
  PromoteToModerator: `Promote to ${acaNames.Moderator}`,
  PromoteToAdministrator: `Promote to ${acaNames.Manager}`,
  DowngradeToMember: `Downgrade to ${acaNames.member}`,
  RemoveMember: `Remove ${acaNames.member}`,
  RemoveMemberAndRemovePosts: `Remove ${acaNames.member} and ${common.posts}`,
  inviteTo_communities: `Invite to ${acaNames.community}`,
  PublicCommunity: `${acaNames.Public} ${acaNames.Community}`,
  PrivateCommunity: `${acaNames.Private} ${acaNames.Community}`,
  InviteOnlyCommunity: `${acaNames.InviteOnly} ${acaNames.Community}`,
  FilterCommunities: `Filter...`,
  NoMatchingCommunities: `No hits...`,
  PendingInvites: 'Invitations',
  GoToSearch: 'Go to search',
  Drafts: 'Drafts',
  Update: 'Update',
  UpdateAvailable: '🚀 New version ready',
  publish: acaNames.Publish,
  Publish: acaNames.Publish,
  PublishPost: 'Post',
  communityName: `${acaNames.community} name`,
  CommunityName: `${acaNames.Community} name`,
  subCommunityName: `${acaNames.Subgroup} name`,
  SubCommunityName: `${acaNames.Subgroup} name`,
  CommunitiesYouManage: `${acaNames.Communities} you manage`,
  YourCommunities: `${acaNames.YourCommunities}`,
  YourPremiumCommunities: acaNames['YourPremiumCommunities']
    ? acaNames['YourPremiumCommunities']
    : acaNames.YourCommunities,
  premiumCommunity: acaNames['premiumCommunity']
    ? acaNames['premiumCommunity']
    : acaNames.community,
  PremiumCommunity: acaNames['PremiumCommunity']
    ? acaNames['PremiumCommunity']
    : acaNames.Community,
  premiumCommunities: acaNames['premiumCommunities']
    ? acaNames['premiumCommunities']
    : acaNames.communities,
  PremiumCommunities: acaNames['PremiumCommunities']
    ? acaNames['PremiumCommunities']
    : acaNames.Communities,
  EnablePremiumCommunities: acaConfig['EnablePremiumCommunities']
    ? acaConfig['EnablePremiumCommunities']
    : false,
  get PremiumDesc1() {
    return `Become a member of ${acaConfig.academondo} ${this.Full} and get access to our ${this.premiumCommunities}. You will get the latest news, tools, experts and network, so you can ${this.dashboardWelcomeTitle}`;
  },
  get YouAreNotPremiumUserYet() {
    return `You are not upgraded to ${this.Full}, reach out for more info at `;
  },
  YouDontHaveAccessToPremiumContent: `You don't have access to this premium content`,
  ThisIs: 'This is ',
  Premium: 'Premium',
  premium: 'premium',
  premiumBackgroundColor: 'Background color for widget',
  premiumBackgroundColorPlaceholder: '#ffffff',
  premiumBackgroundColorHint: 'Insert valid css for background-color',
  by: 'by',
  By: 'By',
  OnBehalfOf: 'in',
  ThisIsHowYourPostWillLook: `This is how ${common.yourPost} will look`,
  GoTo: 'Go to',
  ViewAll: 'View all',
  Comments: 'Comments',
  NumberOfComments: 'Number of comments',
  NumberOfCommentsSubTitle: 'Only showing dates with more than 1 comment',
  LatestComment: 'Latest comment',
  MakeAComment: 'Make a comment',
  DeleteCommunity: `Delete ${acaNames.community}`,
  PublishDraftCommunity: `Save the draft`,
  MissingCanCreateDraftCommunityMsg: `If you wish to create a ${acaNames.community}, please reach out to ${acaConfig.supportMail} for more info`,
  MissingCanPublishCommunityMsg: `You cannot ${acaNames.publish} ${acaNames.communities}, reach out to ${acaConfig.supportMail} for more info`,
  LoadAllComments: 'Load all comments',
  PrivacyCanOnlyBeChangedOnCreation: `❗ Privacy cannot be changed, once ${acaNames.published}`,

  MissingCanCreateDraftGroupMsg: `You cannot create, reach out to ${acaConfig.supportMail} for more info`,
  MissingCanPublishGroupMsg: `You cannot ${acaNames.publish}, reach out to ${acaConfig.supportMail} for more info`,

  maxInfoOnSearchResults: acaConfig['maxInfoOnSearchResults']
    ? acaConfig['maxInfoOnSearchResults']
    : false,

  numberOfTagsOnSearchResults:
    acaConfig['numberOfTagsOnSearchResults'] ||
    acaConfig['numberOfTagsOnSearchResults'] === 0
      ? acaConfig['numberOfTagsOnSearchResults']
      : 2,

  // group
  get groupTagsDescription() {
    return `When creating an ${this.openPrivate} {0}, you have the option to add ${this.tags}. These ${this.tags} act as filters, ensuring that ${this.users} who have selected at least one of the same ${this.tags} receive a notification.`;
  },
  showMemberFilterCategory4: acaConfig['showMemberFilterCategory4']
    ? acaConfig['showMemberFilterCategory4']
    : false,
  showMemberFilterCategory5: acaConfig['showMemberFilterCategory5']
    ? acaConfig['showMemberFilterCategory5']
    : false,
  enableGroupRolesForCommunity: acaConfig['enableGroupRolesForCommunity']
    ? acaConfig['enableGroupRolesForCommunity']
    : false,
  enableGroupRolesForEvent: acaConfig['enableGroupRolesForEvent']
    ? acaConfig['enableGroupRolesForEvent']
    : false,
  enableGroupRolesForIdea: acaConfig['enableGroupRolesForIdea']
    ? acaConfig['enableGroupRolesForIdea']
    : false,
  groupRole: 'role',
  GroupRole: 'Role',
  groupRoles: 'roles',
  GroupRoles: 'Roles',
  get GroupRoleLabel() {
    return `${this.Select} ${this.groupRole}`;
  },
  get RemoveGroupRole() {
    return `${this.Remove} ${this.groupRole}`;
  },
  get RemainingMembers() {
    return acaNames['RemainingMembers']
      ? acaNames['RemainingMembers']
      : this.Members;
  },
  groupRole0: acaNames['groupRole0'] ? acaNames['groupRole0'] : '',
  groupRoles0: acaNames['groupRoles0'] ? acaNames['groupRoles0'] : '',
  groupRoles0Hint: acaNames['groupRoles0Hint']
    ? acaNames['groupRoles0Hint']
    : '',

  groupRoles0Link: acaNames['groupRoles0Link']
    ? acaNames['groupRoles0Link']
    : '',

  groupRoles0Dialog: acaNames['groupRoles0Dialog']
    ? acaNames['groupRoles0Dialog']
    : '',
  groupRoles0Tooltip: acaNames['groupRoles0Tooltip']
    ? acaNames['groupRoles0Tooltip']
    : '',
  groupRole1: acaNames['groupRole1'] ? acaNames['groupRole1'] : '',
  groupRoles1: acaNames['groupRoles1'] ? acaNames['groupRoles1'] : '',
  groupRoles1Hint: acaNames['groupRoles1Hint']
    ? acaNames['groupRoles1Hint']
    : '',

  groupRoles1Link: acaNames['groupRoles1Link']
    ? acaNames['groupRoles1Link']
    : '',

  groupRoles1Dialog: acaNames['groupRoles1Dialog']
    ? acaNames['groupRoles1Dialog']
    : '',
  groupRoles1Tooltip: acaNames['groupRoles1Tooltip']
    ? acaNames['groupRoles1Tooltip']
    : '',
  groupRole2: acaNames['groupRole2'] ? acaNames['groupRole2'] : '',
  groupRoles2: acaNames['groupRoles2'] ? acaNames['groupRoles2'] : '',
  groupRoles2Hint: acaNames['groupRoles2Hint']
    ? acaNames['groupRoles2Hint']
    : '',

  groupRoles2Link: acaNames['groupRoles2Link']
    ? acaNames['groupRoles2Link']
    : '',

  groupRoles2Dialog: acaNames['groupRoles2Dialog']
    ? acaNames['groupRoles2Dialog']
    : '',
  groupRoles2Tooltip: acaNames['groupRoles2Tooltip']
    ? acaNames['groupRoles2Tooltip']
    : '',
  groupRole3: acaNames['groupRole3'] ? acaNames['groupRole3'] : '',
  groupRoles3: acaNames['groupRoles3'] ? acaNames['groupRoles3'] : '',
  groupRoles3Hint: acaNames['groupRoles3Hint']
    ? acaNames['groupRoles3Hint']
    : '',

  groupRoles3Link: acaNames['groupRoles3Link']
    ? acaNames['groupRoles3Link']
    : '',

  groupRoles3Dialog: acaNames['groupRoles3Dialog']
    ? acaNames['groupRoles3Dialog']
    : '',
  groupRoles3Tooltip: acaNames['groupRoles3Tooltip']
    ? acaNames['groupRoles3Tooltip']
    : '',
  groupRole4: acaNames['groupRole4'] ? acaNames['groupRole4'] : '',
  groupRoles4: acaNames['groupRoles4'] ? acaNames['groupRoles4'] : '',
  groupRoles4Hint: acaNames['groupRoles4Hint']
    ? acaNames['groupRoles4Hint']
    : '',

  groupRoles4Link: acaNames['groupRoles4Link']
    ? acaNames['groupRoles4Link']
    : '',

  groupRoles4Dialog: acaNames['groupRoles4Dialog']
    ? acaNames['groupRoles4Dialog']
    : '',
  groupRoles4Tooltip: acaNames['groupRoles4Tooltip']
    ? acaNames['groupRoles4Tooltip']
    : '',
  groupRole5: acaNames['groupRole5'] ? acaNames['groupRole5'] : '',
  groupRoles5: acaNames['groupRoles5'] ? acaNames['groupRoles5'] : '',
  groupRoles5Hint: acaNames['groupRoles5Hint']
    ? acaNames['groupRoles5Hint']
    : '',

  groupRoles5Link: acaNames['groupRoles5Link']
    ? acaNames['groupRoles5Link']
    : '',

  groupRoles5Dialog: acaNames['groupRoles5Dialog']
    ? acaNames['groupRoles5Dialog']
    : '',
  groupRoles5Tooltip: acaNames['groupRoles5Tooltip']
    ? acaNames['groupRoles5Tooltip']
    : '',
  groupRoleMember: acaNames['groupRoleMember']
    ? acaNames['groupRoleMember']
    : '',
  groupRolesMember: acaNames['groupRolesMember']
    ? acaNames['groupRolesMember']
    : '',
  groupRolesMemberHint: acaNames['groupRolesMemberHint']
    ? acaNames['groupRolesMemberHint']
    : '',
  groupRolesMemberLink: acaNames['groupRolesMemberLink']
    ? acaNames['groupRolesMemberLink']
    : '',

  groupRolesMemberDialog: acaNames['groupRolesMemberDialog']
    ? acaNames['groupRolesMemberDialog']
    : '',
  groupRolesMemberTooltip: acaNames['groupRolesMemberTooltip']
    ? acaNames['groupRolesMemberTooltip']
    : '',
  groupRoleNoRole: acaNames['groupRoleNoRole']
    ? acaNames['groupRoleNoRole']
    : '',
  groupRolesNoRole: acaNames['groupRolesNoRole']
    ? acaNames['groupRolesNoRole']
    : '',
  groupRolesNoRoleHint: acaNames['groupRolesNoRoleHint']
    ? acaNames['groupRolesNoRoleHint']
    : '',
  groupRolesNoRoleLink: acaNames['groupRolesNoRoleLink']
    ? acaNames['groupRolesNoRoleLink']
    : '',

  groupRolesNoRoleDialog: acaNames['groupRolesNoRoleDialog']
    ? acaNames['groupRolesNoRoleDialog']
    : '',
  groupRolesNoRoleTooltip: acaNames['groupRolesNoRoleTooltip']
    ? acaNames['groupRolesNoRoleTooltip']
    : '',

  // Forum
  ViewsPosts: 'Views',
  Share: 'Share',
  ShareCopyLink: 'Share this link',
  ShareLinkOnX: 'Share on X',
  Preview: 'Preview',
  GroupPublicPost: `${common.Post}`,
  GroupPublicPosts: `${
    acaNames.overwritePostsButtonInSearch
      ? acaNames.overwritePostsButtonInSearch
      : common.Posts
  }`,
  discardChangesPostCreateTitle: `Discard ${common.post}?`,
  discardChangesPostUpdateTitle: 'Discard the changes?',
  discardChangesPostCreateBody:
    'Are you sure you want to cancel, the information will be lost?',
  discardChangesPostUpdateBody:
    'Are you sure you want to cancel this update, the information will be lost?',
  deletePostTitle: `Delete ${common.post}?`,
  deletePostBody: `${common.ThePost} and all comments will be deleted, are you sure?`,
  attachments: 'attachements',
  Attachments: 'Attachements',
  newComment: 'New Comment',

  CanLeaveCVTitle: 'You need to save to make the profile public',
  CanLeaveCVText: `Do you want to leave or ${acaNames.publish} your changes`,
  CanLeaveCVHint: '',
  PublishCV: `${acaNames.Publish} ${acaNames.cv}`,
  LeaveCvBuilder: `Leave without ${acaNames.publishing}`,

  // reactions
  ReactionLike: acaNames.ReactionLike,
  ReactionLikeFull: acaNames.ReactionLikeFull,
  ReactionLikeText: acaNames.ReactionLikeText,
  ReactionFavorite: acaNames.ReactionFavorite,
  ReactionFavoriteFull: acaNames.ReactionFavoriteFull,
  ReactionFavoriteText: acaNames.ReactionFavoriteText,
  ReactionRecycling: acaNames.ReactionRecycling,
  ReactionRecyclingFull: acaNames.ReactionRecyclingFull,
  ReactionRecyclingText: acaNames.ReactionRecyclingText,
  ReactionDislike: acaNames.ReactionDislike,
  ReactionDislikeFull: acaNames.ReactionDislikeFull,
  ReactionDislikeText: acaNames.ReactionDislikeText,
  ReactionNeutral: acaNames.ReactionNeutral,
  ReactionNeutralFull: acaNames.ReactionNeutralFull,
  ReactionNeutralText: acaNames.ReactionNeutralText,
  ReactionAngry: acaNames.ReactionAngry,
  ReactionAngryFull: acaNames.ReactionAngryFull,
  ReactionAngryText: acaNames.ReactionAngryText,

  // map
  disableMap: acaConfig['disableMap'] ? acaConfig['disableMap'] : false,
  showResultsOnMap: 'View on map',
  showResultsAsList: 'View as list',
  mapSiteTooltip: acaNames.mapSiteTooltip,
  mapSiteHint: acaNames.mapSiteHint,
  u0Marker: acaConfig.u0Marker,
  siteMarker: acaConfig.siteMarker,
  u2Marker: acaConfig.u2Marker,
  u3Marker: acaConfig.u3Marker,
  u4Marker: acaConfig.u4Marker,
  u5Marker: acaConfig.u5Marker,
  gMarker: acaConfig.gMarker,
  cMarker: acaConfig.cMarker,
  eMarker: acaConfig.eMarker,
  iMarker: acaConfig.iMarker,
  nMarker: acaConfig.nMarker,
  p0Marker: acaConfig.p0Marker,
  p1Marker: acaConfig.p1Marker,
  p2Marker: acaConfig.p2Marker,
  p3Marker: acaConfig.p3Marker,
};
