import {
  Category0,
  Category1,
  FieldOfInterest,
  Subtypes,
  Technique,
} from '../../../../src/app/shared/models';

export class GroupTags {
  public static toJson({
    fieldsOfInterest,
    techniques,
    subtypes,
    networkSubtypes,
    category0,
    category1,
    category2,
    category3,
    category4,
    category5,
  }: GroupTags) {
    return {
      fieldsOfInterest: fieldsOfInterest || null,
      techniques: techniques || null,
      subtypes: subtypes || null,
      networkSubtypes: networkSubtypes || null,
      category0: category0 || null,
      category1: category1 || null,
      category2: category2 || null,
      category3: category3 || null,
      category4: category4 || null,
      category5: category5 || null,
    } as GroupTags;
  }

  public static fromJson({
    fieldsOfInterest,
    techniques,
    subtypes,
    networkSubtypes,
    category0,
    category1,
    category2,
    category3,
    category4,
    category5,
  }: GroupTags) {
    return new GroupTags(
      fieldsOfInterest,
      techniques,
      subtypes,
      networkSubtypes,
      category0,
      category1,
      category2,
      category3,
      category4,
      category5
    );
  }
  constructor(
    public fieldsOfInterest: FieldOfInterest[] = [],
    public techniques: Technique[] = [],
    public subtypes: Subtypes[] = [],
    public networkSubtypes: Category0[] = [],
    public category0: Category0[] = [],
    public category1: Category1[] = [],
    public category2: Category1[] = [],
    public category3: Category1[] = [],
    public category4: Category1[] = [],
    public category5: Category1[] = []
  ) {}
}
