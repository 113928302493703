import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { RoutingModel } from 'app/app.routing-model';
import { GroupType } from 'app/groups/models/group-type';
import { Upload } from 'app/shared/models';

@Component({
  selector: 'app-uploads-viewer',
  templateUrl: './uploads-viewer.component.html',
  styleUrls: ['./uploads-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadsViewerComponent implements OnInit {
  @Input() public file: Upload;
  @Input() public groupType: GroupType;
  @Input() public groupKey: string;
  @Input() public showUser = false;
  @Input() public showPreview = false;
  @Input() public showImages = false;

  constructor() {}

  ngOnInit() {}

  getUserId(file: Upload) {
    const arr = file.path.split('/');
    return arr[0];
  }

  getPostId(file: Upload) {
    const arr = file.path.split('/');
    return arr[3];
  }

  gotoPost(postId) {
    switch (this.groupType) {
      case GroupType.Communities:
        return `${RoutingModel.communities.route}/${this.groupKey}/feed/${postId}`;
      case GroupType.Events:
        return `${RoutingModel.events.route}/${this.groupKey}/feed/${postId}`;
      case GroupType.Ideas:
        return `${RoutingModel.ideas.route}/${this.groupKey}/feed/${postId}`;
      default:
        break;
    }
  }

  public getAsMb(bytes: number): number {
    if (bytes) {
      return bytes / 1024 / 1024;
    } else {
      return 0;
    }
  }

  isFileAnImage(file: Upload) {
    return this.isExtensionAnImage(this.getFileExtension(file));
  }

  private isExtensionAnImage(extention: string) {
    const ext = extention.toLowerCase();
    return (
      ext === 'webp' ||
      ext === 'bmp' ||
      ext === 'jpg' ||
      ext === 'jpeg' ||
      ext === 'png' ||
      ext === 'gif' ||
      ext === 'tif' ||
      ext === 'tiff' ||
      ext === 'svg'
    );
  }

  private getFileExtension(file: Upload) {
    if (file.name) {
      const arr = file.name.split('.');
      return arr[arr.length - 1];
    } else {
      return '';
    }
  }

  getFileIcon(file: Upload) {
    const ext = this.getFileExtension(file);
    if (ext === 'pdf') {
      return 'picture_as_pdf';
    } else if (ext === 'ics') {
      return 'calendar_today';
    } else if (this.isExtensionAnImage(ext)) {
      return 'image';
    } else {
      return 'insert_drive_file';
    }
  }
}
