import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { MondoFormBuilder, MondoFormGroup } from 'app/core/mondo-form-builder';
import { StorageService } from 'app/core/storage.service';
import { UserService } from 'app/core/user.service';
import { Comment } from 'app/forum/models/comment';
import { CommentService } from 'app/forum/services/comment.service';
import { MentionService } from 'app/mention/mention.service';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';
import { AccountType } from 'app/shared/consts/accountType';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { ScientistUser } from 'app/shared/models/user/scientistUser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface CommentForm {
  message: string;
  imageLink: string;
}

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent extends DestroyComponent implements OnInit {
  @Input() comment: Comment;
  @Input() isAdmin: boolean;
  @Output() commentRemoved = new EventEmitter<Comment>();
  @Input() canComment = false;
  @Input() imageLinkPathPrefix = `/comment/`;
  @Input() forceShowSubComments = false;
  @Input() unfoldComments = false;
  @Input() currentLevel = 0;
  @Input() maxCommentLevel = 1;

  showSubComments = false;
  isEditing = false;
  owner: MondoUser;
  currentUser: MondoUser;

  private commentForm: MondoFormGroup<CommentForm> =
    this.formBuilder.group<CommentForm>({
      message: '',
      imageLink: '',
    });

  isReplying = false;

  loaded = false;

  mentionConfig = {
    items: [], // Initialize with empty array
    triggerChar: '@',
    labelKey: 'displayName', // The key to display in the mention list
    disableSearch: false, // Set to true if you want to disable the search feature
    maxItems: 5, // Maximum number of items shown in the mention list
    // mentionSelect: (item) => {
    //   console.log('remove surname?', item);
    //   return `@${item.displayName}`;
    // },
  };

  allUsersForTagging$: Observable<MondoUser[]>;
  taggedUsers: MondoUser[] = [];

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private formBuilder: MondoFormBuilder,
    private commentService: CommentService,
    private mentionService: MentionService
  ) {
    super();
  }

  ngOnInit() {
    this.allUsersForTagging$ = this.mentionService.getUsersForTagging$().pipe(
      tap((users) => {
        this.mentionConfig.items = users;
        this.loaded = true;
      })
    );
    this.safeSubscribe(
      this.userService.getUserByUid$(this.comment.ownerId),
      (owner) => (this.owner = owner)
    );
    this.safeSubscribe(
      this.authService.getCurrentUser$(),
      (user) => (this.currentUser = user)
    );

    this.getCommentControl().patchValue(this.comment.message);
    this.getCommentImageControl().patchValue(this.comment.imageLink);
  }

  formatTaggedUserList(taggedUsers: any[]): string {
    return this.mentionService.formatTaggedUserList(taggedUsers);
  }

  onCurrentlyMentionedItemsChanged(event: any) {
    // Handle mention selection here
    // console.log('currentlyMentionedItems', event);
    // console.table(event);
    this.taggedUsers = event;
  }

  get imageLinkPath(): string {
    return `${this.imageLinkPathPrefix}${this.comment.postId}/${this.comment.id}/imageLink`;
  }

  isScientist(type: AccountType) {
    return AuthService.isUserOfScientistType(type);
  }

  async onImageUpload(url: string) {
    this.getCommentImageControl().patchValue(url);
    this.comment.imageLink = url;
    await this.commentService.updateComment(this.comment);
  }

  async removeComment() {
    // await this.removeImg();
    await this.commentService.deleteComment(this.comment);
    this.commentRemoved.emit(this.comment);
  }

  // async removeImg() {
  //   if (this.getCommentImageControl().value) {
  //     const name = this.imageLinkPath.split('/').pop();
  //     return this.storageService.deleteFile(this.imageLinkPath, false, name);
  //   }
  // }
  get indentComment() {
    return this.currentLevel < this.maxCommentLevel;
  }

  getCommentControl() {
    return this.commentForm.getSafeControl((x) => x.message);
  }

  getCommentImageControl() {
    return this.commentForm.getSafeControl((x) => x.imageLink);
  }

  public reply() {
    this.isReplying = !this.isReplying;
    this.showReplies();
  }

  public showReplies() {
    this.showSubComments = true;
  }

  edit() {
    this.getCommentControl().patchValue(
      this.comment.message.replace(/<br\s*[\/]?>/gi, '\n')
    );
    this.isEditing = !this.isEditing;
  }

  cannotSave() {
    return (
      !this.getCommentControl().value.trim() &&
      !this.getCommentImageControl().value.trim()
    );
  }
  async save() {
    if (this.cannotSave()) {
      return;
    }
    this.comment.message = this.getCommentControl().value.replace(
      /\n\r?/g,
      '<br />'
    );
    this.comment.imageLink = this.getCommentImageControl().value;

    const uniqueUserIdsSet = new Set(this.taggedUsers.map((user) => user.uid));
    const taggedUserIds = Array.from(uniqueUserIdsSet).filter(
      (userId) => userId !== undefined && userId !== null
    );
    this.comment.taggedUserIds = taggedUserIds;

    await this.commentService.updateComment(this.comment);
    this.isEditing = false;
  }

  cancel() {
    this.isEditing = false;
  }

  getCommentClass(): string {
    return this.isOwner() ? 'owner' : 'not-owner';
  }

  isOwner(): boolean {
    return this.currentUser && this.currentUser.uid === this.comment.ownerId;
  }

  canEdit(): boolean {
    return this.isOwner() || this.isAdmin;
  }

  showPreview(): void {
    this.authService.showPreview(this.owner);
  }

  getTitle(user: MondoUser) {
    if (AuthService.isUserOfCompanyType(user.type)) {
      // return (user as IndustryUser).company.;
      return '';
    } else if (AuthService.isUserOfScientistType(user.type)) {
      return (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.academicTitle
        ? `${(user as ScientistUser).personalDetails.academicTitle}`
        : '';
    } else {
      return '';
    }
  }

  getLocation(user: MondoUser) {
    if (AuthService.isUserOfCompanyType(user.type)) {
      // return (user as IndustryUser).company.;
      return '';
    } else if (AuthService.isUserOfScientistType(user.type)) {
      return (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.companyName
        ? `@${(user as ScientistUser).personalDetails.companyName}`
        : '';
    } else {
      return '';
    }
  }

  getTooltip(user: MondoUser, noProfiletext: string) {
    return `${this.getTitle(user)}${this.getLocation(user)}
    `;
  }
}
