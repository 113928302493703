import { Pipe, PipeTransform } from '@angular/core';
import { CV } from '../models/cv/cv';

@Pipe({
  name: 'filterCvs'
})
export class FilterCvsPipe implements PipeTransform {

  transform(cvs: CV[], args?: any): any {
    if (!cvs) {
      return cvs;
    }
    return cvs.filter((cv) => this.applyFilter(cv));
  }

  applyFilter(cv: CV) {
    return (cv.personalDetails && cv.personalDetails.address && cv.personalDetails.address.latLng);
  }

}
