import { ItemStatus } from '../../db-strings/ItemStatus';
import { StatusContext } from '../../status-context';
import { BaseStatus } from '../base-status';

export default class Unpublished extends BaseStatus {
  handleQueueForPublish(context: StatusContext): void {
    context.setStatus(ItemStatus.queuedForPublish);
  }
  handleCanPublish(): boolean {
    return true;
  }
  handlePublish(context: StatusContext): void {
    context.setStatus(ItemStatus.published);
  }

  handleUnpublish(context: StatusContext): void {
    context.setStatus(ItemStatus.unpublished);
  }

  queueForPublish(context: StatusContext) {
    context.setStatus(ItemStatus.queuedForPublish);
  }

  handleGetDbString(): string {
    return super.handleGetDbString() + ItemStatus.unpublished;
  }
  handleGetDescription(): string {
    return 'unpublished';
  }
  getShortStatus(): string {
    return ItemStatus.unpublished;
  }
}
