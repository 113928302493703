import { acaConfig } from '../src/aca-config';

const names = {
  auser: 'a user',
  user: 'user',
  User: 'User',
  users: 'users',
  Users: 'Users',
  thesite: 'the company',
  asite: 'a company',
  newsite: 'new company',
  Newsite: 'New company',
  companyName: `company name`,
  CompanyName: `Company name`,
  site: 'company',
  Site: 'Company',
  sites: 'companies',
  Sites: 'Companies',
  thejob: 'the job',
  ajob: 'a job',
  yourjob: 'your job',
  Yourjob: 'Your job',
  newJobPosition: 'New job',
  Oldjob: 'Old job',
  job: 'job',
  Job: 'Job',
  jobs: 'jobs',
  Jobs: 'Jobs',
  subevent: 'subevent',
  Subevent: 'Subevent',
  subevents: 'subevents',
  Subevents: 'Subevents',
};

export const acaNamesACADEMONDO = {
  ReactionLike: 'thumb_up_off_alt',
  ReactionLikeFull: 'thumb_up',
  ReactionLikeText: 'Like',
  ReactionFavorite: 'favorite_border',
  ReactionFavoriteFull: 'favorite',
  ReactionFavoriteText: 'Love',
  ReactionRecycling: 'recycling',
  ReactionRecyclingFull: 'recycling',
  ReactionRecyclingText: '',
  ReactionDislike: 'thumb_down_off_alt',
  ReactionDislikeFull: 'thumb_down',
  ReactionDislikeText: 'Dislike',
  ReactionNeutral: 'sentiment_neutral',
  ReactionNeutralFull: 'sentiment_neutral',
  ReactionNeutralText: 'Neutral',
  ReactionAngry: 'sentiment_very_dissatisfied',
  ReactionAngryFull: 'sentiment_very_dissatisfied',
  ReactionAngryText: '',

  useLanguageForCV: true,
  category0: 'category',
  Category0: 'Category', // leave blank if it should not be shown
  categories0: 'categories',
  Categories0: 'Categories',
  category1: 'Ecorp type',
  Category1: '', // leave blank if it should not be shown
  categories1: 'Ecorp types',
  Categories1: 'Ecorp types',
  category2: 'alternativ region',
  Category2: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories2: 'alternativ regioner',
  Categories2: 'Alternativ regioner',
  category3: 'uddannelse',
  Category3: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories3: 'uddannelser',
  Categories3: 'Uddannelser',
  showCategories3InCVEducationStep: false,
  category4: 'medlemsorganisation',
  Category4: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories4: 'medlemsorganisationer',
  Categories4: 'Medlemsorganisationer',
  category5: 'stage',
  Category5: 'Stage', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories5: 'stages',
  Categories5: 'Stages',
  get networkSubtype() {
    return `${this.community} type`;
  },
  get NetworkSubtype() {
    return `${this.Community} type`; // leave blank if it should not be shown
  },
  get networkSubtypes() {
    return `${this.community} types`;
  },
  get NetworkSubtypes() {
    return `${this.Community} types`;
  },
  Category0Hint: 'You will receive notifications based on your selection',
  Category0Link: '',
  Category0Dialog: '',
  Category0Tooltip: '',
  Category1Hint: 'You will receive notifications based on your selection',
  Category1Link: '',
  Category1Dialog: '',
  Category1Tooltip: '',
  Category2Hint: 'You will receive notifications based on your selection',
  Category2Link: '',
  Category2Dialog: false,
  Category2Tooltip: false,
  Category3Hint: 'You will receive notifications based on your selection',
  Category3Link: '',
  Category3Dialog: '',
  Category3Tooltip: '',
  Category4Hint: 'You will receive notifications based on your selection',
  Category4Link: '',
  Category4Dialog: '',
  Category4Tooltip: '',
  Category5Hint: 'You will receive notifications based on your selection',
  Category5Link: '',
  Category5Dialog: '',
  Category5Tooltip: '',
  FoiHint: 'You will receive notifications based on your selection',
  FoiLink: '',
  FoiDialog: '',
  FoiTooltip: '',
  TechHint: 'You will receive notifications based on your selection',
  TechLink: '',
  TechDialog: '',
  TechTooltip: '',
  siteInfoStepTitle: `${names.Site} Info`,
  siteInfoDate: 'Year of establishment',
  siteInfoDateHint: '',
  siteQuestion0: 'Board of Directors',
  siteQuestion0Hint: ``,
  siteQuestion0Link: '',
  siteQuestion0Dialog: false,
  siteQuestion0Tooltip: true,
  siteQuestion1: 'Advisory Board',
  siteQuestion1Hint: ``,
  siteQuestion1Link: '',
  siteQuestion1Dialog: false,
  siteQuestion1Tooltip: true,
  siteQuestion2: 'Soft Funding',
  siteQuestion2Hint: ``,
  siteQuestion2Link: '',
  siteQuestion2Dialog: false,
  siteQuestion2Tooltip: true,
  siteQuestion3: `Funding`,
  siteQuestion3Hint: '',
  siteQuestion3Link: '',
  siteQuestion3Dialog: false,
  siteQuestion3Tooltip: true,
  siteQuestion4: 'Revenue',
  siteQuestion4Hint: ``,
  siteQuestion4Link: '',
  siteQuestion4Dialog: false,
  siteQuestion4Tooltip: false,
  GraduateProgram: '',
  GraduateProgramHint: '',
  GraduateProgramLink: '',
  GraduateProgramDialog: '',
  GraduateProgramTooltip: '',
  AreYouOnDimittendsats: 'Er du på dimittendsats',
  Dimittendsats: '',
  DimittendsatsHint: '',
  DimittendsatsLink: '',
  DimittendsatsDialog: '',
  DimittendsatsTooltip: '',
  Akademikerkampagnen: '',
  AreYouOnAkademikerkampagnen: 'Har du været på kursus hos Akademikerkampagnen',
  AkademikerkampagnenHint: '',
  AkademikerkampagnenLink: '',
  AkademikerkampagnenDialog: '',
  AkademikerkampagnenTooltip: '',

  CPRHint: '',
  CPRLink: '',
  CPRDialog: false,
  CPRTooltip: false,
  experience0: 'academia',
  Experience0: 'Academia', // leave blank if it should not be shown
  ExperienceUnit0: ``, // `m<sup>2</sup>`,
  experience1: 'industry',
  Experience1: 'Industry', // leave blank if it should not be shown
  ExperienceUnit1: ``, // `m<sup>2</sup>`,
  experience2: 'hospital',
  Experience2: 'Hospital', // leave blank if it should not be shown
  ExperienceUnit2: ``, // `m<sup>2</sup>`,
  experience3: 'entrepreneurship',
  Experience3: '', // 'Entrepreneurship', // leave blank if it should not be shown
  ExperienceUnit3: ``, // ' vær.',
  experience4: 'public sector/health',
  Experience4: '', // 'Public sector/health', // leave blank if it should not be shown
  ExperienceUnit4: ``, // ' ',
  experience5: '',
  Experience5: '', // leave blank if it should not be shown
  ExperienceUnit5: ``, // 'Mio.kr.',
  Specials: 'Lab Technicians',
  UserType0: 'Scientist', // leave blank if it should not be shown
  UserTypes0: 'Scientists',
  UserType1: 'Industry', // leave blank if it should not be shown
  UserTypes1: 'Industries',
  UserType2: '', // leave blank if it should not be shown
  UserTypes2: 'Universities',
  UserType3: 'Lab Technician', // leave blank if it should not be shown
  UserTypes3: 'Lab Technicians',
  UserType4: '', // leave blank if it should not be shown
  UserTypes4: 'Biomedical Scientists',
  UserType5: 'SSO', // leave blank if it should not be shown
  UserTypes5: 'SSO',
  auser: names.auser,
  user: names.user,
  User: names.User,
  users: names.users,
  Users: names.Users,
  thesite: names.thesite,
  asite: names.asite,
  newsite: names.newsite,
  Newsite: names.Newsite,
  companyName: names.companyName,
  CompanyName: names.CompanyName,
  CompanyNameJob: names.CompanyName,
  site: names.site,
  Site: names.Site,
  sites: names.sites,
  Sites: names.Sites,
  siteWebsiteUrl: 'Website url',
  // visitCVWebsite: 'Spinout website',
  // cvAddressDisclaimer:
  //   'Adding your spinout or university address will put you on the map',
  CompanyJobStep: names.Site,
  theplace: 'the place',
  place: 'place',
  Place: 'Place',
  places: 'places',
  Places: 'Places',
  thecv: `the profile`,
  CVRoute: 'profile',
  Yourcv: 'Your profile',
  yourcv: 'your profile',
  CV: 'Profile',
  cv: 'profile',
  CVs: 'Profiles',
  cvs: 'profiles',

  subTitle: '', // choose one
  academicTitle: 'Academic title', // choose one

  JobType0: 'Internship', // leave blank if it should not be shown
  JobType1: 'Parttime', // leave blank if it should not be shown
  JobType2: 'Fulltime', // leave blank if it should not be shown
  JobType3: '', // 'Entrepreneurship', // leave blank if it should not be shown
  JobType4: '', // 'Public sector/health', // leave blank if it should not be shown
  JobType5: '', // leave blank if it should not be shown
  JobType6: '', // leave blank if it should not be shown
  JobType7: '', // leave blank if it should not be shown
  JobType8: '', // leave blank if it should not be shown
  JobType9: '', // leave blank if it should not be shown

  jobType0Desc: '', // leave blank if it should not be shown
  jobType1Desc: '', // leave blank if it should not be shown
  jobType2Desc: '', // leave blank if it should not be shown
  jobType3Desc: '', // leave blank if it should not be shown
  jobType4Desc: '', // leave blank if it should not be shown
  jobType5Desc: '', // leave blank if it should not be shown
  jobType6Desc: '', // leave blank if it should not be shown
  jobType7Desc: '', // leave blank if it should not be shown
  jobType8Desc: '', // leave blank if it should not be shown
  jobType9Desc: '', // leave blank if it should not be shown
  thejob: names.thejob,
  ajob: names.ajob,
  yourjob: names.yourjob,
  Yourjob: names.Yourjob,
  newJobPosition: names.newJobPosition,
  Oldjob: names.Oldjob,
  job: names.job,
  Job: names.Job,
  jobs: names.jobs,
  Jobs: names.Jobs,
  JobType: 'Type',

  shortFoi: 'interests',
  ShortFoi: 'Interests',
  foi: 'field of interest',
  Foi: 'Field of interest', // leave blank if it should not be shown
  fois: 'field of interests',
  Fois: 'Field of interests',
  tech: 'technique',
  Tech: 'Technique', // leave blank if it should not be shown
  techs: 'techniques',
  Techs: 'Techniques',
  companySubtype: 'subtype', // 'type',
  CompanySubtype: 'Subtype', // leave blank if it should not be shown
  companySubtypes: 'subtypes', // 'typer',
  CompanySubtypes: 'Subtypes', // 'Typer',

  groupRole0: 'Column Speaker',
  groupRoles0: 'Column Speakers',
  groupRoles0Hint: `Column speakers have an extensive overview of the research groups and their work within the column.

They also have responsibilities in communicating possibilities and facilitating activities within the column.

Column speakers can function as principal supervisors and co-supervisors of NAD PhD projects, and they can offer lab rotations in the NAD programme.`,
  groupRoles0Link: '',
  groupRoles0Dialog: true,
  groupRoles0Tooltip: false,
  groupRole1: 'Column PI',
  groupRoles1: 'Column PIs',
  groupRoles1Hint: `Column PIs include professors, associate professors, and assistant professors who can act as either principal supervisors or co-supervisors of NAD PhD projects and offer lab rotations in the NAD programme.

Principal supervisors must be employed at a Health Faculty and have an academic level equivalent to at least associate professor or clinical associate professor.

Primary co-supervisors must have an academic level equivalent to at least associate professor or clinical associate professor.

Other co-supervisors must possess qualifications within the scientific area in question in order to contribute to the PhD project and help the PhD student with the research.
    `,
  groupRoles1Link: '',
  groupRoles1Dialog: true,
  groupRoles1Tooltip: false,
  groupRole2: 'Column Collaborator',
  groupRoles2: 'Column Collaborators',
  groupRoles2Hint: `Column collaborators can offer lab rotations in the NAD programme and function as co-supervisors for NAD PhD projects.`,
  groupRoles2Link: '',
  groupRoles2Dialog: true,
  groupRoles2Tooltip: false,

  groupRoleMember: 'Member',
  groupRolesMember: 'Members',
  groupRolesMemberHint: `Column members include junior scientists (bachelor's, master's, PhD and postdoc level) and professors, associate professors and assistant professors not identifying this column as their primary Research Column.`,
  groupRolesMemberLink: '',
  groupRolesMemberDialog: true,
  groupRolesMemberTooltip: false,
  groupRolesNoRoleHint: '',
  groupRolesNoRoleLink: '',
  groupRolesNoRoleDialog: false,
  groupRolesNoRoleTooltip: false,

  RemainingMembers: 'No role yet',

  ApplyCommunity: `Apply`,
  ApplyCommunityTooltip: ``,
  ApplyEvent: `Apply`,
  ApplyEventTooltip: '',
  ApplyIdea: `Apply`,
  ApplyIdeaTooltip: '',
  becomeMemberCommunity: `Join`,
  becomeMemberEvent: `Join`,
  becomeMemberIdea: `Join`,

  MissingCanCreateDraftEventMsg: ``,

  becomeMemberCommunityTooltip: ``,
  becomeMemberEventTooltip: ``,
  becomeMemberIdeaTooltip: ``,
  thenetwork: 'the network',
  network: 'network',
  Network: 'Network',
  networks: 'networks',
  Networks: 'Networks',
  community: 'community',
  Community: 'Community',
  communities: 'communities',
  Communities: 'Communities',
  YourCommunities: 'Your communities',
  communitiesTitle: 'Communities',
  initialCommunityName: `New community`,
  YourPremiumCommunities: 'Fællesskaber',
  premiumCommunity: 'fællesskab',
  PremiumCommunity: 'Fællesskab',
  premiumCommunities: 'fællesskaber',
  PremiumCommunities: 'Fællesskaber',
  yourNewSubgroup: 'your new subcommunity',
  newSubgroup: 'new subcommunity',
  subgroup: 'subcommunity',
  Subgroup: 'Subcommunity',
  subgroups: 'subcommunities',
  Subgroups: 'Subcommunities',
  theidea: 'the project idea',
  idea: 'project idea',
  Idea: 'Project idea', // leave blank if it should not be shown
  ideas: 'project ideas',
  Ideas: 'Project ideas',
  YourIdeas: 'Your project ideas',
  yourIdea: 'your project idea',
  initialIdeaName: `New project idea`,
  ideaName: `project idea name`,
  IdeaName: `Project idea name`,
  IdeasIcon: 'lightbulb',
  IdeasTitle: 'Project ideas',
  ideaStartStep1: `Welcome to project idea builder, follow the steps to create your new project idea.`,
  ideaStartStep2: `The project idea will be visible based on the privacy settings.`,
  showAdressSelectorInIdeas: true,
  showWebsiteInIdeas: true,
  websiteCollaboratoryUrl: '',
  theevent: 'the event',
  Theevent: 'The event',
  thisevent: 'this event',
  Thisevent: 'This event',
  newEvent: 'new event',
  eventName: 'event name',
  EventName: 'Event name',
  event: 'event',
  Event: 'Event',
  events: 'events',
  Events: 'Events',
  YourEvents: 'Your events',
  yourEvent: 'your event',
  EventsTitle: 'Events',

  // thisSubevent: `this ${names.subevent}`,
  // ThisSubevent: `This  ${names.subevent}`,
  // subeventName: `name of ${names.subevent}`,
  // SubeventName: `Name of ${names.subevent}`,
  // subevent: names.subevent,
  // Subevent: names.Subevent,
  // subevents: names.subevents,
  // Subevents: names.Subevents,

  Moderator: 'Moderator',
  Manager: 'Manager',
  member: 'member',
  Member: 'Member',
  members: 'members',
  Members: 'Members',
  participant: 'participant',
  Participant: 'Participant',
  participants: 'participants',
  Participants: 'Participants',
  participantInvite: 'invited',
  ParticipantInvite: 'Invited',
  participantsInvite: 'invited',
  ParticipantsInvite: 'Invited',

  Public: 'Public',
  OpenPrivate: 'Open',
  Private: 'Private',
  Hidden: 'Hidden',
  InviteOnly: 'Invite only',
  public: 'public',
  openPrivate: 'open',
  private: 'private',
  inviteOnly: 'invite only',
  hidden: 'hidden',
  overwriteJobsButtonInSearch: '',
  overwritePostsButtonInSearch: '',
  notEnoughPermission: `Welcome to ${acaConfig.academondo}.
  Create your profile to get access`,
  dashboardWelcomeTitle: `${acaConfig.academondo} Community`,
  dashboardWelcomeSubTitle: ``,
  scienceMatchRoute: 'search',
  scienceMatch: 'Search',
  scienceMatchSubtitle: 'We match scientists',
  accountSelector1: `Hvem er ${acaConfig.academondo} lavet til?`, // `Who is ${acaConfig.academondo} for?`
  accountSelector2: 'Alle i dansk erhvervsliv', // 'Everybody with a passion within Life Science, Health- or Foodtech',
  accountSelector3: 'Jeg er en person', // `I'm an individual`
  accountSelector4: 'Jeg er en virksomhed', // `I'm a company, university, organisation or hospital`
  inviteToAcademondoText: `Invite your contacts`,

  showOrcId: false,
  showGithub: false,
  showTwitter: false,
  thisWillMakeCVSearchable: 'This will make your profile searchable.',
  showTitleadditionalInformation: false,
  additionalInformation: 'Additional information',
  additionalInformationEtc: '',
  writeTheRelevantInformation: 'Write the relevant information',
  writeInfoAboutCompany: `Write information about the ${names.site}. (try copy/paste from ex. word) 😉`, // `Beskriv ${acaNames.site}en.`,
  writeQualificationsPosition:
    'Write about the qualifications required for the position.', // 'Beskriv kategorierne.',
  permanentContract: 'Permanent contract', // 'Fast stilling',
  dateOfBirthHint: '', // 'This will not be published - only for statistics',
  // intro
  showTitleintroductoryText: false,
  introductoryText: 'Introductory text',
  writeAboutYourself: 'What would I learn about you over a cup of coffee?',
  titleOfEducationHint: 'eks. Molecular Nanotechnology',

  dashboardRoute: 'feed',
  dashboardTitle: 'Home',
  jobBuilderRoute: `job-builder`,
  jobBuilderTitle: `Jobs`,
  // jobBuilderIcon: 'loyalty',
  siteBuilderRoute: 'Your Profile',

  Basic: 'Basic',
  Extended: 'Extended',
  Full: 'Full',
  Admin: 'Admin',

  // facility: 'institution',
  // Facility: 'Institution',

  placesUrl2: '',
  PlacesTypes0: 'Places',
  PlacesTypes1: 'OE hub',
  PlacesTypes2: 'Fishing area',
  PlacesTypes3: 'Hunting grounds',

  signUp: 'Sign up',

  // DontHaveAccountText:
  //   'Are you an active researcher in neuroscience or related areas based in Denmark?',
  // SignupButtonText: 'Apply to join NAD Connect',

  // intro dialog - cv
  helpheaderCV:
    'You are now ready to build your professional life science profile!',
  helpLineOneCV:
    'The interactive profile builder allows you to see your profile, while you build it. When the tracker reaches 100% you have entered all the required information.',
  helpLineTwop1CV: 'When you are done, remember to press',
  helpLineTwop2CV: 'to make your profile',
  helpLineTwop3CV: `visible to other members. You can always edit your profile!`,
  helpLineThreep1CV:
    'When you find your dream job, the profile builder allows you to build multiple CVs',
  helpLineThreep2CV: 'specifically designed for the job you are applying for.',

  // intro dialog - job
  helpheaderJob:
    'You are now ready to create job postings! The interactive feature allows you to see the job posting while you create it. When you are ready, just press “Begin building your job post” and watch your job posting take shape as you are writing.',
  helpLineOneJob:
    'When you are done, remember to press “Publish” to make your job posting visible to all the users in our network.',
  helpLineTwoJob:
    'Again, welcome to our Universe, we are happy to help you on your way towards finding the best candidates for you open positions!',
  jobStartStep1: `Welcome to the ${names.job} builder, follow the steps.`,
  get jobStartStep2() {
    return `Remember to ${this.toPublish} by entering the "${this.Publish}" at the end!`;
  },
  beginBuildingYourJobPost: `Build ${names.yourjob}`,
  URLToTheOfficialJobSite: 'Website of employing organisation',
  URLToTheOfficialJobApplicationForm:
    'Link to full description / application form',
  toPublish: 'publish',
  publish: 'publish',
  Publish: 'Publish',
  published: 'published',
  Published: 'Published',
  publishedPlural: 'published',
  publishing: 'publishing',
  Publishing: 'Publishing',
  PublishJob: `Publish ${names.job}`,
  PublishJobs: `Publish ${names.jobs}`,
  publishMenu: 'Publish Menu',
  employmentPeriod: 'Employment period',

  PlanSelectorTitle: 'Choose your plan',
  PlanSelectorSubTitle: `${acaConfig.academondo} provides a plan for everyone. What's yours?`,
  PlanSelectorParagraph1: 'Here is what you get:',
  PlanSelectorListItem1: `Global exposure and find potential customers, investors or CRO's`,
  PlanSelectorListItem2: `Unlimited job-posting`,
  PlanSelectorListItem3: `Search through our database of CV's to find the perfect candidate`,
  PlanSelectorListItem4: `Join networks to collaborate, innovate and share knowledge`,
  // map
  mapSiteTooltip: '',
  mapSiteHint: '',

  // Discover: 'Explore',

  // privacyPolicy: 'persondatapolitik',
  // PrivacyPolicy: 'Persondatapolitik',

  // combineTermsAndPolicyText:
  //   'Ved at forsætte giver du accept og er enig i brugen af dine data på de betingelser, som er angivet i vores ',
};
