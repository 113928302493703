import { Injectable } from '@angular/core';
import {
  INavigationItem,
  RoutingModel,
  MondoRoutes,
  NewUserRoutes,
} from 'app/app.routing-model';
import { AccountPermission } from 'app/shared/consts/accountPermission';
import { AccountType } from 'app/shared/consts/accountType';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { MondoUser } from '../shared/models/user/mondoUser';

@Injectable()
export class NavigationService {
  private navigationItems$: BehaviorSubject<INavigationItem[]> =
    new BehaviorSubject<INavigationItem[]>([]);
  private prevType: AccountType;
  private prevPermission: AccountPermission;
  private prevPublicCV: string;
  private prevPublicSite: string;

  constructor(private authService: AuthService) {
    this.authService.getUserStatus$().subscribe((status) => {
      if (status && status.loggedIn) {
        this.handleLoggedInUser(status.user);
      } else {
        this.prevPermission = null;
        this.prevType = null;
        this.prevPublicCV = '';
        this.prevPublicSite = '';
        this.navigationItems$.next(this.getUnAuthenticatedNavItems());
      }
    });
  }

  get navItems$(): Observable<INavigationItem[]> {
    return this.navigationItems$.asObservable();
  }

  private getUnAuthenticatedNavItems(): INavigationItem[] {
    return Object.values(NewUserRoutes)
      .map((route) => {
        if (!RoutingModel[route]) {
          return null;
        }
        return RoutingModel[route];
      })
      .filter((item) => item !== null);
  }

  private getNavItems(user: MondoUser): INavigationItem[] {
    return Object.keys(MondoRoutes).reduce((result, route) => {
      if (
        !this.authService.canCreateJob &&
        RoutingModel[route] === RoutingModel.jobBuilder
      ) {
        return result;
      }
      if (this.userHavePermission(user, route)) {
        result.push(RoutingModel[route]);
      }
      return result;
    }, []);
  }

  private userHavePermission(user: MondoUser, route: string) {
    const item: INavigationItem = RoutingModel[route];
    if (!item) {
      return false;
    } else {
      return (
        user.permission >= item.routeData.minimumPermission &&
        item.routeData.validAccountTypes.includes(user.type) &&
        !item.excludeFromNavBar
      );
    }
  }

  private handleLoggedInUser(user: MondoUser) {
    if (
      user.type !== this.prevType ||
      user.permission !== this.prevPermission ||
      user.publishedCv !== this.prevPublicCV ||
      (user['company'] && user['company'].uid !== this.prevPublicSite)
    ) {
      this.prevPermission = user.permission;
      this.prevType = user.type;
      this.prevPublicSite = user['company'] ? user['company'].uid : '';
      this.prevPublicCV = user.publishedCv;
      this.navigationItems$.next(this.getNavItems(user));
    }
  }
}
