import { SiteType } from './siteType';
import { Subtypes } from './site-subtypes';
import { MondoLocation } from '../../stepper/job/model/mondoLocation';
import { SiteIdeaStatus } from './siteIdeaStatus';

export class SiteInfo implements ISiteInfo {
  public static fromJson(
    {
      type,
      pictureUrl,
      nameSite,
      catchPhrase,
      addressSite,
      websiteUrl,
      email,
      phone,
      youtubeId,
      linkedin,
      info,
      subtypes,
      coverUrl,
      logoUrl,
      cvr,
      contactEmail,
      price,
      siteInfoDate,
      siteQuestion0,
      siteQuestion1,
      siteQuestion2,
      siteQuestion3,
      siteQuestion4,
      disableChat,
      siteStatus,
    }: ISiteInfo = {} as SiteInfo
  ) {
    return new SiteInfo(
      type,
      pictureUrl,
      nameSite,
      catchPhrase,
      MondoLocation.fromJson(addressSite),
      websiteUrl,
      email,
      phone,
      youtubeId,
      linkedin,
      info,
      subtypes,
      coverUrl,
      logoUrl,
      cvr,
      contactEmail,
      price,
      siteInfoDate ? new Date(siteInfoDate) : null,
      siteQuestion0,
      siteQuestion1,
      siteQuestion2,
      siteQuestion3,
      siteQuestion4,
      disableChat,
      siteStatus
    );
  }

  public static toJson(siteInfo: ISiteInfo) {
    return {
      type: siteInfo.type,
      pictureUrl: siteInfo.pictureUrl,
      nameSite: siteInfo.nameSite,
      catchPhrase: siteInfo.catchPhrase,
      addressSite: MondoLocation.fromJson(siteInfo.addressSite),
      websiteUrl: siteInfo.websiteUrl,
      email: siteInfo.email,
      phone: siteInfo.phone,
      youtubeId: siteInfo.youtubeId,
      linkedin: siteInfo.linkedin,
      info: siteInfo.info,
      subtypes: siteInfo.subtypes,
      coverUrl: siteInfo.coverUrl,
      logoUrl: siteInfo.logoUrl,
      cvr: siteInfo.cvr,
      contactEmail: siteInfo.contactEmail,
      price: siteInfo.price,
      siteInfoDate: siteInfo.siteInfoDate
        ? siteInfo.siteInfoDate.getTime()
        : null,
      siteQuestion0: siteInfo.siteQuestion0,
      siteQuestion1: siteInfo.siteQuestion1,
      siteQuestion2: siteInfo.siteQuestion2,
      siteQuestion3: siteInfo.siteQuestion3,
      siteQuestion4: siteInfo.siteQuestion4,
      disableChat: siteInfo.disableChat ? siteInfo.disableChat : false,
      siteStatus: siteInfo.siteStatus
        ? siteInfo.siteStatus
        : SiteIdeaStatus.SiteIdeaActive,
    };
  }

  constructor(
    public type = SiteType.company,
    public pictureUrl = '',
    public nameSite = '',
    public catchPhrase = '',
    public addressSite: MondoLocation = new MondoLocation(),
    public websiteUrl = '',
    public email = '',
    public phone = '',
    public youtubeId = '',
    public linkedin = '',
    public info = '',
    public subtypes: Subtypes[] = [],
    public coverUrl = '',
    public logoUrl = '',
    public cvr = '',
    public contactEmail = '',
    public price = 0,
    public siteInfoDate = null,
    public siteQuestion0 = null,
    public siteQuestion1 = null,
    public siteQuestion2 = null,
    public siteQuestion3 = null,
    public siteQuestion4 = null,
    public disableChat = false,
    public siteStatus = SiteIdeaStatus.SiteIdeaActive
  ) {}
}

export interface ISiteInfo {
  type: SiteType;
  pictureUrl: string;
  nameSite: string;
  catchPhrase: string;
  addressSite: MondoLocation;
  websiteUrl: string;
  email: string;
  phone: string;
  youtubeId: string;
  linkedin: string;
  info: string;
  subtypes: Subtypes[];
  coverUrl: string;
  logoUrl: string;
  cvr: string;
  contactEmail: string;
  price: number;
  siteInfoDate: Date;
  siteQuestion0: boolean;
  siteQuestion1: boolean;
  siteQuestion2: boolean;
  siteQuestion3: boolean;
  siteQuestion4: boolean;
  disableChat: boolean;
  siteStatus: SiteIdeaStatus;
}
