import { isString } from '../../../../shared/common/acaLodash';
import { ItemStatus } from './db-strings/ItemStatus';
import { SyncStatus } from './db-strings/sync-status';
import { IStatus } from './i-status';
import { StatusFactory } from './status-factory';

export class StatusContext {
  private status: IStatus;

  static fromDbString(
    dbString: string,
    fallBackStatus: ItemStatus = ItemStatus.unpublished
  ): StatusContext {
    if (!isString(dbString)) {
      return new StatusContext(fallBackStatus);
    }
    const dbStingParts = dbString.split('|');
    if (dbStingParts.length !== 2) {
      return new StatusContext(fallBackStatus);
    }

    const syncStatus = dbStingParts[0] as SyncStatus;
    const status = dbStingParts[1] as ItemStatus;

    const statusContext = new StatusContext(status);

    switch (syncStatus) {
      case SyncStatus.inSync:
        statusContext.setInSync();
        break;
      case SyncStatus.outOfSync:
        statusContext.setOutOfSync();
        break;
    }
    return statusContext;
  }

  constructor(status: ItemStatus = ItemStatus.unpublished) {
    this.setStatus(status);
  }

  setStatus(status: ItemStatus): void {
    this.status = StatusFactory.creatStatus(status);
    this.status.onEnter(this);
  }

  canPublish(): boolean {
    return this.status.handleCanPublish();
  }
  canMakepublicallyVisible(): boolean {
    return this.status.handleCanMakepublicallyVisible();
  }
  canQueueForPublish(): boolean {
    return this.status.handleCanPublish();
  }
  canRepublish(): boolean {
    return this.status.handleCanRePublish();
  }
  canUnpublish(): boolean {
    return this.status.handleCanUnPublish();
  }
  canDelete(): boolean {
    return this.status.handleCanDelete();
  }
  isInSync(): boolean {
    return this.status.handleIsInSync();
  }
  isPublished(): boolean {
    return this.status.handleIsPublished();
  }
  isPubliclyVisible(): boolean {
    return this.status.handleIsPubliclyVisible();
  }
  queueForPublish(): void {
    this.status.handleQueueForPublish(this);
  }
  publish(): void {
    this.status.handlePublish(this);
  }
  unpublish(): void {
    this.status.handleUnpublish(this);
  }
  rePublish(): void {
    this.status.handleRepublish(this);
  }
  delete(): void {
    this.status.handleDelete(this);
  }
  setOutOfSync(): void {
    this.status.handleSetOutOfSync();
  }
  setInSync(): void {
    this.status.handleSetInSync();
  }
  getDbString(): string {
    return this.status.handleGetDbString();
  }
  getDescription(): string {
    return this.status.handleGetDescription();
  }
  getIcon(): string {
    return this.status.handleGetIcon();
  }
  getColor(): string {
    return this.status.handleGetColor();
  }
  getSyncMode(): string {
    throw new Error('Method not implemented.');
  }
  getShortStatus(): string {
    throw new Error('Method not implemented.');
  }
}
