import { MultiSelectorItem } from '../../components/multi-selector/multi-selector-item';

export class LanguageMultiSelector implements MultiSelectorItem {

  public static fromJson(
    { id, name, nativeName, iso639_2 }) {
    return new LanguageMultiSelector(id, name, nativeName, iso639_2);
  }

  constructor(
    public id = '',
    public name = '',
    public nativeName = '',
    public iso639_2 = '',
  ) { }

  public getId(): string {
    return this.id;
  }

  public getDisplayText(): string {
    return this.name;
  }
  public getDescription(): string {
    return undefined;
  }

}
