// ---------------------------
// Linkedin Algorithm
// Higher score = higher reach
//
// Update: the real algorithm!
// ---------------------------

// repostCountParams = 20.0             // reposts
// favCountParams = 30.0,                // likes
// replyCountParams =  1.0,              // replies

// reputationParams = 0.2,               // need to figure out what reputation
// luceneScoreParams = 2.0,              // need to figure out what luceneScore
// textScoreParams = 0.18,
// urlParams = 2.0,
// isReplyParams = 1.0,

// langEnglishUIBoost = 0.5,             // post non-English, UI English
// langEnglishPostBoost = 0.2,          // post English, UI non-English
// langDefaultBoost = 0.02,              // post non-English, UI_lang ≠ Post_lang
// unknownLanguageBoost = 0.05,          // post not an understandable language
// offensiveBoost = 0.1,                 // post is offensive
// inTrustedCircleBoost = 3.0,           // post is from a social circle (I guess followers and friends)
// multipleHashtagsOrTrendsBoost = 0.6,  // has multiple hashtags
// inDirectFollowBoost = 4.0,            // post is from a direct follow
// postHasTrendBoost = 1.1,             // post has a trend
// selfPostBoost = 2.0,                 // is my own post?
// postHasImageUrlBoost = 2.0,          // post has image
// postHasVideoUrlBoost = 2.0,          // post has video

import { compact } from 'lodash';
import * as Sentiment from 'sentiment';

// import Sentiment from 'sentiment';

export function rank(
  post: string,
  postImage: boolean,
  postVideo: boolean,
  numberOfAcademondoTags = 0
): RankResponse {
  const parsedPost = post.toLowerCase();
  // Default score
  if (parsedPost.length < 2) {
    return {
      score: 0,
      validations: [],
    };
  }
  const theSentiment = new Sentiment();
  const theSentimentResponse = theSentiment.analyze(post);
  const postData: PostData = {
    post: parsedPost,
    originalPost: post,
    sentiment: theSentimentResponse,
    postImage: postImage,
    postVideo: postVideo,
    numberOfAcademondoTags: numberOfAcademondoTags,
    input: '',
    note: '',
    prompt: '',
  };
  const rules = [
    emojis(postData),
    sentiment(postData),
    lineBreaks(postData),
    questions(postData),
    multipleHashtags(postData),
    academondoTags(postData),
    imageBoost(postData),
    videoBoost(postData),
    postHasUrl(postData),
    lineLength(postData),
  ];
  const scores = rules.map((item) => item.score);
  const validations: Array<Validation> = compact(
    rules.map((item) => {
      if (item.message) {
        const type = item.type
          ? item.type
          : item.score >= 1
          ? 'positive'
          : 'negative';
        const operator = type === 'positive' ? '+' : '-';
        return {
          message: `${item.message}`,
          score: `${operator}${Math.abs(item.score)}`,
          type: item.type,
        };
      }
    })
  );
  const sum = scores.reduce((partialSum, a) => partialSum * a, 1);
  return {
    score: sum,
    validations,
  };
}

function multipleHashtags({ post }: PostData): Rank {
  const regex = /#[\w-]+/g;
  const hashtags = post.match(regex);
  const lowerCasePost = post.toLowerCase();

  if (hashtags && hashtags.length > 3) {
    return {
      score: 0.5,
      message: `Too many hashtags.`,
      type: 'negative',
    };
  }
  if (hashtags && hashtags.length <= 3) {
    if (
      lowerCasePost.includes('#follow') ||
      lowerCasePost.includes('#comment') ||
      lowerCasePost.includes('#like')
    ) {
      return {
        score: 0.5,
        message: `Avoid using hashtags like "follow," "comment," or "like".`,
        type: 'negative',
      };
    }
    return {
      score: 1,
      message: `Combination of general and specific hashtags.`,
      type: 'positive',
    };
  }

  return {
    score: 1.0,
    message: `no hashtags found.`,
  };
}
function academondoTags({ numberOfAcademondoTags }: PostData): Rank {
  if (numberOfAcademondoTags > 3) {
    return {
      score: 0.5,
      message: `Too many tags.`,
      type: 'negative',
    };
  }
  if (numberOfAcademondoTags !== 0 && numberOfAcademondoTags <= 3) {
    return {
      score: 2.0,
      message: `Good amount of tags.`,
      type: 'positive',
    };
  }

  return {
    score: 1.0,
    message: `no tags used.`,
  };
}

// function to detect image
function imageBoost({ postImage }: PostData): Rank {
  const has_media = postImage;
  if (has_media) {
    return {
      score: 2.0,
      message: `Contains image.`,
      type: 'positive',
    };
  }
  return {
    score: 1.0,
    message: `Add image to boost engagement.`,
    type: 'negative',
  };
}

// function to detect video
function videoBoost({ postVideo }: PostData): Rank {
  const has_media = postVideo;
  if (has_media) {
    return {
      score: 2.0,
      message: `Contains video.`,
      type: 'positive',
    };
  }
  return {
    score: 1.0,
    message: `Add video to boost engagement.`,
    type: 'negative',
  };
}

// function to detect urls in post
function postHasUrl({ post }: PostData): Rank {
  const regex =
    /https?:\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/g;
  const urls = post.match(regex);
  if (urls && urls.length > 0) {
    return {
      score: 0.5,
      message: `Remove the link from post and add in comments.`,
      type: 'negative',
    };
  }
  return {
    score: 1.0,
    message: `Good, no links found in post, remember to add them in the comments.`,
    type: 'positive',
  };
}

/**
 * Function to favor posts that use emojis
 */
function emojis({ post, sentiment }: PostData): Rank {
  const regex = new RegExp('[\uD800-\uDBFF][\uDC00-\uDFFF]', 'g');
  const emojis = post.match(regex) || [];
  const totalMatches = emojis.length;
  if (totalMatches > 0) {
    return {
      score: 1.5,
      message: `Included ${totalMatches} emojis in the post.`,
      type: 'positive',
    };
  }
  return {
    score: 1,
    message: 'No emojis found in the post.',
    type: 'negative',
  };
}

/**
 * Promote negative content because it's more likely to go viral.
 * Hide anything positive or uplifting.
 */
function sentiment({ post, sentiment }: PostData): Rank {
  if (sentiment.comparative >= 0.5) {
    if (sentiment.comparative > 1.5) {
      return {
        score: 1.5,
        message: `Exceptionally positive.`,
        type: 'positive',
      };
    } else {
      return {
        score: 1.1,
        message: `Positive sentiment.`,
        type: 'positive',
      };
    }
  } else if (sentiment.comparative <= -0.5) {
    if (sentiment.comparative < -1.5) {
      return {
        score: 0.5,
        message: `Extremely negative.`,
        type: 'negative',
      };
    } else {
      return {
        score: 0.9,
        message: `Negative sentiment.`,
        type: 'negative',
      };
    }
  } else {
    return {
      score: 1,
      message: `Neutral sentiment.`,
    };
  }
}

/**
 * Prioritize douchey post formatting.
 */
function lineBreaks({ post, sentiment }: PostData): Rank {
  const breaks = post.split(/\n\s*\n/);
  const totalBreaks = breaks.length - 1;
  if (totalBreaks >= 1) {
    return {
      score: 1.5,
      message: `Used ${totalBreaks} line breaks.`,
      type: 'positive',
    };
  } else {
    return {
      score: 1,
      message: `Add line breaks between the lines.`,
      type: 'negative',
    };
  }
}

/**
 * Reduce line length
 */
function lineLength({ post }: PostData): Rank {
  const lines = post.split('\n');
  let score = 1.0;
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].length > 200) {
      return {
        score: 0.9,
        message: `Reduce line length to improve readability (200 characters).`,
        type: 'negative',
      };
    }
  }
  return {
    score: 1,
    message: `Good, keep line length 200 characters or less.`,
    type: 'positive',
  };
}
/**
 * Function to ask questions
 */
function questions({ post, sentiment }: PostData): Rank {
  if (post.includes('?')) {
    return {
      score: 1.5,
      message: 'Great! Questions can help to activate discussion',
      type: 'positive',
    };
  } else {
    return {
      score: 1,
      message: 'Add questions to activate discussion',
      type: 'negative',
    };
  }
}
interface Validation {
  type: 'positive' | 'negative';
  score: string;
  message: string;
}

export interface RankResponse {
  score: number;
  validations: Validation[];
}

interface Rank {
  score: number;
  message?: string;
  type?: 'positive' | 'negative';
}

interface AnalysisResult {
  score: number;
  comparative: number;
  calculation: Array<{
    [token: string]: number;
  }>;
  tokens: string[];
  words: string[];
  positive: string[];
  negative: string[];
}

interface PostData {
  post: string;
  note: string;
  prompt: string;
  input: string;
  originalPost: string;
  sentiment: AnalysisResult;
  postImage: boolean;
  postVideo: boolean;
  numberOfAcademondoTags: number;
}
