import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotesService } from 'app/core/notes.service';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesDialogComponent implements OnInit {
  noteForm: FormGroup;
  notes: Observable<any[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { uid: string },
    private dialogRef: MatDialogRef<NotesDialogComponent>,
    private formBuilder: FormBuilder,
    private noteService: NotesService,
  ) { }

  ngOnInit() {
    this.notes = this.noteService.getListOfNotesPerUser(this.data.uid);
    this.initForm();
  }

  initForm() {
    this.noteForm = this.formBuilder.group({
      noteText: new FormControl('', [
        Validators.minLength(2)
      ]),
      uid: new FormControl(this.data.uid, [
        Validators.required
      ])
    });
  }

  get text() {
    return this.noteForm.get('noteText').value;
  }

  addNote() {
    if (this.noteForm.valid && this.noteForm.get('noteText').value.trim().length > 1) {
      this.noteService.addNote({
        uid: this.data.uid,
        text: this.text
      });
      this.noteForm.get('noteText').setValue('');
    }
  }

  public handleKeyPress(event): void {
    if (event.keyCode === 13 && event.ctrlKey) {
      this.addNote();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
