import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { CV } from '../../../shared/models/cv/cv';
import { TeachingExperience } from '../../../shared/models/cv/teaching-experience';

@Component({
  selector: 'app-cv-view-teaching-exp',
  templateUrl: './cv-view-teaching-exp.component.html',
  styleUrls: ['./cv-view-teaching-exp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvViewTeachingExpComponent implements OnInit {
  @Input() cv: CV;
  constructor() { }

  ngOnInit() { }

  get teachingExperiences(): TeachingExperience[] {
    const teachEx = this.cv ? this.cv.experience.teachingExperiences : null;
    return teachEx && teachEx.length > 0
      ? teachEx.sort((a, b) => this.getTimeSortNumber(a.startDate, b.startDate))
      : null;
  }

  private getTimeSortNumber(dateA: Date, dateB: Date): number {
    const aTime = dateA ? dateA.getTime() : Infinity;
    const bTime = dateB ? dateB.getTime() : Infinity;
    return bTime - aTime;
  }

  trackByFn(index, item) {
    return (item.key || item.id || item.uid);
  }
}
