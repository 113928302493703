import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'app/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { AuthService } from 'app/core/auth.service';
import { PostService } from 'app/core/post.service';
import { PostBuilderComponent } from 'app/forum/components/post-builder/post-builder.component';
import { ForumPost } from 'app/forum/models/forum-post';
import { Group } from 'app/groups/models/group';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePostComponent implements OnInit {
  @Input() group: Group;
  @Input() canCreatePosts: boolean;

  constructor(
    public dialog: MatDialog,
    private postService: PostService,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  get user(): MondoUser {
    return this.authService.getCurrentUser();
  }

  isUserOfCompanyType(userType) {
    return AuthService.isUserOfCompanyType(userType);
  }

  async createPost() {
    const post = new ForumPost();
    this.postService.setNewPostProperties(post, this.group);
    const dialogRef = this.dialog.open(PostBuilderComponent, {
      width: '90vw',
      maxWidth: '800px',
      panelClass: 'create-post-dialog',
      disableClose: true,
    });
    dialogRef.componentInstance.post = post;
    dialogRef.componentInstance.group = this.group;
    dialogRef.componentInstance.updatingPost = false;

    dialogRef.backdropClick().subscribe((result) => {
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {});
      confirmDialogRef.componentInstance.title =
        'discardChangesPostCreateTitle';
      confirmDialogRef.componentInstance.text = 'discardChangesPostCreateBody';
      confirmDialogRef.componentInstance.yesText = 'yes';
      confirmDialogRef.componentInstance.noText = 'no';
      return new Promise((resolve) => {
        confirmDialogRef
          .afterClosed()
          .pipe(take(1))
          .toPromise()
          .then((cancel) => {
            if (cancel) {
              dialogRef.close();
            }
          });
      });
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
