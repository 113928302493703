import { GroupRole } from '../../../../../src/app/groups/models/group-role';
import { GroupType } from '../../../../../src/app/groups/models/group-type';

export interface IGroupBatchRequest {
  groupId: string;
  groupType: GroupType;
  userIds: string[];
  functionHandle: MembershipHandlerFunctions;
}

export enum MembershipHandlerFunctions {
  membershipForceMember = 'membershipForceMember',
  membershipInviteMember = 'membershipInviteMember',
  membershipRequestMember = 'membershipRequestMember',
  membershipBecomeMember = 'membershipBecomeMember',
  membershipAcceptMemberInvite = 'membershipAcceptMemberInvite',
  membershipRejectMemberInvite = 'membershipRejectMemberInvite',
  membershipAcceptMemberRequest = 'membershipAcceptMemberRequest',
  membershipRejectMemberRequest = 'membershipRejectMemberRequest',
  membershipMakeModerator = 'membershipMakeModerator',
  membershipMakeAdministrator = 'membershipMakeAdministrator',
  membershipRemoveMember = 'membershipRemoveMember',
  membershipMakeOwner = 'membershipMakeOwner',
  membershipDowngradeToMember = 'membershipDowngradeToMember',
  membershipGrantOwnership = 'membershipGrantOwnership',
  grantOwnershipAndRemoveOldOwner = 'grantOwnershipAndRemoveOldOwner',
  membershipRemoveGroup = 'membershipRemoveGroup',
  membershipLeaveGroup = 'membershipLeaveGroup',
  membershipSetGroupRole = 'membershipSetGroupRole',
}
export interface IRemoveMemberRequest {
  groupId: string;
  groupType: GroupType;
  forumId: string;
  userId: string;
  removePosts: boolean;
  functionHandle: MembershipHandlerFunctions;
}
export interface ILeaveGroupRequest {
  groupId: string;
  groupType: GroupType;
  forumId: string;
  removePosts: boolean;
  functionHandle: MembershipHandlerFunctions;
}
export interface IGroupRequest {
  groupId: string;
  groupType: GroupType;
  userId: string;
  functionHandle: MembershipHandlerFunctions;
}
export interface IGroupRoleRequest {
  groupId: string;
  groupType: GroupType;
  userId: string;
  role: GroupRole;
  functionHandle: MembershipHandlerFunctions;
}

export interface IGroupSelfRequest {
  groupId: string;
  groupType: GroupType;
  functionHandle: MembershipHandlerFunctions;
}
