import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { Injectable } from '@angular/core';

export type FormGroupControlsOf<T> = {
  [P in keyof T]:
    | FormControl
    | MondoFormGroup<T[P]>
    | MondoFormArray<T[P]>
    | T[P];
};

export abstract class MondoFormGroup<T> extends FormGroup {
  value: T;

  public abstract getSafe<P>(propertyFunction: (typeVal: T) => any): P;
  public abstract getSafeGroup<P>(
    propertyFunction: (typeVal: T) => P
  ): MondoFormGroup<P>;
  public abstract getSafeArray<P>(
    propertyFunction: (typeVal: T) => P
  ): MondoFormArray<P>;
  public abstract getSafeControl<P>(
    propertyFunction: (typeVal: T) => P
  ): MondoAbstractControl<P>;
  public abstract setControlSafe(
    propertyFunction: (typeVal: T) => any,
    control: AbstractControl
  ): void;
}

export abstract class MondoAbstractControl<T> extends AbstractControl {
  value: T;
}

export abstract class MondoFormArray<T> extends FormArray {
  value: T;
}

export class MondoFormControl<T> extends FormControl {
  value: T;
}

@Injectable()
export class MondoFormBuilder extends FormBuilder {
  private getPropertyName(propertyFunction: Function): string {
    const propertiesES5 = propertyFunction
      .toString()
      .match(/(?![. ])([a-z0-9_]+)(?=[};.])/gi)
      .splice(1);

    const propertiesES6 = propertyFunction
      .toString()
      .match(/(?![. ])([a-z0-9_]+)(?=[()=>};.])/gi);

    const properties = propertiesES5;
    // const properties = propertiesES6;

    const r = properties.join('.');
    return r;
  }

  array<T>(
    controlsConfig: T[],
    validator?: ValidatorFn | null,
    asyncValidator?: AsyncValidatorFn | null
  ): MondoFormArray<T> {
    return super.array(
      controlsConfig,
      validator,
      asyncValidator
    ) as MondoFormArray<T>;
  }

  group<T>(
    controlsConfig: FormGroupControlsOf<T> | T,
    extra?: Map<string, any> | null
  ): MondoFormGroup<T> {
    const gr = super.group(controlsConfig, extra) as MondoFormGroup<T>;
    if (gr) {
      gr.getSafeGroup = <P>(
        propertyFunction: (typeVal: T) => P
      ): MondoFormGroup<P> => {
        const getStr = this.getPropertyName(propertyFunction);
        const p = gr.get(getStr) as MondoFormGroup<P>;
        return p;
      };

      gr.getSafeArray = <P>(
        propertyFunction: (typeVal: T) => P
      ): MondoFormArray<P> => {
        const getStr = this.getPropertyName(propertyFunction);
        const p = gr.get(getStr) as MondoFormArray<P>;
        return p;
      };

      gr.getSafe = <P>(propertyFunction: (typeVal: T) => P): P => {
        const getStr = this.getPropertyName(propertyFunction);
        const p = gr.get(getStr) as AbstractControl;
        return p.value;
      };

      gr.getSafeControl = <P>(
        propertyFunction: (typeVal: T) => P
      ): MondoAbstractControl<P> => {
        const getStr = this.getPropertyName(propertyFunction);
        const p = gr.get(getStr) as MondoAbstractControl<P>;
        return p;
      };

      gr.setControlSafe = (
        propertyFunction: (typeVal: T) => any,
        control: AbstractControl
      ): void => {
        const getStr = this.getPropertyName(propertyFunction);
        gr.setControl(getStr, control);
      };
    }

    return gr;
  }
}
