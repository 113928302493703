import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TimePeriod } from 'app/stepper/job/model/timePeriod';


@Component({
  selector: 'app-job-viewer-employment-period',
  templateUrl: './job-viewer-employment-period.component.html',
  styleUrls: ['./job-viewer-employment-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobViewerEmploymentPeriodComponent implements OnInit {

  @Input() employmentPeriod: TimePeriod;

  constructor() { }

  ngOnInit() { }

  get startDate(): Date {
    return this.employmentPeriod.startDate;
  }

  get endDate(): Date {
    return this.employmentPeriod.endDate;
  }

  get openEnded(): boolean {
    return this.employmentPeriod.openEnded;
  }

  get startNow(): boolean {
    return this.employmentPeriod.startNow;
  }

}
