export class DataConstants {
  // Users
  static readonly USERS = '_users/';
  static readonly USER_CPR = 'cpr/';
  static readonly USER_USER_PERSONAL_DETAILS = 'pd/';
  static readonly USER_PERMISSIONS = 'userPermissions/';

  // CVs
  static readonly DRAFT_CVS_DEPREECATED = '_cvs/';
  static readonly INTROCV = 'introCV/';

  // new CV
  static readonly ROOT_CV = '_cv/';
  static readonly DRAFT_CVS = DataConstants.ROOT_CV + '_draftCvs/';
  static readonly PUBLISHED_CVS = DataConstants.ROOT_CV + '_publishedCvs/';
  static readonly CV_STATUS = DataConstants.ROOT_CV + 'cvStatus/';
  static readonly USER_CVS = DataConstants.ROOT_CV + 'userCvs/';

  static readonly NETWORK = '/network/authors/';

  // network members
  static readonly NETWORK_MEMBERS = '_network_members/';
  static readonly NETWORK_MEMBERS_GROUP_MEMBERS = '/sites/0/members';

  // Coupling
  static readonly ROOT_COUPLINGS = '_couplings/';
  static readonly DRAFT_COUPLINGS = DataConstants.ROOT_COUPLINGS + '_drafts/';
  static readonly PUBLISHED_COUPLINGS =
    DataConstants.ROOT_COUPLINGS + '_published/';
  static readonly ALLCOUPLINGJOBS = DataConstants.ROOT_COUPLINGS + '_allJobs/';
  static readonly COUPLINGJOBS =
    DataConstants.ROOT_COUPLINGS + '_couplingJobs/';

  // Reaction
  static readonly ROOT_REACTIONS = '_reactions/';
  static readonly REACTIONS = DataConstants.ROOT_REACTIONS + 'reactions/';
  static readonly USER_REACTIONS =
    DataConstants.ROOT_REACTIONS + 'userReactions/';

  // Groups
  static readonly ROOT_GROUPS = '_groups/';
  static readonly DRAFT_GROUPS = DataConstants.ROOT_GROUPS + '_drafts/';
  static readonly PUBLISHED_GROUPS = DataConstants.ROOT_GROUPS + '_published/';
  static readonly PUBLIC_GROUPS = DataConstants.ROOT_GROUPS + '_public/';
  static readonly IDEA_LIST = DataConstants.ROOT_GROUPS + 'ideaList/';
  // Groups - events
  static readonly GROUP_EVENTS = DataConstants.ROOT_GROUPS + 'groupEvents/';

  // Groups - subgroups
  static readonly GROUP_SUBGROUPS =
    DataConstants.ROOT_GROUPS + 'groupSubgroups/';

  // Groups - memberships
  static readonly MEMBERSHIPS = DataConstants.ROOT_GROUPS + '_memberships/';
  static readonly USER_MEMBERSHIPS =
    DataConstants.MEMBERSHIPS + 'userMemberships/';

  // Groups - attendance
  static readonly ATTENDANCE = DataConstants.ROOT_GROUPS + '_attendance/';
  static readonly USER_ATTENDANCE =
    DataConstants.ATTENDANCE + 'userAttendance/';

  // Groups - roles
  static readonly GROUP_ROLE = DataConstants.ROOT_GROUPS + '_roles/';
  static readonly USER_GROUP_ROLES = DataConstants.GROUP_ROLE + 'userRoles/';

  //  Groups - forums
  static readonly ROOT_FORUMS = DataConstants.ROOT_GROUPS + '_forums/';
  static readonly FORUM_POSTS = DataConstants.ROOT_FORUMS + 'forumPosts/';
  static readonly USER_POSTS = DataConstants.ROOT_FORUMS + 'userPosts/';
  static readonly COMMENT_USERS = DataConstants.ROOT_FORUMS + 'commentUsers/';
  static readonly POSTS = DataConstants.ROOT_FORUMS + '_posts/';
  static readonly POST_VIEWS = DataConstants.ROOT_FORUMS + '_postViews/';
  static readonly FORUM_FILES = DataConstants.ROOT_FORUMS + 'forumFiles/';
  static readonly GROUP_PUBLIC_POSTS =
    DataConstants.ROOT_FORUMS + 'groupPublicPosts/';
  static readonly GROUP_OPEN_POSTS =
    DataConstants.ROOT_FORUMS + 'groupOpenPosts/';
  static readonly FORUMS = DataConstants.ROOT_FORUMS + '_forums/';
  static readonly COMMENTS = DataConstants.ROOT_FORUMS + '_comments/';
  static readonly POST_COMMENTS = DataConstants.ROOT_FORUMS + 'postComments/';

  // kanban
  static readonly ROOT_KANBAN = '_kanbans/';
  static readonly ROOT_GROUP_KANBANS =
    DataConstants.ROOT_KANBAN + 'groupKanbans/';
  static readonly ROOT_GROUP_BOARDS =
    DataConstants.ROOT_KANBAN + 'groupBoards/';

  static readonly INTROSITE = 'introSite/';
  // sites
  static readonly ROOT_SITE = '_sites/';
  static readonly DRAFT_SITES = DataConstants.ROOT_SITE + '_draftSites/';
  static readonly PUBLISHED_SITES =
    DataConstants.ROOT_SITE + '_publishedSites/';
  static readonly SITE_STATUS = DataConstants.ROOT_SITE + 'siteStatus/';
  static readonly USER_SITES = DataConstants.ROOT_SITE + 'userSites/';
  static readonly ACTIVE_SITES_ALLOWED =
    DataConstants.ROOT_SITE + 'activeSitesAllowed/';
  static readonly SITE_COMPANY = DataConstants.ROOT_SITE + '_companies/';
  static readonly SITE_SUBTYPES = DataConstants.ROOT_SITE + 'subtypes/';

  static readonly INTROPLACE = 'introPlace/';
  // places
  static readonly ROOT_PLACE = '_places/';
  static readonly DRAFT_PLACES = DataConstants.ROOT_PLACE + '_draftPlaces/';
  static readonly PUBLISHED_PLACES =
    DataConstants.ROOT_PLACE + '_publishedPlaces/';
  static readonly PLACE_STATUS = DataConstants.ROOT_PLACE + 'placeStatus/';
  static readonly USER_PLACES = DataConstants.ROOT_PLACE + 'userPlaces/';
  static readonly PLACE_SUBTYPES = DataConstants.ROOT_PLACE + 'subtypes/';

  // jobs
  static readonly JOBS_ROOT = '_job/';
  static readonly DRAFT_JOBS = DataConstants.JOBS_ROOT + '_draftJobs/';
  static readonly PUBLISHED_JOBS = DataConstants.JOBS_ROOT + '_publishedJobs/';
  static readonly JOB_STATUS = DataConstants.JOBS_ROOT + 'jobStatus/';
  static readonly USER_JOBS = DataConstants.JOBS_ROOT + 'userJobs/';
  static readonly ACTIVE_JOBS_ALLOWED =
    DataConstants.JOBS_ROOT + 'activeJobsAllowed/';
  static readonly JOB_APPLICANTS = DataConstants.JOBS_ROOT + 'applicants/';
  static readonly JOB_LIST = DataConstants.JOBS_ROOT + 'jobList/';
  static readonly JOB_APPLICATION_RECORDS = 'ac/records/';
  static readonly PODIO = '_podio/';
  static readonly PODIO_COMPANY = DataConstants.PODIO + 'company/';
  static readonly PODIO_END_ITEMS = 'items/';
  static readonly PODIO_END_ITEMS_IDS = 'itemsIds/';

  static readonly TECHNIQUES = 'techniques/';
  static readonly INTERESTS = 'fieldOfInterests/';
  static readonly CATEGORIES0 = 'categories0/';
  static readonly CATEGORIES1 = 'categories1/';
  static readonly CATEGORIES2 = 'categories2/';
  static readonly CATEGORIES3 = 'categories3/';
  static readonly CATEGORIES4 = 'categories4/';
  static readonly CATEGORIES5 = 'categories5/';
  static readonly NETWORKSUBTYPES = 'networkSubtypes/';

  static readonly COUNTRIES = 'countries/';
  static readonly LANGUAGES = 'languages/';

  // loggin
  static readonly LOGS = '_logs/';
  static readonly PER_USER_LOGS = DataConstants.LOGS + 'users/';
  static readonly SEARCH_LOGS = DataConstants.LOGS + 'search/';
  static readonly SUGGESTEDTECHNIQUE = '_suggestions/technique/';
  static readonly SUGGESTEDFIELDOFINTEREST = '_suggestions/fieldOfInterest/';
  static readonly SUGGESTEDCATEGORY0 = '_suggestions/category0/';
  static readonly SUGGESTEDCATEGORY1 = '_suggestions/category1/';
  static readonly SUGGESTEDCATEGORY2 = '_suggestions/category2/';
  static readonly SUGGESTEDCATEGORY3 = '_suggestions/category3/';
  static readonly SUGGESTEDCATEGORY4 = '_suggestions/category4/';
  static readonly SUGGESTEDCATEGORY5 = '_suggestions/category5/';
  static readonly SUGGESTEDNETWORKSUBTYPE = '_suggestions/networkSubtypes/';
  static readonly SUGGESTEDSITESUBTYPE = '_suggestions/siteSubtype/';
  static readonly ITEM_LOGS = '_itemLogs/';
  static readonly ITEM_READS = 'itemReads/';
  static readonly ITEM_VIEWS = 'itemViews/';
  static readonly ITEM_USER_ACTIONS = 'itemUserActions/';

  // status
  static readonly STATUS_DIVIDER = '|';

  // Chat
  static readonly CHAT_ROOT = 'chat/';
  static readonly CHAT_ROOMS = DataConstants.CHAT_ROOT + 'rooms/';
  static readonly CHAT_USER_ROOMS = DataConstants.CHAT_ROOT + 'userChatRooms/';
  static readonly CHAT_ROOMS_MESSAGES =
    DataConstants.CHAT_ROOT + 'chatRoomMessages/';
  static readonly CHAT_ROOM_UPLOADS =
    DataConstants.CHAT_ROOT + 'chatRoomUploads/';

  // Partner
  static readonly PARTNERS = 'partners/';

  // Testimonial
  static readonly TESTIMONIALS = 'testimonials/';

  // Profile
  static readonly PROFILE = 'profile/';

  // Content
  static readonly CONTENT_RESOURCES = 'content/resources/';
  static readonly CONTENT_NEWS = 'content/news/';
  static readonly CONTENT_COURSES = 'content/courses/';

  // Fenerum
  static readonly ROOT_FENERUM = 'fenerum/';
  static readonly FENERUM_ACCOUNTS = DataConstants.ROOT_FENERUM + 'accounts/';
  static readonly FENERUM_RECIPIENT = 'recipients/';
  static readonly FENERUM_SUBSCRIPTION =
    DataConstants.ROOT_FENERUM + 'subscriptions/';

  // Notifications
  static readonly ROOT_NOTIFICATIONS = '_notifications/';
  static readonly NOTIFICATIONS_TOKENS =
    DataConstants.ROOT_NOTIFICATIONS + '_fcmTokens/';
  static readonly USER_APP_NOTIFICATIONS =
    DataConstants.ROOT_NOTIFICATIONS + '_userAppNotifications/';
  static readonly NOTIFICATION_QUEUE =
    DataConstants.ROOT_NOTIFICATIONS + '_notificationQueue/';
  static readonly NOTIFICATION_MAIL_OUTBOX =
    DataConstants.ROOT_NOTIFICATIONS + '_mailOutbox/';
  static readonly USER_NOTIFICATION_SETTINGS =
    DataConstants.ROOT_NOTIFICATIONS + '_userNotificationSettings/';
  static readonly USER_NOTIFICATION_STATUS =
    DataConstants.ROOT_NOTIFICATIONS + '_userNotificationStatus/';

  // Notes
  static readonly ROOT_NOTES = 'notes/';

  // Direct Match
  static readonly ROOT_DM = '_dm/';
  static readonly DRAFT_DMS = DataConstants.ROOT_DM + '_draftDMs/';
  static readonly DM_RECORDS = DataConstants.ROOT_DM + 'records/';

  // Visit Registration
  static readonly ROOT_VISIT = '_visit/';
  static readonly VISIT_RECORDS = DataConstants.ROOT_VISIT + 'records/';

  // Config
  static readonly ROOT_CONFIG = '_config/';
  static readonly CONFIG_COLORS = DataConstants.ROOT_CONFIG + 'colors/';
  static readonly CONFIG_HARDCODED_VALUES =
    DataConstants.ROOT_CONFIG + 'hardcodedValues/';
  static readonly CONFIG_SYSTEM = DataConstants.ROOT_CONFIG + 'system/';

  // AI
  static readonly EXPORTED_MESSAGES = 'exportedChatFlowMessages/';
}
