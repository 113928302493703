import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SearchResultV2 } from 'app/shared/common/search.model';
import { MembershipService } from 'app/shared-services/membership/membership.service';
import { RoutingModel } from 'app/app.routing-model';
import { GroupRelation } from 'app/groups/models/group-relation';
import { GroupType } from 'app/groups/models/group-type';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';
import { RoutingService } from 'app/core/routing.service';
import { IFilter } from 'app/shared/models';
import { getFiltersFromSearchResults } from '@helpers';
import { MatDialog } from '@angular/material';
import { PreviewPremiumComponent } from '../display-components/preview-premium/preview-premium.component';
import { hardcodedValues } from 'hardcodedValues';
import { Observable } from 'rxjs';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { UserService } from 'app/core/user.service';
import { Group } from 'app/groups/models/group';
import { CommunitiesService } from 'app/groups/communities/services/communities.service';

@Component({
  selector: 'app-community-result-viewer',
  templateUrl: './community-result-viewer.component.html',
  styleUrls: ['./community-result-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityResultViewerComponent implements OnInit {
  @Input() community: SearchResultV2;
  @Input() isAuthed: boolean;
  @Input() isUserPremium: boolean;
  @Input() communityRelation: Map<string, GroupRelation> = new Map<
    string,
    GroupRelation
  >();

  owner$: Observable<MondoUser>;

  loading = false;
  filters: IFilter[] = [];
  canOpenDescription = true;
  viewEntireDescription = false;
  ownerGroup$: Observable<Group>;

  constructor(
    private userService: UserService,
    private membershipService: MembershipService,
    private routingService: RoutingService,
    private communitiesService: CommunitiesService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.owner$ = this.userService.getUserByUid$(
      this.community.customAttributes['ownerId']
    );
    if (this.community.customAttributes['ownerGroupId']) {
      if (
        this.community.customAttributes['ownerGroupType'] ===
        GroupType.Communities
      ) {
        this.ownerGroup$ = this.communitiesService.getCommunity$(
          this.community.customAttributes['ownerGroupId']
        );
      }
      if (
        this.community.customAttributes['ownerGroupType'] === GroupType.Ideas
      ) {
        this.ownerGroup$ = this.communitiesService.getCommunity$(
          this.community.customAttributes['ownerGroupId']
        );
      }
    }
    this.viewEntireDescription = !this.canOpenDescription;
    this.filters = getFiltersFromSearchResults(this.community);
  }

  toggleDescription(e) {
    // e.preventDefault();
    e.stopPropagation();
    this.viewEntireDescription = !this.viewEntireDescription;
  }

  getStateClass() {
    return this.viewEntireDescription ? 'expanded' : 'collapsed';
  }

  hasCommunityRelation(result: SearchResultV2): boolean {
    return this.getCommunityRelation(result) !== undefined;
  }

  getCommunityRelation(result: SearchResultV2): GroupRelation {
    const relation = this.communityRelation.get(result.id);
    return relation;
  }

  gotoCommunity(key: string) {
    this.routingService.navigateToRoute(RoutingModel.communities.path, [key]);
  }

  applyCommunity(e, result: SearchResultV2) {
    e.stopPropagation();
    this.loading = true;
    this.membershipService
      .requestMembership(result.id, GroupType.Communities)
      .finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  canApplyCommunity(result: SearchResultV2): boolean {
    return (
      this.getCommunityRelation(result) === undefined &&
      result.customAttributes['privacy'] !== GroupPrivacy.Public &&
      result.customAttributes['privacy'] !== GroupPrivacy.OpenPrivate
    );
  }

  joinCommunity(e, result: SearchResultV2) {
    e.stopPropagation();
    this.loading = true;
    this.membershipService
      .becomeMember(result.id, GroupType.Communities)
      .finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  canJoinCommunity(result: SearchResultV2): boolean {
    return (
      (result.customAttributes['privacy'] === GroupPrivacy.Public ||
        result.customAttributes['privacy'] === GroupPrivacy.OpenPrivate) &&
      this.getCommunityRelation(result) === undefined
    );
  }

  canAcceptCommunity(result: SearchResultV2): boolean {
    return this.getCommunityRelation(result) === GroupRelation.Invited;
  }

  acceptCommunity(e, result: SearchResultV2) {
    e.stopPropagation();
    this.loading = true;
    this.membershipService
      .acceptMemberInvite(result.id, GroupType.Communities)
      .finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  private hasRelation(result: SearchResultV2): boolean {
    return (
      this.getCommunityRelation(result) === GroupRelation.Invited ||
      this.getCommunityRelation(result) === GroupRelation.Member ||
      this.getCommunityRelation(result) === GroupRelation.Administrator ||
      this.getCommunityRelation(result) === GroupRelation.Owner
    );
  }

  canVisitCommunity(result: SearchResultV2): boolean {
    return (
      result.customAttributes['privacy'] === GroupPrivacy.Public ||
      result.customAttributes['privacy'] === GroupPrivacy.OpenPrivate ||
      this.hasRelation(result)
    );
  }

  onClickCommunity(result: SearchResultV2) {
    if (!!result.customAttributes['premium'] && this.isUserPremium) {
      this.gotoCommunity(result.id);
    } else if (
      !!result.customAttributes['premium'] &&
      !this.isUserPremium &&
      !this.hasRelation(result)
    ) {
      this.dialog.open(PreviewPremiumComponent, {
        panelClass: 'preview-dialog',
        data: { name: result.fullName },
      });
    } else if (
      !!result.customAttributes['premium'] &&
      !this.isUserPremium &&
      this.hasRelation(result)
    ) {
      this.gotoCommunity(result.id);
    } else if (
      !!!result.customAttributes['premium'] &&
      this.canVisitCommunity(result)
    ) {
      this.gotoCommunity(result.id);
    }
  }

  // canSeeActionBar(result: SearchResultV2) {
  //   if (!!result.customAttributes['premium'] && this.isUserPremium) {
  //     return true;
  //   } else if (
  //     !!result.customAttributes['premium'] &&
  //     !this.isUserPremium &&
  //     !this.hasRelation(result)
  //   ) {
  //     return false;
  //   } else if (
  //     !!result.customAttributes['premium'] &&
  //     !this.isUserPremium &&
  //     this.hasRelation(result)
  //   ) {
  //     return true;
  //   } else if (
  //     !!!result.customAttributes['premium'] &&
  //     this.canVisitCommunity(result) &&
  //     this.isAuthed
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  canSeeActionBar(result: SearchResultV2) {
    const premiumCommunity = !!result.customAttributes['premium'];
    if (hardcodedValues.EnablePremiumCommunities && premiumCommunity) {
      return this.isUserPremium || this.hasRelation(result);
    } else {
      return this.isAuthed;
    }
  }
}
