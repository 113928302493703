import { Site } from '../models';

export const isNumber = (a) => typeof a === 'number';
export const isObject = (a) => a instanceof Object;
export const isNil = (val) => {
  return val == null || val == undefined || val == '';
};
export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;
export const isBoolean = (arg) => arg === !!arg;
export const isString = (str) => {
  if (str != null && typeof str.valueOf() === 'string') {
    return true;
  }
  return false;
};
export const last = (arr) => arr[arr.length - 1]; // double check! used in feed, infinite scroll

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

// throttle
// find orderBy function
// find escaperegex function
// distinctUntilChanged() in Site.comp.
