import {MultiSelectorItem} from '../components/multi-selector/multi-selector-item';

export class FieldOfInterest implements MultiSelectorItem {
  public static fromJson({ uid, name, desc }): FieldOfInterest {
    return new FieldOfInterest(uid, name, desc);
  }
  constructor(public uid: string, public name: string, public desc: string) {}

  public getId(): string {
    return this.uid;
  }

  public getDisplayText(): string {
    return this.name;
  }
  public getDescription(): string {
    return this.desc;
  }
}
