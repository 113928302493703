import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-mondo-hint',
  templateUrl: './mondo-hint.component.html',
  styleUrls: ['./mondo-hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MondoHintComponent implements OnInit {
  @Input() type: string;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  showHint() {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.header = this.type;
    dialogRef.componentInstance.text = this.type + 'Hint';
    dialogRef.componentInstance.link = this.type + 'Link';
  }
}
