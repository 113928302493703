import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { HelperService } from 'app/core/helper.service';
import { Attendance } from 'app/groups/events/models/attendance';
import { Attendee } from 'app/groups/events/models/attendee';
import { GroupType } from 'app/groups/models/group-type';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DAO } from '../db-access/dao';
import {
  AttendanceHandlerFunctions,
  IAttendanceRequest,
  IAttendanceSelfRequest,
} from './models/attendance-function-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  userId: string;
  constructor(
    public fnsHelper: HelperService,
    private dao: DAO,
    private authService: AuthService
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });
  }

  public async iAmAttending(groupId: string, groupType: GroupType) {
    try {
      return this.fnsHelper.createFunctionPromise<IAttendanceSelfRequest, void>(
        environment.attendanceHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: AttendanceHandlerFunctions.attendanceAttending,
      });
    } catch (err) {
      console.error('error - attendance - attending: ' + err);
    }
  }

  public async ownerAttending(groupId: string, groupType: GroupType) {
    try {
      return this.fnsHelper.createFunctionPromise<IAttendanceSelfRequest, void>(
        environment.attendanceHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: AttendanceHandlerFunctions.ownerAttending,
      });
    } catch (err) {
      console.error('error - attendance - Owner attending: ' + err);
    }
  }

  public async iAmMaybeAttending(groupId: string, groupType: GroupType) {
    try {
      return this.fnsHelper.createFunctionPromise<IAttendanceSelfRequest, void>(
        environment.attendanceHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: AttendanceHandlerFunctions.attendanceMaybe,
      });
    } catch (err) {
      console.error('error - attendance - maybe: ' + err);
    }
  }

  public async iAmNotAttending(
    groupId: string,
    groupType: GroupType,
    silent = false
  ) {
    try {
      return this.fnsHelper.createFunctionPromise<IAttendanceSelfRequest, void>(
        environment.attendanceHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: silent
          ? AttendanceHandlerFunctions.attendanceDeclinedSilently
          : AttendanceHandlerFunctions.attendanceDeclined,
      });
    } catch (err) {
      console.error('error - attendance - declined: ' + err);
    }
  }

  public async iAmInterested(groupId: string, groupType: GroupType) {
    try {
      return this.fnsHelper.createFunctionPromise<IAttendanceSelfRequest, void>(
        environment.attendanceHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: AttendanceHandlerFunctions.attendanceInterested,
      });
    } catch (err) {
      console.error('error - attendance - interested: ' + err);
    }
  }

  public async removeAttendance(
    groupId: string,
    groupType: GroupType,
    userId: string
  ) {
    try {
      return this.fnsHelper.createFunctionPromise<IAttendanceRequest, void>(
        environment.attendanceHandler
      )({
        groupId: groupId,
        groupType: groupType,
        userId: userId,
        functionHandle: AttendanceHandlerFunctions.attendanceCleared,
      });
    } catch (err) {
      console.error('error - attendance - cleared: ' + err);
    }
  }

  public getAttendance$(
    groupId: string,
    groupType: GroupType
  ): Observable<Attendance> {
    return this.dao.object$<Attendance>(
      `${DataConstants.ATTENDANCE}${groupType}/${groupId}/${this.userId}`
    );
  }

  public getGroupAttendance$(
    groupType: GroupType,
    batch = 999,
    lastKey?: string
  ): Observable<Map<string, Attendance>> {
    return this.dao
      .listData$(
        `${DataConstants.USER_ATTENDANCE}${this.userId}/${groupType}`,
        (ref) => {
          if (lastKey) {
            return ref.orderByKey().limitToLast(batch).endAt(lastKey);
          } else {
            return ref.orderByKey().limitToLast(batch);
          }
        }
      )
      .pipe(
        map((groupRelations) => {
          return groupRelations.reduce((result, groupRelation) => {
            const key = groupRelation.key;
            const relation = groupRelation.data as Attendance;
            return result.set(key, relation);
          }, new Map<string, Attendance>());
        })
      );
  }

  public getAllAttendees$(
    groupId: string,
    groupType: GroupType,
    includeSelf = false
  ): Observable<Attendee[]> {
    return this.dao
      .object$<Map<string, Attendance>>(
        `${DataConstants.ATTENDANCE}${groupType}/${groupId}`
      )
      .pipe(
        map((attendees) => {
          if (!attendees) {
            return [];
          }
          return Object.keys(attendees)
            .filter((u) => !!u)
            .filter((userId) => (includeSelf ? true : userId !== this.userId))
            .map((userId) => {
              return {
                userId: userId,
                attendance: attendees[userId] as Attendance,
              };
            });
        })
      );
  }

  public isGroupWithAttendees(groupType: GroupType): boolean {
    return groupType === GroupType.Events;
  }
}
