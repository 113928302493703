import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { hardcodedValues } from 'hardcodedValues';
import { filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(
    private readonly swUpdate: SwUpdate,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    if (
      environment.serviceWorker === 'ngsw-worker.js' &&
      'serviceWorker' in navigator
    ) {
      navigator.serviceWorker
        .getRegistration()
        .then((reg) => {
          // console.log('reg', reg);
          if (reg) {
            listenForWaitingServiceWorker(reg, promptUserToRefresh);
          }
        })
        .catch((e) => {
          console.log('err: ', e);
        });
      // reload once when the new Service Worker starts activating
      var refreshing;
      navigator.serviceWorker.addEventListener(
        'controllerchange',
        function (controllerchange) {
          console.log('controllerchange', controllerchange);
          if (refreshing) return;
          refreshing = true;
          window.location.reload();
        }
      );

      addEventListener('message', (messageEvent) => {
        // console.log('messageEvent', messageEvent);
        // console.log('messageEvent.data', messageEvent.data);

        if (messageEvent.data === 'skipWaiting') {
          // console.log('skipWaiting and showAppUpdateAlert()');

          return this.showAppUpdateAlert();
        }
      });
      addEventListener('install', (installEvent: any) => {
        // console.log('installEvent', installEvent);

        installEvent.waitUntil(
          caches
            .open('v1')
            .then((cache) => cache.addAll(['/', '/v1/style.css', '/v1/app.js']))
        );
      });
    }

    function promptUserToRefresh(reg) {
      // this is just an example
      // don't use window.confirm in real life; it's terrible

      const header = hardcodedValues.UpdateAvailable + 'prompter!';
      const action = hardcodedValues.Update;
      this.snackBar
        .open(`${header}`, action, {
          duration: 93000,
          horizontalPosition: 'left',
          verticalPosition: 'top',
          panelClass: ['aca-push-notification'],
        })
        .onAction()
        .subscribe(() => {
          reg.waiting.postMessage('skipWaiting');
        });
    }

    // listenForWaitingServiceWorker(reg, promptUserToRefresh);
    function listenForWaitingServiceWorker(reg, callback) {
      function awaitStateChange() {
        reg.installing.addEventListener('statechange', function (e) {
          // console.log('e', e);
          // console.log('this.state', this.state);

          if (this.state === 'installed') callback(reg);
        });
      }
      if (!reg) return;
      if (reg.waiting) return callback(reg);
      if (reg.installing) awaitStateChange();
      reg.addEventListener('updatefound', awaitStateChange);
    }

    swUpdate.activated.subscribe((event) => {
      // console.log('old version was', event.previous);
      // console.log('new version is', event.current);
      // console.log('should i reload here?');
    });

    this.swUpdate.available.subscribe((event) => {
      // console.log('current version is', event.current);
      // console.log('available version is', event.available);
      // console.log(event);

      // if (event?.type === 'NO_NEW_VERSION_DETECTED') {
      //   console.log('no new version found!');
      // }
      // if (event?.type === 'VERSION_DETECTED') {
      //   console.log('found new version!');
      // }
      // if (event?.type === 'VERSION_READY') {
      //   this.showAppUpdateAlert();
      // }
      if (event.type === 'UPDATE_AVAILABLE') {
        this.showAppUpdateAlert();
        // console.log('found new version!', JSON.stringify(event));
      }
    });

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe({
        next: (v) => {
          if (swUpdate.isEnabled) {
            swUpdate.checkForUpdate();
          }
        },
      });
  }

  showAppUpdateAlert() {
    const caller = this;
    this.snacky(caller);
  }

  doAppUpdate() {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  public snacky(caller: this, duration = 930000) {
    const header = hardcodedValues.UpdateAvailable;
    const action = hardcodedValues.Update;
    this.snackBar
      .open(`${header}`, action, {
        duration: duration,
        horizontalPosition: 'left',
        verticalPosition: 'top',
        panelClass: ['aca-push-notification'],
      })
      .onAction()
      .subscribe(() => {
        // console.log('caller, caller.doAppUpdate', caller, caller.doAppUpdate);

        caller.doAppUpdate();
      });
  }
}
