import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ViewMode } from 'app/stepper/shared/model/ViewMode';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteStepIndex } from 'app/stepper/shared/model/stepIndex';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'app/core/auth.service';
import { MondoRoutes, RoutingModel } from 'app/app.routing-model';
import { Site } from 'app/shared/models/site';
import { SiteService } from 'app/core/site.service';
import { JobService } from 'app/core/job.service';
import { acaConfig } from 'aca-config';
import { IdeasService } from 'app/groups/project-idea/services/ideas.service';

@Component({
  selector: 'app-site-viewer',
  templateUrl: './site-viewer.component.html',
  styleUrls: ['./site-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteViewerComponent implements OnInit {
  @Output() editorMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() siteId: string;
  @Input() isShowActionBar = true;
  @Input() showChatButton = false;
  @Input() viewMode: ViewMode = ViewMode.DRAFT;
  @Input() showPrint = true;
  @Input() showGoToPrintableSite = false;
  showIntroViewer: boolean;
  editMode: boolean;
  editMode$: Observable<boolean>;
  site$: Observable<Site>;
  public isOwner = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public siteService: SiteService,
    private authService: AuthService,
    private jobService: JobService,
    private ideasService: IdeasService
  ) {}

  ngOnInit() {
    this.site$ = this.route.paramMap.pipe(
      switchMap((paramMap) => {
        const newSiteId = paramMap.get('siteId');
        this.siteId = newSiteId ? newSiteId : this.siteId;
        this.getIsOwner(this.siteId);
        // this.showIntroViewer =
        //   parseInt(paramMap.get('step'), 10) === SiteStepIndex.start;
        return this.getSite$(this.viewMode, this.siteId);
      })
    );
    this.editMode$ = this.siteService.editMode$.pipe(
      tap((edit) => (this.editMode = edit))
    );
  }

  private getSite$(viewMode: ViewMode, siteId: string) {
    return viewMode === ViewMode.DRAFT
      ? this.siteService.getDraftSite(siteId).pipe(
          tap((site) => {
            this.showIntroViewer =
              site && site.siteInfo && !site.siteInfo.nameSite;
          })
        )
      : this.siteService.getPublishedSite(siteId).pipe(
          tap((site) => {
            this.showIntroViewer = false;
          })
        );
  }

  private async getIsOwner(key: string): Promise<boolean> {
    if (key !== MondoRoutes.none) {
      return this.siteService
        .isOwnSite(key)
        .then((sites) => (this.isOwner = sites))
        .catch(() => {
          return false;
        });
    }
  }

  showProfile(id: string) {
    const link = this.router.serializeUrl(
      this.router.createUrlTree([
        RoutingModel.home.path,
        RoutingModel.siteBuilder.path,
        id,
        '0',
      ])
    );
    window.open(link, '_blank');
  }

  print() {
    const timeout = 300;
    setTimeout(() => {
      // const doc = document.documentElement;
      // const contentElement = document.getElementById('current-site');
      // const currentContentHeight = contentElement
      //   ? contentElement.offsetHeight
      //   : 2300;
      // doc.style.setProperty('--app-site-height', `${currentContentHeight}px`);
      (window as any).print();
    }, timeout);
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  get isPublished(): boolean {
    return this.viewMode === ViewMode.PUBLISHED;
  }

  setEditMode(value) {
    const edit = value === undefined ? true : value;
    this.siteService.setEditorMode(edit);
    this.siteService.editMode = edit;
  }

  createJob(site: Site) {
    this.jobService.createJobFromSite(site);
  }
  canCreateJob(site: Site): boolean {
    const isSitePublished = site && site.status && site.status.isPublished();
    if (acaConfig.siteIsUserDriven) {
      const isOwner =
        site && this.authService.getCurrentUser()
          ? site.ownerId === this.authService.getCurrentUser().uid
          : false;
      return (
        this.authService.canCreateJobFromSite && isOwner && isSitePublished
      );
    } else {
      return this.authService.canCreateJobFromSite && isSitePublished;
    }
  }

  createIdea(site: Site) {
    this.ideasService.createIdeaFromSite(site);
  }
  canCreateIdea(site: Site): boolean {
    const isSitePublished = site && site.status && site.status.isPublished();
    if (acaConfig.siteIsUserDriven) {
      const isOwner =
        site && this.authService.getCurrentUser()
          ? site.ownerId === this.authService.getCurrentUser().uid
          : false;
      return (
        this.authService.canCreateIdeaFromSite && isOwner && isSitePublished
      );
    } else {
      return this.authService.canCreateIdeaFromSite && isSitePublished;
    }
  }

  removeSite(site: Site) {
    if (site.key) {
      this.siteService
        .removeSite(site.key, site.siteInfo.nameSite)
        .then((res) => {
          if (res) {
            this.setEditMode(false);
          }
        });
    }
  }
}
