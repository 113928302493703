import {
  MondoUser,
  JsonMondoUser,
  StartUpInfo,
  MondoVersion,
} from './mondoUser';
import { Company } from '../../../stepper/job/model/company';
import { AccountPermission } from '../../consts/accountPermission';
import { AccountType } from '../../consts/accountType';
import { IFilter } from '../iFilter';

export class IndustryUser extends MondoUser {
  static fromJson(
    obj: JsonIndustryUser<number>,
    uid: string,
    permission: AccountPermission
  ): IndustryUser {
    const user = MondoUser.fromJson(obj, uid, permission) as IndustryUser;
    user.phone = obj.phone ? obj.phone : '';
    user.company = obj.company ? obj.company : new Company();
    return user;
  }

  static toJson(user: IndustryUser): JsonIndustryUser<number> {
    return {
      email: user.email,
      dateCreated: user.dateCreated ? user.dateCreated.getTime() : null,
      lastLogin: user.lastLogin ? user.lastLogin.getTime() : null,
      photoURL: user.photoURL,
      displayName: user.displayName,
      type: user.type,
      publishedCv: user.publishedCv,
      startUpInfo: user.startUpInfo,
      version: user.version,
      welcomeEmail: user.welcomeEmail,
      phone: user.phone ? user.phone : '',
      company: user.company || new Company(),
      filters: user.filters,
      coverUrl: user.coverUrl,
      disableChat: user.disableChat,
    };
  }

  constructor(
    public readonly uid,
    public dateCreated: Date = null,
    public email,
    public photoURL = '',
    public displayName = '',
    public type: AccountType = AccountType.industry,
    public readonly permission: AccountPermission = AccountPermission.basic,
    public publishedCv = '',
    public startUpInfo: StartUpInfo = new StartUpInfo(),
    public lastLogin: Date = null,
    public version: MondoVersion = MondoVersion.version2,
    public welcomeEmail = true,
    public phone = '',
    public company: Company = new Company(),
    public filters: IFilter[] = [],
    public coverUrl = '',
    public disableChat = false
  ) {
    super(
      uid,
      dateCreated,
      email,
      photoURL,
      displayName,
      type,
      permission,
      publishedCv,
      startUpInfo,
      lastLogin,
      version,
      welcomeEmail,
      filters,
      coverUrl,
      disableChat
    );
  }
}

export interface JsonIndustryUser<T> extends JsonMondoUser<T> {
  dateCreated: T;
  lastLogin: T;
  email: string;
  photoURL: string;
  displayName: string;
  type: AccountType;
  publishedCv: string;
  startUpInfo: StartUpInfo;
  version: MondoVersion;
  welcomeEmail: boolean;
  phone: string;
  company: Company;
  filters: IFilter[];
  coverUrl: string;
  disableChat: boolean;
}
