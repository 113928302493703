import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { TimePeriod } from 'app/stepper/job/model/timePeriod';
import { DateOptions, PickerMode } from '../date-picker/date-picker.component';
import { tap } from 'rxjs/operators';
import { TimeService } from 'app/core/time.service';
import { isNumber } from 'app/shared/common/acaLodash';
@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent implements OnInit {
  @Input() dateRangeForm: MondoFormGroup<TimePeriod>;
  @Input() options: DateRangeOptions;
  startOptions: DateOptions;
  endOptions: DateOptions;
  TimeService: TimeService;
  startTime = '10:00';
  endTime = '16:30';
  valueChanges$;

  constructor() {}

  ngOnInit() {
    this.startOptions = {
      startDate: null,
      pickerMode: this.options.pickerMode,
      disabled: false,
      placeholder: this.options.startPlaceholder || 'startDate',
      timePickerLabel: this.options.startTimePickerLabel,
      optionalHint: '',
      startView: 'multi-year',
      minDate: this.options.allowPastDates ? null : this.now,
      maxDate: this.options.allowFutureDates ? null : this.now,
      showClearBtn: this.options.showClearBtn || false,
      value: this.options.startValue || null,
    };
    this.endOptions = {
      startDate: null,
      pickerMode: this.options.pickerMode,
      disabled: false,
      placeholder: this.options.endPlaceHolder || 'endDate',
      timePickerLabel: this.options.endTimePickerLabel,
      optionalHint: '',
      startView: 'multi-year',
      minDate: this.options.allowPastDates ? null : this.now,
      maxDate: this.options.allowFutureDates ? null : this.now,
      showClearBtn: this.options.showClearBtn || false,
      value: this.options.endValue || null,
    };

    this.setMinMaxDate(this.options.startValue);
    this.valueChanges$ = this.dateRangeForm.valueChanges.pipe(
      tap((val) => this.setMinMaxDate())
    );
    if (this.startNow) {
      this.setStartNowCheckboxValue();
    }
    this.setNoEndCheckboxValue();
  }

  private setStartNowCheckboxValue() {
    this.startNow.setValue(this.isToday(this.startDate.value));
  }

  private get now(): Date {
    return new Date();
  }

  private isToday(date: Date) {
    const today = this.now;
    return (
      date &&
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }

  private setNoEndCheckboxValue(startDate?: Date, endDate?: Date) {
    const start = startDate || this.startDate.value;
    const end = endDate || this.endDate.value;
    if (!start && !end) {
      this.current.setValue(isNumber(this.options.maxTimeSpanInDays));
    }
  }

  private setMinMaxDate(startDate?: Date) {
    const start = this.startDate.value || startDate;
    const end = this.endDate.value;
    if (this.options.maxTimeSpanInDays) {
      this.setMaxTimeSpan(start, end);
    } else {
      this.endOptions.maxDate = this.options.allowFutureDates ? null : this.now;
      this.endOptions.minDate = start || null;

      if (start) {
        this.startTime = `${('0' + TimeService.getHoursFromDate(start)).slice(
          -2
        )}:${('0' + TimeService.getMinutesFromDate(start)).slice(-2)}`;
      }
      if (end) {
        this.startOptions.maxDate = end;
        this.endTime = `${('0' + TimeService.getHoursFromDate(end)).slice(
          -2
        )}:${('0' + TimeService.getMinutesFromDate(end)).slice(-2)}`;
      } else {
        this.startOptions.maxDate = this.options.allowFutureDates
          ? null
          : this.now;
      }
    }
  }

  private setMaxTimeSpan(start: Date, end: Date) {
    this.endOptions.minDate = start || this.getDefaultMinDate();
    this.endOptions.maxDate = start
      ? TimeService.addTimeSpanToDate(start, this.options.maxTimeSpanInDays)
      : this.getDefaultMaxDate();

    this.startOptions.minDate = end
      ? this.setStartOptionsMinDate(end)
      : this.getDefaultMinDate();
    this.startOptions.maxDate = end || this.getDefaultMaxDate();
  }

  private setStartOptionsMinDate(end) {
    const calculatedMinDate = TimeService.subtractTimeSpanToDate(
      end,
      this.options.maxTimeSpanInDays
    );
    if (this.options.allowPastDates) {
      return calculatedMinDate;
    } else {
      return this.now > calculatedMinDate ? this.now : calculatedMinDate;
    }
  }

  private getDefaultMaxDate(): Date {
    return this.options.allowFutureDates ? null : this.now;
  }

  private getDefaultMinDate(): Date {
    return this.options.allowPastDates ? null : this.now;
  }

  get startDate() {
    return this.dateRangeForm.getSafeControl((x) => x.startDate);
  }

  get endDate() {
    return this.dateRangeForm.getSafeControl((x) => x.endDate);
  }

  get current() {
    const openEnded = this.dateRangeForm.getSafeControl((x) => x.openEnded);
    return openEnded ? openEnded : this.dateRangeForm.get('isCurrent');
  }

  get startNow() {
    return this.dateRangeForm.getSafeControl((x) => x.startNow);
  }

  clearEnd() {
    this.endDate.setValue(null);
    this.setMinMaxDate();
  }

  clearStart() {
    this.startDate.setValue(null);
    this.setMinMaxDate();
  }

  startNowChanged(startNowValue: boolean) {
    if (startNowValue) {
      this.startDate.setValue(this.now);
      const maxDate = TimeService.addTimeSpanToDate(
        this.now,
        this.options.maxTimeSpanInDays
      );
      if (this.endDate.value > maxDate) {
        this.endDate.setValue(maxDate);
      }
    }
  }

  get showStartTime() {
    return !!this.startDate.value;
  }

  get showEndTime() {
    return !!this.endDate.value;
  }

  private getHoursAndMinutesFromTime(time: string) {
    return {
      hour: +time.split(':')[0],
      minutes: +time.split(':')[1],
    };
  }

  onChangeStartTime() {
    const { hour, minutes } = this.getHoursAndMinutesFromTime(this.startTime);
    this.startDate.setValue(
      TimeService.setHoursAndMinutesForDate(this.startDate.value, hour, minutes)
    );
  }

  onChangeEndTime() {
    const { hour, minutes } = this.getHoursAndMinutesFromTime(this.endTime);
    this.endDate.setValue(
      TimeService.setHoursAndMinutesForDate(this.endDate.value, hour, minutes)
    );
  }
}

export interface DateRangeOptions {
  pickerMode: PickerMode;
  allowPastDates: boolean;
  allowFutureDates: boolean;
  openEndedCheckboxText?: string;
  hideOpenEndedCheckBox?: boolean;
  startNowCheckboxText?: string;
  endTimePickerLabel?: string;
  startTimePickerLabel?: string;
  hideStartNowCheckBox?: boolean;
  startPlaceholder?: string;
  endPlaceHolder?: string;
  maxTimeSpanInDays?: number;
  showClearBtn?: boolean;
  startValue?: Date;
  endValue?: Date;
}
