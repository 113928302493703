import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { isEmpty } from 'app/shared/common/acaLodash';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Comment, IComment } from '../models/comment';
import { CommentRelation } from '../models/comment-relation';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private userId: string;
  constructor(private dao: DAO, private authService: AuthService) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });
  }

  public async deleteComment(comment: Comment): Promise<void> {
    await this.dao.ref(`${DataConstants.COMMENTS}${comment.id}`).remove();
    await this.dao
      .ref(`${DataConstants.COMMENT_USERS}${comment.id}/${this.userId}`)
      .remove();
    await this.dao
      .ref(`${DataConstants.POST_COMMENTS}${comment.postId}/${comment.id}`)
      .remove();

    if (comment.parrentCommentId) {
      await this.dao
        .ref(
          `${DataConstants.COMMENTS}${comment.parrentCommentId}/subCommentsIds/${comment.id}`
        )
        .remove();
    }
  }

  public async addComment(
    message: string,
    imageLink = '',
    postId: string,
    taggedUserIds: string[] = [],
    parrentCommentId?: string
  ): Promise<Comment> {
    const now = new Date();
    const comment: Comment = {
      created: now,
      updated: now,
      id: this.dao.createPushId(),
      message,
      imageLink,
      ownerId: this.userId,
      postId,
      parrentCommentId: parrentCommentId || null,
      subCommentsIds: [],
      taggedUserIds: taggedUserIds,
    };

    await this.dao
      .ref(`${DataConstants.COMMENT_USERS}${comment.id}/${this.userId}`)
      .set(CommentRelation.Owner);

    await this.dao
      .ref(`${DataConstants.COMMENTS}${comment.id}`)
      .set(Comment.toJson(comment));

    if (comment.parrentCommentId) {
      await this.dao
        .ref(
          `${DataConstants.COMMENTS}${comment.parrentCommentId}/subCommentsIds/${comment.id}`
        )
        .set(true);
      await this.dao
        .ref(
          `${DataConstants.COMMENT_USERS}${comment.parrentCommentId}/${this.userId}`
        )
        .set(CommentRelation.Replied);
    } else {
      await this.dao
        .ref(`${DataConstants.POST_COMMENTS}${comment.postId}/${comment.id}`)
        .set(CommentRelation.Replied);
    }
    return comment;
  }

  public async updateComment(comment: Comment): Promise<Comment> {
    comment.updated = new Date();
    await this.dao
      .ref(`${DataConstants.COMMENTS}${comment.id}`)
      .update(Comment.toJson(comment));
    return comment;
  }

  public getComment$(commentId: string): Observable<Comment> {
    return this.dao
      .object$<IComment<number>>(`${DataConstants.COMMENTS}${commentId}`)
      .pipe(
        filter((p) => !!p),
        map(Comment.fromJson)
      );
  }

  public getSubComments$(
    parrentId: string,
    batch: number,
    lastKey?: string
  ): Observable<Array<Comment>> {
    return this.dao
      .listData$(
        `${DataConstants.COMMENTS}${parrentId}/subCommentsIds`,
        (ref) => {
          if (lastKey) {
            return ref.orderByKey().limitToLast(batch).endAt(lastKey);
          } else {
            return ref.orderByKey().limitToLast(batch);
          }
        }
      )
      .pipe(
        mergeMap((subCommentsIds) => {
          if (isEmpty(subCommentsIds)) {
            return of([]);
          }
          return combineLatest(
            subCommentsIds
              .reverse()
              .map((commentId) => this.getComment$(commentId.key))
          );
        })
      );
  }

  public getComments$(
    postId: string,
    batch: number,
    lastKey?: string
  ): Observable<Array<Comment>> {
    return this.getCommentIds$(postId, batch, lastKey).pipe(
      mergeMap((commentIds) => {
        if (isEmpty(commentIds)) {
          return of([]);
        }
        return combineLatest(
          commentIds.reverse().map((commentId) => this.getComment$(commentId))
        );
      })
    );
  }

  private getCommentIds$(
    postId: string,
    batch: number,
    lastKey?: string
  ): Observable<Array<string>> {
    return this.dao
      .listData$(`${DataConstants.POST_COMMENTS}${postId}`, (ref) => {
        if (lastKey) {
          return ref.orderByKey().limitToLast(batch).endAt(lastKey);
        } else {
          return ref.orderByKey().limitToLast(batch);
        }
      })
      .pipe(
        map((posts) => {
          return posts.map((post) => post.key);
        })
      );
  }
}
