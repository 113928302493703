import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { hardcodedValues } from 'hardcodedValues';

@Component({
  selector: 'app-linkedin-share-button',
  templateUrl: './linkedin-share-button.component.html',
  styleUrls: ['./linkedin-share-button.component.scss'],
})
export class LinkedinShareButtonComponent implements OnInit {
  @Input() shareUrl: string;
  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {}

  shareln() {
    const linkedinWindow = window.open(
      'https://www.linkedin.com/sharing/share-offsite/?url=' +
        encodeURIComponent(this.shareUrl),
      'linkedin-popup',
      'height=570,width=600'
    );
    if (linkedinWindow.focus) {
      linkedinWindow.focus();
    }
    return false;
  }
  sharefb() {
    const linkedinWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' +
        encodeURIComponent(this.shareUrl),
      'facebook-popup',
      'height=350,width=550'
    );
    if (linkedinWindow.focus) {
      linkedinWindow.focus();
    }
    return false;
  }
  sharex() {
    const linkedinWindow = window.open(
      'https://twitter.com/intent/tweet?url=' +
        encodeURIComponent(this.shareUrl),
      'x-popup',
      'height=500,width=600'
    );
    if (linkedinWindow.focus) {
      linkedinWindow.focus();
    }
    return false;
  }

  public copyTextToClipboard(
    text: string,
    copiedItemText = '',
    copiedText = hardcodedValues.copiedToClipboard
  ) {
    // console.log(text.rowData.email);

    const txtArea = document.createElement('textarea');
    txtArea.id = 'txt';
    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = text;
    document.body.appendChild(txtArea);
    txtArea.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.snackBar.open(
          `"${copiedItemText ? copiedItemText : text}" ${copiedText}`,
          null,
          {
            duration: 4000,
            panelClass: ['snackbar-position-center'],
          }
        );
        return true;
      }
    } catch (err) {
    } finally {
      document.body.removeChild(txtArea);
    }
    return false;
  }
}
