import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { PlaceInfo } from 'app/shared/models/';

@Component({
  selector: 'app-place-card-header',
  templateUrl: './place-card-header.component.html',
  styleUrls: ['./place-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceCardHeaderComponent implements OnInit {

  @Input() placeInfo!: PlaceInfo;

  constructor() { }

  ngOnInit() { }

  get address() {
    if (this.placeInfo.address &&
      this.placeInfo.address.city && this.placeInfo.address.country && this.placeInfo.address.country.name) {
      return `${this.placeInfo.address.city}, ${this.placeInfo.address.country.name}`;
    }
    return this.placeInfo.address.city;
  }

  get pictureUrl() {
    return this.placeInfo.pictureUrl;
  }

  get logoUrl() {
    return this.placeInfo.logoUrl;
  }
}
