import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlSizeLimiter'
})
export class UrlSizeLimiterPipe implements PipeTransform {
  transform(value: string, args?: number): string {
    if (value && args) {
      if (value.length > args) {
        return value.substring(0, args) + '...';
      } else {
        return value;
      }
    }
    return null;
  }
}
