import { Injectable, OnDestroy } from '@angular/core';
import { DatabaseHandlerService } from 'app/stepper/shared/services/database-handler.service';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { AuthService } from 'app/core/auth.service';
import { take, takeUntil } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { Place } from 'app/shared/models';
import { HelperService } from 'app/core/helper.service';
import { DAO } from 'app/shared-services/db-access/dao';

@Injectable({
  providedIn: 'root',
})
export class PlacesService extends DatabaseHandlerService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  private firstLoad = false;
  subscriptions: Subscription[] = [];
  placeSubscription: Subscription = new Subscription();
  private placeSubject: BehaviorSubject<object> = new BehaviorSubject({});
  public place$: Observable<object> = this.placeSubject.asObservable();
  place: Place = new Place();
  companyName = '';

  public _editor: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public editMode$: Observable<boolean> = this._editor.asObservable();
  public editMode = true;
  public deleteItem: BehaviorSubject<any> = new BehaviorSubject(null);
  public pingSubscriptions$: Observable<any> = this.deleteItem.asObservable();

  constructor(
    private dialog: MatDialog,
    public dao: DAO,
    authService: AuthService,
    fnsHelper: HelperService
  ) {
    super(
      dao,
      authService,
      DataConstants.DRAFT_PLACES,
      DataConstants.USER_PLACES,
      DataConstants.PUBLISHED_PLACES,
      DataConstants.PLACE_STATUS,
      fnsHelper,
      false
    );
    this.subscriptions.push(
      this.getViewerStatus().subscribe((res) => {
        if (this.editMode !== res) {
          this.editMode = res;
        }
      })
    );
  }

  getViewerStatus(): Observable<boolean> {
    return this._editor.asObservable();
  }

  getPlaceData(placeKey: string, published = false): Observable<Place> {
    return this.getItem<Place>(Place.fromJson, placeKey, published);
  }

  getPublishedPlace(placeKey: string): Observable<Place> {
    return this.getItem<Place>(Place.fromJson, placeKey, true);
  }

  getDraftPlace(key: string): Observable<Place> {
    return this.getItem<Place>(Place.fromJson, key);
  }

  getDraftPlaces(): Observable<Place[]> {
    return this.getDraftItems(Place.fromJson);
  }

  public unsubscribe() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
    this.placeSubscription.unsubscribe();
  }

  placeExists(id: string): Promise<boolean> {
    return this.authService.logged ? this.exists(id) : this.exists('0');
  }

  updateDraftPlace(place: Place): Promise<void> {
    if (!this.firstLoad) {
      this.firstLoad = true;
      return;
    }
    return this.update(place, Place.toJson);
  }

  createPlace(newPlace: string): Promise<string> {
    const place = new Place();
    place.name = newPlace;
    this.editMode = true;
    return this.createItemAnStatus(place, Place.toJson);
  }

  removePlace(id: string): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = 'deletePlace';
    dialogRef.componentInstance.text = 'areYouSureWantToDeleteX';
    dialogRef.componentInstance.textX = 'place';
    dialogRef.componentInstance.yesText = 'delete';
    dialogRef.componentInstance.noText = 'cancel';

    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .toPromise()
        .then((deletePlace) => {
          if (deletePlace) {
            this.removeItemAndStatus(id).then(() => {
              this.deleteItem.next(true);
              this.editMode = false;
              this.setEditorMode(false);
              resolve(true);
            });
          } else {
            resolve(false);
          }
        });
    });
  }

  setEditorMode(mode: boolean) {
    this._editor.next(mode);
  }

  getExistPlaces() {
    return this.getExistItems();
  }

  getNumberUserPlaces(): Promise<number> {
    return new Promise((resolve) => {
      this.getDraftPlaces()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          resolve(res.length);
        });
    });
  }

  public async publishPlace(place: Place) {
    if (place) {
      this.handlePlaceChange(place);
      place.status.queueForPublish();
      this.publish(place, Place.toJson);
      this.editMode = false;
    }
  }

  private handlePlaceChange(place: Place) {
    // const user = this.authService.getCurrentUser() as IndustryUser;
    // this.userService.updateUser(user);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
