import { ChatRoomState } from './chat-room-state';
import { ChatMessage } from './chat-message';

export class Chat implements ChatJson<Date> {
  public static fromJson({
    key,
    created,
    updated,
    userIds,
    chatRoomState,
    lastMessage,
  }): Chat {
    return new Chat(
      key ? key : null,
      created ? new Date(created) : null,
      updated ? new Date(updated) : null,
      userIds ? userIds : [],
      chatRoomState ? chatRoomState : null,
      lastMessage ? lastMessage : null
    );
  }

  public static toJson(chat: Chat): ChatJson<number> {
    return {
      key: chat.key,
      created: chat.created.getTime(),
      updated: chat && chat.updated ? chat.updated.getTime() : null,
      userIds: chat.userIds,
      chatRoomState: chat.chatRoomState,
      lastMessage: chat.lastMessage,
    };
  }

  constructor(
    public key = '',
    public created: Date = new Date(),
    public updated: Date = null,
    public userIds: string[] = [],
    public chatRoomState: ChatRoomState = ChatRoomState.private,
    public lastMessage: Comment = null
  ) {}
}

export interface ChatJson<T> {
  key: string;
  created: T;
  updated: T;
  userIds: string[];
  chatRoomState: ChatRoomState;
  lastMessage: Comment;
}
