import { GroupType } from '../../../../../src/app/groups/models/group-type';

export interface IAttendanceBatchRequest {
  groupId: string;
  groupType: GroupType;
  userIds: string[];
  functionHandle: AttendanceHandlerFunctions;
}

export enum AttendanceHandlerFunctions {
  ownerAttending = 'ownerAttending',
  attendanceAttending = 'attendanceAttending',
  attendanceMaybe = 'attendanceMaybe',
  attendanceDeclined = 'attendanceDeclined',
  attendanceDeclinedSilently = 'attendanceDeclinedSilently',
  attendanceInterested = 'attendanceInterested',
  attendanceCleared = 'attendanceCleared',
}

export interface IAttendanceRequest {
  groupId: string;
  groupType: GroupType;
  userId: string;
  functionHandle: AttendanceHandlerFunctions;
}

export interface IAttendanceSelfRequest {
  groupId: string;
  groupType: GroupType;
  functionHandle: AttendanceHandlerFunctions;
}
