import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckImageUrlDirective } from '../shared-directives/directives/check-image-url.directive';
import { ShaveDirective } from './directives/shave.directive';

@NgModule({
  declarations: [CheckImageUrlDirective, ShaveDirective],
  imports: [CommonModule],
  exports: [CheckImageUrlDirective, ShaveDirective],
})
export class SharedDirectivesModule {}
