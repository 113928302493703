import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SuggestTagDialogComponent } from 'app/suggest-tag-dialog/suggest-tag-dialog/suggest-tag-dialog.component';
import { DataConstants } from 'app/shared/consts/dataConstants';

@Injectable({
  providedIn: 'root',
})
export class SuggestService {
  constructor(private dialog: MatDialog) {}

  openSuggestionDialog(dialog: string) {
    if (dialog === 'tech') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDTECHNIQUE;
      dialogRef.componentInstance.title = 'tech';
    } else if (dialog === 'foi') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDFIELDOFINTEREST;
      dialogRef.componentInstance.title = 'foi';
    } else if (dialog === 'category0') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDCATEGORY0;
      dialogRef.componentInstance.title = 'category0';
    } else if (dialog === 'category1') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDCATEGORY1;
      dialogRef.componentInstance.title = 'category1';
    } else if (dialog === 'category2') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDCATEGORY2;
      dialogRef.componentInstance.title = 'category2';
    } else if (dialog === 'category3') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDCATEGORY3;
      dialogRef.componentInstance.title = 'category3';
    } else if (dialog === 'category4') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDCATEGORY4;
      dialogRef.componentInstance.title = 'category4';
    } else if (dialog === 'category5') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDCATEGORY5;
      dialogRef.componentInstance.title = 'category5';
    } else if (dialog === 'networkSubtypes') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDNETWORKSUBTYPE;
      dialogRef.componentInstance.title = 'networkSubtype';
    } else if (dialog === 'companySubtype') {
      const dialogRef = this.dialog.open(SuggestTagDialogComponent, {
        maxWidth: '500px',
      });
      dialogRef.componentInstance.path = DataConstants.SUGGESTEDSITESUBTYPE;
      dialogRef.componentInstance.title = 'companySubtype';
    } else {
      console.log('tag not supported');
    }
  }
}
