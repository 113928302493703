import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { RoutingModel } from 'app/app.routing-model';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { JobPreviewDialogComponent } from '../job-preview-dialog/job-preview-dialog.component';
import { Job } from 'app/stepper/job/model/job';
import { JobService } from 'app/core/job.service';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Idea } from 'app/groups/project-idea/models/idea';
import { IdeasService } from 'app/groups/project-idea/services/ideas.service';

@Component({
  selector: 'app-related-jobs',
  templateUrl: './related-jobs.component.html',
  styleUrls: ['./related-jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedJobsComponent implements OnChanges {
  @Input() resultId: string;
  @Input() showJobs = false;
  relatedJobIds$: Observable<Observable<Job>[]>;
  relatedIdeaIds$: Observable<Observable<Idea>[]>;
  jobs$: Observable<Job>[];
  ideas$: Observable<Idea>[];

  constructor(
    private jobService: JobService,
    private ideasService: IdeasService,
    private dialog: MatDialog,
    private authService: AuthService,
    private routingService: RoutingService
  ) {}

  ngOnChanges() {
    this.jobs$ = [];
    this.ideas$ = [];
    this.relatedJobIds$ = this.getRelatedIds$();
    this.relatedIdeaIds$ = this.getRelatedIdeaIds$();
  }

  get currentUser() {
    return this.authService.getCurrentUser();
  }
  goToJobBuilder(e, job: Job) {
    e.preventDefault();
    this.routingService.navigateToRoute(RoutingModel.jobBuilder.path, [
      job.key,
      '0',
    ]);
  }
  goToIdea(e, idea: Idea) {
    e.preventDefault();
    this.routingService.navigateToRoute(RoutingModel.ideas.path, [
      idea.key,
      'about',
    ]);
  }

  getRelatedIds$() {
    return this.jobService.getRelatedJobIds$(this.resultId).pipe(
      tap((ids) => {
        this.jobs$ = ids.map((id) => {
          return this.getPublishedJob(id);
        });
      })
    );
  }
  getRelatedIdeaIds$() {
    return this.ideasService.getRelatedIdeaIds$(this.resultId).pipe(
      tap((ids) => {
        this.ideas$ = ids.map((id) => {
          return this.getPublishedIdea(id);
        });
      })
    );
  }

  getPublishedJob(id: string): Observable<Job> {
    return this.jobService
      .getPublishedJob(id)
      .pipe(filter((job) => job !== null));
  }

  getPublishedIdea(id: string): Observable<Idea> {
    return this.ideasService.getIdea$(id).pipe(filter((idea) => idea !== null));
  }

  showJobPreview(e, key: string): void {
    e.stopPropagation();
    this.jobService.logRead(key);
    this.dialog.open(JobPreviewDialogComponent, {
      maxWidth: '85vw',
      maxHeight: '90vh',
      panelClass: 'preview-dialog',
      data: { key: key },
    });
  }

  get tooltip() {
    return this.showJobs ? 'hideRelatedJobs' : 'showRelatedJobs';
  }
}
