import { AfterViewInit, Component, Input } from '@angular/core';
import { hardcodedValues } from 'hardcodedValues';
declare const Chatbot: any;

@Component({
  selector: 'app-ai-chatbot-wrapper',
  templateUrl: './ai-chatbot-wrapper.component.html',
  styleUrls: ['./ai-chatbot-wrapper.component.scss'],
})
export class AiChatbotWrapperComponent implements AfterViewInit {
  @Input() chatId: string;
  @Input() theme: any;
  @Input() chatflowConfig: any;

  defaultConfig = {
    button: {
      backgroundColor: 'var(--primary)',
      right: 20,
      bottom: 20,
      size: 'medium',
      iconColor: 'var(--primary-text)',
      customIconSrc:
        'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
    },
    chatWindow: {
      welcomeMessage: 'You go first! 🚀',
      backgroundColor: '#ffffff',
      height:
        'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
      // width: '400px',
      poweredByTextColor: '#303235',
      title: 'Powered by Academondo',
      botMessage: {
        backgroundColor: '#f7f8ff',
        textColor: '#303235',
        showAvatar: true,
        avatarSrc: '/assets/logos/AM.svg',
      },
      userMessage: {
        backgroundColor: 'var(--primary)',
        textColor: 'var(--primary-text)',
        showAvatar: true,
        avatarSrc:
          'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
      },
      textInput: {
        placeholder: hardcodedValues.TypeYourMessageHere,
        backgroundColor: 'var(--primary-text)',
        textColor: '#303235',
        sendButtonColor: 'var(--primary)',
      },
    },
  };

  constructor() {}

  ngAfterViewInit() {
    if (typeof Chatbot !== 'undefined' && this.chatId) {
      if (this.chatflowConfig) {
        Chatbot.initFull({
          chatflowid: this.chatId,
          apiHost: hardcodedValues.chatbotBuilderApiHost,
          observersConfig: {
            // (optional) Allows you to execute code in parent based upon signal observations within the chatbot.
            // The userinput field submitted to bot ("" when reset by bot)
            observeUserInput: (userInput) => {
              if (userInput === '') {
                console.log('User input reset by bot');
              }
              console.log('observeUserInput', { userInput });
            },
            // The bot message stack has changed
            observeMessages: (messages) => {
              console.log('observeMessages', { messages });
            },
            // The bot loading signal changed
            observeLoading: (loading) => {
              console.log('observeLoading: ', { loading });
            },
          },
          theme: this.theme ? this.theme : this.defaultConfig,
          chatflowConfig: this.chatflowConfig,
        });
      } else {
        Chatbot.initFull({
          chatflowid: this.chatId,
          apiHost: hardcodedValues.chatbotBuilderApiHost,
          theme: this.theme ? this.theme : this.defaultConfig,
        });
      }
    }
  }
}
