import { Injectable } from '@angular/core';
import { GroupType } from 'app/groups/models/group-type';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class StatisticService {
  constructor(private db: DAO) {}

  public getReadsById$(id: string): Observable<any> {
    return this.db.object$(
      DataConstants.ITEM_LOGS + DataConstants.ITEM_READS + id
    );
  }

  public getViewsById$(id: string): Observable<any> {
    return this.db.object$(
      DataConstants.ITEM_LOGS + DataConstants.ITEM_VIEWS + id
    );
  }

  public getUserActionsById$(id: string): Observable<any> {
    return this.db.object$(
      DataConstants.ITEM_LOGS + DataConstants.ITEM_USER_ACTIONS + id
    );
  }

  public getJobStatusById$(id: string): Observable<any> {
    return this.db.object$(DataConstants.JOB_STATUS + id);
  }

  public getNumberOfUserPostsById$(userId: string): Observable<number> {
    return this.db.list$(DataConstants.USER_POSTS + userId).pipe(
      map((posts: any[]) => {
        return posts.reduce((acc) => {
          return acc + 1;
        }, 0);
      })
    );
  }

  public getNumberOfUserMembershipsByIdAndType$(
    userId: string,
    type: GroupType
  ) {
    return this.db.list$(DataConstants.USER_MEMBERSHIPS + userId + '/' + type);
  }
}
