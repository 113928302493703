import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { hardcodedValues } from 'hardcodedValues';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MentionService {
  private userCache$: Observable<MondoUser[]>;

  constructor(private db: DAO) {}

  public formatTaggedUserList(taggedUsers: any[]): string {
    const userNames = taggedUsers.map((user) => user.displayName);
    if (userNames.length === 1) {
      return userNames[0];
    } else if (userNames.length === 2) {
      return userNames.join(` ${hardcodedValues.and} `);
    } else {
      const lastUserName = userNames.pop();
      const formattedUserNames =
        userNames.join(', ') + ` ${hardcodedValues.and} ` + lastUserName;
      return formattedUserNames;
    }
  }

  public getUsersForTagging$(): Observable<MondoUser[]> {
    if (!this.userCache$) {
      this.userCache$ = this.requestUsers();
    }
    return this.userCache$;
  }

  private requestUsers(): Observable<MondoUser[]> {
    return this.db
      .list(DataConstants.USERS, (ref) => ref.orderByChild('lastLogin'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((snaps) => {
          return snaps.map((s) => {
            const user = s.payload.val();
            user['uid'] = s.payload.key;
            return user as MondoUser;
          });
        }),
        map((users) => {
          return users.filter((user) =>
            AuthService.isUserOfScientistType(user.type)
          );
        }),
        map((users) => {
          return users.filter((user) => user.publishedCv);
        })
      );
  }
}
