import {MultiSelectorItem} from '../components/multi-selector/multi-selector-item';

export class Category0 implements MultiSelectorItem {
  public static fromJson({uid, name, desc}): Category0 {
    return new Category0(uid, name, desc);
  }
  constructor(public uid: string, public name: string, public desc: string) {}

  public getId(): string {
    return this.uid;
  }

  public getDisplayText(): string {
    return this.name;
  }
  public getDescription(): string {
    return this.desc;
  }
}
