import { Injectable, OnDestroy } from '@angular/core';
import { DatabaseHandlerService } from 'app/stepper/shared/services/database-handler.service';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { AuthService } from 'app/core/auth.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription, Subject, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RoutingService } from 'app/core/routing.service';
import { MondoRoutes, RoutingModel } from 'app/app.routing-model';
import { ConfirmDialogComponent } from 'app/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { SiteStepIndex } from 'app/stepper/shared/model/stepIndex';
import { IndustryUser } from 'app/shared/models/user/industryUser';
import { UserService } from 'app/core/user.service';
import { formatText, hardcodedValues } from 'hardcodedValues';
import { Site } from 'app/shared/models/site';
import { HelperService } from 'app/core/helper.service';
import { isCVRValid } from '@helpers';
import { DAO } from 'app/shared-services/db-access/dao';
import { StorageService } from './storage.service';
import {
  Category0,
  Category1,
  FieldOfInterest,
  IFilter,
  Place,
  Subtypes,
  Technique,
  UserFilter,
} from 'app/shared/models';
import { acaNames } from 'aca-names';

@Injectable()
export class SiteService extends DatabaseHandlerService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  private firstLoad = false;
  subscriptions: Subscription[] = [];
  siteSubscription: Subscription = new Subscription();
  siteId: string;
  private siteSubject: BehaviorSubject<object> = new BehaviorSubject({});
  public site$: Observable<object> = this.siteSubject.asObservable();

  // siteData$: BehaviorSubject<Site> = new BehaviorSubject<Site>(null);
  site: Site = new Site();
  siteData$: Observable<Site> = new Observable(null);
  step: SiteStepIndex;
  companyName = '';

  public _editor: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public editMode$: Observable<boolean> = this._editor.asObservable();
  public editMode = true;
  public introSite: Site = new Site();
  public deleteItem: BehaviorSubject<any> = new BehaviorSubject(null);
  public pingSubscriptions$: Observable<any> = this.deleteItem.asObservable();

  constructor(
    public routingService: RoutingService,
    public routeк: Router,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    public dao: DAO,
    authService: AuthService,
    private router: Router,
    private userService: UserService,
    fnsHelper: HelperService,
    private storageService: StorageService
  ) {
    super(
      dao,
      authService,
      DataConstants.DRAFT_SITES,
      DataConstants.USER_SITES,
      DataConstants.PUBLISHED_SITES,
      DataConstants.SITE_STATUS,
      fnsHelper
    );
    this.subscriptions.push(
      this.editMode$.subscribe((res) => {
        if (this.editMode !== res) {
          this.editMode = res;
        }
      })
    );
    const introSite$ = this.getIntroSite();
    if (introSite$) {
      this.subscriptions.push(
        introSite$.subscribe((intro: Site) => {
          this.introSite = intro;
        })
      );
    }
    this.getUrlParams(this.router.url);
    this.subscriptions.push(
      this.router.events.subscribe((data) => {
        if (data instanceof RoutesRecognized) {
          this.getUrlParams(data.state.url);
        }
      })
    );
  }

  getUrlParams(url: string) {
    if (
      url.split('/').length === 5 &&
      new RegExp(RoutingModel.siteBuilder.path, 'gi').test(url)
    ) {
      this.siteId = url.split('/')[3];
      this.step = parseInt(url.split('/')[4], 10);
      this.siteSubject.next({ networkId: this.siteId, step: this.step });
    }
  }

  navigateToRoute(path = RoutingModel.siteBuilder.route) {
    this.routingService.navigateToRoute(path);
  }

  getSiteData(siteKey: string, published = false): Observable<Site> {
    return this.getItem<Site>(Site.fromJson, siteKey, published);
  }

  getPublishedSite(siteKey: string): Observable<Site> {
    return this.getItem<Site>(Site.fromJson, siteKey, true);
  }

  getDraftSite(key: string): Observable<Site> {
    return this.getItem<Site>(Site.fromJson, key);
  }

  getDraftSites(): Observable<Site[]> {
    return this.getDraftItems(Site.fromJson);
  }

  getIntroSite(): Observable<Site> {
    return this.getItemDeprecated<Site>(Site.fromJson, DataConstants.INTROSITE);
  }

  public unsubscribe() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
    this.siteSubscription.unsubscribe();
  }

  siteExists(id: string): Promise<boolean> {
    return this.authService.logged ? this.exists(id) : this.exists('0');
  }

  updateDraftSite(site: Site): Promise<void> {
    if (!this.firstLoad) {
      this.firstLoad = true;
      return;
    }
    return this.update(site, Site.toJson);
  }

  createSite(): Promise<string> {
    return this.generateNew();
  }

  generateNew(): Promise<string> {
    const site = new Site();
    site.siteInfo.info = acaNames['defaultSiteDescription']
      ? acaNames['defaultSiteDescription']
      : ``;
    site.name = hardcodedValues.newCompany;
    this.editMode = true;
    return this.createItemAnStatus(site, Site.toJson);
  }

  public getSiteFromUserId(userId: string): Observable<Site> {
    return this.authService
      .readUserByUid(userId)
      .pipe(
        switchMap((user: IndustryUser) =>
          user && user.company && !!user.company.uid
            ? this.getPublishedSite(user.company.uid)
            : of(null)
        )
      );
  }

  loadNewSite(key: string, step: SiteStepIndex = this.step): Observable<Site> {
    this.siteId = key;
    const _navigate = navigate.bind(this);
    this.siteData$ = this.getDraftSite(key);
    // if (intro) {
    //   _navigate(key, SiteStepIndex.start);
    // } else {
    if (key === MondoRoutes.none) {
      _navigate(key, SiteStepIndex.start);
    } else {
      _navigate(key, step);
    }
    // }
    function navigate(k, s) {
      this.routingService.navigateToRoute(RoutingModel.siteBuilder.route, [
        k,
        s,
      ]);
    }
    return this.siteData$;
  }

  unpublishSite(site: Site) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = 'unpublish';
    dialogRef.componentInstance.text = 'areYouSureWantToDeleteX';
    dialogRef.componentInstance.textX = site.siteInfo.nameSite
      ? site.siteInfo.nameSite
      : 'site';
    dialogRef.componentInstance.yesText = 'unpublish';
    dialogRef.componentInstance.noText = 'cancel';

    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .toPromise()
        .then(async (unpublishSite) => {
          if (unpublishSite) {
            await this.unpublish(site);
            return this.userService.removePublicSiteFromUser(site.ownerId);
          } else {
            resolve(false);
          }
        });
    });
  }

  removeSite(id: string, name?: string): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = 'delete';
    dialogRef.componentInstance.text = 'areYouSureWantToDeleteX';
    dialogRef.componentInstance.textX = name && name.length ? name : 'thesite';
    dialogRef.componentInstance.yesText = 'delete';
    dialogRef.componentInstance.noText = 'cancel';

    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .toPromise()
        .then((deleteSite) => {
          if (deleteSite) {
            this.deleteSite(id).then(() => resolve(true));
          } else {
            resolve(false);
          }
        });
    });
  }

  private async deleteSite(id) {
    const iconPath = `sites/${id}/icon/icon`;
    const logoPath = `sites/${id}/logo/logo`;
    // const coverPath = `sites/${id}/cover/cover`;
    try {
      this.storageService.deleteFile(iconPath).toPromise();
      this.storageService.deleteFile(logoPath).toPromise();
    } catch (error) {
      console.error('no img to delete');
    }
    // this.storageService.deleteFile(coverPath).toPromise();
    this.removeItemAndStatus(id).then(() => {
      this.deleteItem.next(true);
      this.editMode = false;
      this.setEditorMode(false);
      const user = this.authService.getCurrentUser();
      if (user && user['company'] && user['company'].uid === id) {
        return this.removePublishedCompany(user);
      }
      return;
    });
  }

  cleanUpSites() {
    return this.getDraftSites().pipe(
      map((sites) => {
        sites.map((site) => {
          if (site.siteInfo.nameSite) {
            return site;
          } else {
            this.deleteSite(site.key);
            return site;
          }
        });
      })
    );
  }

  public removePublishedCompany(user) {
    user['company'] = null;
    return this.userService.updateUser(user);
  }

  setEditorMode(mode: boolean) {
    this._editor.next(mode);
  }

  getExistSites() {
    return this.getExistItems();
  }

  getNumberUserSites(): Promise<number> {
    return new Promise((resolve) => {
      this.getDraftSites()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          resolve(res.length);
        });
    });
  }

  private minRequirementForPublish(site: Site): string {
    let text = '';
    if (this.checkName(site)) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.CompanyName
      )}\n`;
    }
    if (this.checkAddress(site)) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.address
      )}\n`;
    }
    if (this.checkFoi(site)) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.Foi
      )}\n`;
    }

    // if (this.checkCat0(site)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.Category0
    //   )}\n`;
    // }

    // if (this.checkCat1(site)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.Category1
    //   )}\n`;
    // }

    // if (this.checkCat2(site)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.Category2
    //   )}\n`;
    // }

    // if (this.checkCat3(site)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.Category3
    //   )}\n`;
    // }

    // if (this.checkSubtype(site)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.CompanySubtype
    //   )}\n`;
    // }
    if (!this.hasCVR(site) && hardcodedValues.registerCVRONSites) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.cvr
      )}\n`;
    }
    if (!isCVRValid(site.siteInfo.cvr) && hardcodedValues.registerCVRONSites) {
      text += `⛔ ${hardcodedValues.cvr} ${hardcodedValues.invalid}\n`;
    }
    // if (this.checkSubtype(site)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.CompanySubtype
    //   )}\n`;
    // }
    return text;
  }

  private getRequiredStatusPercentage(site: Site): number {
    let value = 0;
    let counter = 0;
    if (!this.checkName(site)) {
      value += 1;
    }
    counter += 1;

    if (!this.checkAddress(site)) {
      value += 1;
    }
    counter += 1;

    if (site.experience.interests.length > 0) {
      value += 1;
    }
    counter += 1;

    // if (!this.checkCat0(site)) {
    //   value += 1;
    // }
    // counter += 1;

    if (hardcodedValues.registerCVRONSites) {
      if (this.hasCVR(site)) {
        value += 1;
      }
      counter += 1;
    }

    // if (!this.checkSubtype(site)) {
    //   value += 1;
    // }
    // counter += 1;

    return Math.floor((value / counter) * 100);
  }

  private hasCVR(site: Site): boolean {
    return site.siteInfo.cvr ? site.siteInfo.cvr.length === 8 : false;
  }

  private checkName(site: Site): boolean {
    return !site.siteInfo.nameSite.trim();
  }

  private checkAddress(site: Site): boolean {
    return (
      !site.siteInfo.addressSite.street.length ||
      !site.siteInfo.addressSite.country.code
    );
  }

  private checkFoi(site: Site): boolean {
    return !site.experience.interests.length;
  }

  private checkCat0(site: Site): boolean {
    return !site.experience.category0.length;
  }

  private checkCat1(site: Site): boolean {
    return !site.experience.category1.length;
  }

  private checkCat2(site: Site): boolean {
    return !site.experience.category2.length;
  }

  private checkCat3(site: Site): boolean {
    return !site.experience.category3.length;
  }

  private checkSubtype(site: Site): boolean {
    return !site.siteInfo.subtypes.length;
  }

  public getRequirementStatusAndText(site: Site) {
    return {
      status: this.getRequiredStatusPercentage(site),
      text: this.minRequirementForPublish(site),
    };
  }

  public async publishSite(site: Site) {
    if (site) {
      this.handleSiteChange(site);
      site.status.queueForPublish();
      this.publish(site, Site.toJson);
      this.editMode = false;
    }
  }

  private handleSiteChange(site: Site) {
    const user = this.authService.getCurrentUser() as IndustryUser;
    user.displayName = site.siteInfo.nameSite;
    user.company.name = site.siteInfo.nameSite;
    user.photoURL = site.siteInfo.pictureUrl;
    user.coverUrl = site.siteInfo.coverUrl;
    user.phone = site.siteInfo.phone;
    user.company.pictureUrl = site.siteInfo.pictureUrl;
    user.company.coverUrl = site.siteInfo.coverUrl;
    user.company.location = site.siteInfo.addressSite;
    user.company.uid = site.key;
    user.filters = this.getFilters(site);
    user.disableChat = site.siteInfo.disableChat
      ? site.siteInfo.disableChat
      : false;
    this.userService.updateUser(user);
  }

  public async isOwnSite(key: string): Promise<boolean> {
    return this.authService.logged ? this.ownItem(key) : this.ownItem('0');
  }

  getFilters(entity: Site | Place): IFilter[] {
    if (!entity || !entity.experience) {
      return [];
    }
    let foiList = [];
    let cat0List = [];
    let cat1List = [];
    let cat2List = [];
    let cat3List = [];
    let cat4List = [];
    let cat5List = [];
    let techList = [];
    let siteSubtypeList = [];

    if (entity.experience.interests) {
      foiList = entity.experience.interests.map((i: FieldOfInterest) => {
        return new UserFilter(i.uid, i.name, 'interests');
      });
    }

    if (entity.experience.category0) {
      cat0List = entity.experience.category0.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category0');
      });
    }
    if (entity.experience.category1) {
      cat1List = entity.experience.category1.map((i: Category1) => {
        return new UserFilter(i.uid, i.name, 'category1');
      });
    }
    if (entity.experience.category2) {
      cat2List = entity.experience.category2.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category2');
      });
    }
    if (entity.experience.category3) {
      cat3List = entity.experience.category3.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category3');
      });
    }
    if (entity.experience.category4) {
      cat4List = entity.experience.category4.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category4');
      });
    }
    if (entity.experience.category5) {
      cat5List = entity.experience.category5.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category5');
      });
    }
    if (entity.experience.techniques) {
      techList = entity.experience.techniques.map((i: Technique) => {
        return new UserFilter(i.id, i.name, 'techniques');
      });
    }
    if (entity && entity['siteInfo'] && entity['siteInfo'].subtypes) {
      siteSubtypeList = entity['siteInfo'].subtypes.map((i: Subtypes) => {
        return new UserFilter(i.id, i.name, 'siteSubtype');
      });
    }
    return [
      ...foiList,
      ...techList,
      ...cat0List,
      ...cat1List,
      ...cat2List,
      ...cat3List,
      ...cat4List,
      ...cat5List,
      ...siteSubtypeList,
    ];
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
