import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { getFiltersFromGroup } from '@helpers';
import { RoutingModel } from 'app/app.routing-model';
import { RoutingService } from 'app/core/routing.service';
import { GroupRelation } from 'app/groups/models/group-relation';
import { GroupType } from 'app/groups/models/group-type';
import { GroupService } from 'app/groups/services/group.service';
import { MembershipService } from 'app/shared-services/membership/membership.service';
import { IFilter } from 'app/shared/models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Event } from '../../groups/events/models/event';

@Component({
  selector: 'app-event-viewer',
  templateUrl: './event-viewer.component.html',
  styleUrls: ['./event-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventViewerComponent implements OnChanges {
  @Input() event: Event;
  @Input() showVisit = false;
  @Input() showCoverImage = true;
  relation: GroupRelation;
  relation$: Observable<GroupRelation>;

  constructor(
    private routingService: RoutingService,
    private membershipService: MembershipService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const event = changes.event;
    if (!event.firstChange && event.currentValue !== event.previousValue) {
      this.event = event.currentValue;
      this.relation$ = this.membershipService
        .getRelation$(this.event.key, GroupType.Events)
        .pipe(
          tap((relation) => {
            this.relation = relation;
          })
        );
    }
  }

  get filters(): IFilter[] {
    return getFiltersFromGroup(this.event);
  }

  hasRelation(): boolean {
    return !!this.relation;
  }

  gotoEvent(key: string) {
    this.routingService.navigateToRoute(RoutingModel.events.path, [
      key,
      'about',
    ]);
  }

  get startDate(): Date {
    return (
      this.event && this.event.eventPeriod && this.event.eventPeriod.startDate
    );
  }

  get endDate(): Date {
    return (
      this.event && this.event.eventPeriod && this.event.eventPeriod.endDate
    );
  }

  get eventAddress(): string {
    return GroupService.getGroupAddress(this.event);
  }

  trackByFn(index, item) {
    return item.key;
  }
}
