import { trigger, state, animate, transition, style } from '@angular/animations';

export const easeInAndOutAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('enterAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('1000ms ease-in-out', style({ opacity: 1 }))
    ])
  ]);


