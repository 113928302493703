import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-aca-carousel',
  templateUrl: './aca-carousel.component.html',
  styleUrls: ['./aca-carousel.component.scss'],
})
export class AcaCarouselComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { slides: string },
    private dialogRef: MatDialogRef<AcaCarouselComponent>
  ) {}

  ngOnInit() {}

  customOptions: OwlOptions = {
    loop: true,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: true,
    center: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,
    autoplay: false,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };

  slides = this.data.slides;

  slideStore = [
    {
      title: 'Title 1',
      caption: 'some important statement 1!',
      style: { color: '#fff' },
      img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-demo.appspot.com/o/landing%2F1.png?alt=media&token=a33a1e10-5e3e-4157-b0bb-213efb74a825',
      icon: 'assets/logos/AM-inv.svg',
    },
    {
      title: 'Title 2',
      caption: 'some important statement 2!',
      style: { color: '#000' },
      img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-demo.appspot.com/o/landing%2F2.png?alt=media&token=12551994-619d-43e7-9bad-9b12ddf2258e',
      icon: 'assets/logos/AM-inv.svg',
    },
    {
      title: 'Title 3',
      caption: 'some important statement 3!',
      style: { color: '#113422' },
      img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-demo.appspot.com/o/landing%2F3.png?alt=media&token=6010f4fc-59ab-4142-9051-87d0cf685b16',
      icon: 'assets/logos/AM-inv.svg',
    },
  ];
  slideStoreVER = [
    {
      // title:'Title 1',
      // caption: "some important statement 1!",
      // style: {color: '#fff'},
      img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-viborg-demo.appspot.com/o/landing-page%2FNY%20slider%20BusinessVIborg_1.1.jpg?alt=media&token=03c648c4-0d45-4b88-b44c-1e8605a3b027',
      // img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-viborg.appspot.com/o/landing-page%2Fimg1_high.png?alt=media&token=a1d523f1-3394-4732-a379-0ba28fb393c3',
      // img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-demo.appspot.com/o/landing%2F1.png?alt=media&token=a33a1e10-5e3e-4157-b0bb-213efb74a825',
      // icon: 'assets/logos/AM-inv.svg'
    },
    {
      // title:'Title 2',
      // caption: "some important statement 2!",
      // style: {color: '#000'},
      img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-viborg.appspot.com/o/landing-page%2Fimg2_high.png?alt=media&token=0cf1a06e-901d-40f7-9cd0-171ce0d5958b',
      // img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-demo.appspot.com/o/landing%2F2.png?alt=media&token=12551994-619d-43e7-9bad-9b12ddf2258e',
      // icon: 'assets/logos/AM-inv.svg'
    },
    {
      // title:'Title 3',
      // caption: "some important statement 3!",
      // style: {color: '#113422'},
      img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-viborg-demo.appspot.com/o/landing-page%2Fimg3_high-tiny.png?alt=media&token=712c78dc-963c-44d9-a9ce-fd0e246e4b1d',
      // img: 'https://firebasestorage.googleapis.com/v0/b/erhverv-demo.appspot.com/o/landing%2F3.png?alt=media&token=6010f4fc-59ab-4142-9051-87d0cf685b16',
      // icon: 'assets/logos/AM-inv.svg'
    },
  ];

  closeClicked() {
    this.dialogRef.close(null);
  }
}
