import { Component, Input, OnInit } from '@angular/core';
import { createEvent, download } from '@helpers';
import { acaConfig } from 'aca-config';
import { AuthService } from 'app/core/auth.service';
import { Attendance } from 'app/groups/events/models/attendance';
import { Event } from 'app/groups/events/models/event';
import { MembershipService } from 'app/shared-services/membership/membership.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { hardcodedValues } from 'hardcodedValues';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-calendar-file',
  templateUrl: './calendar-file.component.html',
  styleUrls: ['./calendar-file.component.scss'],
})
export class CalendarFileComponent implements OnInit {
  @Input() inputEvent: Event;

  organizer$: Observable<MondoUser>;
  organizer: MondoUser;
  constructor(
    private authService: AuthService,
    private membershipService: MembershipService
  ) {}

  ngOnInit() {
    this.organizer$ = this.authService
      .readUserByUid(this.inputEvent.ownerId)
      .pipe(
        tap((user) => {
          this.organizer = user;
        })
      );
  }

  createEvent(event: Event) {
    return createEvent(
      event,
      this.organizer ? this.organizer.email : acaConfig.senderMail
    );
  }
  // cancelEvent(event: Event) {
  //   return createEvent(
  //     event,
  //     this.organizer ? this.organizer.email : acaConfig.senderMail,
  //     'CANCELLED',
  //     'CANCEL',
  //     'TRANSPARENT',
  //     1,
  //     1
  //   );
  // }
  // deleteEvent(event: Event) {
  //   return createEvent(
  //     event,
  //     this.organizer ? this.organizer.email : acaConfig.senderMail,
  //     'CANCELLED',
  //     'PUBLISH',
  //     'TRANSPARENT',
  //     1,
  //     1
  //   );
  // }

  download(ics: string) {
    download(`${this.inputEvent.name}.ics`, ics);
    this.membershipService.becomeMemberAndChangeAttendance(
      this.inputEvent.key,
      hardcodedValues.hideAttending
        ? Attendance.Interested
        : Attendance.Attending
    );
  }
}
