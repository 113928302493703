import { isDevMode, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'environments/environment';
import {
  AngularFireRemoteConfigModule,
  SETTINGS,
} from '@angular/fire/remote-config';

@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireRemoteConfigModule,
    // AngularFirestoreModule,
  ],
  exports: [
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    // AngularFirestoreModule,
  ],
  declarations: [],
  providers: [
    {
      provide: REGION,
      useValue: environment.region,
    },
    // { provide: DEFAULTS, useValue: acaConfig }
    {
      provide: SETTINGS,
      useFactory: () =>
        isDevMode()
          ? { minimumFetchIntervalMillis: 30_000 }
          : { minimumFetchIntervalMillis: 300_000 },
    },
  ],
})
export class AngularfireWrapperModule {}
