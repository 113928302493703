import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoutingModel } from 'app/app.routing-model';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { UserService } from 'app/core/user.service';
import { ForumPost } from 'app/forum/models/forum-post';
import { ForumTopic } from 'app/forum/models/forum-topic';
import { ForumService } from 'app/forum/services/forum.service';
import { Group } from 'app/groups/models/group';
import { GroupType } from 'app/groups/models/group-type';
import { GroupService } from 'app/groups/services/group.service';
import { SearchResultV2 } from 'app/shared/common/search.model';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';
import { ConfirmDialogComponent } from 'app/confirm-dialog/confirm-dialog/confirm-dialog.component';
import {
  Category0,
  Category1,
  FieldOfInterest,
  IFilter,
  Technique,
  Upload,
  UserFilter,
} from 'app/shared/models';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { take } from 'rxjs/operators';
import { PostBuilderComponent } from '../../forum/components/post-builder/post-builder.component';
import { getFiltersFromSearchResults } from '@helpers';
import { hardcodedValues } from 'hardcodedValues';
import { PreviewPremiumComponent } from '../display-components/preview-premium/preview-premium.component';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';
import { PostService } from 'app/core/post.service';
import { Observable } from 'rxjs';
import { ScientistUser } from 'app/shared/models/user/scientistUser';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent
  extends DestroyComponent
  implements OnInit, OnChanges
{
  @Input() result: SearchResultV2;
  @Input() post: ForumPost;
  @Input() user: MondoUser;
  @Input() isAdmin: boolean;
  @Input() group: Group;
  @Input() showLatestComments = false;
  @Input() canOpenPostDetails = false;
  @Output() postRemoved = new EventEmitter<ForumPost>();
  @Input() canComment: boolean;
  @Input() commentsToShow = 1;
  @Input() showGoToPost = false;
  @Input() showGroup = false;
  @Input() showDate = true;
  @Input() preview = false;
  @Input() forceShowSubComments = true;
  @Input() canEdit = true;
  @Input() videoHeight = 420;

  viewEntireDescription = false;
  owner: MondoUser;
  currentUser: MondoUser;
  filters: IFilter[];
  uploads: Upload[];
  taggedUserIds: string[];
  maxTags = 5;
  hasMoreTags = false;
  newPost: ForumPost = null;
  viewAsTeaser = false;
  showViews = true;
  shareUrl = '';
  groupPrivacies = GroupPrivacy;
  views$: Observable<number>;
  isPlatformAdmin = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private forumService: ForumService,
    private groupService: GroupService,
    private postService: PostService,
    public dialog: MatDialog,
    public routingService: RoutingService
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.post) {
      this.views$ = this.postService.getPostViewCount(this.post.key);
      this.shareUrl = `${environment.academondoUrl}/${this.post.groupType}/${this.post.groupId}/feed/${this.post.key}`;
      this.viewAsTeaser = this.setViewAsTeaser();
      if (this.viewAsTeaser) {
        this.post.body = this.getPostBodyText(this.post.body);
      }
      if (!this.preview) {
        this.safeSubscribe(
          this.userService.getUserByUid$(this.post.ownerId),
          (owner) => (this.owner = owner)
        );
      } else {
        this.owner = this.authService.getCurrentUser();
      }

      this.filters = this.forumService.getFilters(this.post);
      this.uploads = [];
      setTimeout(() => {
        this.uploads = this.post.uploads ? this.post.uploads : [];
      }, 0);
      this.taggedUserIds = [];
      setTimeout(() => {
        this.taggedUserIds = this.post.taggedUserIds
          ? this.post.taggedUserIds
          : [];
      }, 0);

      if (this.showGroup) {
        this.safeSubscribe(
          this.groupService.getGroup$(this.post.groupId, this.post.groupType),
          (group) => (this.group = group)
        );
      }
    } else if (this.result) {
      this.views$ = this.postService.getPostViewCount(this.result.id);
      this.shareUrl = `${environment.academondoUrl}/${this.result.customAttributes['groupType']}/${this.result.customAttributes['groupId']}/feed/${this.result.id}`;
      this.safeSubscribe(
        this.userService.getUserByUid$(this.result.customAttributes['ownerId']),
        (owner) => (this.owner = owner)
      );

      this.filters = getFiltersFromSearchResults(this.result);

      this.uploads = this.result.customAttributes['uploads']
        ? JSON.parse(this.result.customAttributes['uploads'])
        : [];

      if (this.showGroup) {
        this.safeSubscribe(
          this.groupService.getGroup$(
            this.result.customAttributes['groupId'],
            this.result.customAttributes['groupType']
          ),
          (group) => (this.group = group)
        );
      }
    }
  }

  ngOnInit() {
    this.isPlatformAdmin = this.authService.isAdmin;
    this.viewEntireDescription = !this.canOpenPostDetails;
    if (!this.post && this.result) {
      this.newPost = this.mapResultToForumPost(this.result);
    }
    this.safeSubscribe(
      this.authService.getCurrentUser$(),
      (user) => (this.currentUser = user)
    );
  }

  setViewAsTeaser() {
    const isGroupPremium =
      this.group && this.group.permissions
        ? this.group.permissions.premium
        : false;
    if (hardcodedValues.EnablePremiumCommunities && isGroupPremium) {
      if (this.post.teaser) {
        return !this.authService.isUserPremium;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public showPremiumPreview() {
    if (this.group) {
      this.dialog.open(PreviewPremiumComponent, {
        panelClass: 'preview-dialog',
        data: { name: this.group.name },
      });
    }
  }

  getPostBodyText(body: string) {
    if (body) {
      const container = document.createElement('div');
      container.innerHTML = body;
      const paragraphs = container.querySelectorAll('p');
      const sortedParagraphs = Array.from(paragraphs).sort((a, b) => {
        return a.textContent.length - b.textContent.length;
      });

      const paragraphsToKeepContainer = document.createElement('div');
      let paragraphsAdded = 0;

      sortedParagraphs.forEach((paragraph) => {
        if (paragraphsAdded < 2) {
          paragraphsToKeepContainer.appendChild(paragraph.cloneNode(true));
          paragraphsAdded++;
        } else {
          paragraph.remove();
        }
      });
      container.appendChild(paragraphsToKeepContainer);
      return container.innerHTML;
    } else {
      return '';
    }
  }

  isSecondToLastPost(userId: string): boolean {
    const secondToLastUserId =
      this.post.taggedUserIds[this.post.taggedUserIds.length - 2];
    return userId === secondToLastUserId;
  }

  toggleDescription() {
    this.viewEntireDescription = !this.viewEntireDescription;
  }

  private mapResultToForumPost(result: SearchResultV2): ForumPost {
    this.viewAsTeaser =
      result.customAttributes['teaser'] &&
      hardcodedValues.EnablePremiumCommunities &&
      !this.authService.isUserPremium;
    const post = new ForumPost(
      result.id,
      result.type,
      result.fullName,
      result.customAttributes['userTags'],
      result.customAttributes['taggedUserIds']
        ? JSON.parse(result.customAttributes['taggedUserIds'])
        : [],
      result.description,
      result.customAttributes['videoUrl'],
      result.customAttributes['imageLink'],
      result.customAttributes['imageUrl'],
      [],
      result.customAttributes['phone'],
      result.customAttributes['email'],
      result.customAttributes['ownerId'],
      result.customAttributes['forumId'],
      result.customAttributes['created']
        ? new Date(result.customAttributes['created'])
        : undefined,
      result.customAttributes['lastUpdate']
        ? new Date(result.customAttributes['lastUpdate'])
        : undefined,
      result.customAttributes['latestComment']
        ? new Date(result.customAttributes['latestComment'])
        : undefined,
      new ForumTopic(),
      result.customAttributes['groupId'],
      result.customAttributes['groupType'],
      0,
      this.viewAsTeaser
    );
    if (this.viewAsTeaser) {
      post.body = this.getPostBodyText(result.description);
    }
    return post;
  }

  isUserOfCompanyType(userType) {
    return AuthService.isUserOfCompanyType(userType);
  }

  editPost(post: ForumPost) {
    const dialogRef = this.dialog.open(PostBuilderComponent, {
      width: '90vw',
      maxWidth: '800px',
      panelClass: 'create-post-dialog',
      disableClose: true,
    });
    dialogRef.componentInstance.post = post;
    dialogRef.componentInstance.group = this.group;
    dialogRef.componentInstance.updatingPost = true;

    dialogRef.backdropClick().subscribe((result) => {
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {});
      confirmDialogRef.componentInstance.title =
        'discardChangesPostUpdateTitle';
      confirmDialogRef.componentInstance.text = 'discardChangesPostUpdateBody';
      confirmDialogRef.componentInstance.yesText = 'yes';
      confirmDialogRef.componentInstance.noText = 'no';
      return new Promise((resolve) => {
        confirmDialogRef
          .afterClosed()
          .pipe(take(1))
          .toPromise()
          .then((cancel) => {
            if (cancel) {
              dialogRef.close();
            }
          });
      });
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  async deletePost(post: ForumPost) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {});
    dialogRef.componentInstance.title = 'deletePostTitle';
    dialogRef.componentInstance.text = 'deletePostBody';
    dialogRef.componentInstance.yesText = 'yes';
    dialogRef.componentInstance.noText = 'no';

    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .toPromise()
        .then(async (yesDelete) => {
          if (yesDelete) {
            await this.forumService.deletePost(post.key, post.forumId);
            this.postRemoved.emit(post);
          }
        });
    });
  }

  getStateClass() {
    return this.viewEntireDescription ? 'expanded' : 'collapsed';
  }

  showPreview(): void {
    this.authService.showPreview(this.owner);
  }

  openGroup(group: Group) {
    switch (group.groupType) {
      case GroupType.Communities:
        this.routingService.navigateToRoute(RoutingModel.communities.path, [
          group.key,
          'feed',
        ]);
        break;
      case GroupType.Events:
        this.routingService.navigateToRoute(RoutingModel.events.path, [
          group.key,
          'feed',
        ]);
        break;
      case GroupType.Ideas:
        this.routingService.navigateToRoute(RoutingModel.ideas.path, [
          group.key,
          'feed',
        ]);
        break;
    }
  }

  getFiltersold(): IFilter[] {
    if (!this.post || !this.post.topic) {
      return [];
    }
    let foiList = [];
    let cat0List = [];
    let cat1List = [];
    let techList = [];

    if (this.post.topic.fieldsOfInterests) {
      foiList = this.post.topic.fieldsOfInterests.map((i: FieldOfInterest) => {
        return <IFilter>{
          key: i.uid,
          value: i.name,
        };
      });
    }

    if (this.post.topic.category0) {
      cat0List = this.post.topic.category0.map((i: Category0) => {
        return <IFilter>{
          key: i.uid,
          value: i.name,
        };
      });
    }
    if (this.post.topic.category1) {
      cat1List = this.post.topic.category1.map((i: Category1) => {
        return <IFilter>{
          key: i.uid,
          value: i.name,
        };
      });
    }
    if (this.post.topic.techniques) {
      techList = this.post.topic.techniques.map((i: Technique) => {
        return <IFilter>{
          key: i.id,
          value: i.name,
        };
      });
    }
    const tags = [...foiList, ...techList, ...cat0List, ...cat1List];
    this.hasMoreTags = tags.length > this.maxTags + 1;
    return tags.slice(0, this.maxTags);
  }

  getPostDetailsPath(groupType: string, groupId: string, key: string): string {
    switch (groupType) {
      case GroupType.Communities:
        return `${RoutingModel.communities.route}/${groupId}/feed/${key}`;
      case GroupType.Events:
        return `${RoutingModel.events.route}/${groupId}/feed/${key}`;
      case GroupType.Ideas:
        return `${RoutingModel.ideas.route}/${groupId}/feed/${key}`;
      default:
        break;
    }
  }

  getTitle(user: MondoUser) {
    if (AuthService.isUserOfCompanyType(user.type)) {
      // return (user as IndustryUser).company.;
      return '';
    } else if (AuthService.isUserOfScientistType(user.type)) {
      return (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.academicTitle
        ? `${(user as ScientistUser).personalDetails.academicTitle}`
        : '';
    } else {
      return '';
    }
  }
  getLocation(user: MondoUser) {
    if (AuthService.isUserOfCompanyType(user.type)) {
      // return (user as IndustryUser).company.;
      return '';
    } else if (AuthService.isUserOfScientistType(user.type)) {
      return (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.companyName
        ? `@${(user as ScientistUser).personalDetails.companyName}`
        : '';
    } else {
      return '';
    }
  }

  getTooltip(user: MondoUser, noProfiletext: string) {
    return `${this.getTitle(user)}${this.getLocation(user)}
    `;
  }
}
