import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CvService } from 'app/core/cv.service';
import { Observable } from 'rxjs';
import { EducationLevel } from 'app/shared/models/cv/education-level';
import { CV } from 'app/shared/models/cv/cv';
import { SearchResultV2 } from 'app/shared/common/search.model';
import { UserService } from 'app/core/user.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from 'app/core/auth.service';
import { PersonalDetails } from 'app/shared/models/cv/personal-details';
import { IFilter } from 'app/shared/models';
import { getFiltersFromSearchResults } from '@helpers';
import { RoutingModel } from 'app/app.routing-model';
import { acaConfig } from 'aca-config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-candidate-result-viewer',
  templateUrl: './candidate-result-viewer.component.html',
  styleUrls: ['./candidate-result-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidateResultViewerComponent implements OnInit {
  cv$: Observable<CV>;
  @Input() hoverZoom = true;
  @Input() showScoreBar: boolean;
  @Input() initialSearch = false;
  @Input() map = false;
  @Input() cvResult: SearchResultV2;
  @Output() cvLoad: EventEmitter<boolean> = new EventEmitter<boolean>();
  canOpenDescription = true;
  viewEntireDescription = false;
  pd: PersonalDetails | any;
  pd$: Observable<PersonalDetails>;
  expanded = false;
  filters: IFilter[] = [];

  private educationLevelSteps = [
    EducationLevel.academy,
    EducationLevel.bachelor,
    EducationLevel.master,
    EducationLevel.phd,
  ];

  constructor(
    private cvService: CvService,
    private router: Router,
    private userService: UserService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.viewEntireDescription = !this.canOpenDescription;
    this.filters = getFiltersFromSearchResults(this.cvResult);
    if (this.authService.canSeeAnonymous) {
      this.cv$ = this.cvService.getPublishedCv(this.cvResult.id).pipe(
        mergeMap((cv) => {
          if (cv) {
            return this.userService.getUserPersonalDetails(cv.ownerId).pipe(
              map((pd) => {
                if (cv.personalDetails.exclude && pd) {
                  cv.personalDetails = pd;
                  return cv;
                } else {
                  return cv;
                }
              }),
              tap(() => this.cvLoad.emit(true))
            );
          }
        })
      );
    }
    if (
      this.cvResult.customAttributes['anon'] === 'true' &&
      this.authService.canSeeAnonymous
    ) {
      this.pd$ = this.userService
        .getUserPersonalDetails(this.cvResult.customAttributes['ownerId'])
        .pipe(
          tap((personalDetails) => {
            this.pd = personalDetails;
          })
        );
    }
  }

  showProfile(id: string) {
    const link = this.router.serializeUrl(
      this.router.createUrlTree([RoutingModel.link.path, id])
    );
    window.open(link, '_blank');
  }

  toggleDescription() {
    this.viewEntireDescription = !this.viewEntireDescription;
  }

  getStateClass() {
    return this.viewEntireDescription ? 'expanded' : 'collapsed';
  }

  isAdmin() {
    return this.authService.isAdmin;
  }

  canSeePrintableCV() {
    return this.authService.isAdmin && acaConfig.showPrintableCVButton;
    // ||
    // this.authService.isUserPartOfGivenAccountType([
    //   AccountType.industry,
    //   AccountType.university,
    //   AccountType.medLabTech,
    // ])
  }

  showPreview(key: string): void {
    this.authService.showCVPreview(key);
  }

  // getEducationalLevel(): string {
  //   const educationalLevelsSorted = this.cvResult.educationalLevels
  //     .map(educationalLevel =>
  //       this.educationLevelSteps.findIndex(eduLevel => {
  //         return eduLevel === educationalLevel;
  //       })
  //     )
  //     .sort();
  //   if (educationalLevelsSorted.length && this.educationLevelSteps[
  //     educationalLevelsSorted[educationalLevelsSorted.length - 1]
  //   ]) {
  //     return this.educationLevelSteps[
  //       educationalLevelsSorted[educationalLevelsSorted.length - 1]
  //     ].toString();
  //   } else {
  //     return '';
  //   }
  // }

  getRecentExperience(cv: CV): string {
    return this.cvService.getRecentExperience(cv, false);
  }
}
