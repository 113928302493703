import { Component, Input, OnInit } from '@angular/core';
import { ReactionService } from 'app/core/reaction.service';
import { IReaction } from 'app/shared/models/iReaction';
import { ReactionType } from 'app/shared/models/reactionType';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
const get = require('lodash/get');
import { Subject } from 'rxjs';
import { AuthService } from 'app/core/auth.service';

@Component({
  selector: 'app-reaction-base',
  templateUrl: './reaction-base.component.html',
  styleUrls: ['./reaction-base.component.scss'],
})
export class ReactionBaseComponent implements OnInit {
  @Input() uid: string;
  @Input() type: string;
  showEmojis = false;
  reactionCount: number;
  userReaction: ReactionType;
  emojiList: string[];
  reactionTypes = ReactionType;
  reactions$: Observable<IReaction[]>;
  reactionDebouncer$: Observable<ReactionType>;
  isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  private reactionDebouncer = new Subject<ReactionType>();

  constructor(
    private reactionService: ReactionService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.emojiList = this.reactionService.emojiList;
    this.reactions$ = this.reactionService
      .getReactions(this.uid, this.type)
      .pipe(
        tap((reactions) => {
          this.reactionCount = this.reactionService.countReactions(reactions);
          this.userReaction = this.reactionService.userReaction(reactions);
        })
      );

    this.reactionDebouncer$ = this.reactionDebouncer.pipe(
      debounceTime(2500),
      tap((reaction) => {
        if (reaction) {
          this.reactionService.updateReaction(this.uid, this.type, reaction);
        } else {
          this.reactionService.removeReaction(this.uid, this.type);
        }
      })
    );
  }

  get canReact() {
    return this.authService.getCurrentUser()
      ? !!this.authService.currentUserhasPublicProfile
      : false;
  }

  handleReaction(reaction: ReactionType) {
    if (this.isTouch) {
      this.showEmojis = false;
    }
    this.react(reaction);
  }
  instantReaction(reaction: ReactionType) {
    if (this.isTouch) {
      // consider longpress?
      this.showEmojis = !this.showEmojis;
      return;
    }
    this.react(reaction);
  }
  react(reaction: ReactionType) {
    if (!this.canReact) {
      this.authService.notEnoughPermissionOpenProfileBuilder(7000);
      return;
    }
    if (this.userReaction === reaction) {
      this.setUserReaction(null);
      this.reactionDebouncer.next(null);
    } else {
      this.setUserReaction(reaction);
      this.reactionDebouncer.next(reaction);
    }
  }

  toggleShow(toggle: boolean) {
    if (!this.isTouch) {
      this.showEmojis = toggle;
    }
  }

  hasReactions(reaction: string) {
    return get(this.reactionCount, reaction);
  }

  private setUserReaction(reaction: ReactionType | null) {
    this.userReaction = reaction;
  }
}
