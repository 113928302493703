export class Note {
  constructor(
    public authorId: string,
    public authorName: string,
    public text: string,
    public key: string,
    public entityUid: string,
    public created = new Date()
  ) { }
}
