import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/core/auth.service';
import { formatText, hardcodedValues } from 'hardcodedValues';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { StrictEmailValidator } from '@helpers';
import { acaConfig } from 'aca-config';
import { ScientistUser } from 'app/shared/models/user/scientistUser';
import { environment } from 'environments/environment';
import { HelperService } from 'app/core/helper.service';
import { Group } from 'app/groups/models/group';
import { GroupType } from 'app/groups/models/group-type';
import { RoutingModel } from 'app/app.routing-model';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteComponent implements OnInit {
  @Input() group: Group;
  @Input() showExpander = true;
  @Input()
  set showInvite(value) {
    this._showInvite = value;
  }
  _showInvite = false;
  inviteForm: FormGroup;
  showTitle = false;

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.setupForm();
  }

  private setupForm(): void {
    this.inviteForm = this.fb.group({
      email: ['', [Validators.required, StrictEmailValidator]],
      inviteName: '',
      personalText: this.group
        ? formatText(
            hardcodedValues.groupInitialInvitePersonalText,
            this.group.name
          )
        : hardcodedValues.initialInvitePersonalText,
    });
  }

  sendInvite() {
    this.sendInvitation(
      this.email.value,
      this.inviteName.value ? this.inviteName.value : this.email.value,
      this.personalText.value ? this.personalText.value : ''
    );
    this.authService.notEnoughPermission(
      `${hardcodedValues.YourInvitationTo} ${
        this.inviteName.value ? this.inviteName.value : this.email.value
      } ${hardcodedValues.hasBeenSent}!`,
      5000
    );
    this.email.reset();
    this.inviteName.reset();
    // this._showInvite = false;
  }

  private sendInviteEmailFns = this.helperService.createFunctionPromise<
    { body: any; json?: any },
    any
  >(environment.sendInviteEmail);

  get email() {
    return this.inviteForm.get('email');
  }

  get inviteName() {
    return this.inviteForm.get('inviteName');
  }

  get personalText() {
    return this.inviteForm.get('personalText');
  }

  private getOrg(user: MondoUser) {
    let org = '';
    if (this.authService.isScientist) {
      org =
        (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.companyName
          ? `${hardcodedValues.from} ${
              (user as ScientistUser).personalDetails.companyName
            }`
          : '';
    }
    return org;
  }

  private getTitle(user: MondoUser) {
    let title = '';
    if (this.authService.isScientist && this.showTitle) {
      title =
        (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.academicTitle
          ? `${(user as ScientistUser).personalDetails.academicTitle} `
          : '';
    } else {
      // org = (user as IndustryUser).company
      //   ? (user as IndustryUser).company.name
      //   : '';
    }
    return title;
  }

  private formatGroupType(group: Group) {
    const groupType = group.groupType;
    let sliced;

    if (groupType === GroupType.Communities) {
      if (group.ownerGroupId) {
        return hardcodedValues.Subgroup;
      }
      return hardcodedValues.Community;
    }
    if (groupType === GroupType.Ideas) {
      if (group.ownerGroupId) {
        return hardcodedValues.Subgroup;
      }
      return hardcodedValues.Idea;
    } else {
      if (groupType) {
        sliced = groupType.slice(1, -1);
        return hardcodedValues[
          sliced.charAt(0).toUpperCase() + sliced.slice(1)
        ];
      } else {
        return '';
      }
    }
  }

  groupLink(group: Group) {
    switch (group.groupType) {
      case GroupType.Communities:
        return `${RoutingModel.communities.route}/${group.key}/about`;
      case GroupType.Events:
        return `${RoutingModel.events.route}/${group.key}/about`;
      case GroupType.Ideas:
        return `${RoutingModel.ideas.route}/${group.key}/about`;

      default:
        break;
    }
  }

  public async sendInvitation(
    toEmail: string,
    inviteName: string,
    personalText = ''
  ) {
    const currentUser: MondoUser = this.authService.getCurrentUser();
    const body = {
      to: toEmail,
      from: hardcodedValues.senderMail,
      subject: hardcodedValues.invitation,
      templateId: acaConfig.inviteTemplateId,
      dynamic_template_data: {
        to: toEmail,
        displayName: inviteName,
        inviteName: `${hardcodedValues.Dear} ${inviteName},`,
        senderText: `${this.getTitle(currentUser)}${
          currentUser.displayName ? currentUser.displayName : currentUser.email
        } ${this.getOrg(currentUser)} ${hardcodedValues.hasInvitedYouTo} ${
          hardcodedValues.join
        } ${hardcodedValues.academondo}`,
        personalText: personalText ? `"${personalText}"` : '',
        platformLink: environment.academondoUrl,
      },
    };
    if (this.group) {
      body.dynamic_template_data.senderText = `${
        body.dynamic_template_data.senderText
      } ${
        this.group
          ? formatText(
              hardcodedValues.groupSenderText,
              this.group.name,
              this.formatGroupType(this.group),
              hardcodedValues.academondo,
              `${environment.academondoUrl}${this.groupLink(this.group)}`
            )
          : hardcodedValues.initialInvitePersonalText
      }`;
    }

    return this.sendInviteEmailFns({ body: body }).finally(() => {
      this.authService.notEnoughPermission(
        `${hardcodedValues.YourInvitationTo} ${toEmail} ${hardcodedValues.hasBeenSent}!`,
        5000
      );
    });
  }
}
