import { Pipe, PipeTransform } from '@angular/core';
import { hardcodedValues } from 'hardcodedValues';

@Pipe({
  name: 'localedate',
})
export class LocaleDatePipe implements PipeTransform {
  constructor() {}

  transform(value: any, args?: any): any {
    return this.dateFormatter(value);
  }

  private dateFormatter(params) {
    if (params) {
      return new Date(params).toLocaleDateString(hardcodedValues.dateFormat);
    }
  }
}
