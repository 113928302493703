import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-mondo-tile',
  templateUrl: './mondo-tile.component.html',
  styleUrls: ['./mondo-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MondoTileComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() title: string;
  @Input() imageUrl: string;
  @Input() icon: string;
  @Input() defaultCover: boolean;

  constructor() {}

  ngOnInit() {}
}
