import { IFilter } from './iFilter';
export class UserFilter implements IFilter {
  constructor(
    public key: string,
    public value: string,
    public origin:
      | 'interests'
      | 'techniques'
      | 'siteSubtype'
      | 'networkSubtype'
      | 'category0'
      | 'category1'
      | 'category2'
      | 'category3'
      | 'category4'
      | 'category5'
  ) {}
}
