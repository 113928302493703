import { acaConfig } from '../../../aca-config';
import {
  FieldOfInterest,
  Technique,
  Subtypes,
  Category0,
  Category1,
} from '../../../../src/app/shared/models';
import { EducationLevel } from '../../../../src/app/shared/models/cv/education-level';
import { LanguageMultiSelector } from '../../../../src/app/shared/models/languages/language-multiselector';
import { TimePeriod } from '../../../../src/app/stepper/job/model/timePeriod';

export interface SearchFormV2 {
  interests: FieldOfInterest[];
  techniques: Technique[];
  category0: Category0[];
  category1: Category1[];
  category2: Category0[];
  category3: Category0[];
  category4: Category0[];
  category5: Category0[];
  radius: number;
  coordinates: string;
  availability: string;
  fullName: string;
  facilityName: string;
  description: string;
  type: string;
  siteSubtype: Subtypes[];
  networkSubtype: Category0[];
  language: LanguageMultiSelector[];
  // educationalLevel: string | string[];
  educationalLevel: EducationLevel[];
  wantedDaysOfExperience0: number;
  wantedDaysOfExperience1: number;
  wantedDaysOfExperience2: number;
  wantedDaysOfExperience3: number;
  wantedDaysOfExperience4: number;
  wantedDaysOfExperience5: number;
  experience0: number;
  experience1: number;
  experience2: number;
  experience3: number;
  experience4: number;
  experience5: number;
  searchSettings: SearchSettings;
  timePeriod: TimePeriod;
  jobTypes: any[];
  rows: number;
  page: number;
}
export enum SearchSettings {
  hidePast = 'hidePast',
  hideStarted = 'hideStarted',
  showOngoing = 'showOngoing',
  showExpired = 'showExpired',
  show24Hours = 'show24Hours',
  show1Week = 'show1Week',
  show4Weeks = 'show4Weeks',
  showAll = 'showAll',
  customRange = 'customRange',
}

export interface SearchResultV2 {
  id: string;
  interests: Array<string>;
  techniques: Array<string>;
  category0: Array<string>;
  category1: Array<string>;
  category2: Array<string>;
  category3: Array<string>;
  category4: Array<string>;
  category5: Array<string>;
  networkSubtype: Array<string>;
  latlon: string;
  distance: number;
  fullName: string;
  facilityName: string;
  description: string;
  type: SearchableTypes;
  score: number;
  experience0: number;
  experience1: number;
  experience2: number;
  experience3: number;
  experience4: number;
  experience5: number;
  wantedDaysOfExperience0: number;
  wantedDaysOfExperience1: number;
  wantedDaysOfExperience2: number;
  wantedDaysOfExperience3: number;
  wantedDaysOfExperience4: number;
  wantedDaysOfExperience5: number;
  startDate: number;
  endDate: number;
  duration: number;
  customAttributes: any;
  country: string;
  availability: string;
  expiryDate: number;
  status; // invite
}

export enum ResultSortingMode {
  TECHNIQUES_DESCENDING,
  INTERESTS_DESCENDING,
}

export enum SearchableTypes {
  All = 'All',
  Community = 'Community',
  Event = 'Event',
  Idea = 'Idea',
  GroupPublicPost = 'GroupPublicPost',
  User = 'User',
  Special = 'Special',
  Site = 'Site',
  Job = 'Job',
  JobType0 = 'JType0',
  JobType1 = 'JType1',
  JobType2 = 'JType2',
  JobType3 = 'JType3',
  JobType4 = 'JType4',
  JobType5 = 'JType5',
  JobType6 = 'JType6',
  JobType7 = 'JType7',
  JobType8 = 'JType8',
  JobType9 = 'JType9',
}

export interface ResultSortingItem {
  displayText: string;
  mode: ResultSortingMode;
}

export interface SolrResult<T> {
  hits: number;
  totalHits: number;
  elapsed: number;
  results: T;
}

export class SolrResult<T> {
  constructor(
    public results: T,
    public hits = 0,
    public totalHits = 0,
    public elapsed = 0
  ) {}
}

export class SolrQueryV2 {
  constructor(
    public filter: ISolrQueryFilter[] = [], // array using OR
    public conditions: ISolrQueryFilter[] = [], // array using AND
    public spatial: {} = {},
    public rows = acaConfig.maxSearchResults,
    public page = 0
  ) {}
}

export interface ISolrQueryFilter {
  operation: string;
  field: string;
  value: string | string[];
}
