import { CvField } from './cv-field';

export class AdditionalInfo extends CvField {
  public static fromJson({ title, description, exclude }): AdditionalInfo {
    return new AdditionalInfo(title, description, exclude);
  }
  constructor(
    public title = '',
    public description = '',
    public exclude = false
  ) {
    super(exclude);
  }
}
