import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MondoFormBuilder, MondoFormGroup } from 'app/core/mondo-form-builder';
import { SearchableTypes } from 'app/shared/common/search.model';
import {
  Category0,
  Category1,
  FieldOfInterest,
  Technique,
  Upload,
} from 'app/shared/models';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ForumPost } from '../models/forum-post';
import { ForumTopic } from '../models/forum-topic';

@Injectable({
  providedIn: 'root',
})
export class PostFormService {
  private postForm: MondoFormGroup<ForumPost> =
    this.formBuilder.group<ForumPost>({
      type: SearchableTypes.GroupPublicPost,
      body: '',
      email: '',
      groupId: '',
      imageLink: '',
      imageUrl: '',
      created: new FormControl(null),
      lastUpdate: new FormControl(null),
      latestComment: new FormControl(null),
      groupType: new FormControl(null),
      key: '',
      numberOfComments: 0,
      ownerId: '',
      forumId: '',
      phone: '',
      title: '',
      userTags: '',
      taggedUserIds: this.formBuilder.array<string[]>([]),
      topic: this.formBuilder.group<ForumTopic>({
        fieldsOfInterests: this.formBuilder.array<FieldOfInterest[]>([]),
        techniques: this.formBuilder.array<Technique[]>([]),
        category0: this.formBuilder.array<Category0[]>([]),
        category1: this.formBuilder.array<Category1[]>([]),
        category2: this.formBuilder.array<Category0[]>([]),
        category3: this.formBuilder.array<Category0[]>([]),
        category4: this.formBuilder.array<Category0[]>([]),
        category5: this.formBuilder.array<Category0[]>([]),
      }),
      uploads: this.formBuilder.array<Upload[]>([]),
      youtubeId: '',
      teaser: false,
    });
  constructor(private formBuilder: MondoFormBuilder) {}

  public getFormChangeStream(): Observable<ForumPost> {
    return this.postForm.valueChanges.pipe(
      debounceTime(500),
      map((value) => {
        if (this.postForm.valid) {
          return value;
        }
      })
    );
  }

  public getForm(): MondoFormGroup<ForumPost> {
    return this.postForm;
  }

  public patchPost(post: ForumPost): void {
    this.postForm.patchValue({
      body: post.body,
      title: post.title,
      imageLink: post.imageLink,
      imageUrl: post.imageUrl,
      phone: post.phone,
      youtubeId: post.youtubeId,
      userTags: post.userTags,
    });

    if (post.topic) {
      this.topics.setControlSafe(
        (x) => x.fieldsOfInterests,
        this.getFormArray<FieldOfInterest>(post.topic.fieldsOfInterests || [])
      );
      this.topics.setControlSafe(
        (x) => x.techniques,
        this.getFormArray<Technique>(post.topic.techniques || [])
      );
      this.topics.setControlSafe(
        (x) => x.category0,
        this.getFormArray<Category0>(post.topic.category0 || [])
      );
      this.topics.setControlSafe(
        (x) => x.category1,
        this.getFormArray<Category1>(post.topic.category1 || [])
      );
      this.topics.setControlSafe(
        (x) => x.category2,
        this.getFormArray<Category0>(post.topic.category2 || [])
      );
      this.topics.setControlSafe(
        (x) => x.category3,
        this.getFormArray<Category0>(post.topic.category3 || [])
      );
      this.topics.setControlSafe(
        (x) => x.category4,
        this.getFormArray<Category0>(post.topic.category4 || [])
      );
      this.topics.setControlSafe(
        (x) => x.category5,
        this.getFormArray<Category0>(post.topic.category5 || [])
      );
    }

    this.postForm.setControlSafe(
      (x) => x.uploads,
      this.getFormArray<Upload>(post.uploads || [])
    );

    this.postForm.setControlSafe(
      (x) => x.taggedUserIds,
      this.formBuilder.array(post.taggedUserIds || [])
    );
  }

  get body() {
    return this.postForm.getSafeControl((x) => x.body);
  }

  get email() {
    return this.postForm.getSafeControl((x) => x.email);
  }

  get imageLink() {
    return this.postForm.getSafeControl((x) => x.imageLink);
  }

  public getFormArray<T>(list: T[]): any {
    const formGroups = list.map((item) => this.formBuilder.group(item));
    return this.formBuilder.array(formGroups);
  }

  get topics(): MondoFormGroup<ForumTopic> {
    return this.postForm.getSafeGroup<ForumTopic>((x) => x.topic);
  }

  get uploads() {
    return this.postForm.getSafeArray((x) => x.uploads);
  }

  get taggedUserIds() {
    return this.postForm.getSafeArray((x) => x.taggedUserIds);
  }

  public addTaggedUsers(userIds: string[]) {
    if (userIds.length > 0) {
      userIds.forEach((id) => {
        if (!this.hasTaggedUser(id, this.taggedUserIds.value)) {
          this.taggedUserIds.push(this.formBuilder.control(id));
        }
      });
    } else {
      this.taggedUserIds.clear();
    }
  }

  private hasTaggedUser(
    userId: string,
    currentlyTaggedUserIds: string[]
  ): boolean {
    return (currentlyTaggedUserIds || []).includes(userId);
  }

  public addUpload(upload: Upload) {
    this.uploads.push(this.formBuilder.control(upload));
  }
}
