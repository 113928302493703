import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { JobInfo } from 'app/stepper/job/model/jobInfo';

@Component({
  selector: 'app-job-viewer-about',
  templateUrl: './job-viewer-about.component.html',
  styleUrls: ['./job-viewer-about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobViewerAboutComponent implements OnInit {
  @Input() jobInfo: JobInfo;

  constructor() {}

  ngOnInit() {}

  get position(): string {
    return this.getStringOrNull(this.jobInfo.position);
  }

  get teaser(): string {
    return this.getStringOrNull(this.jobInfo.teaser);
  }

  get description(): string {
    return this.getStringOrNull(this.jobInfo.description);
  }

  get aboutCompany(): string {
    return this.getStringOrNull(this.jobInfo.about);
  }

  get youtubeId(): string {
    return this.getStringOrNull(this.jobInfo.youtubeId);
  }

  get imageLink(): string {
    return this.getStringOrNull(this.jobInfo.imageLink);
  }

  get imageUrl(): string {
    return this.getStringOrNull(this.jobInfo.imageUrl);
  }

  private getStringOrNull(string: string): string {
    return string && string !== '' ? string : null;
  }
}
