export class Country {
  public static fromJson({id, name, code, region}) {
    return new Country(id, name, code, region);
  }

  constructor(
    public id = '',
    public name = '',
    public code = '',
    public region = '',
  ) { }
}
