import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import 'hammerjs';
import { MultiSelectorComponent } from './components/multi-selector/multi-selector.component';
import { ScrollableDirective, DebounceClickDirective } from './directives';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AddressSelectorComponent } from './components/address-selector/address-selector.component';
import { MapToIterablePipe } from './pipes/map-to-iterable.pipe';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { FilterUsersPipe } from './pipes/filter-users.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { IconTitleComponent } from './components/icon-title/icon-title.component';
import { ViewerLanguagesComponent } from '../stepper/shared/components/viewer-languages/viewer-languages.component';
import { AcaRangeComponent } from './components/aca-range/aca-range.component';
import { DropZoneDirective } from './directives/dropzone';
import { UserStatusComponent } from './components/user-status/user-status.component';
import { ScoringBarComponent } from './components/scoring-bar/scoring-bar.component';
import { PubmedUrlPipe } from './pipes/pubmed-url.pipe';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { JobApplicationComponent } from './components/ac/job-application/job-application.component';
import { JobApplicationHelperComponent } from './components/ac/job-application-helper/job-application-helper.component';
import { InviteComponent } from './components/invite/invite.component';
import { SiteViewerComponent } from './components/site-viewer/site-viewer.component';
import { SiteViewerCoverComponent } from './components/site-viewer/site-viewer-cover/site-viewer-cover.component';
import { SiteViewerHeaderComponent } from './components/site-viewer/site-viewer-header/site-viewer-header.component';
import { SiteViewerContentComponent } from './components/site-viewer/site-viewer-content/site-viewer-content.component';
import { SiteViewerTechniqueComponent } from './components/site-viewer/site-viewer-technique/site-viewer-technique.component';
import { NewActionBarComponent } from './components/new-action-bar/new-action-bar.component';
import { MatTreeComponent } from './components/mat-tree/mat-tree.component';
import { PlaceCardComponent } from './components/place-card/place-card.component';
import { PlaceCardHeaderComponent } from './components/place-card/place-card-header/place-card-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AccountPermissionPipe } from './pipes/account-permission.pipe';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RelatedJobsComponent } from './components/related-jobs/related-jobs.component';
import { JobViewerComponent } from './components/job-viewer/job-viewer.component';
import { JobViewerHeaderComponent } from './components/job-viewer/job-viewer-header/job-viewer-header.component';
import { JobViewerAboutComponent } from './components/job-viewer/job-viewer-about/job-viewer-about.component';
import { JobViewerContactComponent } from './components/job-viewer/job-viewer-contact/job-viewer-contact.component';
import { JobViewerEmploymentPeriodComponent } from './components/job-viewer/job-viewer-employment-period/job-viewer-employment-period.component';
import { JobViewerQualificationsComponent } from './components/job-viewer/job-viewer-qualifications/job-viewer-qualifications.component';
import { FilterCvsPipe } from './pipes/filter-cvs.pipe';
// import { ParseDAO } from './common/parseDAO';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { AngularMaterialSharedModule } from 'app/angular-material-shared/angular-material-shared.module';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';
import { SharedDirectivesModule } from 'app/shared-directives/shared-directives.module';
import { LeaveCvDialogComponent } from './components/leave-cv-dialog/leave-cv-dialog.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SpecificListMapComponent } from './components/specific-list-map/specific-list-map.component';
import { SearchMapComponent } from './components/search-map/search-map.component';
import { PreviewCvComponent } from './components/preview-cv/preview-cv.component';
import { PreviewSiteComponent } from './components/preview-site/preview-site.component';
import { CVViewerComponent } from './components/cv-viewer/cv-viewer.component';
import { CvViewPublicationsComponent } from './components/cv-view-publications/cv-view-publications.component';
import { CvViewWorkshopsComponent } from './components/cv-view-workshops/cv-view-workshops.component';
import { CvViewTeachingExpComponent } from './components/cv-view-teaching-exp/cv-view-teaching-exp.component';
import { CvViewEducationWorkExpComponent } from './components/cv-view-education-work-exp/cv-view-education-work-exp.component';
import { CvViewSkillsWrapComponent } from './components/cv-view-skills-wrap/cv-view-skills-wrap.component';
import { PublishDialogComponent } from './components/publish-dialog/publish-dialog.component';
import { JobResultViewerComponent } from './components/job-result-viewer/job-result-viewer.component';
import { JobPreviewDialogComponent } from './components/job-preview-dialog/job-preview-dialog.component';
import { JobApplicationSimpleComponent } from './components/ac/job-application-simple/job-application-simple.component';
import { GroupRoleViewerComponent } from './components/group-role-viewer/group-role-viewer.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialSharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SharedPipesModule,
    SharedUiModule,
    SharedDirectivesModule,
    NgxSliderModule,
  ],
  exports: [
    CommonModule,
    AngularMaterialSharedModule,
    FormsModule,
    ReactiveFormsModule,
    // MapToIterablePipe,
    FilterUsersPipe,
    FilterCvsPipe,
    ReversePipe,
    PubmedUrlPipe,
    AccountPermissionPipe,
    PageNotFoundComponent,
    FlexLayoutModule,
    PerfectScrollbarModule,
    MultiSelectorComponent,
    ScrollableDirective,
    ReactiveFormsModule,
    AddressSelectorComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    IconTitleComponent,
    ViewerLanguagesComponent,
    DebounceClickDirective,
    AcaRangeComponent,
    RouterModule,
    UserStatusComponent,
    // ScoringBarComponent,
    UploadTaskComponent,
    UploaderComponent,
    JobApplicationComponent,
    JobApplicationSimpleComponent,
    JobApplicationHelperComponent,
    InviteComponent,
    SiteViewerComponent,
    SiteViewerCoverComponent,
    SiteViewerHeaderComponent,
    SiteViewerContentComponent,
    SiteViewerTechniqueComponent,
    NewActionBarComponent,
    MatTreeComponent,
    PlaceCardComponent,
    PlaceCardHeaderComponent,
    FooterComponent,
    RelatedJobsComponent,
    JobViewerComponent,
    NgxSliderModule,
    SpecificListMapComponent,
    SearchMapComponent,
    PreviewCvComponent,
    PreviewSiteComponent,
    CVViewerComponent,
    PublishDialogComponent,
    JobResultViewerComponent,
    JobPreviewDialogComponent,
  ],
  declarations: [
    PageNotFoundComponent,
    MultiSelectorComponent,
    ScrollableDirective,
    AddressSelectorComponent,
    MapToIterablePipe,
    DateRangePickerComponent,
    DatePickerComponent,
    FilterUsersPipe,
    FilterCvsPipe,
    ReversePipe,
    IconTitleComponent,
    ViewerLanguagesComponent,
    DebounceClickDirective,
    AcaRangeComponent,
    DropZoneDirective,
    UserStatusComponent,
    ScoringBarComponent,
    PubmedUrlPipe,
    UploadTaskComponent,
    UploaderComponent,
    JobApplicationComponent,
    JobApplicationSimpleComponent,
    JobApplicationHelperComponent,
    InviteComponent,
    SiteViewerComponent,
    SiteViewerCoverComponent,
    SiteViewerHeaderComponent,
    SiteViewerContentComponent,
    SiteViewerTechniqueComponent,
    NewActionBarComponent,
    MatTreeComponent,
    PlaceCardComponent,
    PlaceCardHeaderComponent,
    FooterComponent,
    AccountPermissionPipe,
    RelatedJobsComponent,
    JobViewerComponent,
    JobViewerHeaderComponent,
    JobViewerAboutComponent,
    JobViewerContactComponent,
    JobViewerEmploymentPeriodComponent,
    JobViewerQualificationsComponent,
    LeaveCvDialogComponent,
    SpecificListMapComponent,
    SearchMapComponent,
    PreviewCvComponent,
    PreviewSiteComponent,
    CVViewerComponent,
    CvViewPublicationsComponent,
    CvViewWorkshopsComponent,
    CvViewTeachingExpComponent,
    CvViewEducationWorkExpComponent,
    CvViewSkillsWrapComponent,
    PublishDialogComponent,
    JobResultViewerComponent,
    JobPreviewDialogComponent,
    GroupRoleViewerComponent,
  ],
  entryComponents: [
    JobApplicationComponent,
    JobApplicationSimpleComponent,
    JobApplicationHelperComponent,
    JobViewerComponent,
    LeaveCvDialogComponent,
    PreviewCvComponent,
    PreviewSiteComponent,
    PublishDialogComponent,
    JobPreviewDialogComponent,
  ],
  providers: [
    // ParseDAO,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class SharedModule {}
