import { Injectable } from '@angular/core';
import {
  AngularFireList,
  PathReference,
  QueryFn,
} from '@angular/fire/database';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IDatabase } from './IDatabase';
import { FirebaseDAO } from './firebaseDAO';

@Injectable()
export class DAO implements IDatabase {
  constructor(private firebaseDriver: FirebaseDAO) {}

  public list<T>(
    table: PathReference,
    cb?: QueryFn,
    database = currentDatabase.firebase
  ): AngularFireList<T> {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver.list<T>(table, cb);
      // case currentDatabase.parse:
      //   return this.parseDriver.list<T>(table, cb);
      default:
        break;
    }
  }

  public list$<T>(
    table: PathReference,
    cb?: QueryFn,
    database = currentDatabase.firebase
  ): Observable<T[]> {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver.list<T>(table, cb).valueChanges();
      // case currentDatabase.parse:
      //   return this.parseDriver.list<T>(table, cb);
      default:
        break;
    }
  }

  public listData$<T>(
    table: PathReference,
    cb?: QueryFn,
    database = currentDatabase.firebase
  ): Observable<{ key: string; data: T }[]> {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver
          .list<T>(table, cb)
          .snapshotChanges()
          .pipe(
            map((snaps) => {
              return snaps.map((snap) => {
                const data = snap.payload.val();
                const key = snap.payload.key;
                return { key, data };
              });
            })
          );
      // case currentDatabase.parse:
      //   return this.parseDriver.list<T>(table, cb);
      default:
        break;
    }
  }

  public listKey$<T>(
    table: PathReference,
    cb?: QueryFn,
    database = currentDatabase.firebase
  ): Observable<string[]> {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver
          .list<T>(table, cb)
          .snapshotChanges()
          .pipe(
            map((snaps) => {
              return snaps.map((snap) => {
                const key = snap.payload.key;
                return key;
              });
            })
          );
      // case currentDatabase.parse:
      //   return this.parseDriver.list<T>(table, cb);
      default:
        break;
    }
  }

  public createPushId(database = currentDatabase.firebase) {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver.createPushId();
      // case currentDatabase.parse:
      //   return this.parseDriver.list<T>(table, cb);
      default:
        break;
    }
  }

  public ref(
    table?: string,
    database = currentDatabase.firebase
  ): firebase.database.Reference {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver.ref(table);
      // case currentDatabase.parse:
      //   return this.parseDriver.list<T>(table, cb);
      default:
        break;
    }
  }

  public object<T>(table: PathReference, database = currentDatabase.firebase) {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver.object<T>(table);
      // case currentDatabase.parse:
      //   return this.parseDriver.object<T>(table);
      default:
        break;
    }
  }

  public object$<T>(
    table: PathReference,
    database = currentDatabase.firebase
  ): Observable<T> {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver
          .object<T>(table)
          .valueChanges()
          .pipe(
            catchError((err) => {
              console.error('DAO - ', err);
              return of(undefined);
            })
          );
      // case currentDatabase.parse:
      //   return this.parseDriver.object<T>(table);
      default:
        break;
    }
  }

  public getAll<T>(table: PathReference, database = currentDatabase.firebase) {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver.getAll<T>(table);
      // case currentDatabase.parse:
      //   return this.parseDriver.getAll<T>(table);
      default:
        break;
    }
  }

  public getById<T>(
    id: string,
    table: PathReference,
    database = currentDatabase.firebase
  ) {
    switch (database) {
      case currentDatabase.firebase:
        return this.firebaseDriver.getById<T>(id, table);
      // case currentDatabase.parse:
      //   return this.parseDriver.getById<T>(id, table);
      default:
        break;
    }
  }
}

export const enum currentDatabase {
  firebase = 'firebase',
  parse = 'parse',
}
