import { Injectable } from '@angular/core';
import { DataConstants } from 'app/shared/consts/dataConstants';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DAO } from 'app/shared-services/db-access/dao';
import { HelperService } from 'app/core/helper.service';
import { NotificationData } from '../models/notification-data';
import { NotificationType } from '../models/notification-type';
import { GroupMemberNotificationSubType } from '../models/notification-sub-type';
import { AuthService } from 'app/core/auth.service';
import { GroupType } from 'app/groups/models/group-type';
import { Member } from 'app/groups/models/member';
import { GroupRelation } from 'app/groups/models/group-relation';
import { UserService } from 'app/core/user.service';
import { GroupRoleService } from 'app/core/group-role.service';
import { NotificationStatus } from '../models/notification-status';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  public messaging: firebase.messaging.Messaging;
  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();

  constructor(
    private dao: DAO,
    private authService: AuthService,
    private userService: UserService,
    private groupRoleService: GroupRoleService,
    private fnsHelper: HelperService
  ) {
    if (firebase.messaging.isSupported()) {
      this.messaging = firebase.messaging();
    } else {
      console.error(`This browser doesn't support web push notifications.`);
    }
  }

  sendCloudMessageFN = this.fnsHelper.createFunctionPromise<
    { notificationData: NotificationData },
    any
  >('sendCloudMessageOnCall');

  sendCustomCloudMessageFN = this.fnsHelper.createFunctionPromise<any>(
    'customCloudMessageOnCall'
  );

  private updateToken(token: string) {
    const user = this.authService.getCurrentUser();
    if (!user) {
      return;
    }
    const data = { [user.uid]: token };
    return this.dao.object(DataConstants.NOTIFICATIONS_TOKENS).update(data);
  }

  public addNewNotificationToQueue(notificationData: NotificationData) {
    notificationData.created = new Date().getTime();
    notificationData.status = NotificationStatus.New;
    notificationData.id = this.dao.createPushId();
    return this.dao
      .object(DataConstants.NOTIFICATION_QUEUE + notificationData.id)
      .set(notificationData);
  }

  async removeToken() {
    return this.dao
      .object(
        DataConstants.NOTIFICATIONS_TOKENS +
          this.authService.getCurrentUser().uid
      )
      .remove();
  }

  fcmToken$() {
    if (this.messaging) {
      return this.dao
        .object(
          DataConstants.NOTIFICATIONS_TOKENS +
            this.authService.getCurrentUser().uid
        )
        .valueChanges() as Observable<string>;
    } else {
      return of('');
    }
  }

  async getPermission() {
    if (this.messaging) {
      return Notification.requestPermission()
        .then((permissionRequested) => {
          // console.log('Notification permission: ', permissionRequested);
          return this.messaging.getToken();
        })
        .then((token) => {
          this.updateToken(token);
          return true;
        })
        .catch((err) => {
          // console.error('Unable to get permission to send push messages.', err);
          this.removeToken();
          return false;
        });
    }
  }

  receiveMessage() {
    if (this.messaging) {
      this.messaging.onMessage((payload) => {
        console.log('onMessage received. ', payload);
        this.playNotificationSound(
          payload.notification,
          'assets/audio/push-notification.mp3'
        );
        this.messageSource.next(payload);
      });
    }
  }

  playNotificationSound(notification, soundUrl?: string, vibrate = false) {
    const { title, body, imageUrl } = notification;
    if (soundUrl) {
      if (vibrate) {
        this.vibrate([100, 200, 100]);
      }
      const audio = new Audio();
      audio.src = soundUrl;
      audio.load();
      audio
        .play()
        .then(() =>
          this.openSnackBar(
            `${body}`,
            imageUrl,
            title,
            '',
            'aca-push-notification',
            7000
          )
        );
    } else {
      this.openSnackBar(
        `${body}`,
        imageUrl,
        title,
        '',
        'aca-push-notification',
        7000
      );
    }
  }

  vibrate(pattern: VibratePattern = 100) {
    if (!window) {
      return;
    }

    if (!window.navigator) {
      return;
    }

    if (!window.navigator.vibrate) {
      return;
    }

    window.navigator.vibrate(pattern);
  }

  openSnackBar(
    message: string,
    image: string,
    title: '',
    action: '',
    className = '',
    duration = 1000
  ) {
    this.authService.imgSnackbar(
      message,
      image,
      title,
      action,
      duration,
      className
    );
  }

  spamSimon(userId: string) {
    const notificationData = {
      title: 'Velkommen til Push!',
      message: 'Hej Simon din gamle svinger',
      fromUserId: userId,
      toUserId: 'TdO9Vjh3sjS0wbWqaDmtI1McEjC2',
      link: `/_communities/-MdJr7pcaF41ammYFtSN/about`,
      notificationType: NotificationType.community,
      notificationSubType: GroupMemberNotificationSubType.invited,
      extraData: {
        groupId: '-MdJr7pcaF41ammYFtSN',
        groupType: '_communities',
      },
    } as NotificationData;
    // console.log(notificationData);

    return (
      this.sendCloudMessageFN({ notificationData: notificationData })
        // .then((response) => console.log(response))
        .catch((err) => {
          Promise.reject(`${err.statusCode} error: ${JSON.stringify(err)}`);
        })
    );
  }
  spamGroup(notificationData: NotificationData) {
    return (
      this.sendCloudMessageFN({ notificationData: notificationData })
        // .then((response) => console.log(response))
        .catch((err) => {
          Promise.reject(`${err.statusCode} error: ${JSON.stringify(err)}`);
        })
    );
  }

  public getAllMembersWithFcmTokens$(
    groupId: string,
    groupType: GroupType,
    includeSelf = false
  ): Observable<Member[]> {
    return this.dao
      .object$<{ [userId: string]: GroupRelation }>(
        `${DataConstants.MEMBERSHIPS}${groupType}/${groupId}`
      )
      .pipe(
        switchMap((membersMap) => {
          const memberUserIds = Object.keys(membersMap).filter((userId) =>
            includeSelf
              ? true
              : userId !== this.authService.getCurrentUser().uid
          );

          // For each userId, fetch user data, role, and fcmToken
          const memberObservables = memberUserIds.map((userId) => {
            return combineLatest([
              // Fetch user data
              this.userService.getUserByUid$(userId),
              // Fetch user's role in the group
              this.groupRoleService
                .getRole$(userId, groupType, groupId)
                .pipe(take(1)),
              // Fetch fcmToken
              this.dao
                .object$<string>(
                  `${DataConstants.NOTIFICATIONS_TOKENS}${userId}`
                )
                .pipe(take(1)),
            ]).pipe(
              map(([user, role, fcmToken]) => {
                if (user) {
                  return {
                    user: {
                      ...user,
                      fcmToken: fcmToken || null,
                    },
                    relation: membersMap[userId] as GroupRelation,
                    role: role,
                  } as Member;
                } else {
                  return null;
                }
              })
            );
          });

          // Combine all member observables
          return combineLatest(memberObservables).pipe(
            map((membersWithTokens) =>
              membersWithTokens.filter((member) => member !== null)
            )
          );
        })
      );
  }
}
