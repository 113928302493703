import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RoutingModel } from 'app/app.routing-model';
import { RoutingService } from 'app/core/routing.service';
import { IFilter } from 'app/shared/models';
import { hardcodedValues } from 'hardcodedValues';

@Component({
  selector: 'app-i-filter-chip-list',
  templateUrl: './i-filter-chip-list.component.html',
  styleUrls: ['./i-filter-chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IFilterChipListComponent {
  @Input() filters: IFilter[];
  @Input() showAll = false;
  @Input() clickableTags = true;
  @Input() limit = 4;
  @Input() showAsHashTags = false;
  @Input() showAsChips = true;
  @Input() showAsBullets = false;

  expanded = false;

  constructor(private routingService: RoutingService) {}

  navigateToSearchWithState(e, filter: IFilter) {
    e.preventDefault();
    if (this.clickableTags && filter.origin) {
      this.routingService.navigateWithNoReuse(
        [RoutingModel.scienceMatch.route],
        {
          state: { data: filter },
        }
      );
    }
  }

  getTooltip(origin: string) {
    if (origin === 'interests') {
      return hardcodedValues.Foi;
    }
    if (origin === 'techniques') {
      return hardcodedValues.Tech;
    }
    if (origin === 'networkSubtype') {
      return hardcodedValues.NetworkSubtype;
    }
    if (origin === 'category0') {
      return hardcodedValues.Category0;
    }
    if (origin === 'category1') {
      return hardcodedValues.Category1;
    }
    if (origin === 'category2') {
      return hardcodedValues.Category2;
    }
    if (origin === 'category3') {
      return hardcodedValues.Category3;
    }
    if (origin === 'category4') {
      return hardcodedValues.Category4;
    }
    if (origin === 'category5') {
      return hardcodedValues.Category5;
    }
  }

  filterByOrigin(origin: string): IFilter[] {
    return this.filters.filter((filter) => filter.origin === origin);
  }

  trackByFn(index, item) {
    return item.key;
  }
}
