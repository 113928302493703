export enum NotificationHandlerFunctions {
  markAllAsRead = 'markAllAsRead',
  markAllAsSeen = 'markAllAsSeen',
  markAsRead = 'markAsRead',
}

export interface NotificationFunctionData {
  functionHandle: NotificationHandlerFunctions;
  notificationId?: string;
}
