import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { isObject } from 'app/shared/common/acaLodash';
import { DataConstants } from 'app/shared/consts/dataConstants';
import {
  Category0,
  Category1,
  FieldOfInterest,
  IFilter,
  Technique,
  UserFilter,
} from 'app/shared/models';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Forum, IForum } from '../models/forum';
import { ForumPost } from '../models/forum-post';

@Injectable({
  providedIn: 'root',
})
export class ForumService {
  constructor(private dao: DAO, private authService: AuthService) {}

  public async createForum(
    onlyOwnerCanCreatePosts: boolean,
    onlyOwnerCanCreateComments: boolean
  ): Promise<Forum> {
    const forumId = this.dao.createPushId();
    const now = new Date();
    const forum = new Forum(
      forumId,
      this.authService.getCurrentUser().uid,
      now,
      onlyOwnerCanCreatePosts,
      onlyOwnerCanCreateComments
    );

    await this.dao
      .ref(`${DataConstants.FORUMS}${forumId}`)
      .set(Forum.toJson(forum));
    return forum;
  }

  public async updateForum(forum: Forum): Promise<Forum> {
    await this.dao
      .ref(`${DataConstants.FORUMS}${forum.id}`)
      .update(Forum.toJson(forum));
    return forum;
  }

  public getForum(forumId: string): Promise<Forum | undefined> {
    return this.getForum$(forumId).pipe(take(1)).toPromise();
  }

  public async deletePost(postId: string, forumId: string) {
    return Promise.all([
      this.dao.ref(`${DataConstants.FORUM_POSTS}${forumId}/${postId}`).remove(),
      this.dao.ref(`${DataConstants.POSTS}${postId}`).remove(),
      this.dao
        .ref(
          `${DataConstants.USER_POSTS}${
            this.authService.getCurrentUser().uid
          }/${postId}`
        )
        .remove(),
    ]);
  }

  public getForum$(forumId: string): Observable<Forum | undefined> {
    return this.dao
      .object$<IForum<number>>(DataConstants.FORUMS + forumId)
      .pipe(
        map((forum) => (isObject(forum) ? Forum.fromJson(forum) : undefined))
      );
  }

  public getFilters(entity: ForumPost): IFilter[] {
    if (!entity || !entity.topic) {
      return [];
    }
    let foiList = [];
    let cat0List = [];
    let cat1List = [];
    let cat2List = [];
    let cat3List = [];
    let cat4List = [];
    let cat5List = [];
    let techList = [];

    if (entity.topic.fieldsOfInterests) {
      foiList = entity.topic.fieldsOfInterests.map((i: FieldOfInterest) => {
        return new UserFilter(i.uid, i.name, 'interests');
      });
    }

    if (entity.topic.category0) {
      cat0List = entity.topic.category0.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category0');
      });
    }
    if (entity.topic.category1) {
      cat1List = entity.topic.category1.map((i: Category1) => {
        return new UserFilter(i.uid, i.name, 'category1');
      });
    }
    if (entity.topic.category2) {
      cat2List = entity.topic.category2.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category2');
      });
    }
    if (entity.topic.category3) {
      cat3List = entity.topic.category3.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category3');
      });
    }
    if (entity.topic.category4) {
      cat4List = entity.topic.category4.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category4');
      });
    }
    if (entity.topic.category5) {
      cat5List = entity.topic.category5.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category5');
      });
    }
    if (entity.topic.techniques) {
      techList = entity.topic.techniques.map((i: Technique) => {
        return new UserFilter(i.id, i.name, 'techniques');
      });
    }
    return [
      ...foiList,
      ...techList,
      ...cat0List,
      ...cat1List,
      ...cat2List,
      ...cat3List,
      ...cat4List,
      ...cat5List,
    ];
  }
}
