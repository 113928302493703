import { Injectable } from '@angular/core';
import { RoutingModel } from 'app/app.routing-model';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { TimeService } from 'app/core/time.service';
import { ForumService } from 'app/forum/services/forum.service';
import { Group } from 'app/groups/models/group';
import { GroupRelation } from 'app/groups/models/group-relation';
import { GroupType } from 'app/groups/models/group-type';
import { GroupPermissions } from 'app/groups/models/groupPermissions';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';
import { GroupTags } from 'app/groups/models/groupTags';
import { GroupService } from 'app/groups/services/group.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { AttendanceService } from 'app/shared-services/membership/attendance.service';
import { MembershipService } from 'app/shared-services/membership/membership.service';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';
import { TimePeriod } from 'app/stepper/job/model/timePeriod';
import { formatText, hardcodedValues } from 'hardcodedValues';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { Attendance } from '../groups/events/models/attendance';
import { Event, IEvent } from '../groups/events/models/event';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  userId: string;
  pinnedEvent: string;
  public eventRelation: Map<string, GroupRelation>;
  eventMap: Map<string, Event> = new Map();

  private events: Map<string, Event> = new Map();
  private events$ = new BehaviorSubject<Event[]>([]);
  public currentPublicEvents: Map<string, Event> = new Map();
  public currentAttendingEvents: Map<string, Event> = new Map();
  public currentInterestEvents: Map<string, Event> = new Map();
  public currentMaybeEvents: Map<string, Event> = new Map();
  public currentNoReplyEvents: Map<string, Event> = new Map();
  public currentOwnerEvents: Map<string, Event> = new Map();
  public currentAdminEvents: Map<string, Event> = new Map();
  public currentModetorEvents: Map<string, Event> = new Map();

  constructor(
    private authService: AuthService,
    private forumService: ForumService,
    private routingService: RoutingService,
    private groupService: GroupService,
    private dao: DAO,
    private attendanceService: AttendanceService,
    private membershipService: MembershipService
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });

    this.getPinnedEventKey$()
      .pipe(
        tap((eventKey) => {
          this.pinnedEvent = eventKey;
        })
      )
      .subscribe();

    this.membershipService
      .getGroupRelations$(GroupType.Events)
      .pipe(
        tap((res) => {
          this.eventRelation = res;

          this.getPublicEvents$()
            .pipe(
              tap((events) =>
                this.checkCurrentEvents(events, this.currentPublicEvents)
              ),
              tap((events) => this.handleEvents(events))
            )
            .subscribe();

          this.getEventsByAttendance$(Attendance.Attending)
            .pipe(
              tap((events) =>
                this.checkCurrentEvents(events, this.currentAttendingEvents)
              ),
              tap((events) => this.handleEvents(events))
            )
            .subscribe();

          this.getEventsByAttendance$(Attendance.Interested)
            .pipe(
              tap((events) =>
                this.checkCurrentEvents(events, this.currentInterestEvents)
              ),
              tap((events) => this.handleEvents(events))
            )
            .subscribe();
          this.getEventsByAttendance$(Attendance.Maybe)
            .pipe(
              tap((events) =>
                this.checkCurrentEvents(events, this.currentMaybeEvents)
              ),
              tap((events) => this.handleEvents(events))
            )
            .subscribe();
          this.getEventsByAttendance$(Attendance.NoReply)
            .pipe(
              tap((events) =>
                this.checkCurrentEvents(events, this.currentNoReplyEvents)
              ),
              tap((events) => this.handleEvents(events))
            )
            .subscribe();

          this.getEvents$(GroupRelation.Owner)
            .pipe(
              tap((events) =>
                this.checkCurrentEvents(events, this.currentOwnerEvents)
              ),
              tap((events) => this.handleEvents(events))
            )
            .subscribe();
          this.getEvents$(GroupRelation.Administrator)
            .pipe(
              tap((events) =>
                this.checkCurrentEvents(events, this.currentAdminEvents)
              ),
              tap((events) => this.handleEvents(events))
            )
            .subscribe();
          this.getEvents$(GroupRelation.Moderator)
            .pipe(
              tap((events) =>
                this.checkCurrentEvents(events, this.currentModetorEvents)
              ),
              tap((events) => this.handleEvents(events))
            )
            .subscribe();
        })
      )
      .subscribe();
  }
  checkCurrentEvents(events: Event[], currentEvents: Map<string, Event>) {
    const eventKeys = events.map((event) => event.key);
    const keysToRemove = [];
    currentEvents.forEach((event, key) => {
      if (!eventKeys.includes(key)) {
        keysToRemove.push(key);
      }
    });
    keysToRemove.forEach((key) => {
      currentEvents.delete(key);
      this.events.delete(key);
    });
    events.map((event) => {
      currentEvents.set(event.key, event);
    });
  }

  handleEvents(events: Array<Event>) {
    events
      .filter((event) => {
        return (
          (this.eventRelation.has(event.key) &&
            this.eventRelation.get(event.key) &&
            this.eventRelation.get(event.key) !== GroupRelation.Requested) ||
          event.privacy === GroupPrivacy.OpenPrivate
        );
      })
      .filter(
        (event) =>
          event &&
          event.eventPeriod &&
          event.eventPeriod.startDate &&
          event.eventPeriod.endDate
      )
      .filter(
        (event) =>
          new Date(event.eventPeriod.endDate).getTime() > new Date().getTime()
      )
      .forEach(async (event) => {
        if (event && event.key) {
          this.events.set(event.key, event);
        }
      });
    this.events$.next(Array.from(this.events.values()));
  }

  getEvents(): Observable<Event[]> {
    return this.events$.pipe(
      map((events) => {
        return events.map((event) => {
          event['pinnedOrder'] = event.key === this.pinnedEvent ? 1 : 0;
          return event;
        });
      }),
      map((results) =>
        results.sort((a, b) => {
          return (
            a.eventPeriod.startDate.getTime() -
            b.eventPeriod.startDate.getTime()
          );
        })
      ),
      map((results) =>
        results.sort((a, b) => b['pinnedOrder'] - a['pinnedOrder'])
      )
    );
  }

  getPinnedEventKey$(): Observable<string> {
    return this.dao.object$<string>(
      DataConstants.CONFIG_SYSTEM + 'pinnedEvent'
    );
  }

  async setPinnedEventKey(eventKey: string) {
    return this.dao
      .ref(DataConstants.CONFIG_SYSTEM + 'pinnedEvent')
      .set(eventKey);
  }

  public getPublicEvents$(): Observable<Event[]> {
    return this.dao
      .list$(DataConstants.PUBLIC_GROUPS + GroupType.Events)
      .pipe(
        map((events) =>
          events.map((event) => Event.fromJson(event as IEvent<number>))
        )
      );
  }

  public canCreateNewDraftEvent(): boolean {
    return this.authService.canCreateDraftEvents;
  }

  public getEventsByAttendance$(
    attendance: Attendance
  ): Observable<Array<Event>> {
    return this.attendanceService.getGroupAttendance$(GroupType.Events).pipe(
      mergeMap((eventIds) =>
        combineLatest(
          Array.from(eventIds.keys())
            .filter((id) => eventIds.get(id) === attendance)
            .map((eventId) => this.getEvent$(eventId))
        )
      ),
      map((events) => {
        return events.filter(
          (event) =>
            event && event.eventPeriod.startDate && event.eventPeriod.endDate
        );
      }),
      map((events) => events.filter((event) => event.ownerId !== this.userId)),

      map((results) =>
        results.sort((a, b) => {
          return (
            a.eventPeriod.startDate.getTime() -
            b.eventPeriod.startDate.getTime()
          );
        })
      ),
      shareReplay(1)
    );
  }

  public getEvents$(relation: GroupRelation): Observable<Event[]> {
    return this.groupService.getGroups$(relation, GroupType.Events).pipe(
      map((groups) =>
        groups.filter((group) => {
          return group && group.key;
        })
      ),
      map((groups) => groups as Event[])
    );
  }

  public getEvent$(
    key: string,
    relation?: GroupRelation
  ): Observable<Event | undefined> {
    return this.groupService
      .getGroup$(key, GroupType.Events, relation)
      .pipe(map((groups) => groups as Event));
  }

  public getGroupEvents$(
    groupKey: string,
    lastKey?: string,
    batch = 999
  ): Observable<Array<Event>> {
    return this.dao
      .listData$(`${DataConstants.GROUP_EVENTS}${groupKey}`, (ref) => {
        if (lastKey) {
          return ref.orderByKey().limitToLast(batch).endAt(lastKey);
        } else {
          return ref.orderByKey().limitToLast(batch);
        }
      })
      .pipe(
        mergeMap((eventIds) =>
          combineLatest(
            eventIds.reverse().map((eventId) => this.getEvent$(eventId.key))
          )
        )
      );
  }

  public async isDraftPublished(key: string): Promise<boolean> {
    return this.groupService.isDraftPublished(key, GroupType.Events);
  }

  public updateDraftEvent(event: Event): Promise<void> {
    return this.groupService.updateDraftGroup(event);
  }

  async publishEvent(event: Event): Promise<void> {
    if (event.ownerGroupId && this.minReqForPublish(event)) {
      const isAdminOrGroupOwner = await this.membershipService
        .hasRelations$(event.ownerGroupId, event.ownerGroupType, [
          GroupRelation.Administrator,
          GroupRelation.Owner,
        ])
        .pipe(take(1))
        .toPromise();

      const ownerGroup = await this.groupService.getGroup(
        event.ownerGroupId,
        event.ownerGroupType
      );
      const membersCanCreateEvents =
        ownerGroup &&
        ownerGroup.permissions &&
        ownerGroup.permissions.membersCanCreateEvents;
      if (isAdminOrGroupOwner || membersCanCreateEvents) {
        return this.groupService.publishGroup(event.key, GroupType.Events);
      } else if (!isAdminOrGroupOwner || !membersCanCreateEvents) {
        return this.authService.notEnoughPermission(
          'whoCanCreateEventsMessage'
        );
      }
    } else if (
      this.checkPermissionPublishEvent() &&
      this.minReqForPublish(event)
    ) {
      return this.groupService.publishGroup(event.key, GroupType.Events);
    } else {
      return Promise.reject();
    }
  }

  async removeEvent(key: string): Promise<void> {
    return this.groupService.removeGroup(key, GroupType.Events);
  }

  public checkPermissionCreateDraftEvent() {
    return this.groupService.checkPermissionCreateDraftGroup(
      GroupType.Events,
      'MissingCanCreateDraftEventMsg'
    );
  }

  public checkPermissionPublishEvent() {
    return this.groupService.checkPermissionPublishGroup(
      GroupType.Events,
      'MissingCanPublishEventsMsg'
    );
  }

  private minRequirementForPublish(event: Event): string {
    let text = '';
    if (!event.name) {
      text +=
        '⛔ ' +
        formatText(hardcodedValues.youHaveToAddX, hardcodedValues.Name) +
        '\n';
    }
    if (!event.eventPeriod.startDate) {
      text += '⛔ ' + hardcodedValues.StartDateReq + '\n';
    }
    if (!event.eventPeriod.endDate) {
      text += '⛔ ' + hardcodedValues.EndDateReq + '\n';
    }
    return text;
  }

  private minReqForPublish(event: Event): boolean {
    const text = this.minRequirementForPublish(event);
    if (text.length > 0) {
      this.authService.notEnoughPermission(text);
      return false;
    } else {
      return true;
    }
  }

  public async createEventAndNavigate(
    ownerGroup?: Group,
    privacySetting?: GroupPrivacy,
    checked = false
  ) {
    await this.createEvent(ownerGroup, privacySetting, checked)
      .then((event) => {
        this.routingService.navigateToRoute(RoutingModel.events.path, [
          'edit',
          event.key,
          0,
        ]);
      })
      .catch((e) => {});
  }

  public async createEvent(
    ownerGroup?: Group,
    privacySetting = GroupPrivacy.OpenPrivate,
    checked = false
  ): Promise<Event> {
    if (checked || this.checkPermissionCreateDraftEvent()) {
      const now = new Date();

      const forum = await this.forumService.createForum(true, true);

      const event: IEvent<Date> = {
        name: '',
        ownerId: this.userId,
        membersCount: 1,
        key: null,
        created: now,
        lastUpdate: now,
        eventDeadline: null,
        publicityDate: null,
        description: '',
        location: new MondoLocation(),
        logoUrl: '',
        permissions: new GroupPermissions(),
        privacy: privacySetting,
        groupType: GroupType.Events,
        forumId: forum.id,
        attendingCount: 1,
        coverUrl: '',
        maxAttendees: null,
        videoUrl: '',
        website: null,
        declinedCount: 0,
        isOnline: false,
        isCanceled: false,
        noReplyCount: 0,
        maybeCount: 0,
        interestedCount: 0,
        onlineFormat: null,
        discountCode: '',
        ownerGroupId: ownerGroup ? ownerGroup.key : null,
        ownerGroupType: ownerGroup ? ownerGroup.groupType : null,
        tags: new GroupTags(),
        uploads: [],
        wantedDaysOfExperience0: 0,
        wantedDaysOfExperience1: 0,
        wantedDaysOfExperience2: 0,
        wantedDaysOfExperience3: 0,
        wantedDaysOfExperience4: 0,
        wantedDaysOfExperience5: 0,
        meetingLink: '',
        signupLink: '',
        eventPeriod: new TimePeriod(
          TimeService.incrementHoursAndSetMinutesForDate(now, 1),
          null
        ),
      };

      const group = (await this.groupService.createGroup(event)) as Event;
      return group;
    } else {
      return Promise.reject();
    }
  }

  public duplicateEvent(event: Event) {
    return this.groupService.duplicateGroup(event);
  }
}
