import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-aca-range',
  templateUrl: './aca-range.component.html',
  styleUrls: ['./aca-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcaRangeComponent {
  public rangeValue: number = null;
  @Output() rangeChanged: EventEmitter<{ coordinates: string; range: number }> =
    new EventEmitter(null);
  @Input() maxValue = 950;
  @Input('patchValue')
  set patchValue(value: number) {
    this.rangeValue = value;
  }
  disabled = false;
  changed(range) {
    if (navigator.geolocation) {
      const that = this;
      const result = { coordinates: '', range: 0 };
      navigator.geolocation.getCurrentPosition(
        function (position) {
          that.disabled = false;
          const coordinates = `${position.coords.latitude},${position.coords.longitude}`;
          const coordsAndRange = {
            coordinates: coordinates,
            range: range.value,
          };
          that.rangeChanged.emit(coordsAndRange);
        },
        function (err) {
          that.disabled = true;
          that.rangeValue = 0;
          that.rangeChanged.emit(result);
        }
      );
    }
  }
}
