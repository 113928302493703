import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { HelperService } from 'app/core/helper.service';
import { UserService } from 'app/core/user.service';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { GroupRelation } from 'app/groups/models/group-relation';
import { GroupType } from 'app/groups/models/group-type';
import { Member } from 'app/groups/models/member';
import { environment } from 'environments/environment';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import {
  map,
  mergeMap,
  reduce,
  switchMap,
  take,
  tap,
  toArray,
} from 'rxjs/operators';
import { DAO } from '../db-access/dao';
import {
  IGroupSelfRequest,
  IGroupBatchRequest,
  IGroupRequest,
  MembershipHandlerFunctions,
  IRemoveMemberRequest,
  ILeaveGroupRequest,
} from './models/membership-function-interfaces';
import { Attendance } from 'app/groups/events/models/attendance';
import { AttendanceService } from './attendance.service';
import { GroupRoleService } from 'app/core/group-role.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { GroupRole } from 'app/groups/models/group-role';
import { acaConfig } from 'aca-config';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  userId: string;
  constructor(
    public fnsHelper: HelperService,
    private dao: DAO,
    private authService: AuthService,
    private attendanceService: AttendanceService,
    private userService: UserService,
    private groupRoleService: GroupRoleService
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });
  }

  public async becomeMemberAndChangeAttendance(
    key: string,
    attendance: Attendance
  ) {
    return Promise.all([
      this.becomeMember(key, GroupType.Events),
      this.attendanceChanged(key, attendance),
    ]).catch((e) => {
      throw new Error(`becomeMemberAndChangeAttendance: ${e}`);
    });
  }

  public async leaveEvent(key: string, forumId: string, removePosts = true) {
    return Promise.all([
      this.leaveGroup(key, GroupType.Events, forumId, removePosts),
      this.attendanceService.iAmNotAttending(key, GroupType.Events),
    ]).catch((e) => {
      throw new Error(`leaveAndChangeAttendance: ${e}`);
    });
  }
  async attendanceChanged(key, attendance: Attendance, forumId?: string) {
    switch (attendance) {
      case Attendance.Attending:
        return this.attendanceService.iAmAttending(key, GroupType.Events);
      case Attendance.Maybe:
        return this.attendanceService.iAmMaybeAttending(key, GroupType.Events);
      case Attendance.Interested:
        return this.attendanceService.iAmInterested(key, GroupType.Events);
      case Attendance.Declined:
        return this.leaveEvent(key, forumId);

      default:
        return Promise.reject();
    }
  }

  public async forceMembership(
    groupId: string,
    groupType: GroupType,
    userId: string
  ) {
    return this.fnsHelper
      .createFunctionPromise<IGroupRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        userId: userId,
        functionHandle: MembershipHandlerFunctions.membershipForceMember,
      })
      .catch((err) => console.error('error - membership - invite: ' + err));
  }

  public async inviteMembers(
    groupId: string,
    groupType: GroupType,
    userIds: string[]
  ) {
    return this.fnsHelper
      .createFunctionPromise<IGroupBatchRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        userIds: userIds,
        functionHandle: MembershipHandlerFunctions.membershipInviteMember,
      })
      .catch((err) => console.error('error - membership - invite: ' + err));
  }

  public async acceptMemberInvite(groupId: string, groupType: GroupType) {
    return this.fnsHelper
      .createFunctionPromise<IGroupSelfRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: MembershipHandlerFunctions.membershipAcceptMemberInvite,
      })
      .catch((err) =>
        console.error('error - membership - accept member invite: ' + err)
      );
  }

  public async makeAdministrator(
    groupId: string,
    groupType: GroupType,
    userId: string
  ) {
    return this.fnsHelper
      .createFunctionPromise<IGroupRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        userId: userId,
        functionHandle: MembershipHandlerFunctions.membershipMakeAdministrator,
      })
      .catch((err) => console.error('error - membership - make admin: ' + err));
  }

  public async makeModerator(
    groupId: string,
    groupType: GroupType,
    userId: string
  ) {
    return this.fnsHelper
      .createFunctionPromise<IGroupRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        userId: userId,
        functionHandle: MembershipHandlerFunctions.membershipMakeModerator,
      })
      .catch((err) =>
        console.error('error - membership - make moderator: ' + err)
      );
  }

  public async becomeMember(groupId: string, groupType: GroupType) {
    return this.fnsHelper
      .createFunctionPromise<IGroupSelfRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: MembershipHandlerFunctions.membershipBecomeMember,
      })
      .catch((err) => {
        console.error('error - membership - becomeMember: ' + err);
      });
  }

  public async requestMembership(groupId: string, groupType: GroupType) {
    return this.fnsHelper
      .createFunctionPromise<IGroupSelfRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: MembershipHandlerFunctions.membershipRequestMember,
      })
      .catch((err) => {
        console.error('error - membership - request: ' + err);
      });
  }

  public async acceptRequestMembership(
    groupId: string,
    groupType: GroupType,
    userId: string
  ) {
    return this.fnsHelper
      .createFunctionPromise<IGroupRequest, void>(
        environment.membershipHandler
      )({
        userId: userId,
        groupId: groupId,
        groupType: groupType,
        functionHandle:
          MembershipHandlerFunctions.membershipAcceptMemberRequest,
      })
      .catch((err) =>
        console.error('error - membership - accept request: ' + err)
      );
  }

  public async rejectRequestMembership(
    groupId: string,
    groupType: GroupType,
    userId: string
  ) {
    return this.fnsHelper
      .createFunctionPromise<IGroupRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        userId: userId,
        functionHandle:
          MembershipHandlerFunctions.membershipRejectMemberRequest,
      })
      .catch((err) =>
        console.error('error - membership - reject request: ' + err)
      );
  }

  public async removeMember(
    groupId: string,
    groupType: GroupType,
    forumId: string,
    userId: string,
    removePosts: boolean
  ) {
    const promises: Promise<void>[] = [];
    if (groupType === GroupType.Events) {
      promises.push(
        this.attendanceService.removeAttendance(
          groupId,
          GroupType.Events,
          userId
        )
      );
    }

    promises.push(
      this.removeMemberFunction(
        groupId,
        groupType,
        userId,
        forumId,
        removePosts
      )
    );

    return Promise.all(promises).catch((e) => {
      throw new Error(`error - membership - remove member: ${e}`);
    });
  }

  private async removeMemberFunction(
    groupId: string,
    groupType: GroupType,
    userId: string,
    forumId: string,
    removePosts: boolean
  ) {
    return this.fnsHelper
      .createFunctionPromise<IRemoveMemberRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        forumId: forumId,
        userId: userId,
        removePosts: removePosts,
        functionHandle: MembershipHandlerFunctions.membershipRemoveMember,
      })
      .catch((err) =>
        console.error('error - membership - remove member function: ' + err)
      );
  }

  public async leaveGroup(
    groupId: string,
    groupType: GroupType,
    forumId: string,
    removePosts: boolean
  ) {
    return this.fnsHelper
      .createFunctionPromise<ILeaveGroupRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        forumId: forumId,
        removePosts: removePosts,
        functionHandle: MembershipHandlerFunctions.membershipLeaveGroup,
      })
      .catch((err) =>
        console.error('error - membership - leave group: ' + err)
      );
  }

  public async removeGroup(groupId: string, groupType: GroupType) {
    return this.fnsHelper
      .createFunctionPromise<IGroupSelfRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: MembershipHandlerFunctions.membershipRemoveGroup,
      })
      .catch((err) =>
        console.error('error - membership - remove group: ' + err)
      );
  }

  public async rejectInviteMembership(groupId: string, groupType: GroupType) {
    return this.fnsHelper
      .createFunctionPromise<IGroupSelfRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: MembershipHandlerFunctions.membershipRejectMemberInvite,
      })
      .catch((err) => console.error('error - membership - reject: ' + err));
  }

  public async makeOwner(groupId: string, groupType: GroupType) {
    return this.fnsHelper
      .createFunctionPromise<IGroupSelfRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        functionHandle: MembershipHandlerFunctions.membershipMakeOwner,
      })
      .catch((err) => console.error('error - membership - make owner: ' + err));
  }

  public async grantOwnershipAndRemoveOldOwner(
    groupId: string,
    groupType: GroupType,
    userId: string,
    forumId: string,
    removePosts: boolean
  ) {
    return this.fnsHelper
      .createFunctionPromise<IRemoveMemberRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        forumId: forumId,
        userId: userId,
        removePosts: removePosts,
        functionHandle:
          MembershipHandlerFunctions.grantOwnershipAndRemoveOldOwner,
      })
      .catch((err) =>
        console.error('error - membership - grant ownership: ' + err)
      );
  }

  public async grantOwnership(
    groupId: string,
    groupType: GroupType,
    userId: string
  ) {
    return this.fnsHelper
      .createFunctionPromise<IGroupRequest, void>(
        environment.membershipHandler
      )({
        groupId: groupId,
        groupType: groupType,
        userId: userId,
        functionHandle: MembershipHandlerFunctions.membershipGrantOwnership,
      })
      .catch((err) =>
        console.error('error - membership - grant ownership: ' + err)
      );
  }

  public async downgradeToMember(
    groupId: string,
    groupType: GroupType,
    userId: string
  ) {
    if (userId === this.userId) {
      console.error('you tried to downgrade yourself');
    } else if (userId && userId !== this.userId) {
      return this.fnsHelper
        .createFunctionPromise<IGroupRequest, void>(
          environment.membershipHandler
        )({
          groupId: groupId,
          groupType: groupType,
          userId: userId,
          functionHandle:
            MembershipHandlerFunctions.membershipDowngradeToMember,
        })
        .catch((err) =>
          console.error('error - membership - downgrade to member: ' + err)
        );
    }
  }

  public hasRelations$(
    groupId: string,
    groupType: GroupType,
    relations: Array<GroupRelation> | undefined
  ): Observable<boolean> {
    return this.dao
      .object$<GroupRelation>(
        `${DataConstants.MEMBERSHIPS}${groupType}/${groupId}/${this.userId}`
      )
      .pipe(
        map((relation) => {
          return relations
            ? relations.some((r) => r === relation)
            : relation === undefined;
        })
      );
  }

  public getRelation$(
    groupId: string,
    groupType: GroupType
  ): Observable<GroupRelation> {
    return this.dao.object$<GroupRelation>(
      `${DataConstants.MEMBERSHIPS}${groupType}/${groupId}/${this.userId}`
    );
  }

  public getRelationWithUserId$(
    groupId: string,
    groupType: GroupType,
    userId: string
  ): Observable<GroupRelation> {
    return this.dao.object$<GroupRelation>(
      `${DataConstants.MEMBERSHIPS}${groupType}/${groupId}/${userId}`
    );
  }

  public getGroupIds$(
    groupType: GroupType,
    batch = 999,
    lastKey?: string
  ): Observable<Map<GroupRelation, string[]>> {
    return this.dao
      .listData$(
        `${DataConstants.USER_MEMBERSHIPS}${this.userId}/${groupType}`,
        (ref) => {
          if (lastKey) {
            return ref.orderByKey().limitToLast(batch).endAt(lastKey);
          } else {
            return ref.orderByKey().limitToLast(batch);
          }
        }
      )
      .pipe(
        map((groupRelations) =>
          groupRelations.reduce((result, groupRelation) => {
            const relation = groupRelation.data as GroupRelation;
            if (!result.has(relation)) {
              result.set(relation, []);
            }
            result.get(relation).push(groupRelation.key);

            return result;
          }, new Map<GroupRelation, Array<string>>())
        )
      );
  }

  public getUserGroupIds$(
    groupType: GroupType,
    batch = 999,
    lastKey?: string
  ): Observable<string[]> {
    return this.dao.listKey$(
      `${DataConstants.USER_MEMBERSHIPS}${this.userId}/${groupType}`,
      (ref) => {
        if (lastKey) {
          return ref.orderByKey().limitToLast(batch).endAt(lastKey);
        } else {
          return ref.orderByKey().limitToLast(batch);
        }
      }
    );
  }

  public getGroupRelations$(
    groupType: GroupType,
    batch = 999,
    lastKey?: string
  ): Observable<Map<string, GroupRelation>> {
    return this.dao
      .listData$(
        `${DataConstants.USER_MEMBERSHIPS}${this.userId}/${groupType}`,
        (ref) => {
          if (lastKey) {
            return ref.orderByKey().limitToLast(batch).endAt(lastKey);
          } else {
            return ref.orderByKey().limitToLast(batch);
          }
        }
      )
      .pipe(
        map((groupRelations) => {
          return groupRelations.reduce((result, groupRelation) => {
            const key = groupRelation.key;
            const relation = groupRelation.data as GroupRelation;
            return result.set(key, relation);
          }, new Map<string, GroupRelation>());
        })
      );
  }

  public getUsersGroupRelations$(
    userId: string,
    groupType: GroupType,
    batch = 999,
    lastKey?: string
  ): Observable<Map<string, GroupRelation>> {
    return this.dao
      .listData$(
        `${DataConstants.USER_MEMBERSHIPS}${userId}/${groupType}`,
        (ref) => {
          if (lastKey) {
            return ref.orderByKey().limitToLast(batch).endAt(lastKey);
          } else {
            return ref.orderByKey().limitToLast(batch);
          }
        }
      )
      .pipe(
        map((groupRelations) => {
          return groupRelations.reduce((result, groupRelation) => {
            const key = groupRelation.key;
            const relation = groupRelation.data as GroupRelation;
            return result.set(key, relation);
          }, new Map<string, GroupRelation>());
        })
      );
  }

  public getNumberOfRequestingUsersFromGroup$(
    groupId: string,
    groupType: GroupType
  ): Observable<number> {
    return this.dao
      .object$<Map<string, GroupRelation>>(
        `${DataConstants.MEMBERSHIPS}${groupType}/${groupId}`
      )
      .pipe(
        map((memberships: Map<string, GroupRelation>) => {
          let count = 0;
          if (memberships) {
            Object.values(memberships).forEach((relation: GroupRelation) => {
              if (relation === GroupRelation.Requested) {
                count++;
              }
            });
          }

          return count;
        })
      );
  }

  public getAllMembers$(
    groupId: string,
    groupType: GroupType,
    includeSelf = false
  ): Observable<Array<Member>> {
    return this.dao
      .object$<Map<string, GroupRelation>>(
        `${DataConstants.MEMBERSHIPS}${groupType}/${groupId}`
      )
      .pipe(
        switchMap((members) => {
          return combineLatest(
            Object.keys(members)
              .filter((userId) => (includeSelf ? true : userId !== this.userId))
              .map((userId) => this.userService.getUserByUid$(userId))
              .filter((user) => !!user)
          ).pipe(
            mergeMap(async (users) => {
              const membersWithRole = [];
              for (const user of users) {
                if (user) {
                  const role = await this.groupRoleService
                    .getRole$(user.uid, groupType, groupId)
                    .pipe(take(1))
                    .toPromise();
                  membersWithRole.push({
                    user: user,
                    relation: members[user.uid] as GroupRelation,
                    role: role,
                  });
                }
              }
              return membersWithRole;
            })
          );
        })
      );
  }
}
