import { Pipe, PipeTransform } from '@angular/core';
import { fromNow } from '@helpers';

@Pipe({
  name: 'lastSeen'
})
export class LastSeenPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return this.lastSeen(value);
  }

  lastSeen(timestamp): string {
    return fromNow(timestamp);
  }
}
