export enum ItemType {
  CV,
  Job,
  CvSearch,
  Network,
  Site,
  Billboard,
  Place,
  ContactConsent,
  Event,
}
