import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
declare const marked: any;

@Pipe({
  name: 'renderMarkdown',
  pure: false,
})
export class RenderMarkdownPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    const renderedMarkdown = marked.parse(value, { sanitize: false });
    return this.sanitizer.bypassSecurityTrustHtml(renderedMarkdown);
  }
}
