import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { IPresence } from 'app/shared/components/user-status/user-status.component';
import { DAO } from 'app/shared-services/db-access/dao';

@Injectable({
  providedIn: 'root',
})
export class PresenceService {
  constructor(private afAuth: AngularFireAuth, private db: DAO) {
    this.updateOnUser().subscribe();
    this.updateOnDisconnect().subscribe();
    this.updateOnAway();
    console.log(
      '%c Powered by Academondo © ',
      'background:#051f2d;color:#fff;font-size: 25px;'
    );
    console.log('%chttps://www.academondo.com', 'font-size: 14px;');
  }

  getPresence(uid: string): Observable<IPresence> {
    return this.db.object$(`status/${uid}`).pipe(
      map((presence: IPresence) => {
        if (presence) {
          presence.status = this.getCorrectedStatus(presence);
        }
        return presence;
      })
    );
  }

  private getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  private getCorrectedStatus(presence: IPresence) {
    if (this.activityExpired(presence.timestamp)) {
      return 'offline';
    } else {
      return presence.status;
    }
  }

  private activityExpired(timestamp) {
    const deadline = this.subtractHoursFromDate(new Date(), 1);
    return new Date(timestamp - 1000) < deadline;
  }

  private subtractHoursFromDate(date: Date, hours: number): Date {
    const result = new Date(date);
    result.setHours(result.getHours() - hours);
    return result;
  }

  async setPresence(status: string) {
    const user = await this.getUser();
    if (user) {
      return this.db
        .object(`status/${user.uid}`)
        .update({ status, timestamp: this.timestamp });
    }
  }

  get timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  updateOnUser() {
    const connection = this.db
      .object$('.info/connected')
      .pipe(map((connected) => (connected ? 'online' : 'offline')));

    return this.afAuth.authState.pipe(
      switchMap((user) => (user ? connection : of('offline'))),
      tap((status) => this.setPresence(status))
    );
  }

  updateOnAway() {
    document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden') {
        this.setPresence('away');
      } else {
        this.setPresence('online');
      }
    };
  }

  // async signOut() {
  //   await this.setPresence('offline');
  //   await this.afAuth.auth.signOut();
  // }

  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap((user) => {
        if (user) {
          this.db.object(`status/${user.uid}`).query.ref.onDisconnect().update({
            status: 'offline',
            timestamp: this.timestamp,
          });
        }
      })
    );
  }
}
