import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent {
  @Input() public imagesOnly = false;
  @Input() public storagePath: string;
  @Input() public dbPath: string;
  @Output() public fileUploaded: EventEmitter<Object> =
    new EventEmitter<Object>();
  @Output() public fileDeleted: EventEmitter<Object> =
    new EventEmitter<Object>();

  public isHovering: boolean;

  public files: File[] = [];

  public toggleHover(event: boolean) {
    this.isHovering = event;
  }

  public onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }

  public uploadComplete($event) {
    this.fileUploaded.emit($event);
  }

  public deleteComplete($event) {
    this.fileDeleted.emit($event);
  }
}
