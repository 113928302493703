import { Guid } from '../guid';
import { Country } from '../country';
import { CvField } from './cv-field';

export class TeachingExperience extends CvField
  implements JsonTeachingExperience<Date> {
  public static fromJson(
    {
      uid,
      title,
      university,
      department,
      country,
      startDate,
      endDate,
      description,
      isCurrent,
      exclude
    }: JsonTeachingExperience<number>,
    cvId: string
  ): TeachingExperience {
    return new TeachingExperience(
      uid,
      cvId,
      title,
      university,
      department,
      country ? (country as Country) : new Country(),
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
      description,
      isCurrent,
      exclude
    );
  }
  public static toJson(
    item: TeachingExperience
  ): JsonTeachingExperience<number> {
    return {
      uid: item.uid,
      cvId: item.cvId,
      title: item.title,
      university: item.university,
      department: item.department,
      country: item.country,
      startDate: item.startDate ? item.startDate.getTime() : null,
      endDate: item.endDate ? item.endDate.getTime() : null,
      description: item.description,
      isCurrent: item.isCurrent,
      exclude: item.exclude
    };
  }
  constructor(
    public readonly uid = Guid.newGuid(),
    public cvId = '',
    public title = '',
    public university = '',
    public department = '',
    public country: Country = new Country(),
    public startDate: Date = null,
    public endDate: Date = null,
    public description = '',
    public isCurrent = false,
    public exclude = false
  ) {
    super(exclude);
  }
}
export interface JsonTeachingExperience<T> {
  uid: string;
  cvId: string;
  title: string;
  university: string;
  department: string;
  country: Country;
  startDate: T;
  endDate: T;
  description: string;
  isCurrent: boolean;
  exclude: boolean;
}
