import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { acaConfig } from 'aca-config';
import { RoutingModel } from 'app/app.routing-model';
import { Event } from 'app/groups/events/models/event';
import { EventService } from 'app/core/event.service';
import { SearchableTypes } from 'app/shared/common/search.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'app-events-widget',
  templateUrl: './events-widget.component.html',
  styleUrls: ['./events-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsWidgetComponent
  extends WidgetBaseComponent
  implements OnInit
{
  @Input() showGroupEventKey = null;
  public pinnedEvent$: Observable<string>;
  public pinnedEvent: string;
  private events: Map<string, Event> = new Map();
  SearchableTypes = SearchableTypes;
  RoutingModel = RoutingModel;
  groupEvents$: Observable<Event[]>;
  upcomingGroupEvents$ = new BehaviorSubject<Array<Event>>([]);

  constructor(private eventService: EventService) {
    super();
  }

  ngOnInit() {
    this.pinnedEvent$ = this.eventService.getPinnedEventKey$().pipe(
      tap((eventKey) => {
        this.pinnedEvent = eventKey;
      })
    );
    if (this.showGroupEventKey) {
      this.groupEvents$ = this.eventService
        .getGroupEvents$(this.showGroupEventKey)
        .pipe(tap((events) => this.handleEvents(events)));
    }
  }

  handleEvents(events: Array<Event>) {
    this.events.clear();
    events.forEach(async (event) => {
      if (event && event.key) {
        const endDate =
          event &&
          event.eventPeriod &&
          event.eventPeriod.endDate &&
          new Date(event.eventPeriod.endDate).getTime();
        if (endDate > new Date().getTime()) {
          this.events.set(event.key, event);
        } else {
          // this.oldEvents.set(event.key, event);
        }
      }
    });
    this.upcomingGroupEvents$.next(Array.from(this.events.values()));
    // this.oldGroupEvents$.next(Array.from(this.oldEvents.values()));
  }

  getEvents() {
    if (this.showGroupEventKey) {
      return this.upcomingGroupEvents$;
    } else {
      return this.eventService.getEvents();
    }
  }

  get TwitterListHref() {
    return acaConfig.TwitterListHref;
  }

  get ExternalFeedRight() {
    return acaConfig.ExternalFeedRight;
  }

  gotoEventsRoute() {
    return this.showGroupEventKey ? '../events' : RoutingModel.events.route;
  }

  gotoEvent(key: string) {
    return `${RoutingModel.events.route}/${key}`;
  }

  createNewDraftEvent() {
    this.eventService.createEventAndNavigate();
  }

  canCreateNewDraftEvent() {
    return this.eventService.canCreateNewDraftEvent();
  }

  showCreateEventButtonForUserWithoutPermission() {
    return acaConfig.showCreateEventButtonForUserWithoutPermission;
  }

  trackByFn(index, item: Event) {
    return item && item.key;
  }
}
