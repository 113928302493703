import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ChatService } from 'app/core/chat.service';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/auth.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { MatDialogRef } from '@angular/material';
import { Chat } from 'app/shared/models/chat/chat';
import { Comment } from 'app/forum/models/comment';
import { Guid } from 'app/shared/models';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-chat-room-dialog',
  templateUrl: './chat-room-dialog.component.html',
  styleUrls: ['./chat-room-dialog.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatRoomDialogComponent
  implements OnInit, OnChanges, AfterViewChecked
{
  @ViewChild('commentArea', { static: false }) commentArea: ElementRef;
  activeChatRoom$: Observable<Chat>;
  activeMessages$: Observable<Comment[]>;
  activeChatRoom: Chat;
  activeMessages: Comment[];
  currentUser: MondoUser;
  @Input() activeChatKey: string;
  @ViewChild('messagesContainer', { static: false })
  private messagesContainer: ElementRef;

  commentMessage = '';
  commentImageLink = '';
  loading = false;
  imgGuid = Guid.newGuid();
  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<ChatRoomDialogComponent>
  ) {}

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    if (this.activeChatKey) {
      this.setupChatRoom(this.activeChatKey);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.activeChatKey &&
      changes.activeChatKey.currentValue !== changes.activeChatKey.previousValue
    ) {
      this.setupChatRoom(changes.activeChatKey.currentValue);
    }
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  get isPartOfChat() {
    return this.activeChatRoom.userIds.includes(this.currentUser.uid);
  }

  okOkClicked() {
    if (this.dialogRef) {
      this.dialogRef.close(true);
    }
  }

  scrollToBottom(): void {
    if (this.messagesContainer) {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    }
  }

  private setupChatRoom(key: string) {
    this.activeMessages$ = this.chatService.getChatRoomsMessages(key).pipe(
      tap((messages) => {
        this.activeMessages = messages;
      })
    );

    this.activeChatRoom$ = this.chatService.openOrCreateNewChatRoom(key).pipe(
      tap((chatRoom) => {
        this.activeChatRoom = chatRoom;
      })
    );
    this.scrollToBottom();
  }

  sendMessage(messageEvent: any) {
    this.chatService.sendMessage(messageEvent.value, this.activeChatKey);
    messageEvent.value = '';
  }

  get imageLinkPathPrefix() {
    return '/chatMessage/';
  }

  isCurrentUser(uid): boolean {
    return this.authService.getCurrentUser().uid === uid;
  }

  adjustHeight() {
    const textarea: HTMLTextAreaElement = this.commentArea.nativeElement;
    textarea.style.overflowY = 'hidden';
    textarea.style.height = 'auto';
    if (this.commentMessage.includes('\n')) {
      textarea.style.height = textarea.scrollHeight + 'px';
    } else {
      textarea.style.height = 18 + 'px';
    }
  }

  public handleKeyPress(event): void {
    event.preventDefault();
    this.adjustHeight();
    if (event.ctrlKey && event.code === 'Enter') {
      this.submit(event);
    }
  }

  async submit(e) {
    e.preventDefault();
    if (this.cannotSubmit()) {
      return;
    }
    this.loading = true;
    await this.chatService.sendMessage(
      this.commentMessage,
      this.activeChatKey,
      this.commentImageLink
    );
    // this.commentAdded.emit();

    this.commentMessage = '';
    this.commentImageLink = '';
    this.loading = false;
  }

  cannotSubmit() {
    return (
      this.loading ||
      (!this.commentMessage.trim() && !this.commentImageLink.trim())
    );
  }

  onImageUpload(url: string) {
    this.commentImageLink = url;
  }

  get imageLinkPath(): string {
    return `${this.imageLinkPathPrefix}${this.imgGuid}/imageLink`;
  }

  trackByFn(index, comment: Comment) {
    return comment.id;
  }
}
