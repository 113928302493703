import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { acaConfig } from 'aca-config';

@Injectable({
  providedIn: 'root',
})
export class WordpressService {
  blogCache = [];
  eventCache = [];

  constructor(private http: HttpClient) {}

  fetchAllBlogPosts(site: string, perPage = 10): Promise<any[]> {
    const url = `${site}/wp-json/wp/v2/posts?_embed&per_page=${perPage}`;

    return this.http
      .get<any[]>(url)
      .toPromise()
      .then((response) => {
        if (response && response.length > 0) {
          this.blogCache = response;
        }
        return this.blogCache;
      })
      .catch((error) => {
        console.error('Error fetching blog posts:', error);
        return this.blogCache;
      });
  }

  get events() {
    return acaConfig['urlForWordpressACFEvents']
      ? this.fetchEvents([acaConfig['urlForWordpressACFEvents']])
      : [];
  }

  fetchAllEvents(site: string): Promise<any[]> {
    const eventParamNaming =
      acaConfig['eventParamForWordpressevents'] || 'event';
    const date = new Date();
    date.setDate(date.getDate());
    const formattedDate = date.toISOString();

    const url = `${site}/wp-json/wp/v2/${eventParamNaming}?meta_key=acf_event_date&meta_value=${formattedDate}&meta_compare=>=&orderby=meta_value&order=asc`;

    return this.http
      .get<any[]>(url)
      .toPromise()
      .then((response) => {
        if (response && response.length > 0) {
          this.eventCache = response;
        }
        return this.eventCache;
      })
      .catch((error) => {
        console.error('Error fetching events:', error);
        return this.eventCache;
      });
  }

  fetchEvents(urls: string[]) {
    if (urls) {
      const promises = urls.map((url) => this.fetchAllEvents(url));

      return Promise.all(promises)
        .then((posts) => {
          const allPosts = [].concat(...posts);
          allPosts.sort((a, b) => {
            const dateAacf = new Date(a.acf.acf_event_date).getTime();
            const dateBacf = new Date(b.acf.acf_event_date).getTime();
            return dateAacf - dateBacf;
          });
          this.eventCache = allPosts;
          return this.eventCache;
        })
        .catch((error) => {
          console.error('Error fetching events:', error);
          return this.eventCache;
        });
    }
    return Promise.resolve([]);
  }
}
