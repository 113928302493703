import { ItemStatus } from '../../db-strings/ItemStatus';
import { StatusContext } from '../../status-context';
import { BaseStatus } from '../base-status';
export class Published extends BaseStatus {
  handleQueueForPublish(context: StatusContext): void {
    context.setStatus(ItemStatus.queuedForPublish);
  }
  handlePublish(context: StatusContext): void {
    return;
  }
  handleIsPublished(): boolean {
    return true;
  }
  handleCanRePublish(): boolean {
    return true;
  }
  handleGetDbString(): string {
    return super.handleGetDbString() + ItemStatus.published;
  }
  handleUnpublish(context: StatusContext): void {
    context.setStatus(ItemStatus.unpublished);
  }
  handleCanUnPublish(): boolean {
    return true;
  }
  handleRepublish(context: StatusContext): void {
    context.setStatus(ItemStatus.queuedForPublish);
  }
  handleGetDescription(): string {
    return 'published';
  }
  handleIsPubliclyVisible(): boolean {
    return true;
  }
  getShortStatus(): string {
    return ItemStatus.published;
  }
}
