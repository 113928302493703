import { Injectable } from '@angular/core';
import {
  AngularFireDatabase,
  PathReference,
  QueryFn,
} from '@angular/fire/database';
import { shareReplay } from 'rxjs/operators';
import { IDatabase } from './IDatabase';

@Injectable({
  providedIn: 'root',
})
export class FirebaseDAO implements IDatabase {
  constructor(private db: AngularFireDatabase) {}

  getAll<T>(table: PathReference) {
    return [];
  }

  getById<T>(id: string, table: PathReference) {
    return {} as T;
  }

  list<T>(table: PathReference, cb?: QueryFn) {
    return this.db.list<T>(table, cb);
  }

  list$<T>(table: PathReference, cb?: QueryFn) {
    return this.db.list<T>(table, cb).valueChanges().pipe(shareReplay(1));
  }

  object<T>(table: PathReference) {
    return this.db.object<T>(table);
  }

  object$<T>(table: PathReference) {
    return this.db.object<T>(table).valueChanges().pipe(shareReplay(1));
  }

  createPushId(): string {
    return this.db.createPushId();
  }

  ref(table: string) {
    return this.db.database.ref(table);
  }
}
