import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, catchError, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Observable, throwError, of } from 'rxjs';
import { Upload } from 'app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    private AFStorage: AngularFireStorage,
    private authService: AuthService
  ) {}

  public uploadImage(
    file: File | Blob,
    userPath: string,
    fileName = '',
    percentageStatus?: (per: Observable<number>) => void
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      if ((file && file instanceof Blob) || file instanceof File) {
        let metadata: firebase.storage.UploadMetadata;
        let path: string;
        if (file instanceof Blob) {
          metadata = {
            cacheControl: 'public,max-age=31536000',
            contentType: 'image/jpeg',
          };
          path = `${
            this.authService.getCurrentUser().uid
          }/${userPath}/${Date.now()}_${fileName}.jpeg`;
        } else {
          path = `${
            this.authService.getCurrentUser().uid
          }/${userPath}/${Date.now()}_${(file as File).name}`;
          metadata = {
            cacheControl: 'public,max-age=31536000',
            contentType: (file as File).type,
          };
        }

        const task = this.AFStorage.upload(path, file, metadata);
        if (percentageStatus) {
          percentageStatus(task.percentageChanges());
        }
        const fileRef = this.AFStorage.ref(path);
        const snapshot = task.snapshotChanges();

        snapshot
          .pipe(
            finalize(() =>
              fileRef
                .getDownloadURL()
                .pipe(take(1))
                .subscribe((p) => resolve(p))
            )
          )
          .subscribe();
      } else {
        reject();
      }
    });
  }

  public deleteFile(filePath: string, fullPath = false, name = '') {
    if (fullPath) {
      const path = name ? `${filePath}/${name}` : filePath;
      return this.AFStorage.ref(path).delete();
    } else {
      const path = name ? `${filePath}/${name}` : filePath;
      return this.AFStorage.ref(
        `${this.authService.getCurrentUser().uid}/${path}`
      ).delete();
    }
  }

  public startUpload(
    storagePath: string,
    file: File,
    cb: Function,
    percentageStatus: (per: Observable<number>) => void,
    fileSizeExceeds: string,
    incorrectFileFormat: string
  ) {
    if (
      !this.isFileTypeAccepted(file, incorrectFileFormat) ||
      !this.IsfileSizeAccepted(file, fileSizeExceeds)
    ) {
      return;
    } else {
      const metadata = {
        cacheControl: 'public,max-age=31536000',
        contentType: file.type,
      };

      const path = `${storagePath}/${Date.now()}_${file.name}`;
      const ref = this.AFStorage.ref(path);
      const task = this.AFStorage.upload(path, file, metadata);

      // Progress monitoring
      percentageStatus(task.percentageChanges());
      return task.snapshotChanges().pipe(
        // tap(console.log),

        // The file's download URL
        finalize(async () => {
          const downloadUrl = await ref.getDownloadURL().toPromise();
          // process.stdout.write('finalize: ', 'post', downloadUrl);
          const upload: Upload = {
            name: file.name,
            path: path,
            url: downloadUrl,
            size: file.size,
            type: file.type,
          };
          cb({
            name: file.name,
            path: path,
            url: downloadUrl,
            size: file.size,
            type: file.type,
          });

          // return this.db.collection('files').add( { downloadURL: downloadURL, path });
        }),
        catchError(() => throwError('Unable to upload file!'))
      );
    }
  }

  private IsfileSizeAccepted(file, fileSizeExceeds: string) {
    const fileSize = file.size / 1024 / 1024; // in MB
    const MAX = 100;
    if (fileSize > MAX) {
      this.authService.notEnoughPermission(
        `${fileSizeExceeds} ${MAX} MB`,
        5000
      );
      return false;
    } else {
      return true;
    }
  }

  private isFileTypeAccepted(file: File, incorrectFileFormat: string) {
    if (!file) {
      return false;
    }
    const acceptedFileTypesDisplay = [
      '.webp',
      '.bmp',
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.tif',
      '.tiff',
      '.svg',
      '.pdf',
      '.doc',
      '.docx',
      '.xls',
      '.xlsx',
      '.ptt',
      '.pttx',
      '.ics',
      '.csv',
      '.txt',
    ].join(', ');
    const acceptedFileTypes = [
      'image/webp',
      'image/bmp',
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/svg+xmls',
      'image/gif',
      'image/tiff',
      'image/tif',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/msword',
      'application/vnd.ms-word',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/msexcel',
      'application/vnd.ms-excel',
      'application/mspowerpoint',
      'application/vnd.ms-powerpoint',
      'application/json',
      'text/calendar',
      'text/plain',
      'text/csv',
    ].join(', ');

    if (acceptedFileTypes.includes(file.type)) {
      return true;
    } else {
      this.authService.notEnoughPermission(
        `${incorrectFileFormat} ${acceptedFileTypesDisplay.toString()}`,
        5000
      );
      return false;
    }
  }
}
