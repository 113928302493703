import { MultiSelectorItem } from '../components/multi-selector/multi-selector-item';

export class Subtypes implements MultiSelectorItem {
  public static fromJson({ id, name, desc }): Subtypes {
    return new Subtypes(id, name, desc);
  }

  constructor(public id: string, public name: string, public desc = '') {}

  public getId(): string {
    return this.id;
  }

  public getDisplayText(): string {
    return this.name;
  }

  public getDescription(): string {
    return this.desc;
  }
}
