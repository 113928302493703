import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { StorageService } from 'app/core/storage.service';
import { Upload } from 'app/shared/models';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss'],
})
export class UploadTaskComponent implements OnInit {
  @Input() public file: File;
  @Input() public storagePath: string;
  @Input() public dbPath: string;
  @Input() public fileSizeExceeds: string;
  @Input() public incorrectFileFormat: string;
  @Output() public onComplete: EventEmitter<Object> =
    new EventEmitter<Object>();
  @Output() public onDeleted: EventEmitter<Object> = new EventEmitter<Object>();

  public task: AngularFireUploadTask;

  public percentage: Observable<number>;
  public snapshot: Observable<any>;
  public downloadURL: string;
  public fileName: string;
  public fileSize: number;

  constructor(private storageService: StorageService) {}

  public ngOnInit() {
    this.startUpload(this.fileSizeExceeds, this.incorrectFileFormat);
  }

  private startUpload(fileSizeExceeds: string, incorrectFileFormat: string) {
    try {
      this.snapshot = this.storageService.startUpload(
        this.storagePath,
        this.file,
        this.cb,
        (percent) => (this.percentage = percent),
        fileSizeExceeds,
        incorrectFileFormat
      );
    } catch (error) {
      console.log('storageService.startUpload failed: ', error);
    }
  }

  private cb = (cbData: Upload) => {
    this.downloadURL = cbData.url;
    this.fileName = cbData.name;
    this.fileSize = cbData.size;
    this.onComplete.emit(cbData);
  };

  public delete() {
    this.onDeleted.emit(this.downloadURL);
  }

  public getAsMb(bytes: number): number {
    if (bytes) {
      return bytes / 1024 / 1024;
    } else {
      return 0;
    }
  }
}
