import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { PostService } from 'app/core/post.service';
import { ForumPost } from 'app/forum/models/forum-post';
import { PostFormService } from 'app/forum/services/post-form.service';
import { Group } from 'app/groups/models/group';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';

@Component({
  selector: 'app-post-step-view',
  templateUrl: './post-step-view.component.html',
  styleUrls: ['./post-step-view.component.scss'],
})
export class PostStepViewComponent extends DestroyComponent implements OnInit {
  @Input() updatingPost = false;
  @Input() group: Group;
  @Input() post: ForumPost;
  postFormValue: ForumPost;

  constructor(
    public postFormService: PostFormService,
    private authService: AuthService,
    private postService: PostService
  ) {
    super();
  }

  ngOnInit() {
    const form = this.postFormService.getForm();
    this.postFormValue = form.value;

    this.safeSubscribe(this.postFormService.getFormChangeStream(), (post) => {
      this.postFormValue = post;
    });
  }

  save() {
    const post = this.postFormValue;
    const postToUpdate = Object.assign(this.post, {});
    postToUpdate.title = post.title;
    postToUpdate.phone = post.phone;
    postToUpdate.uploads = post.uploads;
    postToUpdate.youtubeId = post.youtubeId;
    postToUpdate.email = post.email;
    postToUpdate.body = post.body;
    postToUpdate.topic = post.topic;
    postToUpdate.imageUrl = post.imageUrl;
    postToUpdate.imageLink = post.imageLink;
    postToUpdate.userTags = post.userTags;
    postToUpdate.taggedUserIds = post.taggedUserIds;
    postToUpdate.teaser = this.group.permissions.premium;

    if (this.updatingPost) {
      this.postService.updatePost(postToUpdate);
    } else {
      this.postService.createPost(postToUpdate, this.group);
    }
  }

  get user() {
    return this.authService.getCurrentUser();
  }
}
