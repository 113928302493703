import { ResearchLeader } from './researchLeader';

export class ResearchGroup {
  public static fromJson({ uid, name, researchLeader }: ResearchGroup = {} as ResearchGroup) {
    return new ResearchGroup(uid, name, researchLeader);
  }
  constructor(
    public uid = '',
    public name = '',
    public researchLeader: ResearchLeader = new ResearchLeader()
  ) {}
}
