import { ItemMode } from '../db-strings/item-mode';
import { ItemStatus } from '../db-strings/ItemStatus';
import { SyncStatus } from '../db-strings/sync-status';
import { IStatus } from '../i-status';
import { StatusContext } from '../status-context';
import { ISyncStatus } from './I-sync-status';
import { InSync } from './in-sync';
import { OutOfSync } from './out-of-sync';

export abstract class BaseStatus implements IStatus {
  private syncStatus: ISyncStatus;
  constructor() {
    this.handleSetInSync();
  }
  handleIsPublished(): boolean {
    return false;
  }
  handleIsPubliclyVisible(): boolean {
    return false;
  }
  handleCanQueueForPublish(): boolean {
    return false;
  }
  handleQueueForPublish(context: StatusContext): void {
    throw new Error('Method not implemented.');
  }
  handleCanMakepublicallyVisible(): boolean {
    return false;
  }
  onEnter(context: StatusContext): void {}
  handleUnpublish(context: StatusContext): void {
    context.setStatus(ItemStatus.unpublished);
  }
  handleSetOutOfSync(): void {
    this.syncStatus = new OutOfSync();
  }
  handleSetInSync(): void {
    this.syncStatus = new InSync();
  }
  handleCanPublish(): boolean {
    return false;
  }
  handlePublish(context: StatusContext): void {
    throw new Error('Method not implemented.');
  }
  handleGetDbString(): string {
    return this.syncStatus.getDbString();
  }
  handleCanRePublish(): boolean {
    return false;
  }
  handleCanUnPublish(): boolean {
    return false;
  }
  handleCanDelete(): boolean {
    return true;
  }
  handleIsInSync(): boolean {
    return this.syncStatus.isInSync();
  }
  handleRepublish(context: StatusContext): void {
    throw new Error('Method not implemented.');
  }
  handleDelete(context: StatusContext): void {
    throw new Error('Method not implemented.');
  }
  handleGetDescription(): string {
    throw new Error('Method not implemented.');
  }
  handleGetIcon(): string {
    throw new Error('Method not implemented.');
  }
  handleGetColor(): string {
    throw new Error('Method not implemented.');
  }
  handleGetShortStatus(): ItemStatus {
    throw new Error('Method not implemented.');
  }
}
