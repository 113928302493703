export class Forum implements IForum<Date> {
  public static fromJson({
    id,
    ownerId,
    created,
    onlyOwnerCanCreatePosts,
    onlyOwnerCanCreateComments,
  }: IForum<number>): Forum {
    return {
      id,
      ownerId,
      created: created ? new Date(created) : undefined,
      onlyOwnerCanCreatePosts,
      onlyOwnerCanCreateComments,
    };
  }

  public static toJson(forum: Forum): IForum<number> {
    return {
      id: forum.id,
      ownerId: forum.ownerId,
      created: forum.created ? forum.created.getTime() : null,
      onlyOwnerCanCreatePosts: forum.onlyOwnerCanCreatePosts,
      onlyOwnerCanCreateComments: forum.onlyOwnerCanCreateComments,
    };
  }
  constructor(
    public id: string,
    public ownerId: string,
    public created: Date,
    public onlyOwnerCanCreatePosts: boolean,
    public onlyOwnerCanCreateComments: boolean
  ) {}
}

export interface IForum<T> {
  id: string;
  ownerId: string;
  created: T;
  onlyOwnerCanCreatePosts: boolean;
  onlyOwnerCanCreateComments: boolean;
}
