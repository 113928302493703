import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { acaConfig } from 'aca-config';
import { Job } from 'app/stepper/job/model/job';

@Component({
  selector: 'app-job-viewer-contact',
  templateUrl: './job-viewer-contact.component.html',
  styleUrls: ['./job-viewer-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobViewerContactComponent implements OnInit {
  @Input() job: Job;

  constructor() {}

  ngOnInit() {}

  public apply(href) {
    window.open(href);
  }

  createMailtoHref(): string {
    const displayName = this.job.company.name
      ? acaConfig.language === 'da'
        ? `Hej ${this.job.company.name},`
        : `Hi ${this.job.company.name},`
      : '';
    const subject = `${
      acaConfig.language === 'da'
        ? 'Forespørgelse på dit opslag:'
        : 'Request regarding your post:'
    } ${this.job.jobInfo.position} ${
      acaConfig.language === 'da' ? 'på' : 'on'
    } ${acaConfig.academondo}`;

    const bodyPartTwo =
      acaConfig.language === 'da'
        ? `Jeg vil gerne høre mere om ${this.job.jobInfo.position}`
        : `I would like to hear more about ${this.job.jobInfo.position}`;
    const mailtoUrl = `mailto:${this.job.contact.contactEmail}
    ?subject=${encodeURI(subject)}
    &body=${encodeURI(displayName)}%0A
    %0A${encodeURI(bodyPartTwo)}
    `;
    return mailtoUrl;
  }

  get infoHTML(): string {
    return this.getStringOrNull(this.job.contact.info);
  }

  get urlJobApplication(): string {
    return this.getStringOrNull(this.job.contact.jobLinks.urlJobApplication);
  }
  get contactEmail(): string {
    return this.getStringOrNull(this.job.contact.contactEmail);
  }
  get contactPhone(): string {
    return this.getStringOrNull(this.job.contact.contactPhone);
  }

  get urlJobSite(): string {
    return this.getStringOrNull(this.job.contact.jobLinks.urlJobSite);
  }

  get deadline(): Date {
    return this.job.jobVisibilityPeriod.endDate;
  }

  get researchLeaderName(): string {
    return this.getStringOrNull(
      this.job.contact.researchGroup.researchLeader.name
    );
  }

  private getStringOrNull(string: string): string {
    return string && string !== '' ? string : null;
  }
}
