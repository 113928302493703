import { MultiSelectorItem } from '../components/multi-selector/multi-selector-item';

export class Technique implements MultiSelectorItem {
  public static fromJson({ name, id, category }): Technique {
    return new Technique(name, id, category);
  }
  constructor(
    public name: string,
    public id: string,
    public category: string
  ) {}

  public getId(): string {
    return this.id;
  }

  public getDisplayText(): string {
    return this.name;
  }

  public getDescription(): string {
    return this.category;
  }
}
