import { Publication } from './publication';

export class Publications {
  public static fromJson({
    bibliographicStyle,
    publicationsList
  }): Publications {
    return new Publications(
      bibliographicStyle,
      publicationsList ? publicationsList.map(p => Publication.fromJson(p)) : []
    );
  }
  constructor(
    public bibliographicStyle = '',
    public publicationsList: Publication[] = []
  ) {}
}
