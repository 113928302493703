import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Job } from 'app/stepper/job/model/job';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';

@Component({
  selector: 'app-job-viewer-header',
  templateUrl: './job-viewer-header.component.html',
  styleUrls: ['./job-viewer-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobViewerHeaderComponent implements OnInit {
  @Input() job: Job;

  constructor() {}

  ngOnInit() {}

  get companyName(): string {
    return this.getStringOrNull(this.job.company.name);
  }

  get researchGroupName(): string {
    return this.getStringOrNull(this.job.contact.researchGroup.name);
  }

  get location(): MondoLocation {
    return this.job.company.location;
  }

  get streetAddress(): string {
    return this.getStringOrNull(this.job.company.location.street);
  }

  get zip(): string {
    return this.getStringOrNull(this.job.company.location.postalCode);
  }

  get city(): string {
    return this.getStringOrNull(this.job.company.location.city);
  }

  get number(): string {
    return this.getStringOrNull(this.job.company.location.number);
  }

  get countryCode(): string {
    return this.job.company.location.country
      ? this.getStringOrNull(this.job.company.location.country.code)
      : null;
  }

  private getStringOrNull(string: string): string {
    return string && string !== '' ? string : null;
  }
}
