import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YouTubeVideoComponent implements OnInit {
  @Input() link: string;
  @Input() videoHeight = 420;

  ytLink: SafeResourceUrl;
  vimeoLink: SafeResourceUrl;
  constructor(private _domSanitizer: DomSanitizer) {}

  get yt() {
    if (this.link && this.link.length === 11) {
      return this._domSanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube-nocookie.com/embed/' + this.link
      );
    }
    const split = this.link ? this.link.split('v=')[1] : null;
    if (split) {
      const videoId = split.substring(0, 11);
      if (videoId.length === 11) {
        return this._domSanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube-nocookie.com/embed/' + videoId
        );
      }
    }

    const idFromshareLink = this.getYoutubeId(this.link)
      ? this.getYoutubeId(this.link)
      : null;
    if (idFromshareLink) {
      return this._domSanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube-nocookie.com/embed/' + idFromshareLink
      );
    }

    return null;
  }

  get vimeo() {
    if (this.link && this.link.length === 9) {
      return this._domSanitizer.bypassSecurityTrustResourceUrl(
        'https://player.vimeo.com/video/' +
          this.link +
          '?dnt=1&h=84f0616336&color=ffffff&title=0&byline=0&portrait=0&badge=0'
      );
    }
    const split = this.link ? this.link.split('https://vimeo.com/')[1] : null;
    if (split) {
      const videoId = split.substring(0, 9);
      if (videoId.length === 9) {
        return this._domSanitizer.bypassSecurityTrustResourceUrl(
          'https://player.vimeo.com/video/' +
            videoId +
            '?dnt=1&h=84f0616336&color=ffffff&title=0&byline=0&portrait=0&badge=0'
        );
      }
    }

    const idFromShareLink = this.getVimeoId(this.link)
      ? this.getVimeoId(this.link)
      : null;
    if (idFromShareLink) {
      return this._domSanitizer.bypassSecurityTrustResourceUrl(
        'https://player.vimeo.com/video/' +
          idFromShareLink +
          '?dnt=1&h=84f0616336&color=ffffff&title=0&byline=0&portrait=0&badge=0'
      );
    }

    return null;
  }

  ngOnInit() {
    if (this.isYoutubeLink(this.link) || this.link.length === 11) {
      this.ytLink = this.yt;
    }

    if (this.isVimeoLink(this.link) || this.link.length === 9) {
      this.vimeoLink = this.vimeo;
    }
  }

  isYoutubeLink(link: string): boolean {
    return /youtube\.com|youtu\.be/.test(link);
  }

  isVimeoLink(link: string): boolean {
    return /vimeo\.com/.test(link);
  }

  getYoutubeId(link: string): string {
    const url = link.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

  getVimeoId(link: string): string {
    const regExp = /vimeo\.com\/(video\/)?([0-9]+)/;
    const match = link.match(regExp);

    return match && match[2] ? match[2] : null;
  }
}
