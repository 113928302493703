import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { getFiltersFromGroup } from '@helpers';
import { RoutingModel } from 'app/app.routing-model';
import { RoutingService } from 'app/core/routing.service';
import { Community } from 'app/groups/communities/models/community';
import { GroupService } from 'app/groups/services/group.service';
import { IFilter } from 'app/shared/models';

@Component({
  selector: 'app-community-viewer',
  templateUrl: './community-viewer.component.html',
  styleUrls: ['./community-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityViewerComponent implements OnChanges {
  @Input() community: Community;
  @Input() showVisit = false;
  @Input() showCoverImage = true;

  constructor(private routingService: RoutingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const community = changes.community;
    if (
      !community.firstChange &&
      community.currentValue !== community.previousValue
    ) {
      this.community = community.currentValue;
    }
  }

  get filters(): IFilter[] {
    return getFiltersFromGroup(this.community);
  }

  gotoCommunity(key: string) {
    this.routingService.navigateToRoute(RoutingModel.communities.path, [
      key,
      'about',
    ]);
  }

  get communityAddress(): string {
    return GroupService.getGroupAddress(this.community);
  }

  trackByFn(index, item) {
    return item.key;
  }
}
