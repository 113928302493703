import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DAO } from 'app/shared-services/db-access/dao';
import { AccountPermission } from 'app/shared/consts/accountPermission';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PermissionService {
  permissions: Set<AccountPermission>;

  constructor(private afDatabase: DAO, private afAuth: AngularFireAuth) {}

  public getUserPermission(userId: string): Observable<AccountPermission> {
    if (userId) {
      return this.afDatabase
        .object$(DataConstants.USER_PERMISSIONS + userId)
        .pipe(
          map((permission: any) =>
            permission ? permission.permission : AccountPermission.basic
          )
        );
    } else {
      return of(AccountPermission.basic);
    }
  }
}
