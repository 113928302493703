export enum NotificationType {
  posts = 'posts',
  community = 'community',
  communityOwners = 'communityOwners',
  eventOwners = 'eventOwners',
  event = 'event',
  idea = 'idea',
  comments = 'comments',
  expiration = 'expiration',
  chat = 'chat',
  reaction = 'reaction',
  job = 'job',
  alert = 'alert',
}
