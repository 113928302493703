import { MondoLocation } from './mondoLocation';

export class Company {
  public static fromJson({
    uid,
    name,
    cvr,
    email,
    maxApplicants,
    location,
    pictureUrl,
    coverUrl
  }: Company = {} as Company) {
    return new Company(uid, name, cvr, email, maxApplicants, location, pictureUrl, coverUrl);
  }
  constructor(
    public uid = '',
    public name = '',
    public cvr = '',
    public email = '',
    public maxApplicants = 2,
    public location: MondoLocation = new MondoLocation(),
    public pictureUrl = '',
    public coverUrl = '',
  ) { }
}
