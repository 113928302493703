import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { EducationLevel } from 'app/shared/models/cv/education-level';
import {
  FieldOfInterest,
  Technique,
  Category0,
  Category1,
} from 'app/shared/models';
import { ResearchQualifications } from 'app/stepper/job/model/researchQualifications';
import { JobService } from 'app/core/job.service';

@Component({
  selector: 'app-job-viewer-qualifications',
  templateUrl: './job-viewer-qualifications.component.html',
  styleUrls: ['./job-viewer-qualifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobViewerQualificationsComponent implements OnInit {
  @Input() qualifications: ResearchQualifications; // move to shared
  educationLevel = EducationLevel;

  constructor(private jobService: JobService) {}

  ngOnInit() {}

  canSeeCat1() {
    return this.jobService.canSeeCat1();
  }

  get wantedFieldOfInterest(): FieldOfInterest[] {
    return this.qualifications.wantedFieldOfInterest
      ? this.qualifications.wantedFieldOfInterest.map(
          (wfoi) => new FieldOfInterest(wfoi.uid, wfoi.name, wfoi.desc)
        )
      : [];
  }

  get category0(): Category0[] {
    return this.qualifications.category0
      ? this.qualifications.category0.map(
          (cat) => new Category0(cat.uid, cat.name, cat.desc)
        )
      : [];
  }

  get category1(): Category0[] {
    return this.qualifications.category1
      ? this.qualifications.category1.map(
          (cat) => new Category1(cat.uid, cat.name, cat.desc)
        )
      : [];
  }

  get category2(): Category0[] {
    return this.qualifications.category2
      ? this.qualifications.category2.map(
          (cat) => new Category0(cat.uid, cat.name, cat.desc)
        )
      : [];
  }

  get category3(): Category0[] {
    return this.qualifications.category3
      ? this.qualifications.category3.map(
          (cat) => new Category0(cat.uid, cat.name, cat.desc)
        )
      : [];
  }

  get wantedTechniques(): Technique[] {
    return this.qualifications.wantedTechniques
      ? this.qualifications.wantedTechniques.map(
          (tec) => new Technique(tec.name, tec.id, tec.category)
        )
      : [];
  }

  get wantedDaysOfExperience0(): number {
    return this.getNumberOrNull(this.qualifications.wantedDaysOfExperience0);
  }

  get wantedDaysOfExperience1(): number {
    return this.getNumberOrNull(this.qualifications.wantedDaysOfExperience1);
  }

  get wantedDaysOfExperience2(): number {
    return this.getNumberOrNull(this.qualifications.wantedDaysOfExperience2);
  }
  get wantedDaysOfExperience3(): number {
    return this.getNumberOrNull(this.qualifications.wantedDaysOfExperience3);
  }
  get wantedDaysOfExperience4(): number {
    return this.getNumberOrNull(this.qualifications.wantedDaysOfExperience4);
  }
  get wantedDaysOfExperience5(): number {
    return this.getNumberOrNull(this.qualifications.wantedDaysOfExperience5);
  }

  get qualificationFreeText(): string {
    return this.getStringOrNull(this.qualifications.qualificationFreeText);
  }

  get minimumEducationalLevel(): string {
    const minEduLevel = this.qualifications.minimumEducationalLevel;
    return minEduLevel === EducationLevel.none ? null : minEduLevel;
  }

  private getStringOrNull(text: string): string {
    return text && text !== '' ? text : null;
  }

  private getNumberOrNull(days: number): number {
    return days && days !== 0 ? days : null;
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
