import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pricedk',
})
export class PriceDKPipe implements PipeTransform {
  constructor() {}

  transform(value: any, args?: any): any {
    return this.convertToPrice(value);
  }

  private convertToPrice(price: number) {
    let result = '';
    // const formatterRegEx = '1000000'.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // console.log(formatterRegEx); add dots
    result = price.toLocaleString('da-DK');
    result += ',00 kr.';
    return result;
  }
}
