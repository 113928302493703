import { Country } from '../country';
import { Guid } from '../guid';
import { CvField } from './cv-field';
import { JobExperienceType } from '../../../stepper/shared/model/job-experience-type';

export class WorkExperience extends CvField
  implements JsonWorkExperience<Date> {
  public static fromJson(
    {
      uid,
      jobTitle,
      company,
      country,
      jobType,
      startDate,
      endDate,
      description,
      isCurrent,
      exclude
    }: JsonWorkExperience<number>,
    cvId: string
  ): WorkExperience {
    return new WorkExperience(
      uid,
      cvId,
      jobTitle,
      company,
      country ? (country as Country) : new Country(),
      jobType,
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
      description,
      isCurrent,
      exclude
    );
  }

  public static toJson(item: WorkExperience): JsonWorkExperience<number> {
    return {
      uid: item.uid,
      cvId: item.cvId,
      jobTitle: item.jobTitle,
      company: item.company,
      country: item.country,
      jobType: item.jobType,
      startDate: item.startDate ? item.startDate.getTime() : null,
      endDate: item.endDate ? item.endDate.getTime() : null,
      description: item.description,
      isCurrent: item.isCurrent,
      exclude: item.exclude
    };
  }

  constructor(
    public readonly uid = Guid.newGuid(),
    public cvId = '',
    public jobTitle = '',
    public company = '',
    public country: Country = new Country(),
    public jobType = JobExperienceType.experience0,
    public startDate: Date = null,
    public endDate: Date = null,
    public description = '',
    public isCurrent = false,
    public exclude = false
  ) {
    super(exclude);
  }
}
export interface JsonWorkExperience<T> {
  uid: string;
  cvId: string;
  jobTitle: string;
  company: string;
  jobType: JobExperienceType;
  country: Country;
  startDate: T;
  endDate: T;
  description: string;
  isCurrent: boolean;
  exclude: boolean;
}
