import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-scoring-bar',
  templateUrl: './scoring-bar.component.html',
  styleUrls: ['./scoring-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoringBarComponent {
  @Input() value: number;
}
