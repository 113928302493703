import { Injectable } from '@angular/core';
import { RoutingModel } from 'app/app.routing-model';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { ForumService } from 'app/forum/services/forum.service';
import { Group } from 'app/groups/models/group';
import { GroupRelation } from 'app/groups/models/group-relation';
import { GroupType } from 'app/groups/models/group-type';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';
import { GroupTags } from 'app/groups/models/groupTags';
import { GroupService } from 'app/groups/services/group.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';
import { formatText, hardcodedValues } from 'hardcodedValues';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { GroupPermissions } from '../../models/groupPermissions';
import { Community, ICommunity } from '../models/community';
import { isObject } from 'app/shared/common/acaLodash';

@Injectable({
  providedIn: 'root',
})
export class CommunitiesService {
  userId: string;

  constructor(
    private dao: DAO,
    private authService: AuthService,
    private forumService: ForumService,
    private routingService: RoutingService,
    private groupService: GroupService
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });
  }

  public getPremiumCommunities$(): Observable<Community[]> {
    return this.dao.list$(
      DataConstants.PUBLIC_GROUPS + GroupType.Communities,
      (ref) => ref.orderByChild('permissions/premium').equalTo(true)
    );
  }

  public getPublicCommunities$(showPremium = false): Observable<Community[]> {
    return this.dao
      .list$<ICommunity<number>>(
        DataConstants.PUBLIC_GROUPS + GroupType.Communities
      )
      .pipe(
        map((groups) =>
          groups.map((group) =>
            isObject(group)
              ? Community.fromJson(group as ICommunity<number>)
              : undefined
          )
        ),
        map((groups: Community[]) =>
          groups.filter((group) => {
            return group && group.key
              ? showPremium
                ? true
                : !group.permissions.premium
              : false;
          })
        )
      );
  }

  public canCreateNewDraftCommunity(): boolean {
    return this.authService.canCreateDraftCommunities;
  }

  public getCommunities$(
    relation: GroupRelation,
    showPremium = false
  ): Observable<Community[]> {
    return this.groupService.getGroups$(relation, GroupType.Communities).pipe(
      map((groups) =>
        groups.filter((group) => {
          return group && group.key
            ? hardcodedValues.EnablePremiumCommunities
              ? (showPremium && group.permissions.premium) ||
                !group.permissions.premium
              : true
            : false;
        })
      ),
      map((groups) => groups as Array<Community>)
    );
  }

  getCommunitiesForGroup$(
    groupKey: string,
    lastKey?: string,
    batch = 999
  ): Observable<Array<Community>> {
    return this.dao
      .listData$(`${DataConstants.GROUP_SUBGROUPS}${groupKey}`, (ref) => {
        if (lastKey) {
          return ref.orderByKey().limitToLast(batch).endAt(lastKey);
        } else {
          return ref.orderByKey().limitToLast(batch);
        }
      })
      .pipe(
        mergeMap((groupIds) =>
          combineLatest(
            groupIds.reverse().map((groupId) => this.getCommunity$(groupId.key))
          )
        )
      );
  }

  public getCommunity$(
    key: string,
    relation?: GroupRelation
  ): Observable<Community | undefined> {
    return this.groupService
      .getGroup$(key, GroupType.Communities, relation)
      .pipe(map((groups) => groups as Community));
  }

  public async isDraftPublished(key: string): Promise<boolean> {
    return this.groupService.isDraftPublished(key, GroupType.Communities);
  }

  public updateDraftCommunity(community: Community): Promise<void> {
    return this.groupService.updateDraftGroup(community);
  }

  async publishCommunity(community: Community): Promise<void> {
    if (
      this.checkPermissionPublishCommunity() &&
      this.minReqForPublish(community)
    ) {
      return this.groupService.publishGroup(
        community.key,
        GroupType.Communities
      );
    } else {
      return Promise.reject();
    }
  }

  private minRequirementForPublish(community: Community): string {
    let text = '';
    if (!community.name) {
      text +=
        '⛔ ' +
        formatText(hardcodedValues.youHaveToAddX, hardcodedValues.Name) +
        '\n';
    }
    return text;
  }

  private minReqForPublish(community: Community): boolean {
    const text = this.minRequirementForPublish(community);
    if (text.length > 0) {
      this.authService.notEnoughPermission(text);
      return false;
    } else {
      return true;
    }
  }

  async removeCommunity(key: string): Promise<void> {
    return this.groupService.removeGroup(key, GroupType.Communities);
  }

  public checkPermissionCreateDraftCommunity() {
    return this.groupService.checkPermissionCreateDraftGroup(
      GroupType.Communities,
      'MissingCanCreateDraftCommunityMsg'
    );
  }

  public checkPermissionPublishCommunity() {
    return this.groupService.checkPermissionPublishGroup(
      GroupType.Communities,
      'MissingCanPublishCommunityMsg'
    );
  }

  public async createCommunityAndNavigate() {
    await this.createCommunity()
      .then((community) => {
        this.routingService.navigateToRoute(RoutingModel.communities.path, [
          'edit',
          community.key,
          0,
        ]);
      })
      .catch((e) => {});
  }

  private async createCommunity(
    privacySetting = GroupPrivacy.OpenPrivate
  ): Promise<Community> {
    if (this.checkPermissionCreateDraftCommunity()) {
      const now = new Date();

      const forum = await this.forumService.createForum(true, true);

      const community: Community = {
        name: '',
        ownerId: this.userId,
        key: null,
        created: now,
        lastUpdate: now,
        publicityDate: null,
        coverUrl: '',
        description: '',
        location: new MondoLocation(),
        logoUrl: '',
        membersCount: 1,
        permissions: new GroupPermissions(),
        privacy: privacySetting,
        tags: new GroupTags(),
        uploads: [],
        wantedDaysOfExperience0: 0,
        wantedDaysOfExperience1: 0,
        wantedDaysOfExperience2: 0,
        wantedDaysOfExperience3: 0,
        wantedDaysOfExperience4: 0,
        wantedDaysOfExperience5: 0,
        groupType: GroupType.Communities,
        videoUrl: '',
        website: '',
        forumId: forum.id,
        ownerGroupId: null,
        ownerGroupType: null,
      };

      return (await this.groupService.createGroup(community)) as Community;
    } else {
      return Promise.reject();
    }
  }

  getGroupSubgroups$(
    groupKey: string,
    lastKey?: string,
    batch = 999
  ): Observable<Array<Community>> {
    return this.dao
      .listData$(`${DataConstants.GROUP_SUBGROUPS}${groupKey}`, (ref) => {
        if (lastKey) {
          return ref.orderByKey().limitToLast(batch).endAt(lastKey);
        } else {
          return ref.orderByKey().limitToLast(batch);
        }
      })
      .pipe(
        mergeMap((subgroupIds) =>
          combineLatest(
            subgroupIds
              .reverse()
              .map((subgroupId) => this.getCommunity$(subgroupId.key))
          )
        )
      );
  }

  async createSubgroupAndNavigate(
    ownerGroup?: Group,
    privacySetting?: GroupPrivacy,
    checked = false
  ) {
    await this.createSubgroup(ownerGroup, privacySetting, checked)
      .then((subgroup) => {
        this.routingService.navigateToRoute(RoutingModel.communities.path, [
          'edit',
          subgroup.key,
          0,
        ]);
      })
      .catch((e) => {});
  }

  public async createSubgroup(
    ownerGroup?: Group,
    privacySetting = GroupPrivacy.OpenPrivate,
    checked = false
  ): Promise<Community> {
    if (checked || this.checkPermissionCreateDraftCommunity()) {
      const now = new Date();

      const forum = await this.forumService.createForum(true, true);

      const community: Community = {
        name: '',
        ownerId: this.userId,
        key: null,
        created: now,
        lastUpdate: now,
        publicityDate: null,
        coverUrl: '',
        description: '',
        location: new MondoLocation(),
        logoUrl: '',
        membersCount: 1,
        permissions: new GroupPermissions(),
        privacy: privacySetting,
        tags: new GroupTags(),
        uploads: [],
        wantedDaysOfExperience0: 0,
        wantedDaysOfExperience1: 0,
        wantedDaysOfExperience2: 0,
        wantedDaysOfExperience3: 0,
        wantedDaysOfExperience4: 0,
        wantedDaysOfExperience5: 0,
        groupType: GroupType.Communities,
        videoUrl: '',
        website: '',
        forumId: forum.id,
        ownerGroupId: ownerGroup ? ownerGroup.key : null,
        ownerGroupType: ownerGroup ? ownerGroup.groupType : null,
      };

      const group = (await this.groupService.createGroup(
        community
      )) as Community;
      return group;
    } else {
      return Promise.reject();
    }
  }

  public duplicateCommunity(community: Community) {
    return this.groupService.duplicateGroup(community);
  }
}
