import { Component, OnInit } from '@angular/core';
import { RoutingModel } from 'app/app.routing-model';
import { RoutingService } from 'app/core/routing.service';
import { NotificationData } from 'app/notifications-new/models/notification-data';
import { UserNotificationStatus } from 'app/notifications-new/models/user-notification-status';
import { AppNotificationService } from 'app/notifications-new/services/app-notification.service';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';
import { last } from 'app/shared/common/acaLodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent
  extends DestroyComponent
  implements OnInit
{
  routingModel = RoutingModel;
  loading = false;
  finished = false;
  userNotificationStatus$: Observable<UserNotificationStatus>;
  userNotificationStatus: UserNotificationStatus;

  private lastKey = null;
  private notifications$ = new BehaviorSubject<Array<NotificationData>>([]);
  private batch = 20;
  private notifications: Map<string, NotificationData> = new Map();

  constructor(
    private appNotificationService: AppNotificationService,
    private routingService: RoutingService
  ) {
    super();
  }

  ngOnInit() {
    this.getNextNotifications();
    this.userNotificationStatus$ = this.appNotificationService
      .getNotificationStatus$()
      .pipe(
        tap((userNotificationStatus) => {
          this.userNotificationStatus = userNotificationStatus;
          const badges = this.userNotificationStatus
            ? this.userNotificationStatus.unseen
            : 0;
          if ((navigator as any).setAppBadge) {
            (navigator as any).setAppBadge(badges);
          }
          // localStorage.setItem('badges', badges.toString());
          // Save badges in IndexedDB instead of localStorage
          const db = indexedDB.open('sw-db', 1);
          db.onupgradeneeded = (e: any) => {
            e.target.result.createObjectStore('notifications');
          };
          db.onsuccess = (e: any) => {
            const store = e.target.result
              .transaction('notifications', 'readwrite')
              .objectStore('notifications');
            store.put(badges, 'unseen');
          };
        })
      );
  }

  onScroll() {
    this.getNextNotifications();
  }

  async routeTo(notification: NotificationData): Promise<void> {
    await this.appNotificationService.markAsRead(notification.id);
    const path = notification.link.split('/');
    const url: string = path.slice(0, 2).join('/');
    const data = path.slice(2);
    this.routingService.navigateToUrlWithDataArrayWithNoReuse(url, data);
  }

  private getNextNotifications() {
    this.loading = true;

    this.safeSubscribe(
      this.appNotificationService.getAppNotifications$(
        this.batch,
        this.lastKey
      ),
      (notifications) => {
        if (notifications.length === 0) {
          return;
        }
        if (
          notifications.length === 1 &&
          notifications[0].id === this.lastKey
        ) {
          this.finished = true;
          return;
        }
        if (notifications.length < this.batch - 2) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.lastKey = last(notifications).id;
        notifications.forEach((notification) =>
          this.notifications.set(notification.id, notification)
        );

        this.notifications$.next(Array.from(this.notifications.values()));

        this.loading = false;
      }
    );
  }

  navigateToSettings() {
    this.routingService.navigateToRoute(this.routingModel.profileEditor.path);
  }

  getNotifications() {
    return this.notifications$.asObservable();
  }

  async markAllOpenAsSeen() {
    await this.appNotificationService.markAllAsSeen();
    // this.msgService.spamSimon(this.authService.getCurrentUser().uid);
  }

  async markAllOpenAsRead() {
    await this.appNotificationService.markAllAsRead();
  }

  trackByFn(index, item) {
    return item.id;
  }
}
