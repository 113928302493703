import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { HelperService } from 'app/core/helper.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationData } from '../models/notification-data';
import {
  NotificationFunctionData,
  NotificationHandlerFunctions,
} from '../models/notification-handler-functions';
import { NotificationSettings } from '../models/notification-settings';
import { NotificationStatus } from '../models/notification-status';
import { UserNotificationStatus } from '../models/user-notification-status';

@Injectable({
  providedIn: 'root',
})
export class AppNotificationService {
  private userId: string;
  constructor(
    private dao: DAO,
    private authService: AuthService,
    private fnsHelper: HelperService
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });
  }

  public getAppNotifications$(
    batch: number,
    lastKey?: string
  ): Observable<Array<NotificationData>> {
    return this.dao
      .listData$(
        `${DataConstants.USER_APP_NOTIFICATIONS}${this.userId}`,
        (ref) => {
          if (lastKey) {
            return ref.orderByKey().limitToLast(batch).endAt(lastKey);
          } else {
            return ref.orderByKey().limitToLast(batch);
          }
        }
      )
      .pipe(
        map((notifications) => {
          return notifications.reverse().map((n) => n.data as NotificationData);
        })
      );
  }

  public getNotificationStatus$(): Observable<UserNotificationStatus> {
    return this.dao.object$<UserNotificationStatus>(
      DataConstants.USER_NOTIFICATION_STATUS + this.userId
    );
  }

  public getNotificationSettings$(): Observable<NotificationSettings> {
    return this.dao.object$<NotificationSettings>(
      DataConstants.USER_NOTIFICATION_SETTINGS + this.userId
    );
  }

  public async updateNotificationSettings(settings: NotificationSettings): Promise<void> {
    await this.dao
      .ref(`${DataConstants.USER_NOTIFICATION_SETTINGS}${this.userId}`)
      .update(settings);
  }

  public async markAllAsSeen(): Promise<void> {
    try {
      return this.fnsHelper.createFunctionPromise<
        NotificationFunctionData,
        void
      >(environment.notificationHandler)({
        functionHandle: NotificationHandlerFunctions.markAllAsSeen,
      });
    } catch (err) {
      console.error('error - notification - mark all as seen: ' + err);
    }
  }

  public async markAllAsRead(): Promise<void> {
    try {
      return this.fnsHelper.createFunctionPromise<
        NotificationFunctionData,
        void
      >(environment.notificationHandler)({
        functionHandle: NotificationHandlerFunctions.markAllAsRead,
      });
    } catch (err) {
      console.error('error - notification - mark all as read: ' + err);
    }
  }

  public async markAsRead(notificationId: string): Promise<void> {
    try {
      return this.fnsHelper.createFunctionPromise<
        NotificationFunctionData,
        void
      >(environment.notificationHandler)({
        functionHandle: NotificationHandlerFunctions.markAsRead,
        notificationId: notificationId,
      });
    } catch (err) {
      console.error('error - notification - mark all as read: ' + err);
    }
  }
}
