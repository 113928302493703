import { Country } from '../country';
import { Guid } from '../guid';
import { CvField } from './cv-field';

export class WorkShop extends CvField implements JsonWorkShop<Date> {
  public static fromJson(
    {
      uid,
      title,
      country,
      city,
      date,
      contribution,
      description,
      abstract,
      exclude
    }: JsonWorkShop<number>,
    cvId: string
  ): WorkShop {
    return new WorkShop(
      uid,
      cvId,
      title,
      country ? (country as Country) : new Country(),
      city,
      date ? new Date(date) : null,
      contribution,
      description,
      abstract,
      exclude
    );
  }
  public static toJson(item: WorkShop): JsonWorkShop<number> {
    return {
      uid: item.uid,
      cvId: item.cvId,
      title: item.title,
      city: item.city,
      country: item.country,
      date: item.date ? item.date.getTime() : null,
      description: item.description,
      abstract: item.abstract,
      contribution: item.contribution,
      exclude: item.exclude
    };
  }
  constructor(
    public readonly uid = Guid.newGuid(),
    public cvId = '',
    public title = '',
    public country: Country = new Country(),
    public city = '',
    public date: Date = null,
    public contribution = '',
    public description = '',
    public abstract = '',
    public exclude = false
  ) {
    super(exclude);
  }
}
export interface JsonWorkShop<T> {
  uid: string;
  cvId: string;
  title: string;
  city: string;
  country: Country;
  date: T;
  contribution: string;
  description: string;
  abstract: string;
  exclude: boolean;
}
