import { JobLinks } from './JobLinks';
import { ResearchGroup } from './researchGroup';

export class Contact {
  public static fromJson({ info, jobLinks, researchGroup, contactEmail, contactPhone }: Contact = {} as Contact): Contact {
    return new Contact(info, jobLinks, ResearchGroup.fromJson(researchGroup), contactEmail, contactPhone);
  }
  constructor(
    public info = '',
    public jobLinks: JobLinks = new JobLinks(),
    public researchGroup: ResearchGroup =  new ResearchGroup(),
    public contactEmail = '',
    public contactPhone = '',
  ) {}
}
