import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { AngularMaterialSharedModule } from 'app/angular-material-shared/angular-material-shared.module';



@NgModule({
  declarations: [
    ImageCropperComponent,
    FileUploadComponent,
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    SharedPipesModule,
    AngularMaterialSharedModule,
  ],
  exports: [
    FileUploadComponent,
  ],
  entryComponents: [
    ImageCropperComponent,
  ],
  providers: [
    NgxImageCompressService,
  ]
})
export class FileUploadModule { }
