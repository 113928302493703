import { Component, Input, OnInit } from '@angular/core';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';
import { ScientistUser } from 'app/shared/models/user/scientistUser';

@Component({
  selector: 'app-widget-base',
  templateUrl: './widget-base.component.html',
  styleUrls: ['./widget-base.component.scss'],
})
export class WidgetBaseComponent extends DestroyComponent implements OnInit {
  @Input()
  user: ScientistUser;
  @Input()
  titleKey: string;

  constructor() {
    super();
  }

  ngOnInit() {}
}
