import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { acaConfig } from 'aca-config';
import { MondoFormControl } from 'app/core/mondo-form-builder';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() dateOptions: DateOptions;
  @Input() fControl: MondoFormControl<Date>;

  @ViewChild('dateInputField', { static: false }) dateInputField: ElementRef;
  @ViewChild('picker', { static: false }) picker: MatDatepicker<Date>;

  displayDate: MondoFormControl<string> = new MondoFormControl('');
  date: Date;
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.date = this.fControl.value;
    this.updateInputField(this.date);
    this.changeDetectorRef.markForCheck();
  }

  ngAfterViewInit(): void {
    this.date = this.fControl.value;
    this.updateInputField(this.date);
    this.changeDetectorRef.markForCheck();
  }

  dateChanged(date) {
    this.checkAndUpdate(date, PickerMode.day);
  }

  monthSelected(date) {
    this.checkAndUpdate(date, PickerMode.month);
  }

  yearSelected(date) {
    this.checkAndUpdate(date, PickerMode.year);
  }

  checkAndUpdate(date: Date, pickerMode?: PickerMode) {
    this.date = new Date(date);
    if (pickerMode === this.dateOptions.pickerMode) {
      this.updateInputField(this.date);
      this.fControl.setValue(date);
      this.picker.close();
    }
  }

  updateInputField(date: Date) {
    if (this.dateInputField) {
      if (date) {
        this.dateInputField.nativeElement.value = this.getDisplayText(date);
      } else {
        this.dateInputField.nativeElement.value = '';
      }
      this.changeDetectorRef.markForCheck();
    }
  }

  getDisplayText(date: Date) {
    switch (this.dateOptions.pickerMode) {
      case PickerMode.day:
        return date.toLocaleDateString(acaConfig.dateFormat, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      case PickerMode.month:
        return date.toLocaleDateString(acaConfig.dateFormat, {
          year: 'numeric',
          month: 'short',
        });
      case PickerMode.year:
        return date.toLocaleDateString(acaConfig.dateFormat, {
          year: 'numeric',
        });
    }
  }

  openPicker() {
    this.picker.open();
  }

  pickerOpened() {
    switch (this.dateOptions.pickerMode) {
      case PickerMode.day:
        break;
      case PickerMode.month:
      case PickerMode.year:
        break;
    }
  }

  getBtnClass() {
    switch (this.dateOptions.pickerMode) {
      case PickerMode.day:
        return '';
      case PickerMode.month:
      case PickerMode.year:
        return 'hide-btn';
    }
  }

  clear() {
    this.fControl.reset();
    this.date = null;
    this.displayDate.reset();
    this.updateInputField(null);
  }
}

export interface DateOptions {
  minDate?: Date;
  maxDate?: Date;
  startDate: Date;
  pickerMode: PickerMode;
  disabled: boolean;
  placeholder: string;
  timePickerLabel: string;
  optionalHint: string;
  startView: 'multi-year';
  showClearBtn?: boolean;
  value?: Date;
}

export enum PickerMode {
  day,
  month,
  year,
}
