import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimeService } from 'app/core/time.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent {
  @Input() date: Date | number;
  @Input() endDate: Date;
  @Input() showTime = false;

  constructor() {}

  getDateText() {
    if (this.endDate && this.date) {
      return TimeService.getDateIntervalText(
        new Date(this.date),
        new Date(this.endDate),
        this.showTime
      );
    } else if (this.date) {
      return TimeService.getDateText(new Date(this.date), this.showTime);
    }
  }
}
