import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { ScientistUser } from 'app/shared/models/user/scientistUser';
import { AuthService } from 'app/core/auth.service';
import { GroupRole } from 'app/groups/models/group-role';
import { IndustryUser } from 'app/shared/models/user/industryUser';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCardComponent implements OnInit {
  @Output()
  clicked = new EventEmitter();

  @Input()
  user: MondoUser;
  @Input()
  name: string;
  @Input()
  line0: string;
  @Input()
  line1: string;
  @Input()
  line2: string;
  @Input()
  line3: string;
  @Input()
  profilePictureUrl: string;
  @Input() userId: string;
  @Input() role;
  info: string;
  description: string;
  groupRoles = GroupRole;

  constructor() {}

  ngOnInit() {
    this.getInfo(this.user);
  }

  getInfo(user: MondoUser) {
    if (AuthService.isUserOfScientistType(user.type)) {
      this.info = `${
        (user as ScientistUser) &&
        (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.academicTitle
          ? (user as ScientistUser).personalDetails.academicTitle
          : ''
      } ${
        (user as ScientistUser) &&
        (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.companyName
          ? (user as ScientistUser).personalDetails.companyName
          : ''
      }`;

      this.description =
        this.role === GroupRole.groupRole0 &&
        (user as ScientistUser) &&
        (user as ScientistUser)['intro'] &&
        (user as ScientistUser)['intro'].text;
    }
    if (AuthService.isUserOfCompanyType(user.type)) {
      this.info = '';
      this.description =
        this.role === GroupRole.groupRole0 &&
        (user as IndustryUser) &&
        (user as IndustryUser)['intro'] &&
        (user as IndustryUser)['intro'].text;
    }
  }
}
