import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommentService } from 'app/forum/services/comment.service';
import { MentionService } from 'app/mention/mention.service';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';
import { Guid } from 'app/shared/models';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-new-comment',
  templateUrl: './new-comment.component.html',
  styleUrls: ['./new-comment.component.scss'],
})
export class NewCommentComponent
  extends DestroyComponent
  implements AfterViewInit, OnInit
{
  @ViewChild('commentArea', { static: false }) inputEl: ElementRef;
  @Input()
  parrentCommentId: string;
  @Input()
  postId: string;
  @Output()
  commentAdded = new EventEmitter<void>();

  mentionConfig = {
    items: [], // Initialize with empty array
    triggerChar: '@',
    labelKey: 'displayName', // The key to display in the mention list
    disableSearch: false, // Set to true if you want to disable the search feature
    maxItems: 5, // Maximum number of items shown in the mention list
    // mentionSelect: (item) => {
    //   console.log('remove surname?', item);
    //   return `@${item.displayName}`;
    // },
  };
  allUsersForTagging$: Observable<MondoUser[]>;
  taggedUsers: MondoUser[] = [];
  loaded = false;

  commentMessage = '';
  commentImageLink = '';
  loading = false;
  imgGuid = Guid.newGuid();
  constructor(
    private commentService: CommentService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private mentionService: MentionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allUsersForTagging$ = this.mentionService.getUsersForTagging$().pipe(
      tap((users) => {
        this.mentionConfig.items = users;
        this.loaded = true;
      })
    );
  }

  ngAfterViewInit(): void {
    this.safeSubscribe(this.route.queryParams, (params) => {
      if (params.mode === 'comments') {
        this.inputEl.nativeElement.focus();
        this.cd.markForCheck();
      }
    });
  }

  onCurrentlyMentionedItemsChanged(event: any) {
    // Handle mention selection here
    // console.log('currentlyMentionedItems', event);
    // console.table(event);
    this.taggedUsers = event;
  }

  formatTaggedUserList(taggedUsers: any[]): string {
    return this.mentionService.formatTaggedUserList(taggedUsers);
  }

  get imageLinkPath(): string {
    return `/comment/${this.imgGuid}/imageLink`;
  }

  onImageUpload(url: string) {
    this.commentImageLink = url;
  }

  cannotSubmit() {
    return (
      this.loading ||
      (!this.commentMessage.trim() && !this.commentImageLink.trim())
    );
  }

  public handleKeyPress(event): void {
    event.preventDefault();
    if (event.ctrlKey && event.code === 'Enter') {
      this.submit(event);
    }
  }

  async submit(e) {
    e.preventDefault();
    if (this.cannotSubmit()) {
      return;
    }
    this.loading = true;
    const uniqueUserIdsSet = new Set(this.taggedUsers.map((user) => user.uid));
    const taggedUserIds = Array.from(uniqueUserIdsSet).filter(
      (userId) => userId !== undefined && userId !== null
    );
    await this.commentService.addComment(
      this.commentMessage,
      this.commentImageLink,
      this.postId,
      taggedUserIds,
      this.parrentCommentId ? this.parrentCommentId : undefined
    );
    this.commentAdded.emit();

    this.commentMessage = '';
    this.commentImageLink = '';
    this.taggedUsers = [];
    this.loading = false;
  }
}
