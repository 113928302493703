import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Job } from 'app/stepper/job/model/job';
import { JobApplicationHelperComponent } from '../job-application-helper/job-application-helper.component';
import { CvService } from 'app/core/cv.service';
import { take } from 'rxjs/operators';
import { ApplicationRegistration } from 'app/shared/models/applicationRegistration';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { CPRValidator, StrictEmailValidator } from '@helpers';
import { UserService } from 'app/core/user.service';
import { AuthService } from 'app/core/auth.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { SearchResultV2 } from 'app/shared/common/search.model';
import { Technique } from 'app/shared/models';

@Component({
  selector: 'app-job-application',
  templateUrl: './job-application.component.html',
  styleUrls: ['./job-application.component.scss'],
})
export class JobApplicationComponent implements OnInit {
  applicationForm: FormGroup;
  formSubmitted = false;
  formError = false;
  formErrorMessage = '';
  formLoading = false;
  userId = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { job: SearchResultV2 },
    private dialogRef: MatDialogRef<JobApplicationComponent>,
    private db: DAO,
    private cvService: CvService,
    private userService: UserService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.applicationForm = this.createFormGroup();
    this.populateForm();
  }

  private async populateForm() {
    const techniquesFilters = JSON.parse(
      this.data.job.customAttributes['techniquesFilters']
    ).map(
      (tech: Technique) => new Technique(tech.name, tech.id, tech.category)
    );

    this.cvService
      .getCurrentUsersPublishedCv()
      .pipe(take(1))
      .subscribe((cv) => {
        this.userId = cv.ownerId;
        const mostRecentExp = this.cvService.getRecentExperience(cv);
        const cvTechniques = cv.experience.techniques;
        this.applicationForm.get('mostRecentExp').setValue(mostRecentExp);
        this.applicationForm.get('cvTechniques').setValue(cvTechniques);
      });
    // console.log('this.data.job.customAttributes[techniques]', this.data.job.customAttributes['techniques']);
    this.applicationForm
      .get('cvr')
      .setValue(this.data.job.customAttributes['cvr']);
    this.applicationForm
      .get('email')
      .setValue(this.data.job.customAttributes['email']);
    this.applicationForm
      .get('jobTitle')
      .setValue(this.data.job.customAttributes['fullName']);
    this.applicationForm.get('jobTechniques').setValue(techniquesFilters);
    this.getApplicantsCPR()
      .pipe(take(1))
      .subscribe((cpr) => {
        this.applicationForm.get('cpr').setValue(cpr ? cpr.cpr : '');
      });
    // this.applicationForm.get('acceptConditions').setValue(false);
  }

  private getApplicantsCPR() {
    return this.userService.getUserCpr(this.authService.getCurrentUser().uid);
  }

  private createFormGroup() {
    return new FormGroup({
      cpr: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$'),
        CPRValidator,
      ]),
      mostRecentExp: new FormControl(''),
      cvTechniques: new FormControl(''),
      jobTechniques: new FormControl(''),
      cvr: new FormControl('', [Validators.required]),
      email: new FormControl('', [StrictEmailValidator]),
      jobTitle: new FormControl(''),
      date: new FormControl(''),
    });
  }

  retry(): void {
    this.formError = false;
    this.applicationForm.enable({
      onlySelf: false,
      emitEvent: true,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  applicationHelperDialog(job: Job): void {
    this.cancel();
    this.dialog.open(JobApplicationHelperComponent, {
      maxWidth: '760px',
      maxHeight: '98vh',
      width: '100%',
      panelClass: 'plan-dialog',
      data: { job: job },
    });
  }

  async createApplication() {
    this.applicationForm.disable({
      onlySelf: false,
      emitEvent: true,
    });
    this.formLoading = true;
    const cpr = this.applicationForm.get('cpr').value;
    const mostRecentExp = this.applicationForm.get('mostRecentExp').value;
    const cvTechniques = this.applicationForm.get('cvTechniques').value;
    const jobTechniques = this.applicationForm.get('jobTechniques').value;
    const cvr = this.applicationForm.get('cvr').value;
    const email = this.applicationForm.get('email').value;
    const jobTitle = this.applicationForm.get('jobTitle').value;
    const date = new Date().getTime();

    const applicationRegistration: ApplicationRegistration = {
      cpr,
      mostRecentExp,
      cvTechniques,
      jobTechniques,
      cvr,
      email,
      jobTitle,
      date,
    };
    (await this.db.ref(DataConstants.JOB_APPLICATION_RECORDS).push()).set(
      applicationRegistration
    );
    (await this.db.ref(DataConstants.JOB_APPLICANTS + this.data.job.id).push())
      .set(this.userId)
      .then(() => {
        this.formLoading = false;
        this.formSubmitted = true;
      })
      .catch((e) => {
        this.formLoading = false;
        this.formErrorMessage = 'somethingWentWrongAttemptingToApplyForJob';
        this.formError = true;
      });
  }
}
