import { acaConfig } from '../src/aca-config';
import { hardcodedValuesDK } from '../src/hardcodedValuesDK';
import { hardcodedValuesENG } from '../src/hardcodedValuesENG';
// const supportedLanguage =
//   navigator.language === 'da' || 'en' ? navigator.language : acaConfig.language;

export let hardcodedValues =
  acaConfig.language === 'da' ? hardcodedValuesDK : hardcodedValuesENG;

export function handleRemoteConfigValues(value: {
  [key: string]: any; //: remoteConfig.Value;
}) {
  Object.entries(value).forEach((val) =>
    changeHardcodedValue(val[0], val[1].asString())
  );
}

export function changeHardcodedValue(
  key: string,
  value: boolean | Number | string
) {
  try {
    const result = checkAndUseCorrectType(value);
    if (hardcodedValues.hasOwnProperty(key)) {
      hardcodedValues[key] = result;
    } else {
      console.error('unknown config -', key, ' : ', result);
    }
  } catch (error) {
    console.error('error in Try/Catch - changeHardcodedValue: ', key);
  }
}

function checkAndUseCorrectType(
  value: boolean | Number | string
): boolean | Number | string {
  if (value === 'true' || value === 'false') {
    return JSON.parse(value);
  }
  if (!isNaN(value as number)) {
    return Number(value);
  }
  if (typeof value === 'string') {
    return value;
  }
}

export const formatText = function (text: string, ...replacement: string[]) {
  return text.replace(/{(\d+)}/g, (match, number) => {
    return typeof replacement[number] !== 'undefined'
      ? replacement[number]
      : match;
  });
};
