export enum LogAction {
  cvPublished = 'cvPublished',
  cvPrinted = 'cvPrinted',
  jobPublished = 'jobPublished',
  userInvited = 'userInvited',
  userInvitedError = 'userInvitedError',
  suggestedFieldOfInterest = 'suggestedFieldOfInterest',
  suggestedCategory0 = 'suggestedCategory0',
  suggestedCategory1 = 'suggestedCategory1',
  suggestedCategory2 = 'suggestedCategory2',
  suggestedCategory3 = 'suggestedCategory3',
  suggestedCategory4 = 'suggestedCategory4',
  suggestedCategory5 = 'suggestedCategory5',
  suggestedTechnique = 'suggestedTechnique',
  suggestedNetworkSubtype = 'suggestedNetworkSubtype',
  suggestedSiteSubtype = 'suggestedSiteSubtype',
  suggestionAccepted = 'suggestionAccepted',
  suggestionAdded = 'suggestionAdded',
  search = 'search',
  error = 'error',
}

export class Log {
  constructor(public action: LogAction, public logMsg: string) {}
}

export class ErrorLog extends Log {
  constructor(error: Error) {
    const msg = error.message || '' + error;
    super(LogAction.error, msg);
  }
}

export class ExtendedLog extends Log {
  constructor(
    public action: LogAction,
    public logMsg: string,
    public extra: string
  ) {
    super(action, logMsg);
  }
}
