import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-delete-button',
  template: `
    <button
      style="min-height: 37.5px"
      mat-button
      color="warn"
      matTooltip="{{ 'delete' | preventHardcoding }} {{
        translatedTooltipItemText
      }}"
      (click)="canDelete ? deleteItem() : prepareForDelete()"
    >
      <mat-icon>delete</mat-icon>
      <span *ngIf="canDelete">{{ 'confirm' | preventHardcoding }}</span>
    </button>

    <button
      style="min-height: 37.5px"
      *ngIf="canDelete"
      mat-button
      (click)="cancel()"
    >
      {{ 'cancel' | preventHardcoding }}
    </button>
  `,
  styleUrls: ['./delete-button.component.scss'],
})
export class DeleteButtonComponent {
  @Input() translatedTooltipItemText: string;
  @Output() delete = new EventEmitter<boolean>();
  canDelete: boolean;

  prepareForDelete() {
    this.canDelete = true;
  }

  cancel() {
    this.canDelete = false;
  }

  deleteItem() {
    this.delete.emit(true);
    this.canDelete = false;
  }
}
