import { JobSituation } from '../../../shared/models/job-situation';
import { CvField } from './cv-field';
import { ContactInfo } from './contact-info';
import { MondoLocation } from '../../../stepper/job/model/mondoLocation';

export class PersonalDetails
  extends CvField
  implements JsonPersonalDetails<Date>
{
  public static fromJson({
    firstName,
    middleName,
    lastName,
    sex,
    subTitle,
    academicTitle,
    companyName,
    department,
    dob,
    address,
    pictureUrl,
    coverUrl,
    contactInfo,
    currentJobSituation,
    nationality,
    linkedin,
    twitter,
    github,
    orcId,
    cpr,
    videoUrl,
    graduateProgram,
    dimittendsats,
    akademikerkampagnen,
    exclude,
    disableChat,
  }: JsonPersonalDetails<number>): PersonalDetails {
    return new PersonalDetails(
      firstName,
      middleName,
      lastName,
      sex,
      subTitle,
      academicTitle,
      companyName,
      department,
      dob ? new Date(dob) : null,
      MondoLocation.fromJson(address),
      pictureUrl,
      coverUrl,
      contactInfo,
      currentJobSituation,
      nationality,
      linkedin,
      twitter,
      github,
      orcId,
      cpr,
      videoUrl,
      graduateProgram,
      dimittendsats,
      akademikerkampagnen,
      exclude,
      disableChat
    );
  }

  public static toJson(
    personalDetails: PersonalDetails
  ): JsonPersonalDetails<number> {
    return {
      firstName: personalDetails.firstName,
      middleName: personalDetails.middleName,
      lastName: personalDetails.lastName,
      sex: personalDetails.sex,

      subTitle: personalDetails.subTitle,
      academicTitle: personalDetails.academicTitle,
      companyName: personalDetails.companyName,
      department: personalDetails.department,
      dob: personalDetails.dob ? personalDetails.dob.getTime() : null,
      address: personalDetails.address,
      pictureUrl: personalDetails.pictureUrl,
      coverUrl: personalDetails.coverUrl,
      contactInfo: personalDetails.contactInfo,
      currentJobSituation: personalDetails.currentJobSituation,
      nationality: personalDetails.nationality,
      linkedin: personalDetails.linkedin,
      twitter: personalDetails.twitter,
      github: personalDetails.github,
      orcId: personalDetails.orcId,
      cpr: personalDetails.cpr,
      videoUrl: personalDetails.videoUrl,
      graduateProgram: personalDetails.graduateProgram,
      dimittendsats: personalDetails.dimittendsats,
      akademikerkampagnen: personalDetails.akademikerkampagnen,
      exclude: personalDetails.exclude,
      disableChat: personalDetails.disableChat
        ? personalDetails.disableChat
        : false,
    };
  }

  constructor(
    public firstName = '',
    public middleName = '',
    public lastName = '',
    public sex = '',
    public subTitle = '',
    public academicTitle = '',
    public companyName = '',
    public department = '',
    public dob: Date = null,
    public address: MondoLocation = new MondoLocation(),
    public pictureUrl = '',
    public coverUrl = '',
    public contactInfo: ContactInfo = new ContactInfo(),
    public currentJobSituation: JobSituation = JobSituation.employedAvailable,
    public nationality = '',
    public linkedin = '',
    public twitter = '',
    public github = '',
    public orcId = '',
    public cpr = null,
    public videoUrl = '',
    public graduateProgram = null,
    public dimittendsats = null,
    public akademikerkampagnen = null,
    public exclude = false,
    public disableChat = false
  ) {
    super(exclude);
  }
}
export interface JsonPersonalDetails<T> {
  firstName: string;
  middleName: string;
  lastName: string;
  sex: string;
  subTitle: string;
  academicTitle: string;
  companyName: string;
  department: string;
  dob: T;
  address: MondoLocation;
  pictureUrl: string;
  coverUrl: string;
  contactInfo: ContactInfo;
  currentJobSituation: JobSituation;
  nationality: string;
  linkedin: string;
  twitter: string;
  github: string;
  orcId: string;
  cpr: number;
  videoUrl: string;
  graduateProgram: boolean;
  dimittendsats: boolean;
  akademikerkampagnen: boolean;
  exclude: boolean;
  disableChat: boolean;
}
