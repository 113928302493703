import { PlaceInfo } from './place-info';
import { StatusContext } from '../../stepper/shared/model/mondo-status/status-context';
import { Experience } from './cv/experience';
import { ItemType } from '../../stepper/shared/model/ItemType';

export class Place implements IJsonPlace<Date> {
  public readonly type = ItemType.Place;

  public static fromJson({
    name, ownerId, created, lastUpdate, madePublic, placeInfo, experience,
  }: IJsonPlace<number>,
    key: string,
    status: StatusContext = new StatusContext()
  ): Place {
    return new Place(
      status,
      name,
      ownerId,
      key,
      created ? new Date(created) : null,
      lastUpdate ? new Date(lastUpdate) : null,
      madePublic ? new Date(madePublic) : null,
      PlaceInfo.fromJson(placeInfo),
      Experience.fromJson(experience || {}, key),
    );
  }

  public static toJson(place: Place): IJsonPlace<number> {
    return {
      name: place.name,
      ownerId: place.ownerId,
      key: place.key,
      created: place.created ? place.created.getTime() : null,
      lastUpdate: place.lastUpdate ? place.lastUpdate.getTime() : null,
      madePublic: place.madePublic ? place.madePublic.getTime() : null,
      placeInfo: place.placeInfo,
      experience: Experience.toJson(place.experience)
    };
  }

  constructor(
    public status: StatusContext = new StatusContext(),
    public name = '',
    public ownerId = '',
    public key = '',
    public created: Date = null,
    public lastUpdate: Date = null,
    public madePublic: Date = null,
    public placeInfo: PlaceInfo = new PlaceInfo(),
    public experience: Experience = new Experience()
  ) { }

}

interface IJsonPlace<T> {
  name: string;
  ownerId: string;
  key: string;
  created: T;
  lastUpdate: T;
  madePublic: T;
  placeInfo: PlaceInfo;
  experience;
}
