export class TimePeriod implements JsonTimePeriod<Date> {
  public static fromJson({ startDate, endDate, openEnded, startNow }: JsonTimePeriod<number> = {} as JsonTimePeriod<number>) {
    return new TimePeriod(
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
      !!openEnded,
      !!startNow
    );
  }

  public static toJson(timePeriod: TimePeriod): JsonTimePeriod<number> {
    return {
      startDate: timePeriod.startDate ? timePeriod.startDate.getTime() : null,
      endDate: timePeriod.endDate ? timePeriod.endDate.getTime() : null,
      openEnded: timePeriod.openEnded,
      startNow: timePeriod.startNow
    };
  }

  constructor(
    public startDate: Date = null,
    public endDate: Date = null,
    public openEnded = false,
    public startNow = false
  ) {}
}

export interface JsonTimePeriod<T> {
  startDate: T;
  endDate: T;
  openEnded: boolean;
  startNow: boolean;
}
