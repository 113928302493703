import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { CV } from '../../../shared/models/cv/cv';
import { Publication } from '../../../shared/models/cv/publication';

@Component({
  selector: 'app-cv-view-publications',
  templateUrl: './cv-view-publications.component.html',
  styleUrls: ['./cv-view-publications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvViewPublicationsComponent implements OnInit {
  @Input() cv: CV;
  constructor() { }

  ngOnInit() { }

  get publicationsList(): Publication[] {
    const pubs = this.cv
      ? this.cv.experience.publications.publicationsList
      : null;
    return pubs && pubs.length > 0 ? pubs.sort((a, b) =>
      this.getTimeSortNumber(a.date, b.date)
    ) : null;
  }

  private getTimeSortNumber(dateA: Date, dateB: Date): number {
    const aTime = dateA ? dateA.getTime() : Infinity;
    const bTime = dateB ? dateB.getTime() : Infinity;
    return bTime - aTime;
  }

  trackByFn(index, item) {
    return (item.pmId || item.key);
  }
}
