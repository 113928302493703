import { CvField } from './cv-field';

export class Intro extends CvField {
  public static fromJson({ text, exclude }): Intro {
    return new Intro(text, exclude);
  }
  constructor(public text = '', public exclude = false) {
    super(exclude);
  }
}
