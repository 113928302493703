import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/core/auth.service';

@Component({
  selector: 'app-publish-dialog',
  templateUrl: './publish-dialog.component.html',
  styleUrls: ['./publish-dialog.component.scss']
})
export class PublishDialogComponent implements OnInit {
  havePublicCV: boolean;
  constructor(
    private dialogRef: MatDialogRef<PublishDialogComponent>,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.havePublicCV = !!this.authService.getCurrentUser().publishedCv;
  }

  onUploadClicked() {
    this.dialogRef.close(true);
  }

  onCancelClicked() {
    this.dialogRef.close(false);
  }
}
