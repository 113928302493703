import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import {
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper/lib/interfaces/index';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperComponent implements OnInit {
  @Input() image;
  @Input() maintainAspectRatio: boolean;
  @Input() aspectRatio;
  @Input() resizeToWidth: number;
  @Input() resizeToHeight: number;
  @Input() compressQuality: number;
  @Input() containWithinAspectRatio: boolean;
  @Input() showPreview = false;
  @Input() roundCropper = false;
  croppedImage: string;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  transform: ImageTransform = {};

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    private imageCompress: NgxImageCompressService
  ) {}

  ngOnInit() {}

  async onSave() {
    this.dialogRef.close(
      this.base64toBlob(
        await this.imageCompress.compressFile(
          this.croppedImage,
          DOC_ORIENTATION.NotDefined,
          100,
          this.compressQuality
        )
      )
    );
  }

  onNewFile(event: any): void {
    this.image = event;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  base64toBlob(base64Data: string): Blob {
    const sliceSize = 1024;
    const byteCharacters = atob(
      base64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    );
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: '' });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // console.log(event, base64ToFile(event.base64));
  }
  imageLoaded() {}
  loadImageFailed() {
    this.dialogRef.close(null);
  }
}
