import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from 'app/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { MatButtonModule } from '@angular/material';

@NgModule({
  imports: [CommonModule, SharedPipesModule, MatButtonModule],
  declarations: [ConfirmDialogComponent],
  entryComponents: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
