import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { Upload } from 'app/shared/models';
import { AcaCarouselComponent } from '../aca-carousel/aca-carousel.component';

@Component({
  selector: 'app-uploaded-images-viewer',
  templateUrl: './uploaded-images-viewer.component.html',
  styleUrls: ['./uploaded-images-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadedImagesViewerComponent implements OnInit {
  @Input() uploads: Upload[];

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  public getImagesToPreviewFromUploads(): string[] {
    let result = [];
    if (this.uploads && this.uploads.length) {
      result = this.uploads
        .filter((upload) => {
          if (upload.type) {
            return upload.type.startsWith('image');
          } else {
            return /.(webp|jpg|jpeg|png|gif|bmp|tif|tiff|svg)$/.test(
              upload.name.toLowerCase()
            );
          }
        })
        .map((upload) => upload.url);
    }
    return result;
  }

  openImages(images: string[]) {
    const slideShow = [];
    images.forEach((upload) => {
      slideShow.push({ img: upload });
    });
    const config = {
      panelClass: 'job-carousel-dialog',

      data: { slides: slideShow },
    };

    this.dialog.open(AcaCarouselComponent, config);
  }
}
