import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { SiteService } from 'app/core/site.service';
import { IFilter } from 'app/shared/models';
import { Site } from 'app/shared/models/site';

@Component({
  selector: 'app-site-viewer-technique',
  templateUrl: './site-viewer-technique.component.html',
  styleUrls: ['../site-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteViewerTechniqueComponent {
  @Input() site!: Site;
  @Input() showAsFilters = false;
  showTags = true;

  constructor(private siteService: SiteService) {}

  get filters(): IFilter[] {
    return this.siteService.getFilters(this.site);
  }

  trackByFn(index, item) {
    return item.key;
  }
}
