import { AfterViewInit, Component, Input } from '@angular/core';
import { hardcodedValues } from 'hardcodedValues';

declare const FlipDown: any;
declare const confetti: any;
@Component({
  selector: 'app-countdown',
  template: `<div
    [class.hide-headings]="hideHeadings"
    [class.small-countdown]="small"
  >
    <div [attr.id]="flipdownId" class="flipdown"></div>
  </div>`,
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements AfterViewInit {
  @Input() date: Date;
  @Input() time: number;
  @Input() theme: 'light' | 'dark' = 'dark';
  @Input() hideHeadings = true;
  @Input() small = true;

  flipdownId = `flipdown-${Date.now()}+${this.randomInRange(55, 125)}`;
  myConfetti;
  private confettiCanvas: HTMLCanvasElement;
  constructor() {}

  ngAfterViewInit() {
    const correctedDateTime =
      this.date && this.date.getTime()
        ? this.date.getTime() / 1000
        : this.time
        ? this.time / 1000
        : null;

    // : new Date().getTime() / 1000 + 6;
    // : new Date().getTime() / 1000 + 86400 * 2 + 6;
    if (correctedDateTime) {
      setTimeout(() => {
        new FlipDown(correctedDateTime, this.flipdownId, {
          theme: this.theme,
          headings: [
            hardcodedValues.days,
            hardcodedValues.hours,
            hardcodedValues.minutes,
            hardcodedValues.seconds,
          ],
        })
          .start()
          .ifEnded(() => {
            if (this.isNotOlderthan(correctedDateTime)) {
              this.createConfettiCanvas();
              this.fanfare(this.myConfetti);
              this.stars(this.myConfetti, 3300);
              setTimeout(() => {
                this.removeConfettiCanvas();
              }, 6000);
            }
          }, 250);
      });
    }
  }
  isNotOlderthan(deadline: number): boolean {
    const now = new Date().getTime() / 1000 - 60;
    return deadline - now > -60;
  }

  fanfare(confetti) {
    this.randowmConfetti(confetti);
    this.randowmConfetti(confetti, 300);
    this.randowmConfetti(confetti, 450);
    this.randowmConfetti(confetti, 600);
    this.randowmConfetti(confetti, 1200);
    this.randowmConfetti(confetti, 1500);
    this.randowmConfetti(confetti, 2500);
  }
  randowmConfetti(confetti, delay = 0) {
    setTimeout(() => {
      confetti({
        angle: this.randomInRange(55, 125),
        spread: this.randomInRange(50, 70),
        particleCount: this.randomInRange(50, 100),
        origin: { y: 0.6 },
      });
    }, delay);
  }

  stars(confetti, delay = 0) {
    this.shoot(confetti, 0 + delay);
    this.shoot(confetti, 100 + delay);
    this.shoot(confetti, 200 + delay);
  }

  shoot(confetti, delay = 0) {
    const defaults = {
      spread: 360,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      shapes: ['star'],
      colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
    };
    setTimeout(() => {
      confetti({
        ...defaults,
        particleCount: 40,
        scalar: 1.2,
        shapes: ['star'],
      });

      confetti({
        ...defaults,
        particleCount: 10,
        scalar: 0.75,
        shapes: ['circle'],
      });
    }, delay);
  }

  createConfettiCanvas() {
    this.confettiCanvas = document.createElement('canvas');
    this.confettiCanvas.style.position = 'fixed';
    this.confettiCanvas.style.top = '0';
    this.confettiCanvas.style.left = '0';
    this.confettiCanvas.style.width = '100%';
    this.confettiCanvas.style.height = '100%';
    document.body.appendChild(this.confettiCanvas);

    this.myConfetti = confetti.create(this.confettiCanvas, {
      resize: true,
      useWorker: true,
    });
  }

  removeConfettiCanvas() {
    if (this.confettiCanvas) {
      this.confettiCanvas.remove();
      this.confettiCanvas = null;
    }
  }

  randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }
}
