import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { PlacesService } from 'app/admin-page/places/services/places.service';
import { Observable } from 'rxjs';
import { Place } from 'app/shared/models';

@Component({
  selector: 'app-place-card',
  templateUrl: './place-card.component.html',
  styleUrls: ['./place-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceCardComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() showActionBar = false;
  @Input() public = false;

  placeStream: Observable<Place>;
  editMode$: Observable<boolean>;

  constructor(private placesService: PlacesService) { }

  ngOnInit() {
    this.editMode$ = this.placesService.editMode$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id !== changes.id.currentValue && !changes.id.firstChange) {
      this.getPlace();
    }
  }

  getPlace() {
    if (this.public) {
      this.placeStream = this.placesService.getPublishedPlace(this.id);
    } else {
      this.placeStream = this.placesService.getDraftPlace(this.id);
    }
  }

  edit() {
    this.placesService.setEditorMode(true);
  }

  deletePlace() {
    this.placesService.removePlace(this.id);
  }

}
