import { take } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/core/auth.service';
import { SuggestionService } from 'app/core/suggestion.service';
import { StrictEmailValidator } from '@helpers';
import { hardcodedValues } from 'hardcodedValues';

@Component({
  selector: 'app-suggest-tag-dialog',
  templateUrl: './suggest-tag-dialog.component.html',
  styleUrls: ['./suggest-tag-dialog.component.scss'],
})
export class SuggestTagDialogComponent implements OnInit {
  @Input() path: string;
  @Input() title = 'suggestion';
  suggestionForm: FormGroup;
  formError = false;
  formErrorMessage = '';

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SuggestTagDialogComponent>,
    private suggestionService: SuggestionService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.suggestionForm = this.formBuilder.group({
      suggestion: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      email: new FormControl('', [Validators.required, StrictEmailValidator]),
      description: new FormControl(
        '',
        hardcodedValues.TagDescRequired ? [Validators.required] : []
      ),
      uid: new FormControl('', []),
    });
    const user$ = this.authService.getCurrentUser$();
    const loggedIn$ = this.authService.getUserStatus$();

    loggedIn$.pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        user$.pipe(take(1)).subscribe((user) => {
          this.suggestionForm.get('email').setValue(user.email);
          this.suggestionForm.get('uid').setValue(user.uid);
        });
      }
    });
  }

  sendSuggestion(): void {
    const suggestion = this.suggestionForm.get('suggestion').value;
    const email = this.suggestionForm.get('email').value;
    const description = this.suggestionForm.get('description').value;
    const uid = this.suggestionForm.get('uid').value;
    this.suggestionService.suggestTag(
      suggestion,
      email,
      description,
      uid,
      this.path
    );

    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
