import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iosinstall-instructions-dialog',
  templateUrl: './iosinstall-instructions-dialog.component.html',
  styleUrls: ['./iosinstall-instructions-dialog.component.scss'],
})
export class IOSInstallInstructionsDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
