export class MondoError extends Error {
  constructor(
    message: string,
    public errorType: MondoErrorTypes,
    public displayText: string
  ) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export enum MondoErrorTypes {
  EMAIL_ALREADY_EXISTS,
  USER_NOT_VERIFIED,
  USER_NOT_FOUND,
  WRONG_EMAIL_OR_PASSWORD,
  INVALID_EMAIL,
  ACCOUNT_DISABLED,
  UNKNOWN,
}
