import { Component } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { ReactionService } from 'app/core/reaction.service';
import { ReactionBaseComponent } from 'app/shared-ui/reaction-base/reaction-base.component';

@Component({
  selector: 'app-reaction',
  templateUrl: './reaction.component.html',
  styleUrls: ['./reaction.component.scss'],
})
export class ReactionComponent extends ReactionBaseComponent {
  constructor(reactionService: ReactionService, authService: AuthService) {
    super(reactionService, authService);
  }
}
