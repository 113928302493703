import { ItemStatus } from '../../db-strings/ItemStatus';
import { StatusContext } from '../../status-context';
import { BaseStatus } from '../base-status';

export class Deleted extends BaseStatus {
  handleCanPublish(): boolean {
    return false;
  }
  handlePublish(context: StatusContext): void {
    return;
  }
  handleGetDbString(): string {
    return super.handleGetDbString() + ItemStatus.deleted;
  }
  handleCanRePublish(): boolean {
    return true;
  }
  handleCanUnPublish(): boolean {
    return false;
  }
  handleRepublish(context: StatusContext): void {
    context.setStatus(ItemStatus.queuedForPublish);
  }
  getShortStatus(): string {
    return ItemStatus.deleted;
  }
}
