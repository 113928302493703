import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-cv-dialog',
  templateUrl: './leave-cv-dialog.component.html',
  styleUrls: ['./leave-cv-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveCvDialogComponent implements OnInit {
  @Input() cvKey: string;
  title = 'CanLeaveCVTitle';
  text = 'CanLeaveCVText';
  hint = 'CanLeaveCVHint';
  yesText = 'PublishCV';
  noText = 'LeaveCvBuilder';
  constructor(private dialogRef: MatDialogRef<LeaveCvDialogComponent>) {}

  ngOnInit() {}

  okOkClicked() {
    this.dialogRef.close(true);
  }

  okCancelClicked() {
    this.dialogRef.close(false);
  }
}
