import { Pipe, PipeTransform } from '@angular/core';
import { MondoUser } from '../models/user/mondoUser';

@Pipe({
  name: 'filterUsers'
})
export class FilterUsersPipe implements PipeTransform {

  transform(users: MondoUser[], args?: any): any {
    if (!users) {
      return users;
    }
    return users.filter((user) => this.applyFilter(user));
  }


  applyFilter(user) {
    return (user.publishedCv && user.personalDetails && user.personalDetails.address && user.personalDetails.address.latLng)
      || (user.company && user.company.location && user.company.location.latLng);
  }
}


