import { WorkExperience } from './work-experience';
import { Education } from './education';
import { ResearchStay } from './research-stay';
import { Country } from '../country';

export class SortedExperience {
  constructor(
    public exp: WorkExperience | Education | ResearchStay,
    public title: string,
    public department: string,
    public locationName: string,
    public country: Country,
    public isCurrent: boolean
  ) { }
}
