import { Injectable } from '@angular/core';
import { hardcodedValues } from 'hardcodedValues';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  constructor() {}

  public static getDateText(date: Date, showTime = false): string {
    let dateText = '';
    const checkedDate = new Date(date);

    dateText = checkedDate.toLocaleDateString(hardcodedValues.dateFormat, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    if (showTime) {
      dateText += `${
        hardcodedValues.datePrefix ? ` ${hardcodedValues.datePrefix} ` : ''
      }${checkedDate.getHours().toString().padStart(2, '0')}:${checkedDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    }
    return dateText;
  }

  public static getDateIntervalText(
    startDate: Date,
    endDate: Date,
    showTime = false
  ): string {
    let dateText = '';
    const checkedStartDate = new Date(startDate);
    const checkedEndDate = new Date(endDate);
    const from = checkedStartDate.toLocaleDateString(
      hardcodedValues.dateFormat,
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }
    );
    if (showTime) {
      dateText += `${from} ${
        hardcodedValues.datePrefix ? ` ${hardcodedValues.datePrefix} ` : ''
      }${checkedStartDate
        .getHours()
        .toString()
        .padStart(2, '0')}:${checkedStartDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    } else {
      dateText += from;
    }
    const to = checkedEndDate.toLocaleDateString(hardcodedValues.dateFormat, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    if (showTime) {
      dateText += ` - ${to} ${
        hardcodedValues.datePrefix ? ` ${hardcodedValues.datePrefix} ` : ''
      }${checkedEndDate.getHours().toString().padStart(2, '0')}:${checkedEndDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    } else {
      dateText += ` - ${to}`;
    }

    return dateText;
  }

  public static getHoursFromDate(date: Date): number {
    const result = new Date(date);
    return result.getHours();
  }

  public static getMinutesFromDate(date: Date): number {
    const result = new Date(date);
    return result.getMinutes();
  }

  public static incrementHoursAndSetMinutesForDate(
    date: Date,
    hours?: number,
    minutes?: number
  ): Date {
    const result = new Date(date);
    const currentHour = result.getHours();
    const currentMinutes = result.getMinutes();
    result.setHours(currentHour + hours);
    result.setMinutes(
      minutes
        ? minutes
        : this.setMinutesToNearestQuater(currentMinutes, currentHour)
    );
    return result;
  }

  private static setMinutesToNearestQuater(
    minutes: number,
    controlHours: number
  ) {
    if (minutes < 15) {
      return 15;
    }
    if (minutes < 30) {
      return 30;
    }
    if (minutes < 45) {
      return 45;
    }
    if (minutes < 60) {
      return controlHours === 23 ? 0 : 60;
    }
    return minutes;
  }

  public static setHoursAndMinutesForDate(
    date: Date,
    hours = 0,
    minutes = 0
  ): Date {
    const result = new Date(date);
    result.setHours(hours);
    result.setMinutes(minutes);
    return result;
  }

  public static addTimeSpanToDate(date: Date, days): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public static subtractTimeSpanToDate(date: Date, days): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  public static getYearTextFromDays(days: number): string {
    if (days <= 0 || undefined) {
      return '0 ' + hardcodedValues.yearss;
    } else {
      const years = Math.floor(days / 365);
      if (years === 1) {
        return '1 ' + hardcodedValues.year;
      } else {
        return `${years} ${hardcodedValues.yearss}`;
      }
    }
  }

  public static getYearTextFromYears(years: number): string {
    if (years <= 0 || undefined) {
      return '0 ' + hardcodedValues.yearss;
    } else {
      if (years === 1) {
        return '1 ' + hardcodedValues.year;
      } else {
        return `${years} ${hardcodedValues.yearss}`;
      }
    }
  }

  public static getMonthTextFromMonths(months: number): string {
    if (months <= 0 || undefined) {
      return '0 ' + hardcodedValues.monthss;
    } else {
      if (months === 1) {
        return '1 ' + hardcodedValues.month;
      } else {
        return `${months} ${hardcodedValues.monthss}`;
      }
    }
  }
}
