import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { getFiltersFromExperience } from '@helpers';
import { CV, IFilter } from 'app/shared/models';
import { CvStepId } from 'app/stepper/cv/model/cv-steps';

@Component({
  selector: 'app-cv-view-skills-wrap',
  templateUrl: './cv-view-skills-wrap.component.html',
  styleUrls: ['./cv-view-skills-wrap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CvViewSkillsWrapComponent {
  @Input() cv: CV;
  @Input() showAllTags = false;

  cvSteps = CvStepId;

  constructor() {}

  get filters(): IFilter[] {
    return getFiltersFromExperience(this.cv.experience);
  }

  trackByFn(index, item) {
    return item.key;
  }
}
