import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.auth.issuer,
  // issuer: 'https://iam.ebrains.eu/auth/realms/hbp',
  // issuer: 'https://auths.tobiaswaagefeldballe.dk/auth/realms/hbp',
  // clientId: environment.auth.clientId, // The "Auth Code + PKCE" client
  // clientId: 'academondo-demo', // The "Auth Code + PKCE" client
  clientId: environment.auth.clientId, // The "Auth Code + PKCE" client
  // clientId: 'academondo-community', // The "Auth Code + PKCE" client
  responseType: environment.auth.responseType,
  redirectUri: window.location.origin + '/login/',
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  scope: environment.auth.scope, // Ask offline_access to support refresh token refreshes
  silentRefreshTimeout: environment.auth.silentRefreshTimeout, // For faster testing
  timeoutFactor: environment.auth.timeoutFactor, // For faster testing
  sessionChecksEnabled: environment.auth.sessionChecksEnabled,
  showDebugInformation: environment.auth.showDebugInformation, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: environment.auth.clearHashAfterLogin, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: environment.auth.nonceStateSeparator, // Real semicolon gets mangled by IdentityServer's URI encoding
};
