import { Injectable } from '@angular/core';
import { DAO } from 'app/shared-services/db-access/dao';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { DataConstants } from '../shared/consts/dataConstants';
import { Country } from '../shared/models';

@Injectable()
export class CountryService {
  private countries: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>(
    []
  );
  constructor(private db: DAO) {
    this.db
      .list(DataConstants.COUNTRIES, ref => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        map((snapCountries: any) =>
          snapCountries.map(c => Country.fromJson(c.payload.val()))
        ),
        take(1)
      )
      .subscribe(contries => this.countries.next(contries));
  }

  public getCountries$(): Observable<Array<Country>> {
    return this.countries.asObservable().pipe(filter(list => !!list.length));
  }

  public getCountries(): Array<Country> {
    return this.countries.value;
  }

  public getCountryFromCode(country: string) {
    return this.countries.value.find(c => c.code === country);
  }
}
