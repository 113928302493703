import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Observable, interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { fromNow } from '@helpers';

@Pipe({
  name: 'lastSeenAsync',
  pure: false
})
export class LastSeenAsyncPipe extends AsyncPipe implements PipeTransform {
  value: Date;
  timer: Observable<string>;

  constructor(
    public ref: ChangeDetectorRef
  ) {
    super(ref);
  }

  transform(obj: any, args?: any[]): any {
    this.value = obj;
    if (!this.timer) {
      this.timer = this.getObservable(obj);
    }
    return super.transform(this.timer);
  }

  private getObservable(value): Observable<any> {
    return interval(20000).pipe(
      startWith(0),
      map(() => this.lastSeen(value))
    );
  }

  lastSeen(timestamp: number): string {
    return fromNow(timestamp);
  }
}
