import {
  Component,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NetworkRoles } from 'app/shared/models/networkRules';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { RoutingService } from 'app/core/routing.service';

@Component({
  selector: 'app-new-action-bar',
  templateUrl: './new-action-bar.component.html',
  styleUrls: ['./new-action-bar.component.scss'],
})
export class NewActionBarComponent implements OnInit, OnChanges, OnDestroy {
  static readonly userRoles = NetworkRoles;
  @Input() userRole: NetworkRoles;
  @Input() isPublished: boolean;
  @Input() itemName: string;
  @Input() actionBarType: string;
  @Input() itemId: string;
  @Input() editMode: boolean;
  @Output() edit: EventEmitter<string> = new EventEmitter();
  @Output() delete: EventEmitter<string> = new EventEmitter();
  @Output() members: EventEmitter<string> = new EventEmitter();
  @Output() terminate: EventEmitter<string> = new EventEmitter();
  @Output() administrative: EventEmitter<string> = new EventEmitter();
  @Output() invite: EventEmitter<string> = new EventEmitter();
  public urlId: string;
  public showBillboardList: EventEmitter<string> = new EventEmitter();
  destroy$: Subject<boolean> = new Subject();

  actionBarItems: Array<NewActionItem> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setupActions() {
    const params = this.route.snapshot.params;
    this.urlId =
      params.billboardId || params.networkId || params.siteId || params.cvId;
    this.actionBarItems = [];
    if (this.actionBarType === 'site') {
      this.setSiteActionItems();
    }
  }

  setSiteActionItems() {
    if (this.userRole) {
      this.actionBarItems.push(
        new NewActionItem('edit', 'edit', this.editItem.bind(this), true)
      );
      this.actionBarItems.push(
        new NewActionItem('delete', 'delete', this.deleteItem.bind(this), false)
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const key in changes) {
      if (
        !changes[key].firstChange &&
        changes[key].currentValue !== changes[key].previousValue
      ) {
        this.userRole =
          key === 'userRole' ? changes[key].currentValue : this.userRole;
        this.actionBarType =
          key === 'actionBarType'
            ? changes[key].currentValue
            : this.actionBarType;
      }
    }
    this.setupActions();
  }

  editItem(): void {
    this.edit.emit();
  }
  deleteItem(): void {
    this.delete.emit();
  }

  isActive(canActive, instruction: any[]): boolean {
    if (!instruction) {
      return false;
    }
    const step = this.route.snapshot.params.step;
    return (
      canActive &&
      this.router.isActive(
        this.router.createUrlTree(step ? [...instruction, step] : instruction),
        true
      )
    );
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}

export class NewActionItem {
  constructor(
    public title: string,
    public icon: string,
    public onClick: (data: any) => void,
    public canBeActive = true,
    public activationUrl?: string[]
  ) {}
}
