import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UserService } from 'app/core/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cpr',
  templateUrl: './cpr.component.html',
  styleUrls: ['./cpr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CprComponent implements OnInit {
  @Input() userId: string;
  cpr$: Observable<{ cpr: string }>;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.cpr$ = this.userService.getUserCpr(this.userId);
  }
}
