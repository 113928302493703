import { Group, IGroup } from '../../models/group';
import { GroupType } from '../../models/group-type';
import { GroupPrivacy } from '../../models/groupPrivacy';
import { GroupTags } from '../../models/groupTags';
import { MondoLocation } from '../../../stepper/job/model/mondoLocation';
import { GroupPermissions } from '../../models/groupPermissions';
import { Upload } from '../../../../../src/app/shared/models';

export class Community implements ICommunity<Date>, Group {
  public readonly groupType = GroupType.Communities;
  public static fromJson({
    name,
    created,
    ownerId,
    lastUpdate,
    coverUrl,
    videoUrl,
    website,
    tags,
    uploads,
    wantedDaysOfExperience0,
    wantedDaysOfExperience1,
    wantedDaysOfExperience2,
    wantedDaysOfExperience3,
    wantedDaysOfExperience4,
    wantedDaysOfExperience5,
    privacy,
    permissions,
    membersCount,
    logoUrl,
    location,
    description,
    publicityDate,
    key,
    forumId,
    ownerGroupId,
    ownerGroupType,
  }: ICommunity<number>): Community {
    return new Community(
      name,
      description,
      ownerId,
      key,
      lastUpdate ? new Date(lastUpdate) : null,
      created ? new Date(created) : null,
      publicityDate ? new Date(publicityDate) : null,
      membersCount,
      GroupPermissions.fromJson(permissions),
      privacy,
      logoUrl,
      coverUrl,
      videoUrl,
      location,
      website,
      tags ? GroupTags.fromJson(tags) : new GroupTags(),
      uploads ? uploads : [],
      wantedDaysOfExperience0,
      wantedDaysOfExperience1,
      wantedDaysOfExperience2,
      wantedDaysOfExperience3,
      wantedDaysOfExperience4,
      wantedDaysOfExperience5,
      forumId,
      ownerGroupId,
      ownerGroupType
    );
  }

  public static toJson(community: Community): ICommunity<number> | any {
    return {
      name: community.name,
      ownerId: community.ownerId,
      key: community.key,
      created: community.created ? community.created.getTime() : null,
      lastUpdate: community.lastUpdate ? community.lastUpdate.getTime() : null,
      publicityDate: community.publicityDate
        ? community.publicityDate.getTime()
        : null,
      coverUrl: community.coverUrl,
      description: community.description,
      location: MondoLocation.fromJson(community.location),
      logoUrl: community.logoUrl,
      permissions: GroupPermissions.toJson(community.permissions),
      privacy: community.privacy,
      tags: GroupTags.toJson(community.tags),
      uploads: community.uploads ? community.uploads : [],
      wantedDaysOfExperience0: community.wantedDaysOfExperience0
        ? community.wantedDaysOfExperience0
        : 0,
      wantedDaysOfExperience1: community.wantedDaysOfExperience1
        ? community.wantedDaysOfExperience1
        : 0,
      wantedDaysOfExperience2: community.wantedDaysOfExperience2
        ? community.wantedDaysOfExperience2
        : 0,
      wantedDaysOfExperience3: community.wantedDaysOfExperience3
        ? community.wantedDaysOfExperience3
        : 0,
      wantedDaysOfExperience4: community.wantedDaysOfExperience4
        ? community.wantedDaysOfExperience4
        : 0,
      wantedDaysOfExperience5: community.wantedDaysOfExperience5
        ? community.wantedDaysOfExperience5
        : 0,
      videoUrl: community.videoUrl,
      website: community.website,
      groupType: GroupType.Communities,
      forumId: community.forumId,
      ownerGroupId: community.ownerGroupId ? community.ownerGroupId : '',
      ownerGroupType: community.ownerGroupType ? community.ownerGroupType : '',
    };
  }
  constructor(
    public name: string,
    public description: string,
    public ownerId: string,
    public key: string,
    public lastUpdate: Date,
    public created: Date,
    public publicityDate: Date,
    public membersCount: number,
    public permissions: GroupPermissions,
    public privacy: GroupPrivacy,
    public logoUrl: string,
    public coverUrl: string,
    public videoUrl: string,
    public location: MondoLocation = new MondoLocation(),
    public website: string,
    public tags: GroupTags,
    public uploads: Upload[] = [],
    public wantedDaysOfExperience0 = 0,
    public wantedDaysOfExperience1 = 0,
    public wantedDaysOfExperience2 = 0,
    public wantedDaysOfExperience3 = 0,
    public wantedDaysOfExperience4 = 0,
    public wantedDaysOfExperience5 = 0,
    public forumId: string,
    public ownerGroupId: string,
    public ownerGroupType: GroupType
  ) {}
}

export interface ICommunity<D> extends IGroup<D> {}
