export enum GroupRole {
  groupRole0 = 'groupRole0',
  groupRole1 = 'groupRole1',
  groupRole2 = 'groupRole2',
  groupRole3 = 'groupRole3',
  groupRole4 = 'groupRole4',
  groupRole5 = 'groupRole5',
  groupRoleMember = 'groupRoleMember',
}

export interface GroupRoleHolder {
  userId: string;
  role: GroupRole;
}
