import { AccountPermission } from '../consts/accountPermission';

export class MondoPermission {
  permission: AccountPermission;
  icon: string;
  name: string;
  iconInv: string;

  static fromAccountPermission(permission: AccountPermission): MondoPermission {
    switch (permission) {
      case AccountPermission.basic:
        return new MondoPermission(
          AccountPermission.basic,
          '../../../assets/pictures/profile.png',
          'Basic',
          '../../../assets/pictures/profile.png'
        );
      case AccountPermission.extended:
        return new MondoPermission(
          AccountPermission.extended,
          '../../../assets/pictures/profile.png',
          'Extended',
          '../../../assets/pictures/profile.png'
        );
      case AccountPermission.full:
        return new MondoPermission(
          AccountPermission.full,
          '../../../assets/pictures/profile.png',
          'Full',
          '../../../assets/pictures/profile.png'
        );
      case AccountPermission.admin:
        return new MondoPermission(
          AccountPermission.admin,
          '../../../assets/pictures/profile.png',
          'Admin',
          '../../../assets/pictures/profile.png'
        );
      default:
        return new MondoPermission(
          AccountPermission.basic,
          '../../../assets/logos/Mayn.svg',
          'Basic',
          '../../../assets/pictures/profile.png'
        );
    }
  }
  private constructor(
    permission: AccountPermission,
    icon: string,
    name: string,
    iconInv: string
  ) {
    this.permission = permission;
    this.icon = icon;
    this.name = name;
    this.iconInv = iconInv;
  }
}
