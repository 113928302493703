import { MondoLocation } from '../../stepper/job/model/mondoLocation';
import { ItemType } from '../../stepper/shared/model/ItemType';

export class PlaceInfo implements IPlaceInfo {
  public static fromJson(
    {
      type,
      pictureUrl,
      name,
      address,
      websiteUrl,
      placesUrl2,
      email,
      phone,
      youtubeId,
      linkedin,
      info,
      coverUrl,
      logoUrl,
      placeType,
    }: IPlaceInfo = {} as PlaceInfo
  ) {
    return new PlaceInfo(
      type,
      pictureUrl,
      name,
      MondoLocation.fromJson(address),
      websiteUrl,
      placesUrl2,
      email,
      phone,
      youtubeId,
      linkedin,
      info,
      coverUrl,
      logoUrl,
      placeType
    );
  }

  constructor(
    public type = ItemType.Place,
    public pictureUrl = '',
    public name = '',
    public address: MondoLocation = new MondoLocation(),
    public websiteUrl = '',
    public placesUrl2 = '',
    public email = '',
    public phone = '',
    public youtubeId = '',
    public linkedin = '',
    public info = '',
    public coverUrl = '',
    public logoUrl = '',
    public placeType = ''
  ) {}
}

export interface IPlaceInfo {
  type: ItemType;
  pictureUrl: string;
  name: string;
  address: MondoLocation;
  websiteUrl: string;
  placesUrl2: string;
  email: string;
  phone: string;
  youtubeId: string;
  linkedin: string;
  info: string;
  coverUrl: string;
  logoUrl: string;
  placeType: string;
}
