import { Component, Inject, OnInit } from '@angular/core';
import { ViewMode } from 'app/stepper/shared/model/ViewMode';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-preview-site',
  template: `
    <app-site-viewer
      [siteId]="data?.key"
      [isShowActionBar]="false"
      [viewMode]="viewMode"
      [showPrint]="false"
      [showGoToPrintableSite]="true"
    >
    </app-site-viewer>
  `,
})
export class PreviewSiteComponent implements OnInit {
  public viewMode: ViewMode = ViewMode.PUBLISHED;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { key: string }) {}

  ngOnInit() {}
}
