import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private openPostBuilder$ = new BehaviorSubject({state: false, withRelations: false });
  public currentOpenPost = this.openPostBuilder$.asObservable();

  constructor() { }

  public openPostBuilder(pbm: PostbuilderModel) {
    this.openPostBuilder$.next(pbm);
  }
}

export interface PostbuilderModel {
  state: boolean;
  withRelations: boolean;
}
