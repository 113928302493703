export class Comment implements IComment<Date> {
  public static fromJson(comment: IComment<number>): Comment {
    return {
      id: comment.id,
      ownerId: comment.ownerId,
      message: comment.message,
      imageLink: comment.imageLink ? comment.imageLink : '',
      created: comment.created ? new Date(comment.created) : undefined,
      updated: comment.updated ? new Date(comment.updated) : undefined,
      subCommentsIds: comment.subCommentsIds
        ? Object.keys(comment.subCommentsIds)
        : [],
      parrentCommentId: comment.parrentCommentId,
      postId: comment.postId,
      taggedUserIds: comment.taggedUserIds,
    };
  }

  public static toJson(comment: Comment): IComment<number> {
    return {
      id: comment.id,
      ownerId: comment.ownerId,
      message: comment.message,
      imageLink: comment.imageLink ? comment.imageLink : '',
      created: comment.created ? comment.created.getTime() : null,
      updated: comment.updated ? comment.updated.getTime() : null,
      subCommentsIds: comment.subCommentsIds
        ? orderSubComments(comment.subCommentsIds)
        : [],
      parrentCommentId: comment.parrentCommentId || null,
      postId: comment.postId,
      taggedUserIds: comment.taggedUserIds,
    };
  }

  constructor(
    public id: string,
    public ownerId: string,
    public message: string,
    public imageLink: string,
    public created: Date,
    public updated: Date,
    public subCommentsIds: Array<string>,
    public parrentCommentId: string,
    public postId: string,
    public taggedUserIds: string[]
  ) {}
}

function orderSubComments(array: string[]) {
  const object = {};

  for (const key of array) {
    object[key] = true;
  }
  return object;
}

export interface IComment<D> {
  id: string;
  ownerId: string;
  message: string;
  imageLink: string;
  created: D;
  updated: D;
  subCommentsIds: Object;
  parrentCommentId: string;
  postId: string;
  taggedUserIds: string[];
}
