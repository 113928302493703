import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyComponent implements OnInit {
  @Input() privacy: GroupPrivacy;

  constructor() {}

  ngOnInit() {}

  get privacyIcon() {
    switch (this.privacy) {
      case GroupPrivacy.Public:
        return 'language';
      case GroupPrivacy.OpenPrivate:
        return 'lock_open';
      case GroupPrivacy.Private:
        return 'lock';
      case GroupPrivacy.InviteOnly:
        return 'remove_circle';
      default:
        break;
    }
  }
}
