import { Injectable } from '@angular/core';
import { encryptBase64 } from '@helpers';
import { Comment, IComment } from 'app/forum/models/comment';
import { DAO } from 'app/shared-services/db-access/dao';
import { Chat, ChatJson } from 'app/shared/models/chat/chat';
import { combineLatest as observableCombineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { DataConstants } from '../shared/consts/dataConstants';
import { AuthService } from './auth.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(
    private dao: DAO,
    public authService: AuthService,
    private fnsHelper: HelperService
  ) {}

  createChatRoomFN = this.fnsHelper.createFunctionPromise<
    { roomId: string },
    Chat
  >('createChatRoomOnCall');

  private getChatRoom(roomId: string): Observable<Chat> {
    return this.dao
      .object(DataConstants.CHAT_ROOMS + roomId)
      .valueChanges()
      .pipe(
        map((chatRoom: Chat) =>
          chatRoom && chatRoom.updated ? chatRoom : null
        )
      );
  }

  getChatRooms(): Observable<Chat[]> {
    return this.dao
      .list(DataConstants.CHAT_ROOMS)
      .snapshotChanges()
      .pipe(
        mergeMap((chatRoomIds) => {
          if (chatRoomIds.length === 0) {
            return of([]);
          }
          return observableCombineLatest(
            chatRoomIds.map((chatRoomSnap) =>
              this.getChatRoom(chatRoomSnap.key)
            )
          );
        }),
        map((rooms) => {
          return rooms.filter((room) => !!room);
        })
      );
  }

  getUserChatRooms(): Observable<Chat[]> {
    return this.dao
      .list(
        DataConstants.CHAT_USER_ROOMS + this.authService.getCurrentUser().uid
      )
      .snapshotChanges()
      .pipe(
        mergeMap((chatRoomIds) => {
          if (chatRoomIds.length === 0) {
            return of([]);
          }
          return observableCombineLatest(
            chatRoomIds.map((chatRoomSnap) =>
              this.getChatRoom(chatRoomSnap.key)
            )
          );
        }),
        map((rooms) => {
          return rooms
            .filter((room) => !!room)
            .map((room) => Chat.fromJson(room));
        })
      );
  }

  public getRoomIdFromUser(userId: string): string {
    const currentUser = this.authService.getCurrentUser().uid;
    if (userId && userId === currentUser) {
      return null;
    }
    const roomId =
      currentUser < userId
        ? currentUser + '_' + userId
        : userId + '_' + currentUser;
    const hashedRoomId = encryptBase64(roomId);
    // const dehashedRoomId = decryptBase64(hashedRoomId);

    return hashedRoomId;
  }

  public openOrCreateNewChatRoom(roomId: string) {
    return this.dao
      .object(DataConstants.CHAT_ROOMS + roomId)
      .snapshotChanges()
      .pipe(
        switchMap(async (snap) => {
          if (snap.payload.exists()) {
            const chat = snap.payload.val() as ChatJson<string>;
            return Chat.fromJson(chat);
          } else {
            return this.createChatRoomFN({ roomId: roomId }).then((chat) =>
              Chat.fromJson(chat)
            );
          }
        })
      );
  }
  public openChatRoom(roomId: string) {
    return this.dao
      .object(DataConstants.CHAT_ROOMS + roomId)
      .snapshotChanges()
      .pipe(
        switchMap(async (snap) => {
          if (snap.payload.exists()) {
            const chat = snap.payload.val() as ChatJson<string>;
            return Chat.fromJson(chat);
          } else {
            return Chat.fromJson(new Chat(roomId));
          }
        })
      );
  }

  public getChatRoomsMessages(
    chatRoomId: string,
    batch = 100,
    lastKey?: string
  ) {
    return this.dao
      .listData$(`${DataConstants.CHAT_ROOMS_MESSAGES}${chatRoomId}`, (ref) => {
        if (lastKey) {
          // console.log(lastKey);
          return ref.orderByKey().limitToLast(batch).endAt(lastKey);
        } else {
          return ref.orderByKey().limitToLast(batch);
        }
      })
      .pipe(
        // tap((messages) => {
        //   console.log('pre map: ', messages);
        // }),
        map((messages) =>
          messages.map((n) => {
            const messageJson = n.data as IComment<number>;
            return Comment.fromJson(messageJson);
          })
        )
        // tap((messages) => {
        //   console.log('post map: ', messages);
        // })
      );
  }

  // public getChatUploads(chatRoomId: string) {
  //   return this.dao.list$<string>(
  //     `${DataConstants.CHAT_ROOM_UPLOADS}${chatRoomId}`
  //   );
  // }

  public sendMessage(
    chatMessage: string,
    chatRoomId: string,
    imageLink = null
  ) {
    const message = new Comment(
      this.dao.createPushId(),
      this.authService.getCurrentUser().uid,
      chatMessage,
      imageLink,
      new Date(),
      new Date(),
      null,
      null,
      chatRoomId,
      []
    );
    const promises = [];
    promises.push(this.saveLastMessageOnChatRoom(chatRoomId, message));
    promises.push(this.saveMessage(chatRoomId, message));
    return Promise.all(promises);
  }

  private saveLastMessageOnChatRoom(chatRoomId: string, message: Comment) {
    return this.dao
      .ref(DataConstants.CHAT_ROOMS)
      .child(chatRoomId)
      .update({
        lastMessage: message.message,
        updated: message.updated ? message.updated.getTime() : null,
      });
  }
  private saveMessage(chatRoomId: string, message: Comment) {
    return this.dao
      .object(DataConstants.CHAT_ROOMS_MESSAGES + chatRoomId + '/' + message.id)
      .set(Comment.toJson(message))
      .then(() => message);
  }
}
