import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { UserService } from 'app/core/user.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/auth.service';
import { AccountType } from 'app/shared/consts/accountType';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-chat-user-viewer',
  templateUrl: './chat-user-viewer.component.html',
  styleUrls: ['./chat-user-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatUserViewerComponent implements OnInit {
  @Input() userId: string;
  @Input() showName: boolean;
  user$: Observable<MondoUser | any>;
  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.user$ = this.userService
      .getUserByUid$(this.userId)
      .pipe(map((user) => (user ? user : {})));
  }

  showPreview($event, user: MondoUser): void {
    // $event.stopPropagation();
    // $event.preventDefault();
    this.authService.showPreview(user);
  }

  getDisplayText(user: MondoUser, noProfiletext: string) {
    if (user.displayName) {
      return user.displayName;
    } else {
      return noProfiletext;
    }
  }

  isUserOfCompanyType(type: AccountType) {
    return AuthService.isUserOfCompanyType(type);
  }
}
