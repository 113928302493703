import { Component, Input, OnInit } from '@angular/core';
import { RoutingModel } from 'app/app.routing-model';
import { GroupRoleService } from 'app/core/group-role.service';
import { Group } from 'app/groups/models/group';
import { GroupRole } from 'app/groups/models/group-role';
import { GroupType } from 'app/groups/models/group-type';
import { GroupService } from 'app/groups/services/group.service';
import { hardcodedValues } from 'hardcodedValues';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-group-role-viewer',
  templateUrl: './group-role-viewer.component.html',
  styleUrls: ['./group-role-viewer.component.scss'],
})
export class GroupRoleViewerComponent implements OnInit {
  @Input() userId!: string;

  communityGroupRoles$;
  eventGroupRoles$;
  ideaGroupRoles$;
  groupTypes = GroupType;

  constructor(
    private groupRoleService: GroupRoleService,
    private groupService: GroupService
  ) {}

  ngOnInit() {
    if (hardcodedValues.enableGroupRolesForCommunity) {
      this.communityGroupRoles$ = this.getUsersRole(
        this.userId,
        GroupType.Communities
      );
    }
    if (hardcodedValues.enableGroupRolesForEvent) {
      this.eventGroupRoles$ = this.getUsersRole(this.userId, GroupType.Events);
    }
    if (hardcodedValues.enableGroupRolesForIdea) {
      this.ideaGroupRoles$ = this.getUsersRole(this.userId, GroupType.Ideas);
    }
  }

  gotoGroup(group: Group) {
    switch (group.groupType) {
      case GroupType.Communities:
        return `${RoutingModel.communities.route}/${group.key}/about`;
      case GroupType.Events:
        return `${RoutingModel.events.route}/${group.key}/about`;
      case GroupType.Ideas:
        return `${RoutingModel.ideas.route}/${group.key}/about`;

      default:
        break;
    }
  }

  getUsersRole(
    userId: string,
    groupType: GroupType
  ): Observable<
    { role: string; groups: { group: Group; role: GroupRole }[] }[]
  > {
    return this.groupRoleService.getUserRolesByType$(userId, groupType).pipe(
      switchMap(async (groupRoles) => {
        const rolesArray = Object.keys(groupRoles || {}).map((groupKey) => ({
          groupKey,
          role: groupRoles[groupKey],
        }));

        const rolesMap = {};
        for (const { groupKey, role } of rolesArray) {
          const group = await this.groupService.getGroup(groupKey, groupType);

          if (!rolesMap[role]) {
            rolesMap[role] = [];
          }

          rolesMap[role].push({ group: group, role: role });
        }

        return Object.keys(rolesMap).map((role) => ({
          role: role,
          groups: rolesMap[role],
        }));
      })
    );
  }
}
