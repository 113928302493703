import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SearchResultV2 } from 'app/shared/common/search.model';
import { MembershipService } from 'app/shared-services/membership/membership.service';
import { RoutingModel } from 'app/app.routing-model';
import { GroupRelation } from 'app/groups/models/group-relation';
import { GroupType } from 'app/groups/models/group-type';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';
import { RoutingService } from 'app/core/routing.service';
import { getFiltersFromSearchResults } from '@helpers';
import { IFilter } from 'app/shared/models';
import { Observable } from 'rxjs';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { UserService } from 'app/core/user.service';

@Component({
  selector: 'app-idea-result-viewer',
  templateUrl: './idea-result-viewer.component.html',
  styleUrls: ['./idea-result-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeaResultViewerComponent implements OnInit {
  @Input() idea: SearchResultV2;
  @Input() isAuthed: boolean;
  @Input() ideaRelation: Map<string, GroupRelation> = new Map<
    string,
    GroupRelation
  >();

  owner$: Observable<MondoUser>;

  loading = false;
  filters: IFilter[] = [];
  canOpenDescription = true;
  viewEntireDescription = false;

  constructor(
    private userService: UserService,
    private membershipService: MembershipService,
    private routingService: RoutingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.owner$ = this.userService.getUserByUid$(
      this.idea.customAttributes['ownerId']
    );
    this.viewEntireDescription = !this.canOpenDescription;
    this.filters = getFiltersFromSearchResults(this.idea);
  }

  toggleDescription(e) {
    // e.preventDefault();
    e.stopPropagation();
    this.viewEntireDescription = !this.viewEntireDescription;
  }

  getStateClass() {
    return this.viewEntireDescription ? 'expanded' : 'collapsed';
  }

  category5(idea: SearchResultV2): string {
    if (idea.customAttributes['categories5Filters']) {
      const categories5 = JSON.parse(
        idea.customAttributes['categories5Filters']
      );
      return categories5[0] ? categories5[0] : {};
    }
    return '';
  }

  hasIdeaRelation(result: SearchResultV2): boolean {
    return this.getIdeaRelation(result) !== undefined;
  }

  getIdeaRelation(result: SearchResultV2): GroupRelation {
    const relation = this.ideaRelation.get(result.id);
    return relation;
  }

  gotoIdea(key: string) {
    this.routingService.navigateToUrlWithDataArray(
      `${RoutingModel.ideas.path}`,
      [key, 'about']
    );
  }

  applyIdea(e, result: SearchResultV2) {
    e.stopPropagation();
    this.loading = true;
    this.membershipService
      .requestMembership(result.id, GroupType.Ideas)
      .finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  canApplyIdea(result: SearchResultV2): boolean {
    return (
      this.getIdeaRelation(result) === undefined &&
      result.customAttributes['privacy'] !== GroupPrivacy.Public &&
      result.customAttributes['privacy'] !== GroupPrivacy.OpenPrivate
    );
  }

  joinIdea(e, result: SearchResultV2) {
    e.stopPropagation();
    this.loading = true;
    this.membershipService
      .becomeMember(result.id, GroupType.Ideas)
      .finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  canJoinIdea(result: SearchResultV2): boolean {
    return (
      (result.customAttributes['privacy'] === GroupPrivacy.Public ||
        result.customAttributes['privacy'] === GroupPrivacy.OpenPrivate) &&
      this.getIdeaRelation(result) === undefined
    );
  }

  canAcceptIdea(result: SearchResultV2): boolean {
    return this.getIdeaRelation(result) === GroupRelation.Invited;
  }

  acceptIdea(e, result: SearchResultV2) {
    e.stopPropagation();
    this.loading = true;
    this.membershipService
      .acceptMemberInvite(result.id, GroupType.Ideas)
      .finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  canVisitIdea(result: SearchResultV2) {
    return (
      result.customAttributes['privacy'] === GroupPrivacy.Public ||
      result.customAttributes['privacy'] === GroupPrivacy.OpenPrivate ||
      this.getIdeaRelation(result) === GroupRelation.Invited ||
      this.getIdeaRelation(result) === GroupRelation.Member ||
      this.getIdeaRelation(result) === GroupRelation.Administrator ||
      this.getIdeaRelation(result) === GroupRelation.Owner
    );
  }
}
