import { Pipe, PipeTransform } from '@angular/core';
import { formatText, hardcodedValues } from 'hardcodedValues';
import { environment } from 'environments/environment';

@Pipe({
  name: 'preventHardcodingFormatText',
})
export class PreventHardcodingFormatTextPipe implements PipeTransform {
  public transform(value: any, ...args: any[]): any {
    if (value in hardcodedValues) {
      return formatText(hardcodedValues[value], ...args);
    } else {
      if (!environment.production) {
        console.error(
          `
        No properly value set for key '${value}'.
        Please, check available keys.
        Feel free to add new one if you need.
        `
        );
      }
      return formatText(hardcodedValues[value], ...args);
    }
  }
}
