import { MondoFormGroup } from 'app/core/mondo-form-builder';

export class MondoStep {
  constructor(
    public stepIndex: number,
    public component: any,
    public title: string,
    public properties: Map<string, any>,
    public hideFooterBtns: [boolean, boolean] = [false, false]
  ) {}
}
