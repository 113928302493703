import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchResultV2 } from 'app/shared/common/search.model';

@Component({
  selector: 'app-job-application-helper',
  templateUrl: './job-application-helper.component.html',
  styleUrls: ['./job-application-helper.component.scss'],
})
export class JobApplicationHelperComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { job: SearchResultV2 }) {}

  ngOnInit() {}
}
