import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
  @Input() header: string;
  @Input() text: string;
  @Input() link: string;

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
