import {Injectable} from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { NameActionItem } from 'app/shared/models/name-action-item.type';

@Injectable()
export class ActionBarEventService {

  private eventStream = new ReplaySubject<NameActionItem | null>(1);

  emitEvent(event: NameActionItem | null) {
    this.eventStream.next(event);
  }

  onEvent(): Observable<NameActionItem | null> {
    return this.eventStream.asObservable();
  }

}
