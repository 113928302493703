import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tour-guide',
  templateUrl: './tour-guide.component.html',
  styleUrls: ['./tour-guide.component.scss'],
})
export class TourGuideComponent implements OnInit {
  showBlinky = false;

  constructor() {}

  ngOnInit() {}

  startGuide() {
    console.log('startguide!');
    this.showBlinky = true;
  }
}
