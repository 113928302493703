import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataConstants } from 'app/shared/consts/dataConstants';
import {
  SuggestedTechnique,
  SuggestedFieldOfInterest,
  SuggestedCategory0,
  SuggestedCategory1,
  SuggestedSubtype,
} from 'app/shared/models';
import { environment } from 'environments/environment';
import { formatText, hardcodedValues } from 'hardcodedValues';
import { DAO } from 'app/shared-services/db-access/dao';
import { acaConfig } from 'aca-config';
import { HelperService } from './helper.service';

@Injectable()
export class SuggestionService {
  constructor(
    private db: DAO,
    private snackBar: MatSnackBar,
    private helperService: HelperService
  ) {}

  private sendEmailFns = this.helperService.createFunctionPromise<
    { body: any; json?: any },
    any
  >(environment.sendEmail);

  suggestTag(
    title: string,
    email: string,
    description: string,
    userId: string,
    path: string
  ) {
    const newKey = this.db.createPushId();
    let newSuggestedTag;
    switch (path) {
      case DataConstants.SUGGESTEDFIELDOFINTEREST:
        newSuggestedTag = new SuggestedFieldOfInterest(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;
      case DataConstants.SUGGESTEDTECHNIQUE:
        newSuggestedTag = new SuggestedTechnique(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;
      case DataConstants.SUGGESTEDCATEGORY0:
        newSuggestedTag = new SuggestedCategory0(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;
      case DataConstants.SUGGESTEDCATEGORY1:
        newSuggestedTag = new SuggestedCategory1(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;
      case DataConstants.SUGGESTEDCATEGORY2:
        newSuggestedTag = new SuggestedCategory0(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;
      case DataConstants.SUGGESTEDCATEGORY3:
        newSuggestedTag = new SuggestedCategory0(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;
      case DataConstants.SUGGESTEDCATEGORY4:
        newSuggestedTag = new SuggestedCategory0(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;
      case DataConstants.SUGGESTEDCATEGORY5:
        newSuggestedTag = new SuggestedCategory0(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;
      case DataConstants.SUGGESTEDNETWORKSUBTYPE:
        newSuggestedTag = new SuggestedCategory0(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;
      case DataConstants.SUGGESTEDSITESUBTYPE:
        newSuggestedTag = new SuggestedSubtype(
          title,
          email,
          description,
          newKey,
          userId
        );
        break;

      default:
        break;
    }
    this.db.list(path).push(newSuggestedTag);
    this.salute();
    this.suggestionAddedEmail('Tag' + ': ' + title, email);
  }

  private salute(): void {
    this.snackBar.open(hardcodedValues.suggestionSalute, null, {
      duration: 5000,
      panelClass: ['snackbar-position-center'],
    });
  }

  suggestionAddedEmail(suggestion, email: string) {
    const text = formatText(hardcodedValues.PleaseReviewSuggestionFromX, email);
    const html = `<a href="${environment.academondoUrl}/Admin">
      <p>${text}</p>
      <p><b>${suggestion}</b></p>
    </a>`;
    const body = {
      to: acaConfig.reviewMail,
      from: acaConfig.senderMail,
      subject: `${hardcodedValues.Suggestions}`,
      html: html,
    };

    return this.sendEmailFns({ body: body });
  }
}
