import { Pipe, PipeTransform } from '@angular/core';
import { hardcodedValues } from 'hardcodedValues';
import { environment } from 'environments/environment';

@Pipe({
  name: 'preventHardcoding'
})
export class PreventHardcodingPipe implements PipeTransform {

  public transform(value: any, ...args: any[]): any {
    if (value in hardcodedValues) {
      return hardcodedValues[value];
    } else {
      if (!environment.production) {
        console.error(
          `
        No properly value set for key '${value}'.
        Please, check available keys.
        Feel free to add new one if you need.
        `
        );
      }
      return value;
    }
  }

}
