import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreventHardcodingPipe } from './pipes/prevent-hardcoding.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { LastSeenPipe } from './pipes/last-seen.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { SafeAngularLinkPipe } from './pipes/safe-angular-link.pipe';
import { PreventHardcodingFormatTextPipe } from './pipes/prevent-hardcoding-format-text.pipe';
import { LinkifyPipe } from './pipes/linkfy.pipe';
import { PriceDKPipe } from './pipes/price-dk.pipe';
import { LocaleDatePipe } from './pipes/locale-date.pipe';
import { JsonParsePipe } from './pipes/json-parse.pipe';
import { UrlSizeLimiterPipe } from './pipes/url-size-limiter.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ShortenFirstCharInEveryWordPipe } from 'app/shared/pipes/shorten-first-char-in-every-word.pipe';
import { LastSeenAsyncPipe } from './pipes/last-seen-async.pipe';
import { RenderMarkdownPipe } from './pipes/render-markdown.pipe';

@NgModule({
  declarations: [
    PreventHardcodingPipe,
    PreventHardcodingFormatTextPipe,
    SafeHtmlPipe,
    LastSeenPipe,
    LastSeenAsyncPipe,
    SortByPipe,
    SafeAngularLinkPipe,
    LinkifyPipe,
    PriceDKPipe,
    LocaleDatePipe,
    JsonParsePipe,
    UrlSizeLimiterPipe,
    FileSizePipe,
    ShortenFirstCharInEveryWordPipe,
    RenderMarkdownPipe,
  ],
  imports: [CommonModule],
  exports: [
    PreventHardcodingPipe,
    PreventHardcodingFormatTextPipe,
    SafeHtmlPipe,
    LastSeenPipe,
    LastSeenAsyncPipe,
    SortByPipe,
    SafeAngularLinkPipe,
    LinkifyPipe,
    PriceDKPipe,
    LocaleDatePipe,
    JsonParsePipe,
    UrlSizeLimiterPipe,
    FileSizePipe,
    ShortenFirstCharInEveryWordPipe,
    RenderMarkdownPipe,
  ],
})
export class SharedPipesModule {}
