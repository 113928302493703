import { Pipe, PipeTransform } from '@angular/core';
import { MondoPermission } from '../models/mondoPermission';

@Pipe({
  name: 'accountPermission',
})
export class AccountPermissionPipe implements PipeTransform {
  getPermissionText(permission: number) {
    return MondoPermission.fromAccountPermission(permission).name;
  }
  transform(value: any, ...args: any[]): any {
    return this.getPermissionText(value);
  }
}
