import { ISyncStatus } from './I-sync-status';
import { SyncStatus } from '../db-strings/sync-status';
import { DataConstants } from '../../../../../shared/consts/dataConstants';

export class OutOfSync implements ISyncStatus {
  getSyncStatus(): SyncStatus {
    return SyncStatus.inSync;
  }
  getDbString(): string {
    return SyncStatus.outOfSync + DataConstants.STATUS_DIVIDER;
  }
  isInSync(): boolean {
    return false;
  }
  isOutOfSync(): boolean {
    return true;
  }
}
