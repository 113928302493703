export enum LocalStorageKeys {
  StackedCommunities = 'academondo-stacked-communities',
  StackedIdeas = 'academondo-stacked-ideas',
  StackedEvents = 'academondo-stacked-events',
  UnfoldComments = 'academondo-unfold-comments',
  ShowSearchOneColumn = 'academondo-search-one-column',
  AdvancedSearchOptions = 'academondo-advanced-search-options',
  SideBarExpanded = 'academondo-side-bar-expanded',
  SideBarCollapsed = 'academondo-side-bar-collapsed',
  magicEmailForSignIn = 'magicEmailForSignIn',
  StackedWPPosts = 'academondo-stacked-wp-posts',
}
