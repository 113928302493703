import { Injectable } from '@angular/core';
import { translatedMonthText } from '@helpers';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from '../shared/consts/dataConstants';
import { ErrorLog, Log } from '../shared/models/log';
import { AuthService } from './auth.service';

@Injectable()
export class LoggingService {
  constructor(private auth: AuthService, private db: DAO) {}

  logActionByUser(log: Log) {
    const now = new Date();
    if (this.auth.getCurrentUser()) {
      return this.db
        .object(
          DataConstants.PER_USER_LOGS +
            this.auth.getCurrentUser().uid +
            '/' +
            log.action +
            '/' +
            now.getTime().toString()
        )
        .set(log);
    }
  }

  logAction(log: Log) {
    const now = new Date();
    const timestamp = `${translatedMonthText(
      now.getMonth()
    )}-${now.getFullYear()}`;

    if (this.auth.getCurrentUser()) {
      return this.db
        .object(
          DataConstants.LOGS +
            log.action +
            '/' +
            timestamp +
            '/' +
            this.db.createPushId()
        )
        .set(log.logMsg);
    }
  }

  logError(error: Error) {
    const log = new ErrorLog(error);
    return this.logActionByUser(log);
  }
}
