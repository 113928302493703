import { JobType } from '../../shared/model/job-type';

export class JobInfo {
  public static fromJson(
    { position, teaser, description, youtubeId, imageLink, imageUrl, about, jobType }: JobInfo = {} as JobInfo
  ) {
    return new JobInfo(position, teaser, description, youtubeId, imageLink, imageUrl, about, jobType);
  }
  constructor(
    public position = '',
    public teaser = '',
    public description = '',
    public youtubeId = '',
    public imageLink = '',
    public imageUrl = '',
    public about = '',
    public jobType: JobType = null
  ) {}
}
