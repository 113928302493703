import { ItemStatus } from '../../db-strings/ItemStatus';
import { StatusContext } from '../../status-context';
import { BaseStatus } from '../base-status';

export class QueuedForPublished extends BaseStatus {
  handleIsPublished(): boolean {
    return true;
  }
  handleGetDbString(): string {
    return super.handleGetDbString() + ItemStatus.queuedForPublish;
  }
  handleUnpublish(context: StatusContext): void {
    context.setStatus(ItemStatus.unpublished);
  }
  handleCanRePublish(): boolean {
    return true;
  }
  handleCanUnPublish(): boolean {
    return true;
  }
  handleRepublish(context: StatusContext): void {
    return;
  }
  handlePublish(context: StatusContext): void {
    context.setStatus(ItemStatus.published);
  }
  handleQueueForPublish(context: StatusContext): void {
    return;
  }
  handleGetDescription(): string {
    return 'queuedForPublishing';
  }
  handleCanMakepublicallyVisible(): boolean {
    return true;
  }
  getShortStatus(): string {
    return ItemStatus.queuedForPublish;
  }
}
