import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeAngularLink',
})
export class SafeAngularLinkPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return this.getAngularSafeLink(value);
  }

  private checkIfHttpIsPresent(url: string): boolean {
    return !/^http[s]?:\/\//.test(url);
  }

  private getAngularSafeLink(url: string): string {
    if (url !== '' && this.checkIfHttpIsPresent(url)) {
      return 'https://' + url;
    } else {
      return url;
    }
  }
}
