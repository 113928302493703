import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { JobService } from 'app/core/job.service';
import { Job } from 'app/stepper/job/model/job';

@Component({
  selector: 'app-job-viewer',
  templateUrl: './job-viewer.component.html',
  styleUrls: ['./job-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobViewerComponent {
  @Input() showMetaData = true;
  @Input() job: Job;

  constructor(private jobService: JobService) {}

  get filters() {
    return this.jobService.getFilters(this.job);
  }
}
