import { Injectable } from '@angular/core';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { IReaction } from 'app/shared/models/iReaction';
import { ReactionType } from 'app/shared/models/reactionType';
import { hardcodedValues } from 'hardcodedValues';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
const mapValues = require('lodash/mapValues');
const groupBy = require('lodash/groupBy');
const get = require('lodash/get');

@Injectable({
  providedIn: 'root',
})
export class ReactionService {
  emojis: string[];
  constructor(private dao: DAO, private authService: AuthService) {
    this.emojis = this.getCurrentListOfEmojis();
  }

  get emojiList(): string[] {
    return this.emojis;
  }

  private getCurrentListOfEmojis(): string[] {
    return Object.values(ReactionType)
      .map((emoji) => {
        return hardcodedValues[emoji + 'Text'] ? emoji : null;
      })
      .filter((emoji) => !!emoji);
  }

  getReactions(uid: string, type: string): Observable<IReaction[]> {
    return this.dao.object$<IReaction[]>(
      `${DataConstants.REACTIONS}${type}/${uid}`
    );
  }

  updateReaction(
    uid: string,
    type: string,
    reaction: ReactionType = ReactionType.ReactionLike
  ) {
    const user = this.authService.getCurrentUser();
    if (!user) {
      return;
    }
    const data: IReaction = { [user.uid]: reaction };
    this.dao.object(`${DataConstants.REACTIONS}${type}/${uid}`).update(data);
    this.dao
      .object(`${DataConstants.USER_REACTIONS}${user.uid}/${type}/${uid}`)
      .update(data);
  }

  removeReaction(uid: string, type: string) {
    const user = this.authService.getCurrentUser();
    if (!user) {
      return;
    }
    this.dao
      .object(`${DataConstants.REACTIONS}${type}/${uid}/${user.uid}`)
      .remove();
    this.dao
      .object(`${DataConstants.USER_REACTIONS}${user.uid}/${type}/${uid}`)
      .remove();
  }

  getUserReactions() {}

  countReactions(reactions: IReaction[]) {
    return mapValues(groupBy(reactions), 'length');
  }

  userReaction(reactions: IReaction[]) {
    const user = this.authService.getCurrentUser();
    if (!user) {
      return;
    }
    return get(reactions, user.uid);
  }
}
