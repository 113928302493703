import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-site-viewer-cover',
  templateUrl: './site-viewer-cover.component.html',
  styleUrls: ['./site-viewer-cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteViewerCoverComponent {
  @Input() coverUrl: string | undefined;
}
