import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StaticItemsService } from 'app/core/static-items.service';
import { ForumPost } from 'app/forum/models/forum-post';
import { PostFormService } from 'app/forum/services/post-form.service';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';
import {
  FieldOfInterest,
  Technique,
  Category0,
  Category1,
} from 'app/shared/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-post-step-topics',
  templateUrl: './post-step-topics.component.html',
  styleUrls: ['./post-step-topics.component.scss'],
})
export class PostStepTopicsComponent
  extends DestroyComponent
  implements OnInit
{
  private patchStreamInterest = new BehaviorSubject(null);
  private patchStreamTech = new BehaviorSubject(null);
  private patchStreamCategory0 = new BehaviorSubject(null);
  private patchStreamCategory1 = new BehaviorSubject(null);
  constructor(
    public postFormService: PostFormService,
    private staticItemsService: StaticItemsService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.safeSubscribe(
      this.postFormService.getFormChangeStream(),
      (post: ForumPost) => {
        this.patchStreamInterest.next(
          post.topic.fieldsOfInterests.map((entry) =>
            FieldOfInterest.fromJson(entry)
          )
        );
        this.patchStreamTech.next(
          post.topic.techniques.map((entry) => Technique.fromJson(entry))
        );
        if (post.topic.category0) {
          this.patchStreamCategory0.next(
            post.topic.category0.map((entry) => Category0.fromJson(entry))
          );
        }
        if (post.topic.category1) {
          this.patchStreamCategory1.next(
            post.topic.category1.map((entry) => Category1.fromJson(entry))
          );
        }
        this.cdr.markForCheck();
      }
    );
  }

  get fieldsOfInterests$() {
    return this.staticItemsService.getFieldOfInterest$();
  }

  get labTechnique$() {
    return this.staticItemsService.getTechniques$();
  }

  get techniquePatch$() {
    return this.patchStreamTech.asObservable();
  }

  get interestsPatch$() {
    return this.patchStreamInterest.asObservable();
  }

  get categories0$() {
    return this.staticItemsService.getCategories0$();
  }

  get categories1$() {
    return this.staticItemsService.getCategories1$();
  }

  get patchCategory0$() {
    return this.patchStreamCategory0.asObservable();
  }

  get patchCategory1$() {
    return this.patchStreamCategory1.asObservable();
  }
}
