import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private fns: AngularFireFunctions) {}

  public createFunctionPromise = <T = any, R = any>(
    name: string
  ): ((data: T) => Promise<R>) => {
    const callable = this.fns.httpsCallable(name);
    return (data: T) => {
      return callable(data).pipe(take(1)).toPromise();
    };
  };
}
