import { ChatBot } from './chatBot';
import { ExternalLink } from './externalLink';
export class GroupPermissions {
  public static fromJson({
    accessWithOutCode,
    onlyOwnerCanComment,
    onlyOwnerCanCreatePosts,
    showMaps,
    showMembers,
    forumEnabled,
    eventsEnabled,
    subgroupsEnabled,
    membersCanInvite,
    membersCanCreateEvents,
    membersCanCreateSubgroups,
    inviteAllMembersToNewSubgroup,
    showFiles,
    hideFromSearch,
    groupChatEnabled,
    groupKanbanEnabled,
    groupChatBot1: groupChatBot1,
    groupChatBot2: groupChatBot2,
    externalLinks,
    premium,
    premiumColor,
  }: GroupPermissions): GroupPermissions {
    return new GroupPermissions(
      accessWithOutCode,
      onlyOwnerCanComment,
      onlyOwnerCanCreatePosts,
      showMaps,
      showMembers,
      forumEnabled,
      eventsEnabled,
      subgroupsEnabled,
      membersCanInvite,
      membersCanCreateEvents,
      membersCanCreateSubgroups,
      inviteAllMembersToNewSubgroup,
      showFiles,
      hideFromSearch,
      groupChatEnabled,
      groupKanbanEnabled,
      groupChatBot1,
      groupChatBot2,
      externalLinks,
      premium,
      premiumColor
    );
  }

  public static toJson(permissions: GroupPermissions): GroupPermissions {
    return {
      accessWithOutCode: permissions.accessWithOutCode || false,
      onlyOwnerCanComment: permissions.onlyOwnerCanComment || false,
      onlyOwnerCanCreatePosts: permissions.onlyOwnerCanCreatePosts || false,
      showMaps: permissions.showMaps || false,
      showMembers: permissions.showMembers || false,
      forumEnabled: permissions.forumEnabled || false,
      eventsEnabled: permissions.eventsEnabled || false,
      subgroupsEnabled: permissions.subgroupsEnabled || false,
      membersCanInvite: permissions.membersCanInvite || false,
      membersCanCreateEvents: permissions.membersCanCreateEvents || false,
      membersCanCreateSubgroups: permissions.membersCanCreateSubgroups || false,
      inviteAllMembersToNewSubgroup:
        permissions.inviteAllMembersToNewSubgroup || false,
      showFiles: permissions.showFiles || false,
      hideFromSearch: permissions.hideFromSearch || false,
      groupChatEnabled: permissions.groupChatEnabled || false,
      groupKanbanEnabled: permissions.groupKanbanEnabled || false,
      groupChatBot1: permissions.groupChatBot1 || { text: '', key: '' },
      groupChatBot2: permissions.groupChatBot2 || { text: '', key: '' },
      externalLinks: permissions.externalLinks || [],
      premium: permissions.premium || false,
      premiumColor: permissions.premiumColor || '',
    };
  }

  constructor(
    public accessWithOutCode = false,
    public onlyOwnerCanCreatePosts = false,
    public onlyOwnerCanComment = false,
    public showMembers = true,
    public showMaps = true,
    public forumEnabled = true,
    public eventsEnabled = true,
    public subgroupsEnabled = false,
    public membersCanInvite = true,
    public membersCanCreateEvents = true,
    public membersCanCreateSubgroups = false,
    public inviteAllMembersToNewSubgroup = false,
    public showFiles = true,
    public hideFromSearch = false,
    public groupChatEnabled = false,
    public groupKanbanEnabled = false,
    public groupChatBot1: ChatBot = { text: '', key: '' },
    public groupChatBot2: ChatBot = { text: '', key: '' },
    public externalLinks: ExternalLink[] = [],
    public premium = false,
    public premiumColor = ''
  ) {}
}
