import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
  SolrResult,
  SearchFormV2,
  SearchResultV2,
  SearchableTypes,
  ISolrQueryFilter,
} from '../shared/common/search.model';
import { AuthService } from 'app/core/auth.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { HelperService } from 'app/core/helper.service';
import {
  createCouplingMatchQuery,
  createInviteQuery,
  createScienceMatchQuery,
} from 'app/shared/common/search-query';
import { AccountType } from 'app/shared/consts/accountType';
import { acaConfig } from 'aca-config';
import { MondoFormBuilder, MondoFormGroup } from './mondo-form-builder';
import {
  FieldOfInterest,
  Technique,
  Category0,
  Category1,
  Subtypes,
  EducationLevel,
  IFilter,
  Log,
  LogAction,
} from 'app/shared/models';
import { LanguageMultiSelector } from 'app/shared/models/languages/language-multiselector';
import { LoggingService } from './logging.service';
import { acaNames } from 'aca-names';
import { TimePeriod } from 'app/stepper/job/model/timePeriod';

@Injectable()
export class SearchService {
  public couplingResult: BehaviorSubject<SolrResult<SearchResultV2[]>> =
    new BehaviorSubject(null);
  public couplingResult$: Observable<SolrResult<SearchResultV2[]>> =
    this.couplingResult.asObservable();
  public inviteResult: BehaviorSubject<SolrResult<SearchResultV2[]>> =
    new BehaviorSubject(null);
  public inviteResult$: Observable<SolrResult<SearchResultV2[]>> =
    this.inviteResult.asObservable();
  public scienceMatchResults: BehaviorSubject<SolrResult<SearchResultV2[]>> =
    new BehaviorSubject(null);
  public scienceMatchResults$: Observable<SolrResult<SearchResultV2[]>> =
    this.scienceMatchResults.asObservable();

  private scienceMatchSearchHandler = this.fnsHelper.createFunctionPromise<
    any,
    SolrResult<SearchResultV2[]>
  >(environment.searchHandler);

  public static getDistance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  constructor(
    private formBuilder: MondoFormBuilder,
    private authService: AuthService,
    public fnsHelper: HelperService,
    private loggingService: LoggingService
  ) {}

  public scienceMatchInviteSearch(
    form: SearchFormV2,
    types: string[]
  ): Promise<SolrResult<SearchResultV2[]>> {
    // alter createInviteQuery() to enable specialCase.
    const specialCase = this.authService.isUserPartOfGivenAccountType([
      AccountType.medLabTech,
      AccountType.labTech,
      AccountType.industry,
    ]);

    const query = createInviteQuery(
      form,
      this.authService.logged,
      this.authService.isAdmin,
      types,
      specialCase
    );

    const partialUrl = environment.solrV2;
    return this.scienceMatchSearchHandler({
      state: 'search',
      query: query,
      partialUrl: partialUrl,
    });
  }

  public scienceMatchCouplingSearch(
    form: SearchFormV2,
    jobTypes: number[]
  ): Promise<SolrResult<SearchResultV2[]>> {
    const query = createCouplingMatchQuery(form, jobTypes);

    const partialUrl = environment.solrV2;
    return this.scienceMatchSearchHandler({
      state: 'search',
      query: query,
      partialUrl: partialUrl,
    });
  }
  public scienceMatchSearch(
    form: SearchFormV2
  ): Promise<SolrResult<SearchResultV2[]>> {
    // can user based on their userType see ex. users.
    const specialCase = this.authService.isUserPartOfGivenAccountType([
      AccountType.medLabTech,
      AccountType.labTech,
      AccountType.industry,
    ]);
    const query = createScienceMatchQuery(
      // check science-filters whether to show it as an option.
      form,
      this.authService.logged || acaConfig['enableProfilesForUnAuthedUsers'], // specialCase,
      this.authService.logged ||
        (acaConfig['showPostsInSearchWhenNotLoggedIn']
          ? acaConfig['showPostsInSearchWhenNotLoggedIn']
          : false),
      acaConfig.enableCommunities,
      acaConfig.enableEvents,
      !!acaNames.Idea,
      this.authService.canSearchForSites,
      (acaConfig.enableJobs && this.authService.logged) ||
        (acaConfig.enableJobs && acaConfig['showJobsInSearchWhenNotLoggedIn']
          ? acaConfig['showJobsInSearchWhenNotLoggedIn']
          : false),
      this.authService.isAdmin // specialCase,
    );

    this.loggingService.logAction(
      new Log(LogAction.search, this.createLogMessage(form))
    );

    const partialUrl = environment.solrV2;
    return this.scienceMatchSearchHandler({
      state: 'search',
      query: query,
      partialUrl: partialUrl,
    });
  }

  private getConditions(
    conditions: { field: string; value: string | string[] }[]
  ) {
    return conditions
      .map((solrQuery) => {
        if (solrQuery && solrQuery.value && solrQuery.value.length > 0) {
          return (
            solrQuery.field +
            ': ' +
            [].concat(solrQuery.value).join(' or ') +
            ' '
          );
        }
      })
      .filter((res) => !!res)
      .join('and ');
  }

  createLogMessage(form: SearchFormV2): string {
    const query = [];
    const fullName = form.fullName;
    query.push({ field: 'search', value: fullName });
    const desc = form.description;
    query.push({ field: 'description', value: desc });
    const interests = form.interests.map((tag) => tag.name);
    query.push({ field: acaNames.Fois, value: interests });
    const techs = form.techniques.map((tag) => tag.name);
    query.push({ field: acaNames.Techs, value: techs });
    const cat0 = form.category0.map((tag) => tag.name);
    query.push({ field: acaNames.Categories0, value: cat0 });
    const cat1 = form.category1.map((tag) => tag.name);
    query.push({ field: acaNames.Categories1, value: cat1 });
    const cat2 = form.category2.map((tag) => tag.name);
    query.push({ field: acaNames.Categories2, value: cat2 });
    const cat3 = form.category3.map((tag) => tag.name);
    query.push({ field: acaNames.Categories3, value: cat3 });
    const cat4 = form.category4.map((tag) => tag.name);
    query.push({ field: acaNames.Categories4, value: cat4 });
    const cat5 = form.category5.map((tag) => tag.name);
    query.push({ field: acaNames.Categories5, value: cat5 });
    const lang = form.language.map((tag) => tag.name);
    query.push({ field: 'Languages', value: lang });
    const type = form.type;
    query.push({ field: 'Type', value: type });
    const distance = form.radius ? '' + form.radius : '';
    query.push({ field: 'Distance', value: distance });
    return `${
      acaConfig['anonymousSearchLog']
        ? acaNames.auser
        : this.authService.getCurrentUser()
        ? this.authService.getCurrentUser().displayName
        : 'not signed in'
    } searched for ${this.getConditions(query)}`;
  }

  public checkPermissionSearch() {
    if (
      this.authService.getCurrentUser() &&
      !this.authService.currentUserhasPublicProfile
    ) {
      return this.authService.notEnoughPermissionOpenProfileBuilder();
    }
    if (!this.authService.canSearch) {
      return this.authService.notEnoughPermission();
    }
    return this.authService.canSearch;
  }

  private createEmptyFilters() {
    const filters: [] = [];
    filters['interests'] = [];
    filters['techniques'] = [];
    filters['category0'] = [];
    filters['category1'] = [];
    filters['category2'] = [];
    filters['category3'] = [];
    filters['category4'] = [];
    filters['category5'] = [];
    filters['siteSubtype'] = [];
    filters['networkSubtype'] = [];
    return filters;
  }

  public populateFilters(iFilter: IFilter) {
    const filters: [] = this.createEmptyFilters();
    return this.mutateFiltersArray(filters, iFilter);
  }

  private mutateFiltersArray(filters, iFilter: IFilter) {
    if (iFilter) {
      switch (iFilter.origin) {
        case 'interests':
          filters['interests'].push(
            new FieldOfInterest(iFilter.key, iFilter.value, '')
          );
          break;
        case 'techniques':
          filters['techniques'].push(
            new Technique(iFilter.value, iFilter.key, '')
          );
          break;
        case 'category0':
          filters['category0'].push(
            new Category0(iFilter.key, iFilter.value, '')
          );
          break;
        case 'category1':
          filters['category1'].push(
            new Category1(iFilter.key, iFilter.value, '')
          );
          break;
        case 'category2':
          filters['category2'].push(
            new Category0(iFilter.key, iFilter.value, '')
          );
          break;
        case 'category3':
          filters['category3'].push(
            new Category0(iFilter.key, iFilter.value, '')
          );
          break;
        case 'category4':
          filters['category4'].push(
            new Category0(iFilter.key, iFilter.value, '')
          );
          break;
        case 'category5':
          filters['category5'].push(
            new Category0(iFilter.key, iFilter.value, '')
          );
          break;
        case 'siteSubtype':
          filters['siteSubtype'].push(
            new Subtypes(iFilter.key, iFilter.value, '')
          );
          break;
        case 'networkSubtype':
          filters['networkSubtype'].push(
            new Subtypes(iFilter.key, iFilter.value, '')
          );
          break;
        default:
          break;
      }
    }
    return filters;
  }

  public populateFiltersWithArray(iFilters: IFilter[]) {
    let filters: [] = this.createEmptyFilters();
    if (iFilters) {
      iFilters.forEach((iFilter) => {
        filters = this.mutateFiltersArray(filters, iFilter);
      });
    }

    return filters;
  }

  public createSearchFormV2(filters): MondoFormGroup<SearchFormV2> {
    return this.formBuilder.group<SearchFormV2>({
      availability: '',
      fullName: '',
      facilityName: '',
      description: null,
      type: acaConfig.defaultSearchType,
      interests: this.formBuilder.array<FieldOfInterest[]>(
        filters['interests']
      ),
      techniques: this.formBuilder.array<Technique[]>(filters['techniques']),
      category0: this.formBuilder.array<Category0[]>(filters['category0']),
      category1: this.formBuilder.array<Category1[]>(filters['category1']),
      category2: this.formBuilder.array<Category0[]>(filters['category2']),
      category3: this.formBuilder.array<Category0[]>(filters['category3']),
      category4: this.formBuilder.array<Category0[]>(filters['category4']),
      category5: this.formBuilder.array<Category0[]>(filters['category5']),
      language: this.formBuilder.array<LanguageMultiSelector[]>([]),
      siteSubtype: this.formBuilder.array<Subtypes[]>(filters['siteSubtype']),
      networkSubtype: this.formBuilder.array<Category0[]>(
        filters['networkSubtype']
      ),
      educationalLevel: this.formBuilder.array<EducationLevel[]>([]),
      wantedDaysOfExperience0: new FormControl([0, 0]),
      wantedDaysOfExperience1: new FormControl([0, 0]),
      wantedDaysOfExperience2: new FormControl([0, 0]),
      wantedDaysOfExperience3: new FormControl([0, 0]),
      wantedDaysOfExperience4: new FormControl([0, 15000]),
      wantedDaysOfExperience5: new FormControl([0, 18]),
      experience0: undefined,
      experience1: undefined,
      experience2: undefined,
      experience3: undefined,
      experience4: undefined,
      experience5: undefined,
      jobTypes: [],
      radius: null,
      coordinates: null,
      searchSettings: null,
      rows: acaConfig.maxSearchResults,
      page: 0,
      timePeriod: this.formBuilder.group<TimePeriod>({
        startDate: new Date(),
        endDate: null,
        openEnded: new FormControl(false),
        startNow: new FormControl(false),
      }),
    });
  }
}
